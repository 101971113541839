import { useUrlState } from '@village/tools';
import { Button } from '@village/ui';
import parsePhoneNumber from 'libphonenumber-js';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';

import { PharmacyDetails } from './components';
import { GoogleMapsPreview } from './components/google-maps-preview';
import * as Styled from './styles';
import { CenteredSpinner } from 'components/centered-spinner';
import { Icon } from 'components/icon';
import { useMedications } from 'data/hooks/use-medication-query';
import { useGetPreferredPharmacy } from 'data/hooks/use-preferred-pharmacy';
import { useNativeControls } from 'hooks/use-native-controls';
import { useSetPreferredPharmacyIPCEvent } from 'hooks/use-pharmacy-update';
import { Countly } from 'modules/countly';
import { sendToNative } from 'modules/ipc';
import { PharmacyBox } from 'pages/medications/components/pharmacy-box';

const PharmacyDetailsContent: FC = () => {
    const [medicationId] = useUrlState('medicationId');
    const showMedicationPharmacy = Boolean(medicationId);
    const { data: preferredPharmacy, isLoading: isLoadingPharmacy } = useGetPreferredPharmacy(!showMedicationPharmacy);
    const { data: medicationData, isLoading: isLoadingMedication } = useMedications(true, showMedicationPharmacy);
    const medication = useMemo(
        () => medicationData?.results.find((item) => item.medication_entry_id === medicationId),
        [medicationData, medicationId]
    );

    const hasMedicationPharmacyData = !!medication?.pharmacy_facility;
    const address = hasMedicationPharmacyData ? medication?.pharmacy_facility?.address : preferredPharmacy?.address1;
    const city = hasMedicationPharmacyData ? medication?.pharmacy_facility?.city : preferredPharmacy?.city;
    const state = hasMedicationPharmacyData ? medication?.pharmacy_facility?.state : preferredPharmacy?.state;
    const zip = hasMedicationPharmacyData ? medication?.pharmacy_facility?.zipcode : preferredPharmacy?.zip;
    const phonenumber = hasMedicationPharmacyData ? medication?.pharmacy_facility?.phonenumber : preferredPharmacy?.phonenumber;
    const clinicalprovidername = hasMedicationPharmacyData
        ? medication?.pharmacy_facility?.name
        : preferredPharmacy?.clinicalprovidername;

    useNativeControls({
        ipcEvents: ['showBackButton'],
        source: 'health-records',
        title: hasMedicationPharmacyData ? 'Medication Pharmacy' : 'Preferred Pharmacy',
    });

    useSetPreferredPharmacyIPCEvent(!showMedicationPharmacy);

    useEffect(() => {
        Countly.addEvent({ key: 'medicationPharmacy' });
    }, []);

    const changePharmacy = useCallback(() => {
        sendToNative('openPharmacyDrawer', 'messaging');
    }, []);

    const formattedPhoneNumber = phonenumber ? parsePhoneNumber(phonenumber, 'US')?.number : '';
    const formattedZip = zip && zip.length > 5 ? `${zip?.slice(0, 5)}-${zip?.slice(5)}` : zip;
    const addressPieces = [address, city, state];
    const fullAddress = addressPieces
        .filter(Boolean)
        .join(', ')
        .concat(formattedZip ? ` ${formattedZip}` : '');

    const getDirections = useCallback(() => {
        Countly.addEvent({
            key: 'pharmacyDirections',
        });
        if (fullAddress) {
            sendToNative('directions', 'health-records', {
                formattedaddress: fullAddress,
            });
        }
    }, [fullAddress]);

    const callPharmacy = useCallback(() => {
        Countly.addEvent({
            key: 'pharmacyCall',
        });
        sendToNative('callPhoneNumber', 'health-records', {
            phone: phonenumber,
        });
    }, [phonenumber]);

    const hasAddress = Boolean(address || (city && state && zip));
    const hasPhoneNumber = Boolean(phonenumber);

    if (isLoadingPharmacy || isLoadingMedication) {
        return <CenteredSpinner />;
    }

    if (!showMedicationPharmacy && !preferredPharmacy) {
        return (
            <Styled.Container>
                <PharmacyBox />
            </Styled.Container>
        );
    }

    const HeroText = (
        <Styled.ContainerHero data-testid="pharmacy-box">
            {!hasAddress ? (
                <Styled.IconContainer data-testid="no-address-icon">
                    <Styled.IconCloud>
                        <Icon name="building" size={1.5} />
                    </Styled.IconCloud>
                </Styled.IconContainer>
            ) : null}
            <Styled.Text1>{clinicalprovidername}</Styled.Text1>
            <Styled.Text2>{address}</Styled.Text2>
            <Styled.Text2>
                {city}, {state}
            </Styled.Text2>
        </Styled.ContainerHero>
    );

    return (
        <div>
            {hasAddress && fullAddress ? (
                <Styled.GoogleMapsContainer>
                    <GoogleMapsPreview
                        locationAddress={fullAddress}
                        phoneNumber={formattedPhoneNumber}
                        placeName={clinicalprovidername}
                    />
                </Styled.GoogleMapsContainer>
            ) : null}
            <Styled.Container>
                {HeroText}
                {hasAddress || hasPhoneNumber ? (
                    <Styled.FlexActions>
                        {hasAddress ? (
                            <Styled.ActionBox onClick={getDirections}>
                                <Icon name="directions" size={4} />
                                <Styled.ActionText>Get directions</Styled.ActionText>
                            </Styled.ActionBox>
                        ) : null}
                        {hasPhoneNumber ? (
                            <Styled.ActionBox onClick={callPharmacy}>
                                <Icon name="call-us" size={4} />
                                <Styled.ActionText>Call pharmacy</Styled.ActionText>
                            </Styled.ActionBox>
                        ) : null}
                    </Styled.FlexActions>
                ) : null}
                {!hasMedicationPharmacyData ? (
                    <Styled.Grid>
                        <Button fullWidth={true} onClick={changePharmacy} size="medium" variant="primary">
                            Change preferred pharmacy
                        </Button>
                    </Styled.Grid>
                ) : null}
                <PharmacyDetails medicationPharmacy={medication?.pharmacy_facility} preferredPharmacy={preferredPharmacy} />
            </Styled.Container>
        </div>
    );
};

export { PharmacyDetailsContent };
