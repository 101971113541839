import { Button, Input } from '@village/ui';
import { Fragment, useState, useCallback, useMemo, useEffect } from 'react';
import type { FC } from 'react';

import * as Styled from './styles';
import { useMessaging } from 'hooks/use-messaging';
import { useNativeControls } from 'hooks/use-native-controls';
import { Countly } from 'modules/countly';
import { RoundedContainer } from 'pages/page-layouts';
import { useScheduling } from 'pages/scheduling-flow/hooks/use-scheduling';

const VisitReason: FC = () => {
    const {
        messagingState: { messageText },
        setMessagingFields,
    } = useMessaging();
    const [description, setDescription] = useState<string>(messageText ?? '');
    const { goToNextStep } = useScheduling();

    const handleDescription = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setDescription(event.currentTarget.value);
    }, []);

    const goToVisitOptions = useCallback((): void => {
        setMessagingFields({
            messageText: description,
        });
        goToNextStep();
    }, [description, goToNextStep, setMessagingFields]);

    const handleSkip = useCallback((): void => {
        goToNextStep();
    }, [goToNextStep]);

    useNativeControls({
        ipcEvents: ['showControls', 'hideBottomNavBar'],
        source: 'messaging',
    });

    useEffect(() => {
        Countly.addEvent({
            key: 'viewVisitReasonsPage',
            segmentation: {
                flow: 'scheduling',
                messagingVersion: 2,
                source: 'messaging',
            },
        });
    }, []);

    const headerText = useMemo(
        () => (
            <Fragment>
                <Styled.Header>What is the reason for your visit?</Styled.Header>
                <Styled.Subheader>
                    Please include symptoms and preferred time to help us find you the right visit.
                </Styled.Subheader>
            </Fragment>
        ),
        []
    );

    return (
        <RoundedContainer headerContent={headerText}>
            <Styled.Description>
                <Input
                    data-testid="visit-reason-input"
                    fullWidth={true}
                    maxLength={1000}
                    onChange={handleDescription}
                    placeholder="Ex. I have a fever"
                    rows={8}
                    type="textarea"
                    value={description}
                />
            </Styled.Description>
            <Styled.Next>
                <Button data-testid="next-button" fullWidth={true} onClick={goToVisitOptions}>
                    Next
                </Button>
            </Styled.Next>
            <Styled.Skip data-testid="skip-link" onClick={handleSkip}>
                Skip
            </Styled.Skip>
        </RoundedContainer>
    );
};

export { VisitReason };
