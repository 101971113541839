import { Text } from '@village/ui';
import { Fragment } from 'react';
import type { FC } from 'react';

import * as Styled from './styles';
import * as RootStyled from '../../styles';
import type { EncounterDetail } from 'types';
import { formatDate, parseDate } from 'utils';

interface Props {
    readonly encounter: EncounterDetail;
}

const Diagnoses: FC<Props> = ({ encounter }) => {
    const { diagnoses, provider, date } = encounter;
    const formattedDate = date ? formatDate(parseDate(date, 'yyyy-MM-dd'), 'shortDayWithFullDate') : '-';

    return (
        <Fragment>
            <RootStyled.SectionTitle>Your visit</RootStyled.SectionTitle>
            <Styled.Container data-testid="diagnoses">
                <Text type="body1">
                    You had a visit with {provider.displayname} on {formattedDate}.
                </Text>
                <Text type="body1">The following list includes diagnoses that were discussed at your visit.</Text>

                <Styled.List>
                    {diagnoses.map(({ diagnosisid, description }) => (
                        <Text key={diagnosisid} tag="li" type="body1">
                            {description}
                        </Text>
                    ))}
                </Styled.List>
            </Styled.Container>
        </Fragment>
    );
};

export { Diagnoses };
