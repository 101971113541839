import type { FC, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';

import { CenteredSpinner } from 'components/centered-spinner';
import { useAuthState } from 'hooks';

interface Props extends Omit<RouteProps, 'component'> {
    readonly element: ReactElement;
}

const PrivateRoute: FC<Props> = ({ element }) => {
    const { isLoggedIn, isLoggingIn } = useAuthState();

    if (isLoggedIn || window.location.pathname.includes('/auth/login')) {
        return element;
    }

    if (isLoggingIn) {
        return <CenteredSpinner />;
    }

    return (
        <Navigate
            replace={true}
            to={`/auth/login?redirectTo=${window.location.pathname}${encodeURIComponent(window.location.search)}`}
        />
    );
};

export { PrivateRoute };
