import { memo } from 'react';
import type { FC } from 'react';

import { LettersContent } from './letters-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <LettersContent />,
};

const LettersPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Documents" />);

export { LettersPage };
