import * as Sentry from '@sentry/react';

import type { WebToNativeIpcEvents, IpcSources, MessageBase, IPCBodyType } from './types';

const sendToNative = (event: WebToNativeIpcEvents, sender: IpcSources = 'health-records', body?: IPCBodyType): void => {
    const message: MessageBase<IPCBodyType> = {
        body,
        event,
        sender,
        timestamp: Date.now(),
    };

    try {
        const serializedMessage = JSON.stringify(message);
        if (window.webkit?.messageHandlers?.mobileListener?.postMessage) {
            window.webkit.messageHandlers.mobileListener.postMessage(serializedMessage);
        } else if (window.javascript_obj?.textFromWeb) {
            window.javascript_obj.textFromWeb(serializedMessage);
        } else if (window.ReactNativeWebView?.postMessage) {
            window.ReactNativeWebView.postMessage(serializedMessage);
        }
    } catch (error: unknown) {
        Sentry.captureException(error);
    }
};

const initializeIpc = (): void => {
    window.addEventListener('message', ({ origin, data }: MessageEvent<MessageBase>) => {
        if (origin === window.origin) {
            const customEvent = new CustomEvent<MessageBase>(data.event, { detail: data });
            document.dispatchEvent(customEvent);
        }
    });
};

export { initializeIpc, sendToNative };
