import { Text as VUIText } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
    padding: ${rem(16)};
`;

const SidePadding = styled.div`
    padding: 0 ${rem(16)};
`;

const SectionTitle = styled(VUIText).attrs(() => ({ type: 'h4' }))`
    margin: ${rem(32)} 0 ${rem(16)};
    font-weight: 600;
`;

const Grid = styled.div`
    padding: 1rem 0;
    border-bottom: 1px solid ${({ theme }) => theme.vui.colors.border.default.default};
`;

const FlexActions = styled.div`
    display: flex;
    justify-content: space-evenly;
    padding: 1rem 0;
    border-bottom: 1px solid ${({ theme }) => theme.vui.colors.border.default.default};
`;

const ActionBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ActionText = styled(VUIText).attrs(() => ({ type: 'caption' }))`
    color: ${({ theme }) => theme.vui.colors.icon.primary};
    margin-top: 0.5rem;
`;

const GoogleMapsContainer = styled.div`
    height: 10rem;
`;

const ContainerHero = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem;
    gap: 0.25rem;
    border-bottom: 1px solid ${({ theme }) => theme.vui.colors.border.default.default};
`;
const Title = styled(VUIText).attrs(() => ({ type: 'caption' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
    text-align: center;
`;

const Text1 = styled(VUIText).attrs(() => ({ type: 'h3' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
    text-align: center;
`;

const Text2 = styled(VUIText).attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.vui.colors.text.graySubtle};
    text-align: center;
`;

const IconContainer = styled.div`
    display: block;
    text-align: center;
`;

const IconCloud = styled.div`
    align-items: center;
    background-color: ${({ theme }) => theme.vui.colors.background.gray};
    padding: 0.75rem;
`;

export {
    Container,
    SidePadding,
    SectionTitle,
    Grid,
    FlexActions,
    ActionBox,
    ActionText,
    GoogleMapsContainer,
    ContainerHero,
    Title,
    Text1,
    Text2,
    IconContainer,
    IconCloud,
};
