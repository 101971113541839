import { useUrlState } from '@village/tools';
import { List, Tab } from '@village/ui';
import { Fragment, useEffect, useMemo } from 'react';
import type { FC } from 'react';

import { PharmacyBox } from './components/pharmacy-box';
import { MedicationsLoading } from './medication-loading';
import * as Styled from './styles';
import { Banner } from 'components/banner';
import { NoData } from 'components/no-data';
import { TabPanel, a11yProps } from 'components/tab-panel';
import { useMedications } from 'data/hooks/use-medication-query';
import { useGetPreferredPharmacy } from 'data/hooks/use-preferred-pharmacy';
import { useFeatureFlags } from 'hooks';
import { useNativeControls } from 'hooks/use-native-controls';
import { useSetPreferredPharmacyIPCEvent } from 'hooks/use-pharmacy-update';
import { appConfig } from 'modules/config';
import { Countly } from 'modules/countly';
import { MedicationEntry } from 'pages/medications/components/medication-entry';

const MedicationsContent: FC = () => {
    const { hasFeature } = useFeatureFlags();
    const pharmacyEnabled = hasFeature('preferredPharmacy.medicationList');
    const { data: medications, isLoading: isLoadingMedications } = useMedications();
    const { data: preferredPharmacy, isLoading: isLoadingPharmacy } = useGetPreferredPharmacy(pharmacyEnabled);
    const metadata = medications?.__metadata;
    const matchStrategy = metadata !== undefined ? metadata.identity_match_strategy : '';

    const banner = useMemo(
        () => (appConfig.showBanner ? <Banner message={`Athena Patient Matched Using: ${matchStrategy}`} /> : null),
        [matchStrategy]
    );

    useNativeControls({
        ipcEvents: ['showBackButton'],
        source: 'health-records',
        title: '',
    });

    useSetPreferredPharmacyIPCEvent(pharmacyEnabled);

    useEffect(() => {
        if (medications) {
            Countly.addEvent({
                key: 'viewMedications',
                segmentation: { noData: medications.results.length === 0 },
            });
        }
    }, [medications]);

    const [activeTab, setActiveTab] = useUrlState('activeTab');

    const handleChange = (_event: React.ChangeEvent<unknown>, newValue: number | string): void => {
        if (newValue === '1' || newValue === 1) {
            Countly.addEvent({
                key: 'medicationsInactive',
            });
        }
        setActiveTab(newValue.toString());
    };

    const activeMedications = useMemo(
        () => medications?.results.filter((medication) => medication.medication_type === 'ACTIVE'),
        [medications]
    );
    const historicalMedications = useMemo(
        () => medications?.results.filter((medication) => medication.medication_type === 'HISTORICAL'),
        [medications]
    );

    if (isLoadingMedications || isLoadingPharmacy) {
        return <MedicationsLoading />;
    }

    if (
        medications?.results.length === 0 ||
        activeMedications === undefined ||
        historicalMedications === undefined ||
        (activeMedications !== undefined &&
            activeMedications.length === 0 &&
            historicalMedications !== undefined &&
            historicalMedications.length === 0)
    ) {
        return (
            <Styled.RootContainer>
                <Styled.Title>Medications</Styled.Title>
                <Fragment>
                    {banner}
                    {pharmacyEnabled ? (
                        <Styled.PharmacyContainer>
                            <PharmacyBox pharmacy={preferredPharmacy} />
                        </Styled.PharmacyContainer>
                    ) : null}
                    <Styled.NoDataStyled>
                        <NoData iconName="medications" title="No Medications" />
                    </Styled.NoDataStyled>
                </Fragment>
            </Styled.RootContainer>
        );
    }

    return (
        <Styled.RootContainer>
            <Styled.Title>Medications</Styled.Title>
            {banner}
            {pharmacyEnabled ? (
                <Styled.PharmacyContainer>
                    <PharmacyBox pharmacy={preferredPharmacy} />
                </Styled.PharmacyContainer>
            ) : null}
            <Styled.StyledTabs onChange={handleChange} value={Number(activeTab)} variant="standard">
                <Tab label="Active" {...a11yProps(0)} data-testid="active-medications-tab" />
                <Tab label="Inactive" {...a11yProps(1)} data-testid="historical-medications-tab" />
            </Styled.StyledTabs>
            <Styled.ScrollableContainer>
                <TabPanel activeTab={Number(activeTab)} index={0}>
                    <List>
                        {activeMedications.map((medication) => (
                            <MedicationEntry key={medication.medication_entry_id} medication={medication} />
                        ))}
                    </List>
                </TabPanel>
                <TabPanel activeTab={Number(activeTab)} index={1}>
                    <List>
                        {historicalMedications.map((medication) => (
                            <MedicationEntry key={medication.medication_entry_id} medication={medication} />
                        ))}
                    </List>
                </TabPanel>
            </Styled.ScrollableContainer>
        </Styled.RootContainer>
    );
};

export { MedicationsContent };
