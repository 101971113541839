import type { Cognito } from '@village/tools';
import { Countly as CountlyTools } from '@village/tools';

import { appConfig } from './config';
import type { CountlyKey, CountlySegmentation } from 'types/countly';

const countlyProductKey = 'healthrecords_villagemedical';

const Countly = CountlyTools.setup<CountlyKey, CountlySegmentation, Record<string, unknown>>({
    beforeSend: (event) => ({
        ...event,
        key: `${event.segmentation?.source ?? countlyProductKey}.${event.key}`,
        segmentation: {
            ...event.segmentation,
            environment: appConfig.environment,
            origin: window.location.origin,
            pathname: window.location.pathname,
        },
    }),
});

const initCountly = (): void => {
    if (appConfig.environment === 'production') {
        Countly.init({
            app_key: appConfig.countlyAppKey,
            namespace: countlyProductKey,
            offline_mode: true,
            url: appConfig.countlyUrl,
        });
    }
};

const setCountlyUserDetails = (cognitoUserData: ReturnType<typeof Cognito.getUserDetails>): void => {
    const { firstName, lastName, birthdate, email, id, phoneNumber } = cognitoUserData;

    // Disable offline mode and set User ID as device_id for countly
    if (id) {
        Countly.disableOfflineMode(id);
    }

    // Reference: https://support.count.ly/hc/en-us/articles/360037441932-Web-analytics-JavaScript-
    Countly.setUserDetails({
        byear: birthdate ? new Date(birthdate).getFullYear() : undefined,
        email,
        name: firstName && lastName ? `${firstName} ${lastName}` : undefined,
        phone: phoneNumber,
    });
};

export { setCountlyUserDetails, Countly, initCountly };
