import {
    LAB_RESULT_ID_WITHOUT_ATTACHMENT_HEP_C,
    LAB_RESULT_ID_WITH_ATTACHMENT_HEMOGLOBIN,
    LAB_RESULT_ID_WITHOUT_ATTACHMENT_CMP,
    LAB_RESULT_ID_NO_RESULTS,
    LAB_RESULT_ID_NO_PROVIDER,
    LAB_RESULT_ID_NO_PROVIDER_NOTE,
} from './labs';
import { fillInLabResult } from 'data/hooks/helper-functions';
import type { ApiResponse } from 'types/api';
import type { LabResultDetails } from 'types/lab-result-details';

const LAB_RESULT_HEP_C_SUCCESS_RESPONSE: { readonly d: ApiResponse<LabResultDetails> } = {
    d: {
        __metadata: {
            identity_match_strategy: 'SomeStrategy',
        },
        results: [
            {
                document_pages: [],
                lab_result_datetime: '2019-02-13T15:12:00-05:00',
                lab_result_id: LAB_RESULT_ID_WITHOUT_ATTACHMENT_HEP_C,
                lab_result_note: 'When were you treated for hepatitis C?',
                lab_test_name: 'hepatitis C virus RNA, quant, PCR, serum or plasma',
                performing_lab_name: 'Village Medical - Laboratory',
                provider: {
                    entitytype: 'Person',
                    firstname: 'Stephanie',
                    lastname: 'Wiman',
                    npi: 1932531779,
                    provider_image_url: 'https://cdn2.hubspot.net/hubfs/6509886/Stephanie%20Wiman.jpg',
                    providerid: 16,
                    providertype: 'MD',
                    providertypeid: 'MD',
                    specialty: 'Family Medicine',
                },
                provider_id: 16,
                results: [
                    {
                        abnormal_flag: 'abnormal',
                        analyte_date: null,
                        analyte_id: 8456940,
                        analyte_name: 'hepatitis C antibody',
                        description: 'hepatitis C virus RNA, quant, PCR, serum or plasma',
                        loinc: null,
                        note: null,
                        reference_range: 'non-reactive',
                        result_status: 'final',
                        units: null,
                        value: 'reactive',
                    },
                    {
                        abnormal_flag: 'high',
                        analyte_date: null,
                        analyte_id: 8456941,
                        analyte_name: 'signal to cut-off',
                        description: 'hepatitis C virus RNA, quant, PCR, serum or plasma',
                        loinc: null,
                        note: "\nFollowing CDC recommendations (MMWR No. 62, 2013), this\npatient's HCV Antibody Reactive sample will be tested for\nthe presence of HCV RNA by a Nucleic Acid Amplification\nTest (NAAT) to determine if the patient has an active HCV\ninfection.\n",
                        reference_range: '<1.00',
                        result_status: 'final',
                        units: null,
                        value: '28.50',
                    },
                ],
                status: 'CLOSED',
            },
        ],
    },
};

const LAB_RESULT_HEMOGLOBIN_SUCCESS_RESPONSE: { readonly d: ApiResponse<LabResultDetails> } = {
    d: {
        __metadata: {
            identity_match_strategy: 'SomeStrategy',
        },
        results: [
            {
                document_pages: [
                    {
                        page_id: 1,
                        page_order: 1,
                        page_uri: `http://0.0.0.0:8000/health_records/v1/lab_results/${LAB_RESULT_ID_WITH_ATTACHMENT_HEMOGLOBIN}/pages/1`,
                    },
                    {
                        page_id: 2,
                        page_order: 2,
                        page_uri: `http://0.0.0.0:8000/health_records/v1/lab_results/${LAB_RESULT_ID_WITH_ATTACHMENT_HEMOGLOBIN}/pages/2`,
                    },
                ],
                lab_result_datetime: '2019-02-13T15:12:00-05:00',
                lab_result_id: LAB_RESULT_ID_WITH_ATTACHMENT_HEMOGLOBIN,
                lab_result_note: 'Your diabetes is doing really well. Keep up the good work.',
                lab_test_name: 'HbA1c (hemoglobin A1c), blood',
                performing_lab_name: 'Village Medical - Laboratory',
                provider: {
                    entitytype: 'Person',
                    firstname: 'Mai',
                    lastname: 'Somphet',
                    npi: 1962603126,
                    provider_image_url: null,
                    providerid: 32,
                    providertype: 'MD',
                    providertypeid: 'MD',
                    specialty: 'Family Medicine',
                },
                provider_id: 32,
                results: [
                    {
                        abnormal_flag: 'high',
                        analyte_date: null,
                        analyte_id: 8456959,
                        analyte_name: 'A1C w/EAG',
                        description: 'Hemoglobin A1c/Hemoglobin.total in Blood',
                        loinc: '4548-4',
                        note: null,
                        reference_range: '1.0-5.7',
                        result_status: 'final',
                        units: '%',
                        value: '7.3',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456960,
                        analyte_name: 'average blood glucose',
                        description: 'HbA1c (hemoglobin A1c), blood',
                        loinc: null,
                        note: '"According to ADA guidelines, hemoglobin A1C <7.0% represents optimal controlin\nnon-pregnant diabetic patients.  Different metrics may apply to specific patient\npopulations.  Standards of Medical Care in Diabetes-2013.  Diabetes Care. \n2013;36:s11-s66\n \nFor the purpose of screening for the presence of diabetes:\n \n<5.7%      Consistent with the absence of diabetes\n5.7-6.4%  Consistent with increased risk for diabetes (prediabetes)\n>or 6.5%  Consistent with diabetes\nDiabetic education recommended for A1c >7%\n \nCurrently, no consensus exists for use of hemoglobin A1C for diagnosis of\ndiabetes for children."',
                        reference_range: null,
                        result_status: 'final',
                        units: 'mg/dL',
                        value: '163',
                    },
                ],
                status: 'CLOSED',
            },
        ],
    },
};

const LAB_RESULT_CMP_SUCCESS_RESPONSE: { readonly d: ApiResponse<LabResultDetails> } = {
    d: {
        __metadata: {
            identity_match_strategy: 'SomeStrategy',
        },
        results: [
            {
                document_pages: [],
                lab_result_datetime: '2019-02-13T15:12:00-05:00',
                lab_result_id: LAB_RESULT_ID_WITHOUT_ATTACHMENT_CMP,
                lab_result_note: 'Your electrolytes, kidney function and liver function are normal.',
                lab_test_name: 'CMP, serum or plasma',
                performing_lab_name: 'Village Medical - Laboratory',
                provider: {
                    entitytype: 'Person',
                    firstname: 'Stephanie',
                    lastname: 'Wiman',
                    npi: 1932531779,
                    provider_image_url: 'https://cdn2.hubspot.net/hubfs/6509886/Stephanie%20Wiman.jpg',
                    providerid: 16,
                    providertype: 'MD',
                    providertypeid: 'MD',
                    specialty: 'Family Medicine',
                },
                provider_id: 16,
                results: [
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456942,
                        analyte_name: 'ALT',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '0-55',
                        result_status: 'final',
                        units: 'U/L',
                        value: '47',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456943,
                        analyte_name: 'AST',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '5-34',
                        result_status: 'final',
                        units: 'U/L',
                        value: '26',
                    },
                    {
                        abnormal_flag: 'high',
                        analyte_date: null,
                        analyte_id: 8456944,
                        analyte_name: 'BUN',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '9.8-20.1',
                        result_status: 'final',
                        units: 'mg/dL',
                        value: '21.4',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456945,
                        analyte_name: 'alk phos',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '40-150',
                        result_status: 'final',
                        units: 'unit/L',
                        value: '95',
                    },
                    {
                        abnormal_flag: 'high',
                        analyte_date: null,
                        analyte_id: 8456946,
                        analyte_name: 'glucose',
                        description: null,
                        loinc: null,
                        note: 'GLUCOSE REFERENCE RANGE LISTED ABOVE IS THE FASTING REFERENCE INTERVAL\nNON-FASTING REFERENCE INTERVAL: 73 mg/dL - 107 mg/dL',
                        reference_range: '70-99',
                        result_status: 'final',
                        units: 'mg/dL',
                        value: '139',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456947,
                        analyte_name: 'albumin',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '3.5-5.0',
                        result_status: 'final',
                        units: 'g/dL',
                        value: '4.0',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456948,
                        analyte_name: 'creatinine',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '0.57-1.11',
                        result_status: 'final',
                        units: 'mg/dL',
                        value: '0.87',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456950,
                        analyte_name: 'eGFR  non-african american',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: null,
                        result_status: 'final',
                        units: 'mL/min/1.73m2',
                        value: '>60',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456951,
                        analyte_name: 'eGFR  non-african american',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: null,
                        result_status: 'final',
                        units: 'mL/min/1.73m2',
                        value: '>60',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456952,
                        analyte_name: 'total bilirubin',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '0.2-1.2',
                        result_status: 'final',
                        units: 'mg/dL',
                        value: '0.3',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456953,
                        analyte_name: 'eGFR - african american',
                        description: null,
                        loinc: null,
                        note: '"The eGFR is calculated based on the IDMS-traceable MDRD study equation. The\nNKDEP presently recommends reporting estimated GFR values greater than or equal\nto 60 mL/min/1.73 m2 simply as "> or = 60 mL/min/1.73 m2", not an exact number.\nThe normal range for eGFR calculation is any value >60. "',
                        reference_range: null,
                        result_status: 'final',
                        units: 'mL/min/1.73m2',
                        value: '>60',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456954,
                        analyte_name: 'sodium',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '136-145',
                        result_status: 'final',
                        units: 'mEq/L',
                        value: '141',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456955,
                        analyte_name: 'potassium',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '3.5-5.1',
                        result_status: 'final',
                        units: 'mEq/L',
                        value: '5.1',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456956,
                        analyte_name: 'chloride',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '98-107',
                        result_status: 'final',
                        units: 'mmol/L',
                        value: '104',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456957,
                        analyte_name: 'total protein',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '6.4-8.3',
                        result_status: 'final',
                        units: 'g/dL',
                        value: '7.2',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456958,
                        analyte_name: 'calcium',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '8.4-10.2',
                        result_status: 'final',
                        units: 'mg/dL',
                        value: '10.1',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456959,
                        analyte_name: 'CO2',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '23.0-31.0',
                        result_status: 'final',
                        units: 'mmol/L',
                        value: '26.4',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456960,
                        analyte_name: 'anion gap',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: null,
                        result_status: 'final',
                        units: 'calc',
                        value: '11',
                    },
                ],
                status: 'CLOSED',
            },
        ],
    },
};

const LAB_RESULT_IN_REVIEW_SUCCESS_RESPONSE: { readonly d: ApiResponse<LabResultDetails> } = {
    d: {
        __metadata: {
            identity_match_strategy: 'SomeStrategy',
        },
        results: [
            {
                document_pages: [],
                lab_result_datetime: '2019-02-13T15:12:00-05:00',
                lab_result_id: LAB_RESULT_ID_WITHOUT_ATTACHMENT_CMP,
                lab_result_note: 'Your electrolytes, kidney function and liver function are normal.',
                lab_test_name: 'CMP, serum or plasma',
                performing_lab_name: 'Village Medical - Laboratory',
                provider: {
                    entitytype: 'Person',
                    firstname: 'Stephanie',
                    lastname: 'Wiman',
                    npi: 1932531779,
                    provider_image_url: 'https://cdn2.hubspot.net/hubfs/6509886/Stephanie%20Wiman.jpg',
                    providerid: 16,
                    providertype: 'MD',
                    providertypeid: 'MD',
                    specialty: 'Family Medicine',
                },
                provider_id: 16,
                results: [
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456942,
                        analyte_name: 'ALT',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '0-55',
                        result_status: 'final',
                        units: 'U/L',
                        value: '47',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456943,
                        analyte_name: 'AST',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '5-34',
                        result_status: 'final',
                        units: 'U/L',
                        value: '26',
                    },
                    {
                        abnormal_flag: 'high',
                        analyte_date: null,
                        analyte_id: 8456944,
                        analyte_name: 'BUN',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '9.8-20.1',
                        result_status: 'final',
                        units: 'mg/dL',
                        value: '21.4',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456945,
                        analyte_name: 'alk phos',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '40-150',
                        result_status: 'final',
                        units: 'unit/L',
                        value: '95',
                    },
                    {
                        abnormal_flag: 'high',
                        analyte_date: null,
                        analyte_id: 8456946,
                        analyte_name: 'glucose',
                        description: null,
                        loinc: null,
                        note: 'GLUCOSE REFERENCE RANGE LISTED ABOVE IS THE FASTING REFERENCE INTERVAL\nNON-FASTING REFERENCE INTERVAL: 73 mg/dL - 107 mg/dL',
                        reference_range: '70-99',
                        result_status: 'final',
                        units: 'mg/dL',
                        value: '139',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456947,
                        analyte_name: 'albumin',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '3.5-5.0',
                        result_status: 'final',
                        units: 'g/dL',
                        value: '4.0',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456948,
                        analyte_name: 'creatinine',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '0.57-1.11',
                        result_status: 'final',
                        units: 'mg/dL',
                        value: '0.87',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456950,
                        analyte_name: 'eGFR  non-african american',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: null,
                        result_status: 'final',
                        units: 'mL/min/1.73m2',
                        value: '>60',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456951,
                        analyte_name: 'eGFR  non-african american',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: null,
                        result_status: 'final',
                        units: 'mL/min/1.73m2',
                        value: '>60',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456952,
                        analyte_name: 'total bilirubin',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '0.2-1.2',
                        result_status: 'final',
                        units: 'mg/dL',
                        value: '0.3',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456953,
                        analyte_name: 'eGFR - african american',
                        description: null,
                        loinc: null,
                        note: '"The eGFR is calculated based on the IDMS-traceable MDRD study equation. The\nNKDEP presently recommends reporting estimated GFR values greater than or equal\nto 60 mL/min/1.73 m2 simply as "> or = 60 mL/min/1.73 m2", not an exact number.\nThe normal range for eGFR calculation is any value >60. "',
                        reference_range: null,
                        result_status: 'final',
                        units: 'mL/min/1.73m2',
                        value: '>60',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456954,
                        analyte_name: 'sodium',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '136-145',
                        result_status: 'final',
                        units: 'mEq/L',
                        value: '141',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456955,
                        analyte_name: 'potassium',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '3.5-5.1',
                        result_status: 'final',
                        units: 'mEq/L',
                        value: '5.1',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456956,
                        analyte_name: 'chloride',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '98-107',
                        result_status: 'final',
                        units: 'mmol/L',
                        value: '104',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456957,
                        analyte_name: 'total protein',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '6.4-8.3',
                        result_status: 'final',
                        units: 'g/dL',
                        value: '7.2',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456958,
                        analyte_name: 'calcium',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '8.4-10.2',
                        result_status: 'final',
                        units: 'mg/dL',
                        value: '10.1',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456959,
                        analyte_name: 'CO2',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '23.0-31.0',
                        result_status: 'final',
                        units: 'mmol/L',
                        value: '26.4',
                    },
                    {
                        abnormal_flag: null,
                        analyte_date: null,
                        analyte_id: 8456960,
                        analyte_name: 'anion gap',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: null,
                        result_status: 'final',
                        units: 'calc',
                        value: '11',
                    },
                ],
                status: 'REVIEW',
            },
        ],
    },
};

const LAB_RESULT_NO_RESULTS_SUCCESS_RESPONSE: { readonly d: ApiResponse<LabResultDetails> } = {
    d: {
        __metadata: {
            identity_match_strategy: 'SomeStrategy',
        },
        results: [
            {
                document_pages: [],
                lab_result_datetime: '',
                lab_result_id: LAB_RESULT_ID_NO_RESULTS,
                lab_result_note: null,
                lab_test_name: '',
                performing_lab_name: null,
                provider: null,
                provider_id: null,
                results: [],
                status: 'CLOSED',
            },
        ],
    },
};

const LAB_RESULT_NO_PROVIDER_SUCCESS_RESPONSE: { readonly d: ApiResponse<LabResultDetails> } = {
    d: {
        __metadata: {
            identity_match_strategy: 'SomeStrategy',
        },
        results: [
            {
                document_pages: [],
                lab_result_datetime: '2017-04-25T15:18:00-05:00',
                lab_result_id: LAB_RESULT_ID_NO_PROVIDER,
                lab_result_note: 'Your urine test was normal',
                lab_test_name: 'urinalysis, dipstick',
                performing_lab_name: 'Village Medical - Laboratory',
                provider: null,
                provider_id: null,
                results: [
                    {
                        abnormal_flag: 'low',
                        analyte_date: null,
                        analyte_id: 8456942,
                        analyte_name: 'HDL',
                        description: null,
                        loinc: null,
                        note: 'Male desired HDL range is >40 mg/dL.  Female desired HDL range is >50 mg/dL.',
                        reference_range: null,
                        result_status: 'final',
                        units: 'mg/dL',
                        value: '45',
                    },
                    {
                        abnormal_flag: 'Normal',
                        analyte_date: null,
                        analyte_id: 8456943,
                        analyte_name: 'triglyceride',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '0-150',
                        result_status: 'final',
                        units: 'mg/dL',
                        value: '110',
                    },
                ],
                status: 'CLOSED',
            },
        ],
    },
};

const LAB_RESULT_NO_PROVIDER_NOTE_SUCCESS_RESPONSE: { readonly d: ApiResponse<LabResultDetails> } = {
    d: {
        __metadata: {
            identity_match_strategy: 'SomeStrategy',
        },
        results: [
            {
                document_pages: [],
                lab_result_datetime: '2017-02-07T15:18:00-05:00',
                lab_result_id: LAB_RESULT_ID_NO_PROVIDER_NOTE,
                lab_result_note: null,
                lab_test_name: 'lipase',
                performing_lab_name: 'Village Medical - Laboratory',
                provider: null,
                provider_id: null,
                results: [
                    {
                        abnormal_flag: 'Normal',
                        analyte_date: null,
                        analyte_id: 8456943,
                        analyte_name: 'triglyceride',
                        description: null,
                        loinc: null,
                        note: null,
                        reference_range: '0-150',
                        result_status: 'final',
                        units: 'mg/dL',
                        value: '110',
                    },
                ],
                status: 'CLOSED',
            },
        ],
    },
};

const LAB_RESULT_HEP_C_SUCCESS_RESPONSE_FILLED = fillInLabResult(LAB_RESULT_HEP_C_SUCCESS_RESPONSE.d.results[0]);
const LAB_RESULT_HEMOGLOBIN_SUCCESS_RESPONSE_FILLED = fillInLabResult(LAB_RESULT_HEMOGLOBIN_SUCCESS_RESPONSE.d.results[0]);
const LAB_RESULT_CMP_SUCCESS_RESPONSE_FILLED = fillInLabResult(LAB_RESULT_CMP_SUCCESS_RESPONSE.d.results[0]);
const LAB_RESULT_NO_RESULTS_SUCCESS_RESPONSE_FILLED = fillInLabResult(LAB_RESULT_NO_RESULTS_SUCCESS_RESPONSE.d.results[0]);
const LAB_RESULT_NO_PROVIDER_SUCCESS_RESPONSE_FILLED = fillInLabResult(LAB_RESULT_NO_PROVIDER_SUCCESS_RESPONSE.d.results[0]);
const LAB_RESULT_NO_PROVIDER_NOTE_SUCCESS_RESPONSE_FILLED = fillInLabResult(
    LAB_RESULT_NO_PROVIDER_NOTE_SUCCESS_RESPONSE.d.results[0]
);

export {
    LAB_RESULT_HEP_C_SUCCESS_RESPONSE,
    LAB_RESULT_HEMOGLOBIN_SUCCESS_RESPONSE,
    LAB_RESULT_CMP_SUCCESS_RESPONSE,
    LAB_RESULT_IN_REVIEW_SUCCESS_RESPONSE,
    LAB_RESULT_NO_RESULTS_SUCCESS_RESPONSE,
    LAB_RESULT_NO_PROVIDER_SUCCESS_RESPONSE,
    LAB_RESULT_NO_PROVIDER_NOTE_SUCCESS_RESPONSE,
    LAB_RESULT_HEP_C_SUCCESS_RESPONSE_FILLED,
    LAB_RESULT_HEMOGLOBIN_SUCCESS_RESPONSE_FILLED,
    LAB_RESULT_CMP_SUCCESS_RESPONSE_FILLED,
    LAB_RESULT_NO_RESULTS_SUCCESS_RESPONSE_FILLED,
    LAB_RESULT_NO_PROVIDER_SUCCESS_RESPONSE_FILLED,
    LAB_RESULT_NO_PROVIDER_NOTE_SUCCESS_RESPONSE_FILLED,
};
