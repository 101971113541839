import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const InfoWrapper = styled.li`
    display: flex;
    align-items: center;
    height: ${rem(64)};
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.vui.colors.border.divider.default};
    padding-inline: ${({ theme }) => theme.page.pagePaddingInline};
`;

const Value = styled(Text).attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
`;

const Label = styled(Text).attrs(() => ({ type: 'body2' }))`
    color: ${({ theme }) => theme.vui.colors.text.graySubtle};
`;
export { InfoWrapper, Value, Label };
