import { Text } from '@village/ui';
import styled from 'styled-components';

import { chatTheme } from './theme';
import type { ChatProps } from './types';

const Chat = styled.div<ChatProps>`
    display: flex;
    align-items: center;
    background-color: ${({ $variant }) => chatTheme.variant[$variant].background};
    gap: 0.75rem;
    padding: 0.75rem;
    border-radius: 8px;
    color: ${({ theme }) => theme.color.brightGray};
`;

const ContactUs = styled.div`
    display: flex;
    gap: 2px;
    flex-flow: column;
`;

const ChatText = styled(Text).attrs(() => ({ type: 'sub2' }))`
    color: ${({ theme }) => theme.vui.colors.action.primary.default};
    line-height: 1.4286;
`;

const HelpText = styled(Text).attrs(() => ({ type: 'body2' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayMuted};
    line-height: 1.4286;
`;

export { Chat, ContactUs, ChatText, HelpText };
