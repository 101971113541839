import { Text, ListItem as VuiListItem } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const ChatContainer = styled.div`
    padding: ${rem(16)};
`;

const LoaderContainer = styled.section`
    padding: ${rem(16)};
`;

const TestResultNameLoader = styled.div`
    padding-bottom: ${rem(10)};
`;

const TestResultDateLoader = styled.div`
    margin-bottom: ${rem(4)};
`;

const TestName = styled(Text).attrs(() => ({ type: 'sub1' }))`
    color: ${({ theme }) => theme.color.shark};
`;

const TestDate = styled(Text).attrs(() => ({ type: 'body2' }))`
    color: ${({ theme }) => theme.color.brightGray};
`;

const TestInfo = styled.div`
    display: grid;
    row-gap: 0.25rem;
`;

const ListItem = styled(VuiListItem)`
    width: 100%;
    padding-inline: ${({ theme }) => theme.page.pagePaddingInline};
    & > div:first-child {
        overflow: unset;
        width: ${rem(24)};
    }
    & > div:nth-child(2) {
        width: 0;
    }
    hr {
        height: 0;
        border-top: thin solid ${({ theme }) => theme.vui.colors.border.divider.default};
        width: calc(100% - ${({ theme }) => theme.page.pagePaddingInline});
    }
`;

export { ChatContainer, LoaderContainer, TestResultNameLoader, TestResultDateLoader, TestName, TestDate, TestInfo, ListItem };
