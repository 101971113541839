import type { VaccineInfo } from 'types/vaccine-history';

const VACCINE_HISTORY_RESPONSE: readonly VaccineInfo[] = [
    {
        administerdate: '04/27/2023',
        administerroute: 'PO',
        administerroutedescription: 'Oral',
        administersite: 'OTHER',
        amount: 2,
        clinicalorderclasses: [
            {
                clinicalorderclassid: 6,
                name: 'Influenza',
            },
        ],
        cvx: 151,
        description: 'influenza nasal, unspecified formulation',
        enteredby: 'bmonnier',
        entereddate: '04/28/2023',
        expirationdate: '04/24/2024',
        genusname: 'influenza nasal, unspecified formulation',
        mvx: 'OTH',
        ndc: '50428032112',
        partiallyadministered: false,
        status: 'ADMINISTERED',
        units: 'mcg',
        vaccineid: 'H5742',
        vaccineinformationstatements: [
            {
                clinicalorderclassid: 6,
            },
        ],
        vaccinetype: 'HISTORICAL',
    },
    {
        administerdate: '04/27/2023',
        administerroute: 'IJ',
        administerroutedescription: 'Injection',
        administersite: 'ARM, LU',
        amount: 1,
        clinicalorderclasses: [
            {
                clinicalorderclassid: 6,
                name: 'Influenza',
            },
        ],
        cvx: 151,
        description: 'influenza nasal, unspecified formulation',
        enteredby: 'bmonnier',
        entereddate: '03/15/2022',
        expirationdate: '03/15/2023',
        genusname: 'COVID 19',
        mvx: 'OTH',
        partiallyadministered: false,
        status: 'ADMINISTERED',
        units: 'mL',
        vaccineid: 'H5741',
        vaccineinformationstatements: [
            {
                clinicalorderclassid: 6,
            },
        ],
        vaccinetype: 'HISTORICAL',
    },
];

export { VACCINE_HISTORY_RESPONSE };
