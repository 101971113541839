import styled from 'styled-components';

import { Icon } from 'components/icon';

const centerContentHorizontal = `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Container = styled.section`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const MessagesContainer = styled.section`
    height: 100%;
    overflow: hidden;
    flex: 1;
`;

const FooterContainer = styled.section`
    ${centerContentHorizontal}
    padding: 1rem;
    border-top: 1px solid ${({ theme }) => theme.color.athensGray};
`;

const PencilIcon = styled(Icon)`
    margin-right: 1rem;
`;

const SpinnerContainer = styled.div`
    ${centerContentHorizontal}
    min-height: 5rem;
    height: 100%;
`;

const ButtonContainer = styled.div`
    ${centerContentHorizontal}
    padding: 1.5rem 0;
`;

export { Container, MessagesContainer, FooterContainer, PencilIcon, SpinnerContainer, ButtonContainer };
