import { Text } from '@village/ui';
import type { FC } from 'react';

import * as Styled from './styles';
import * as RootStyled from '../../test-result/styles';
import { Icon } from 'components/icon';
import type { TestResultDetails, TestResultType } from 'types';
import { formatDate } from 'utils/date';
import { getProviderDisplayName } from 'utils/provider';

interface Props {
    readonly testResult: TestResultDetails;
}

const headerMapping: Record<TestResultType, string> = {
    imagingresult: 'Imaging result',
    labresult: 'Lab result',
};

const Summary: FC<Props> = ({ testResult }) => {
    const { lastmodifieddatetime, description, provider, type, iconName } = testResult;
    const providerDisplayName = provider ? getProviderDisplayName(provider) : null;

    return (
        <RootStyled.SidePadding>
            <Styled.Heading>
                <Styled.Title>{description}</Styled.Title>
            </Styled.Heading>
            <Styled.PageType>
                <Icon name={iconName} size={1.25} />
                <Text type="body2">{headerMapping[type]}</Text>
            </Styled.PageType>
            <Styled.SummaryInfo>
                {provider?.specialty ? <Text type="body2">{provider.specialty}</Text> : null}
                {providerDisplayName ? <Text type="body2">Ordered by {providerDisplayName}</Text> : null}
            </Styled.SummaryInfo>
            <Styled.Date>{lastmodifieddatetime ? formatDate(lastmodifieddatetime, 'fullDate') : '(Date not found)'}</Styled.Date>
        </RootStyled.SidePadding>
    );
};

export { Summary };
