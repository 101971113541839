import axios from 'axios';

import { TIMEOUT_MS } from './constants';
import { appConfig } from 'modules/config';

const identityApi = axios.create({
    baseURL: appConfig.identityApiBaseUrl,
    timeout: TIMEOUT_MS,
});

export { identityApi };
