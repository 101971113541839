import { useCallback, useState } from 'react';
import type { FC, PropsWithChildren } from 'react';

import { FontSizeContext } from './font-size-context';
import { useNativeIpc } from 'hooks/use-native-ipc';
import type { MessageBase } from 'modules/ipc/types';
import { globalBaseFontSize } from 'theme';

interface scaleFactor {
    readonly scale: number;
}

const FontSizeProvider: FC<PropsWithChildren> = ({ children }) => {
    const [fontSize, setFontSize] = useState(globalBaseFontSize);

    const fontSizeIpcHandler = useCallback(
        (event: CustomEvent<MessageBase<scaleFactor>>) => {
            if (event.detail.body?.scale && event.detail.body.scale !== fontSize) {
                setFontSize(Math.round(event.detail.body.scale * globalBaseFontSize));
            }
        },
        [fontSize]
    ) as EventListener;

    useNativeIpc('nativeScaleFactor', fontSizeIpcHandler);

    return <FontSizeContext.Provider value={fontSize}>{children}</FontSizeContext.Provider>;
};

export { FontSizeProvider };
