import { Button, Text } from '@village/ui';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { VisitSummary, ProviderNote, Diagnoses, Orders, Vitals, UpcomingVisits } from './components';
import * as Styled from './styles';
import { CenteredSpinner } from 'components/centered-spinner';
import { Icon } from 'components/icon';
import { useEncounterDetails } from 'data/hooks/use-encounter-details-query';
import { useMessaging } from 'hooks/use-messaging';
import { useNativeControls } from 'hooks/use-native-controls';
import { Countly } from 'modules/countly';
import { formatDate, getDateWithoutTimezone } from 'utils';

type ParametersTypes = 'encounterId';

const CareSummaryContent: FC = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { encounterId } = useParams<ParametersTypes>();
    const { data: encounterDetails, isLoading } = useEncounterDetails(Number(encounterId));
    const { setMessagingFields, resetMessagingState } = useMessaging();

    const composeMessage = useCallback(() => {
        if (encounterDetails) {
            Countly.addEvent({
                key: 'composeInitiate',
                segmentation: {
                    initiatedSource: 'healthrecords_CareSummary',
                    source: 'healthrecords_villagemedical',
                },
            });

            resetMessagingState();
            setMessagingFields({
                messageSubject: 'Question about care summary',
                messageType: 'PATIENTCASE_CLINICALQUESTION',
                provider: encounterDetails.provider,
            });
            navigate(`/compose-message?source=care_summary_details&redirect-to=${pathname}`, { replace: true });
        }
    }, [resetMessagingState, setMessagingFields, navigate, encounterDetails, pathname]);

    useNativeControls({
        ipcEvents: ['showBackButton'],
        source: 'health-records',
        suspend: !encounterDetails,
        title: encounterDetails?.date ? formatDate(getDateWithoutTimezone(encounterDetails.date), 'fullDate') : 'Care Summaries',
    });

    useEffect(() => {
        if (encounterDetails) {
            Countly.addEvent({ key: 'viewCareSummary' });
        }
    }, [encounterDetails]);

    if (isLoading) {
        return <CenteredSpinner />;
    }

    if (!encounterDetails) {
        return <Text type="h3">Care summary not found</Text>;
    }

    return (
        <Styled.Container>
            <VisitSummary encounter={encounterDetails} />
            <ProviderNote composeMessage={composeMessage} encounter={encounterDetails} />
            <Diagnoses encounter={encounterDetails} />
            <Orders encounter={encounterDetails} />
            <Vitals encounter={encounterDetails} />

            <Styled.SectionTitle>Upcoming visits</Styled.SectionTitle>
            <UpcomingVisits />

            <Styled.Grid>
                <Button
                    fullWidth={true}
                    onClick={composeMessage}
                    size="medium"
                    startIcon={<Icon name="email" size={1.25} />}
                    variant="secondary"
                >
                    Send a message to my provider
                </Button>
            </Styled.Grid>
        </Styled.Container>
    );
};

export { CareSummaryContent };
