import { ListItem as VuiListItem, Text, Button } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

import { Icon } from 'components/icon';

const Group = styled.div`
    margin-bottom: ${rem(32)};
`;

const GroupHeader = styled.div`
    display: flex;
    align-items: center;
    gap: ${rem(16)};
`;

const ListIcon = styled(Icon)`
    color: ${({ theme }) => theme.color.brightGray};
`;

const List = styled.div`
    margin-left: ${rem(24)};
    margin-right: ${rem(-16)};
`;

const ListItem = styled(VuiListItem)`
    & > div {
        flex-direction: column;
        align-items: start;
    }
`;

const OrderDescription = styled(Text).attrs(() => ({ type: 'sub1' }))`
    color: ${({ theme }) => theme.vui.colors.neutral.gray90};
`;

const OrderDetails = styled(Text).attrs(() => ({ type: 'body2' }))`
    color: ${({ theme }) => theme.color.shuttleGray};
    margin-top: ${({ children }) => (children ? rem(8) : 0)};
`;

const Link = styled(Button).attrs(() => ({ variant: 'link' }))`
    font-family: inherit;
    font-size: 16px;
`;

export { OrderDescription, OrderDetails, Link, Group, GroupHeader, ListIcon, List, ListItem };
