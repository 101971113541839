import styled from 'styled-components';

import { Icon } from 'components/icon';

const AnalyteNoteContainer = styled.div`
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 0.75rem;
    background: ${({ theme }) => theme.color.ghostWhite};
`;

const AnalyteNote = styled.div`
    color: ${({ theme }) => theme.color.stormGray};
`;

const ChevronDown = styled(Icon)`
    color: ${({ theme }) => theme.color.primaryBlue};
`;

const ChevronUp = styled(Icon)`
    color: ${({ theme }) => theme.color.primaryBlue};
`;

const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 0.25rem;
`;

const ToggleText = styled.div`
    font-weight: 600;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.color.primaryBlue};
    margin-right: 0.3125rem;
`;

export { AnalyteNote, AnalyteNoteContainer, ChevronDown, ChevronUp, ToggleContainer, ToggleText };
