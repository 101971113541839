import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.vui.colors.primary.primaryBlue};
    padding: ${rem(4)};
    border-radius: ${rem(8)};
    border: 1px solid ${({ theme }) => theme.vui.colors.primary.primaryBlue};
    display: flex;
    align-items: center;
`;

const TitleText2 = styled(Text).attrs(() => ({ type: 'sub1' }))`
    cursor: pointer;
`;

const Icon = styled.span`
    padding: ${rem(8)};
    display: flex;
    align-items: center;
`;

export { Icon, Container, TitleText2 };
