const PROVIDERS_SUCCESS_RESPONSE = {
    d: {
        __metadata: {
            identity_match_strategy: 'EmpiStrategy',
        },
        results: [
            {
                department_ids: [1, 2],
                displayname: 'Adejumo, Lara MD',
                firstname: 'Khadijah',
                image: {
                    height: 250,
                    url: 'https://cdn2.hubspot.net/hubfs/6509886/K%20Lara%20Adejumo.jpg',
                    width: 250,
                },
                lastname: 'Adejumo',
                npi: 1235216334,
                providerid: 119,
                providertype: 'MD',
                recentencountersdate: ['December 23, 2021'],
                specialty: 'Family Medicine',
            },
            {
                department_ids: [1, 2],
                displayname: 'Chris, Pedroza MD',
                firstname: 'Chris',
                image: {
                    height: 250,
                    url: 'https://cdn2.hubspot.net/hubfs/6509886/Dr.%20Pedroza%20094FF.jpg',
                    width: 250,
                },
                lastname: 'Pedroza',
                npi: 3215554579,
                providerid: 120,
                providertype: 'MD',
                recentencountersdate: ['June 21, 2021'],
                specialty: 'Family Medicine',
            },
            {
                department_ids: [1, 2],
                firstname: 'Matt',
                lastname: 'Perkins',
                npi: 6458445131,
                providerid: 124,
                providertype: 'MD',
                recentencountersdate: ['April 2, 2022'],
                specialty: 'Family Medicine',
            },
            {
                department_ids: [1, 2],
                displayname: 'Stephanie, Wiman MD',
                firstname: 'Stephanie',
                image: {
                    height: 250,
                    url: 'https://cdn2.hubspot.net/hubfs/6509886/Stephanie%20Wiman.jpg',
                    width: 250,
                },
                lastname: 'Wiman',
                npi: 1932531779,
                providerid: 16,
                providertype: 'MD',
                recentencountersdate: ['May 5, 2033'],
                specialty: 'Family Medicine',
            },
        ],
    },
};

export { PROVIDERS_SUCCESS_RESPONSE };
