import styled from 'styled-components';

import { Icon } from 'components/icon';

const Container = styled.section`
    top: 0;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.color.athensGray};
`;

const ControlsBlock = styled.div`
    flex: 50%;
`;

const iconMargins = `
    margin-left: 0.35rem;
    margin-right: 0.35rem;
`;

const TabIcon = styled(Icon)`
    ${iconMargins}
`;

const MessageCount = styled.span`
    margin-left: 0.25rem;
`;

const TabLabel = styled.span`
    display: flex;
    align-items: center;
`;

export { Container, ControlsBlock, MessageCount, TabIcon, TabLabel };
