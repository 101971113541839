import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';

import { messagingApi } from '../messaging-api-config';
import { useFeatureFlags } from 'hooks';
import type { ApiResponse, MessageSource, MessageThread } from 'types';

const useMessageThread = (
    threadId: number,
    source: MessageSource | '' | undefined,
    enabled?: boolean,
    ignoreCache?: boolean
): UseQueryResult<ApiResponse<MessageThread>> => {
    const { hasFeature } = useFeatureFlags();
    const onlyReadyDocuments = hasFeature('messaging.allTestResults');
    const customHeaders = {};
    // eslint-disable-next-line functional/immutable-data
    if (ignoreCache) customHeaders['Cache-Control'] = 'no-cache';

    return useQuery(
        ['message-thread', { threadId }],
        async () => {
            const response = await messagingApi.get<ApiResponse<MessageThread>>(
                `/message/${threadId}?source=${source ?? ''}&only_ready_documents=${onlyReadyDocuments.toString()}`,
                { headers: customHeaders }
            );
            return response.data;
        },
        {
            enabled,
        }
    );
};
export { useMessageThread };
