import styled from 'styled-components';

const MedicationInfo = styled.div`
    flex: 1;
`;

const MedicationName = styled.span`
    font-weight: 600;
    font-size: 1rem;
    color: ${({ theme }) => theme.color.shark};
`;

const MedicationQuantity = styled.div`
    padding-top: 0.5rem;
    font-weight: 400;
    font-size: 0.813rem;
    color: ${({ theme }) => theme.color.shuttleGray};
`;

export { MedicationName, MedicationQuantity, MedicationInfo };
