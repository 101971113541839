import { memo } from 'react';
import type { FC } from 'react';

import { LabsContent } from './labs-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <LabsContent />,
};

const LabsPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Labs" />);

export { LabsPage };
