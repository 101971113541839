import type { FC } from 'react';
import { memo } from 'react';

import { MessagingTestResults } from './select-test-result';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <MessagingTestResults />,
};

const SelectLabPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Select lab" />);

export { SelectLabPage };
