import { useToggle } from '@village/tools';
import type { FC } from 'react';
import { useMemo, useEffect, useCallback } from 'react';

import { ProviderCard } from './components/provider-card';
import * as Styled from './styles';
import { SuggestedProviderLoading } from './suggested-providers-loading';
import { useProviders } from 'data/hooks/use-providers';
import { useMessaging } from 'hooks/use-messaging';
import { useNativeControls } from 'hooks/use-native-controls';
import { Countly } from 'modules/countly';
import { RoundedContainer } from 'pages/page-layouts';
import { useScheduling } from 'pages/scheduling-flow/hooks/use-scheduling';

const SuggestedProviderContent: FC = () => {
    const { data, isLoading } = useProviders();
    const { value: showAllProviders, toggle } = useToggle(false);
    const { goToNextStep } = useScheduling();
    const { setMessagingFields } = useMessaging();

    const handleNoPreference = useCallback((): void => {
        setMessagingFields({
            provider: undefined,
        });
        goToNextStep();
    }, [goToNextStep, setMessagingFields]);

    useEffect(() => {
        setMessagingFields({
            provider: undefined,
        });
    }, [setMessagingFields]);

    useNativeControls({
        ipcEvents: ['showControls', 'hideBottomNavBar'],
        source: 'messaging',
    });

    useEffect(() => {
        Countly.addEvent({
            key: 'viewSuggestedProvidersPage',
            segmentation: {
                flow: 'scheduling',
                messagingVersion: 2,
                source: 'messaging',
            },
        });
    }, []);

    const getProvidersToDisplay = useMemo(
        () => (showAllProviders ? data?.results : data?.results.slice(0, 2)),
        [data?.results, showAllProviders]
    );

    return (
        <RoundedContainer headerContent="Who would you like to see?">
            <Styled.SuggestedContainer>
                <span>SUGGESTED PROVIDERS</span>
                <Styled.Expand onClick={toggle}>{showAllProviders ? 'Show Less' : 'View All'}</Styled.Expand>
            </Styled.SuggestedContainer>
            <Styled.OptionsContainer>
                {isLoading ? (
                    <SuggestedProviderLoading />
                ) : (
                    getProvidersToDisplay?.map((provider) => <ProviderCard key={provider.providerid} provider={provider} />)
                )}
            </Styled.OptionsContainer>
            <Styled.NoProvider onClick={handleNoPreference}>I have no provider preference</Styled.NoProvider>
        </RoundedContainer>
    );
};

export { SuggestedProviderContent };
