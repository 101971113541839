import { Button, Input } from '@village/ui';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { useQueryErrorResetBoundary } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';

import * as Styled from './styles';
import { CenteredSpinner } from 'components/centered-spinner';
import { useAuthActions, useAuthState } from 'hooks';
import { useNavHeader } from 'hooks/use-nav';
import { getCurrentSession } from 'utils/auth';

const LoginContent: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { login } = useAuthActions();
    const { isLoggedIn, isLoggingIn } = useAuthState();
    const [email, setEmail] = useState<string>('');
    const { reset } = useQueryErrorResetBoundary();
    const { setShowingHeaderNav } = useNavHeader();

    const [password, setPassword] = useState<{
        readonly value: string;
        readonly showPassword: boolean;
    }>({
        showPassword: false,
        value: '',
    });

    useEffect(() => {
        setShowingHeaderNav(false);

        return () => setShowingHeaderNav(true);
    }, [setShowingHeaderNav]);

    const redirectTo = useMemo(() => new URLSearchParams(location.search).get('redirectTo'), [location.search]);

    const handleSubmit = useCallback((): void => {
        reset();
        login(email, password.value);
    }, [reset, login, email, password.value]);

    const onEmailChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
        setEmail(event.currentTarget.value);
    }, []);

    const onPasswordChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
        const { value } = event.currentTarget;
        setPassword((previousPassword) => ({ ...previousPassword, value }));
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            getCurrentSession().then(() => {
                navigate(redirectTo ?? '/medications', { replace: true });
            });
        }
    }, [navigate, isLoggedIn, redirectTo]);

    if (isLoggingIn) {
        return <CenteredSpinner />;
    }

    return (
        <Styled.Container>
            <Input fullWidth={true} label="Email address" onChange={onEmailChange} type="email" value={email} />

            <Input
                fullWidth={true}
                label="Password"
                onChange={onPasswordChange}
                type={password.showPassword ? 'text' : 'password'}
                value={password.value}
            />

            <Button disabled={isLoggingIn} onClick={handleSubmit}>
                Log in
            </Button>
        </Styled.Container>
    );
};

export { LoginContent };
