import { ListItem, Button, Text } from '@village/ui';
import type { FC } from 'react';
import { useCallback, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Styled from './styles';
import * as RootStyled from '../../styles';
import type { EncounterDetail } from 'types';
import { titleCase } from 'utils/text';

interface Props {
    readonly encounter: EncounterDetail;
}

const Orders: FC<Props> = ({ encounter }) => {
    const navigate = useNavigate();
    const { orders } = encounter;

    const flatOrders = orders.flatMap((orderItem) => orderItem.orders);
    const prescriptionOrders = flatOrders.filter(({ ordertype }) => ordertype === 'Prescription');
    const labsOrders = flatOrders.filter(({ ordertype }) => ordertype === 'Lab');
    const imagingOrders = flatOrders.filter(({ ordertype }) => ordertype === 'Imaging');
    const referredProvidersOrders = flatOrders.filter(({ ordertype }) => ordertype === 'Consult');
    const surgeriesOrders = flatOrders.filter(({ ordertype }) => ordertype && ['Surgery', 'Procedure'].includes(ordertype));

    const gotToMedication = useCallback(() => {
        navigate('/medications');
    }, [navigate]);

    if (prescriptionOrders.length === 0 && labsOrders.length === 0 && imagingOrders.length === 0) {
        return null;
    }

    return (
        <Fragment>
            <RootStyled.SectionTitle>Your plan</RootStyled.SectionTitle>
            <div data-testid="orders">
                {prescriptionOrders.length > 0 ? (
                    <Styled.Group>
                        <Styled.GroupHeader>
                            <Styled.ListIcon name="medication" size={1.5} />
                            <Styled.OrderDescription>Pick up medication</Styled.OrderDescription>
                        </Styled.GroupHeader>
                        <Styled.List>
                            {prescriptionOrders.map((order) => (
                                <Styled.ListItem key={order.orderid}>
                                    <Styled.OrderDescription>{order.description}</Styled.OrderDescription>
                                    <Styled.OrderDetails>{order.quantityvalue}</Styled.OrderDetails>
                                </Styled.ListItem>
                            ))}
                            <Button onClick={gotToMedication} variant="link">
                                <Text type="sub1">View my medication list</Text>
                            </Button>
                        </Styled.List>
                    </Styled.Group>
                ) : null}

                {referredProvidersOrders.length > 0 ? (
                    <Styled.Group>
                        <Styled.GroupHeader>
                            <Styled.ListIcon name="user" size={1.5} />
                            <Styled.OrderDescription>See a referred provider</Styled.OrderDescription>
                        </Styled.GroupHeader>
                        <Styled.List>
                            {referredProvidersOrders.map((order) => (
                                <Styled.ListItem key={order.orderid}>
                                    <Styled.OrderDescription>
                                        {order.description ? titleCase(order.description) : null}
                                    </Styled.OrderDescription>
                                </Styled.ListItem>
                            ))}
                        </Styled.List>
                    </Styled.Group>
                ) : null}

                {labsOrders.length > 0 ? (
                    <Styled.Group>
                        <Styled.GroupHeader>
                            <Styled.ListIcon name="lab" size={1.5} />
                            <Styled.OrderDescription>Labs ordered</Styled.OrderDescription>
                        </Styled.GroupHeader>
                        <Styled.List>
                            {labsOrders.map((order) => (
                                <Styled.ListItem key={order.orderid}>
                                    <Styled.OrderDescription>{order.description}</Styled.OrderDescription>
                                    <Styled.OrderDetails>{order.clinicalprovidername}</Styled.OrderDetails>
                                </Styled.ListItem>
                            ))}
                        </Styled.List>
                    </Styled.Group>
                ) : null}

                {imagingOrders.length > 0 ? (
                    <Styled.Group>
                        <Styled.GroupHeader>
                            <Styled.ListIcon name="radiology" size={1.5} />
                            <Styled.OrderDescription>Imaging</Styled.OrderDescription>
                        </Styled.GroupHeader>
                        <Styled.List>
                            {imagingOrders.map((order) => (
                                <Styled.ListItem key={order.orderid}>
                                    <Styled.OrderDescription>{order.description}</Styled.OrderDescription>
                                    <Styled.OrderDetails>{order.clinicalprovidername}</Styled.OrderDetails>
                                </Styled.ListItem>
                            ))}
                        </Styled.List>
                    </Styled.Group>
                ) : null}

                {surgeriesOrders.length > 0 ? (
                    <Styled.Group>
                        <Styled.GroupHeader>
                            <Styled.ListIcon name="surgery" size={1.5} />
                            <Styled.OrderDescription>Procedures and surgeries</Styled.OrderDescription>
                        </Styled.GroupHeader>
                        <Styled.List>
                            {surgeriesOrders.map((order) => (
                                <ListItem key={order.orderid}>
                                    <Styled.OrderDescription>{order.description}</Styled.OrderDescription>
                                    <Styled.OrderDetails>{order.clinicalprovidername}</Styled.OrderDetails>
                                </ListItem>
                            ))}
                        </Styled.List>
                    </Styled.Group>
                ) : null}
            </div>
        </Fragment>
    );
};

export { Orders };
