import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { VaccineDetailEntry } from './components/vaccine-detail-entry';
import * as Styled from './styles';
import { VaccineDetailsLoading } from './vaccine-details-loading';
import { useVaccineHistory } from 'data/hooks/use-vaccine-history';
import { useNativeControls } from 'hooks/use-native-controls';
import { Countly } from 'modules/countly';
import { Page } from 'pages/page-layouts';
import { formatDate } from 'utils';

type ParametersTypes = 'vaccineId';

const VaccineDetailsContent: FC = () => {
    const { vaccineId } = useParams<ParametersTypes>();
    const { data: vaccineHistoryData, isLoading } = useVaccineHistory();

    useNativeControls({
        ipcEvents: ['showBackButton'],
        source: 'health-records',
        title: '',
    });

    useEffect(() => {
        Countly.addEvent({ key: 'viewIndividualImmunization' });
    }, []);

    const vaccine = useMemo(
        () => vaccineHistoryData?.results.find((item) => item.vaccineid === vaccineId),
        [vaccineHistoryData?.results, vaccineId]
    );

    if (isLoading) {
        return <VaccineDetailsLoading />;
    }

    if (!vaccine) {
        // TODO: Replace this with a proper 404 message component
        return <div>Vaccine info not found</div>;
    }

    const { genusname, administerdate, administerroute, administersite, amount, units, mvx, expirationdate, lotnumber } = vaccine;

    return (
        <Page.Padding>
            <Styled.VaccineName>{genusname}</Styled.VaccineName>
            <Page.FullBleed>
                <Styled.List>
                    <VaccineDetailEntry
                        label="Administration date"
                        value={administerdate ? formatDate(administerdate, 'fullDate') : undefined}
                    />
                    <VaccineDetailEntry label="Manufacturer" value={mvx} />
                    <VaccineDetailEntry label="Lot number" value={lotnumber} />
                    <VaccineDetailEntry
                        label="Expiration"
                        value={expirationdate ? formatDate(expirationdate, 'fullDate') : undefined}
                    />
                    <VaccineDetailEntry label="Amount" value={amount && units ? `${amount}${units}` : undefined} />
                    <VaccineDetailEntry label="Route" value={administerroute} />
                    <VaccineDetailEntry label="Site" value={administersite} />
                </Styled.List>
            </Page.FullBleed>
        </Page.Padding>
    );
};

export { VaccineDetailsContent };
