import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
    display: grid;
    gap: ${rem(24)};
`;

const ItemContainer = styled.div`
    display: flex;
    gap: ${rem(16)};
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${rem(48)};
    height: ${rem(48)};
    border: 2px solid ${({ theme }) => theme.color.athensGray};
    border-radius: 12px;
    color: ${({ theme }) => theme.color.brightGray};
`;

const VitalTitle = styled(Text).attrs(() => ({ type: 'sub2' }))`
    color: ${({ theme }) => theme.color.brightGray};
    margin-bottom: ${rem(4)};
`;

const ValueContainer = styled.div`
    display: flex;
    align-items: baseline;
    gap: ${rem(4)};
`;

const VitalValue = styled(Text).attrs(() => ({ type: 'h3' }))`
    color: ${({ theme }) => theme.color.catalinaBlue};
`;

const VitalUnit = styled(Text).attrs(() => ({ type: 'caption' }))`
    color: ${({ theme }) => theme.color.brightGray};
`;

export { Container, IconContainer, VitalTitle, ItemContainer, ValueContainer, VitalValue, VitalUnit };
