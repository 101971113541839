import { ListItem } from '@village/ui';
import { useMemo } from 'react';
import type { FC } from 'react';

import * as Styled from './styles';
import { LabAnalyteNote } from '../lab-analyte-note';
import type { LabAnalyte as Analyte } from 'types/lab-result-details';

interface Props {
    readonly labAnalyte: Analyte;
}

const LabAnalyte: FC<Props> = ({ labAnalyte }) => {
    const { analyte_id, analyte_name, value, units, abnormal_flag, reference_range, note } = labAnalyte;

    const analyteInterpretation = useMemo(() => {
        if (!abnormal_flag) {
            return null;
        }

        return abnormal_flag.toLowerCase() !== 'normal' ? (
            <Styled.AnalyteAbnormalInterpretation>{abnormal_flag}</Styled.AnalyteAbnormalInterpretation>
        ) : (
            <Styled.AnalyteInterpretation>{abnormal_flag}</Styled.AnalyteInterpretation>
        );
    }, [abnormal_flag]);

    return (
        <ListItem>
            <Styled.AnalyteContainer>
                <Styled.AnalyteInfo>
                    <Styled.AnalyteName>{analyte_name}</Styled.AnalyteName>
                    <Styled.AnalyteValueUnitContainer>
                        <Styled.AnalyteValue>{value}</Styled.AnalyteValue>
                        <Styled.AnalyteUnit>{units}</Styled.AnalyteUnit>
                    </Styled.AnalyteValueUnitContainer>
                    {reference_range ? (
                        <Styled.ReferenceRange>
                            Reference Range: {reference_range} {units}
                        </Styled.ReferenceRange>
                    ) : null}
                </Styled.AnalyteInfo>
                {analyteInterpretation}
            </Styled.AnalyteContainer>
            {note ? <LabAnalyteNote analyteId={analyte_id} analyteName={analyte_name} content={note} /> : null}
        </ListItem>
    );
};

export { LabAnalyte };
