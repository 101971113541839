import type { LegacyRef, FC } from 'react';
import { createRef, memo, useRef } from 'react';
import type { ListRowProps } from 'react-virtualized';
import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import type { CellMeasurerChildProps } from 'react-virtualized/dist/es/CellMeasurer';

import * as Styled from './styles';
import { MedicationListItem } from '../medication-entry';
import type { Medication } from 'types';

interface MedicationListProps {
    readonly medications: readonly Medication[];
    readonly selectedMedicationId?: string;
    readonly handleSelectMedication: (selectedMedication: Medication) => void;
}

const MedicationVirtualizedList: FC<MedicationListProps> = memo(
    ({ handleSelectMedication, medications, selectedMedicationId }) => {
        const listRef = createRef<List>();

        const cache = useRef(
            new CellMeasurerCache({
                defaultHeight: 50,
                fixedWidth: true,
            })
        );

        const rowRenderer = ({ index, key, parent, style }: ListRowProps): React.ReactNode => (
            <CellMeasurer key={key} cache={cache.current} columnIndex={0} parent={parent} rowIndex={index}>
                {({ measure, registerChild }: CellMeasurerChildProps) => (
                    <div ref={registerChild as LegacyRef<HTMLDivElement>} style={style}>
                        <MedicationListItem
                            handleSelectMedication={handleSelectMedication}
                            measureListItemHeight={measure}
                            medication={medications[index]}
                            selectedMedicationId={selectedMedicationId}
                        />
                    </div>
                )}
            </CellMeasurer>
        );

        return (
            <Styled.ListContainer>
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            ref={listRef}
                            deferredMeasurementCache={cache.current}
                            height={height}
                            rowCount={medications.length}
                            rowHeight={cache.current.rowHeight}
                            rowRenderer={rowRenderer}
                            width={width}
                        />
                    )}
                </AutoSizer>
            </Styled.ListContainer>
        );
    }
);

export { MedicationVirtualizedList };
