import { Text } from '@village/ui';
import styled from 'styled-components';

import { Page } from 'pages/page-layouts';

const ErrorBoundaryContainer = styled(Page.Padding)`
    width: 100vw;
    height: 100vh;
    align-items: center;
    background-color: ${({ theme }) => theme.vui.colors.surface.paleBlue};
`;

const ErrorBoundaryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const ErrorMessage = styled(Text).attrs(() => ({ type: 'h3' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayMuted};
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
`;

const Hint = styled(Text).attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayMuted};
    margin-bottom: 2rem;
`;

export { ErrorBoundaryContainer, ErrorBoundaryWrapper, ErrorMessage, Hint };
