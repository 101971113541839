import { Route } from 'react-router-dom';

import { schedulingRoutesMap } from './const/scheduling-routes';
import { PrivateRoute } from 'components/private-route';

export const schedulingRoutes = schedulingRoutesMap.map(({ route, component: Component, privateRoute }) =>
    privateRoute ? (
        <Route key={route} element={<PrivateRoute element={<Component />} />} path={route} />
    ) : (
        <Route key={route} element={<Component />} path={route} />
    )
);
