import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';

import { healthRecordsApi } from '../health-records-api-config';
import type { ApiResponse, Medication } from 'types';

const useMedications = (useCache = true, enabled = true): UseQueryResult<ApiResponse<Medication>> =>
    useQuery(
        'medications',
        async () => {
            const response = await healthRecordsApi.get<ApiResponse<Medication>>(
                '/medications?medication_types=ACTIVE&medication_types=HISTORICAL'
            );
            return response.data;
        },
        {
            cacheTime: useCache ? 5 * 60 * 1000 : 0,
            enabled,
        }
    );

export { useMedications };
