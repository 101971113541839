import { memo } from 'react';
import type { FC } from 'react';

import { SuggestedProviderContent } from './suggested-providers-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <SuggestedProviderContent />,
};

const SuggestedProvidersPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Suggested providers" />);

export { SuggestedProvidersPage };
