import styled from 'styled-components';

const Container = styled.div`
    text-align: center;
    align-items: center;
    padding: 0 1rem;
    margin-top: 8.125rem;
`;

const ButtonContainer = styled.div`
    margin-top: 1.5rem;
    padding: 0 4rem;
`;

const UnverifiedMessage = styled.div`
    font-weight: 600;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.color.brightGray};
    padding: 1.25rem 1.5rem 0.5rem;
`;

const ActionMessage = styled.div`
    font-size: 1rem;
    color: ${({ theme }) => theme.color.brightGray};
    line-height: 1.5;
`;

export { Container, ButtonContainer, UnverifiedMessage, ActionMessage };
