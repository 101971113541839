import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';

import { messagingApi } from '../messaging-api-config';
import type { ApiResponse } from 'types/api';
import type { Provider } from 'types/provider';

const useProviders = (enabled = true): UseQueryResult<ApiResponse<Provider>> =>
    useQuery(
        'providers',
        async () => {
            const response = await messagingApi.get<ApiResponse<Provider>>('/message/providers', {
                params: { suggestedProviders: true },
            });
            return response.data;
        },
        {
            enabled,
        }
    );

export { useProviders };
