import { ButtonCard } from '@village/ui';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Styled from './styles';
import { CenteredSpinner } from 'components/centered-spinner';
import { Icon } from 'components/icon';
import { useMessaging } from 'hooks/use-messaging';
import { Countly } from 'modules/countly';
import type { MessageType } from 'types';

interface Props {
    readonly messageType: MessageType;
    readonly isLoadingProviders: boolean;
    readonly providersLength: number | undefined;
    readonly disableButtons: () => void;
    readonly isEnabled: boolean;
}

const MessageTypeEntry: FC<Props> = ({ disableButtons, isEnabled, messageType, isLoadingProviders, providersLength }) => {
    const navigate = useNavigate();
    const { setMessagingFields, resetMessagingState } = useMessaging();
    const { text, iconName, redirectsTo, key, requiresProviders } = messageType;
    const [messageTypeClicked, setMessageTypeClicked] = useState(false);
    const icon = useMemo(() => <Icon name={iconName} size={4} />, [iconName]);

    const handleMessageTypeClicked = useCallback((): void => {
        if (!isEnabled) return;
        resetMessagingState();
        setMessagingFields({
            defaultSubject: text,
            messageType: key,
        });
        setMessageTypeClicked(true);
        Countly.addEvent({
            key: 'composeMessageTypeSelection',
            segmentation: {
                messageType: key,
                messagingVersion: 2,
                source: 'messaging',
            },
        });
        disableButtons();
    }, [isEnabled, resetMessagingState, setMessagingFields, text, key, disableButtons]);

    useEffect(() => {
        if (!messageTypeClicked || (requiresProviders && isLoadingProviders)) return;
        if (requiresProviders && providersLength === 0) {
            navigate(`/compose-message`);
        } else {
            navigate(redirectsTo);
        }
    }, [
        messageTypeClicked,
        isLoadingProviders,
        providersLength,
        navigate,
        key,
        redirectsTo,
        requiresProviders,
        setMessagingFields,
        text,
    ]);

    if (messageTypeClicked && isLoadingProviders) {
        return (
            <Styled.Container>
                <ButtonCard primaryText={<CenteredSpinner />} />
            </Styled.Container>
        );
    }

    return (
        <Styled.Container>
            <ButtonCard onClick={handleMessageTypeClicked} picture={icon} primaryText={text} />
        </Styled.Container>
    );
};

export { MessageTypeEntry };
