import { Tabs, Text as VUIText } from '@village/ui';
import styled from 'styled-components';

const ChatContainer = styled.div`
    padding: 1rem;
    padding-bottom: 0;
`;

const LoaderContainer = styled.section`
    padding: 1rem;
`;

const StyledTabs = styled(Tabs)`
    background-color: ${({ theme }) => theme.color.white};
    border-bottom: 1px solid ${({ theme }) => theme.color.athensGray};
`;

const RootContainer = styled.div`
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const ScrollableContainer = styled.div`
    overflow: auto;
    flex: 1;
`;

const Title = styled(VUIText).attrs(() => ({ type: 'h3' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
    padding: 1rem 1rem 0;
`;

const PharmacyContainer = styled.div`
    padding: 1rem;
`;

const NoDataStyled = styled.div`
    [data-testid='no-data'] {
        margin-top: 3rem;
    }
`;

export { ChatContainer, LoaderContainer, StyledTabs, RootContainer, ScrollableContainer, Title, PharmacyContainer, NoDataStyled };
