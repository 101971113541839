import type { FC, ReactNode } from 'react';
import { Fragment } from 'react';

import * as Styled from './styles';
import * as RootStyled from '../../styles';
import type { IconName } from 'components/icon';
import { Icon } from 'components/icon';
import type { EncounterDetail } from 'types';
import { cmToFeet, gramToPound } from 'utils';

interface Props {
    readonly encounter: EncounterDetail;
}

const Vitals: FC<Props> = ({ encounter }) => {
    const { vitals } = encounter;

    const heightVital = vitals.find((vital) => vital.key === 'HEIGHT');
    const heightValue = heightVital?.readings[0]?.find(({ clinicalelementid }) => clinicalelementid === 'VITALS.HEIGHT');

    const heightInFeet = cmToFeet(Number(heightValue?.value));

    const weightVital = vitals.find((vital) => vital.key === 'WEIGHT');
    const weightValue = weightVital?.readings[0]?.find(({ clinicalelementid }) => clinicalelementid === 'VITALS.WEIGHT');

    const bmiVital = vitals.find((vital) => vital.key === 'BMI');
    const bmiValue = bmiVital?.readings[0]?.find(({ clinicalelementid }) => clinicalelementid === 'VITALS.BMI');

    const bloodPressureVital = vitals.find((vital) => vital.key === 'BLOODPRESSURE');
    const bloodPressureDiastolic = bloodPressureVital?.readings[0]?.find(
        ({ clinicalelementid }) => clinicalelementid === 'VITALS.BLOODPRESSURE.DIASTOLIC'
    );
    const bloodPressureSystolic = bloodPressureVital?.readings[0]?.find(
        ({ clinicalelementid }) => clinicalelementid === 'VITALS.BLOODPRESSURE.SYSTOLIC'
    );

    const pulseVital = vitals.find((vital) => vital.key === 'PULSE');
    const pulseRate = pulseVital?.readings[0]?.find(({ clinicalelementid }) => clinicalelementid === 'VITALS.PULSE.RATE');

    const o2SaturationVital = vitals.find((vital) => vital.key === 'O2SATURATION');
    const o2SaturationRate = o2SaturationVital?.readings[0]?.find(
        ({ clinicalelementid }) => clinicalelementid === 'VITALS.O2SATURATION'
    );

    const temperatureVital = vitals.find((vital) => vital.key === 'TEMPERATURE');
    const temperatureValue = temperatureVital?.readings[0]?.find(
        ({ clinicalelementid }) => clinicalelementid === 'VITALS.TEMPERATURE'
    );

    const respirationRateVital = vitals.find((vital) => vital.key === 'RESPIRATIONRATE');
    const respirationRateValue = respirationRateVital?.readings[0]?.find(
        ({ clinicalelementid }) => clinicalelementid === 'VITALS.RESPIRATIONRATE'
    );

    const values: readonly {
        readonly icon: IconName;
        readonly key: string;
        readonly title: string;
        readonly valid: boolean;
        readonly value: ReactNode;
    }[] = (
        [
            {
                icon: 'height',
                key: 'HEIGHT',
                title: 'Height',
                valid: Boolean(heightValue?.value),
                value: (
                    <Styled.ValueContainer>
                        <Styled.VitalValue>{heightInFeet.feet}</Styled.VitalValue>
                        <Styled.VitalUnit> ft</Styled.VitalUnit>
                        <Styled.VitalValue>{heightInFeet.inches}</Styled.VitalValue>
                        <Styled.VitalUnit> in</Styled.VitalUnit>
                    </Styled.ValueContainer>
                ),
            },
            {
                icon: 'weight',
                key: 'WEIGHT',
                title: 'Weight',
                valid: Boolean(weightValue?.value),
                value: (
                    <Fragment>
                        <Styled.VitalValue>{gramToPound(Number(weightValue?.value))}</Styled.VitalValue>
                        <Styled.VitalUnit>lbs</Styled.VitalUnit>
                    </Fragment>
                ),
            },
            {
                icon: 'bmi',
                key: 'BMI',
                title: 'BMI',
                valid: Boolean(bmiValue?.value),
                value: (
                    <Fragment>
                        <Styled.VitalValue>{bmiValue?.value}</Styled.VitalValue>
                        <Styled.VitalUnit>Kg/m2</Styled.VitalUnit>
                    </Fragment>
                ),
            },
            {
                icon: 'blood-pressure',
                key: 'BLOODPRESSURE',
                title: 'Blood pressure',
                valid: Boolean(bloodPressureSystolic?.value && bloodPressureDiastolic?.value),
                value: (
                    <Fragment>
                        <Styled.VitalValue>
                            {bloodPressureSystolic?.value}/{bloodPressureDiastolic?.value}
                        </Styled.VitalValue>
                        <Styled.VitalUnit>mmHg</Styled.VitalUnit>
                    </Fragment>
                ),
            },
            {
                icon: 'pulse',
                key: 'PULSE',
                title: 'Pulse',
                valid: Boolean(pulseRate?.value),
                value: (
                    <Fragment>
                        <Styled.VitalValue>{pulseRate?.value}</Styled.VitalValue>
                        <Styled.VitalUnit>bpm</Styled.VitalUnit>
                    </Fragment>
                ),
            },
            {
                icon: 'o2',
                key: 'O2SATURATION',
                title: 'O2 saturation',
                valid: Boolean(o2SaturationRate?.value),
                value: (
                    <Fragment>
                        <Styled.VitalValue>{o2SaturationRate?.value}</Styled.VitalValue>
                        <Styled.VitalUnit>%</Styled.VitalUnit>
                    </Fragment>
                ),
            },
            {
                icon: 'temperature',
                key: 'TEMPERATURE',
                title: 'Temperature',
                valid: Boolean(temperatureValue?.value),
                value: (
                    <Fragment>
                        <Styled.VitalValue>{temperatureValue?.value}</Styled.VitalValue>
                        <Styled.VitalUnit>F°</Styled.VitalUnit>
                    </Fragment>
                ),
            },
            {
                icon: 'respiration-rate',
                key: 'RESPIRATIONRATE',
                title: 'Respiration rate',
                valid: Boolean(respirationRateValue?.value),
                value: <Styled.VitalValue>{respirationRateValue?.value}</Styled.VitalValue>,
            },
        ] as const
    ).filter(({ valid }) => valid);

    if (values.length === 0) {
        return null;
    }

    return (
        <div data-testid="vitals">
            <RootStyled.SectionTitle>Vitals</RootStyled.SectionTitle>
            <Styled.Container>
                {values.map(({ key, title, value, icon }) => (
                    <Styled.ItemContainer key={key}>
                        <Styled.IconContainer>
                            <Icon name={icon} size={1.5} />
                        </Styled.IconContainer>
                        <div>
                            <Styled.VitalTitle>{title}</Styled.VitalTitle>
                            <Styled.ValueContainer>{value}</Styled.ValueContainer>
                        </div>
                    </Styled.ItemContainer>
                ))}
            </Styled.Container>
        </div>
    );
};

export { Vitals };
