import { Text, ListItem as VuiListItem } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const VaccineContainer = styled.article`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${rem(4)};
    width: 100%;
`;

const VaccineName = styled(Text).attrs(() => ({ type: 'sub1' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
    text-transform: capitalize;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
`;

const ApplicationDate = styled(Text).attrs(() => ({ type: 'body2' }))`
    color: ${({ theme }) => theme.vui.colors.text.graySubtle};
`;

const ListItem = styled(VuiListItem)`
    width: 100%;
    padding-inline: ${({ theme }) => theme.page.pagePaddingInline};
    div:first-child {
        overflow: unset;
        width: ${rem(24)};
    }
    div:nth-child(2) {
        width: 0;
    }
    hr {
        width: calc(100% - ${({ theme }) => theme.page.pagePaddingInline});
        height: 0;
        border-top: thin solid ${({ theme }) => theme.vui.colors.border.divider.default};
    }
`;

export { ApplicationDate, VaccineContainer, VaccineName, ListItem };
