import { memo } from 'react';
import type { FC, PropsWithChildren } from 'react';

import * as Styled from './styles';
import { useAuthState } from 'hooks';
import { useDebugging } from 'hooks/use-debugging';

const DebuggingPage: FC<PropsWithChildren> = memo(({ children }) => {
    const { isLoggedIn, isLoggingIn } = useAuthState();
    const { state: debuggingState } = useDebugging();

    return (
        <Styled.RootContainer>
            <Styled.PageContainer>{children}</Styled.PageContainer>
            <Styled.DebugContainer>
                {`Is logged in: ${isLoggedIn.toString()}`}
                <br />
                {`Is logging in: ${isLoggingIn.toString()}`}
                <Styled.EventContainer>Debugging events:</Styled.EventContainer>
                {[...debuggingState].reverse().map((element, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Styled.EventContainer key={index}>
                        <Styled.MinimalPadding>{debuggingState.length - index}.</Styled.MinimalPadding>
                        <div>{element}</div>
                    </Styled.EventContainer>
                ))}
            </Styled.DebugContainer>
        </Styled.RootContainer>
    );
});

export { DebuggingPage };
