import type { FC } from 'react';
import { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { MessageReply } from './components/message-reply';
import * as Styled from './styles';
import { CenteredSpinner } from 'components/centered-spinner';
import { useMessageThread } from 'data/hooks/use-message-thread-query';
import { useProviders } from 'data/hooks/use-providers';
import { Countly } from 'modules/countly';
import type { MessageSource } from 'types';

type ParameterType = 'source' | 'threadId';

const MessageReplyContent: FC = () => {
    const { threadId, source } = useParams<ParameterType>();
    const { data, isLoading, refetch } = useMessageThread(Number(threadId), (source as MessageSource) ?? '', !!source);
    const { data: patientProvidersData, isLoading: providersLoading } = useProviders();
    const messageThread = useMemo(() => data?.results[0], [data]);

    useEffect(() => {
        const messagesInThread = data?.results.length;
        if (messagesInThread)
            Countly.addEvent({
                key: 'viewMessage',
                segmentation: {
                    messageThreadId: Number(threadId),
                    messagesInThread,
                    messagingVersion: 2,
                    source: 'messaging',
                },
            });
    }, [data, threadId]);

    if (isLoading || providersLoading) {
        return <CenteredSpinner />;
    }

    if (!messageThread) {
        // TODO: Replace this with a proper 404 message component
        return <div data-testid="no-message-thread">No message thread found</div>;
    }

    return (
        <Styled.Container>
            <MessageReply
                messageThread={messageThread}
                messageThreadId={messageThread.message_thread_id}
                patientProviders={patientProvidersData?.results}
                refetchThread={refetch}
            />
        </Styled.Container>
    );
};

export { MessageReplyContent };
