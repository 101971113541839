import { CardHeader, Collapse, IconButton } from '@material-ui/core';
import { useCallback, useMemo, useState } from 'react';
import type { FC } from 'react';

import * as Styled from './styles';
import type { AthenaPortalUser } from 'types/athena-portal-user';
import type { Message } from 'types/message';
import type { Provider } from 'types/provider';
import { getDateTag } from 'utils/date';

interface MessageCardProps {
    readonly isMostRecentMessage: boolean;
    readonly message: Message;
    readonly patientProviders?: readonly Provider[];
    readonly provider: AthenaPortalUser | null;
    readonly isUnread: boolean;
    readonly navigateToReplyScreen?: () => void;
}

const MessageCard: FC<MessageCardProps> = ({
    isMostRecentMessage,
    message,
    navigateToReplyScreen,
    patientProviders,
    provider,
    isUnread,
}) => {
    const [expanded, setExpanded] = useState<boolean>(isMostRecentMessage || isUnread);
    const { timestamp, text, from, to } = message;
    const messageDate = getDateTag(timestamp, 'shortDateWithTime');

    const messageUserAvatar = useMemo(() => {
        const providerObject = patientProviders?.find((element) => element.providerid === from?.providerid);
        if (providerObject?.image?.url) {
            return <Styled.MessageAvatar alt={providerObject.displayname ?? ''} src={providerObject.image.url} />;
        }

        if (from?.username === provider?.username) {
            return (
                <Styled.MessageAvatar>
                    <Styled.DefaultProvider name="icProvider" size={2} />
                </Styled.MessageAvatar>
            );
        }

        return <Styled.MessageAvatar>{(from?.displayname ?? '-').charAt(0)}</Styled.MessageAvatar>;
    }, [provider?.username, from, patientProviders]);

    const handleExpand = useCallback(() => {
        setExpanded((previousState) => !previousState);
    }, []);

    const messageHeaderTitle = useMemo(
        () => (
            <Styled.MessageTitle>
                <Styled.MessageSenderTitle>{from?.displayname}</Styled.MessageSenderTitle>
                <Styled.MessageDate>{messageDate}</Styled.MessageDate>
            </Styled.MessageTitle>
        ),
        [from?.displayname, messageDate]
    );

    const cardReplyAction = useMemo(
        () => (
            <IconButton onClick={navigateToReplyScreen}>
                <Styled.ReplyButtonIcon name="reply" size={1.25} />
            </IconButton>
        ),
        [navigateToReplyScreen]
    );

    const expandedHeaderTitle = to?.displayname ? `To: ${to?.displayname ?? '-'}` : null;
    const messageHeader = useMemo(
        () => (
            <CardHeader
                action={navigateToReplyScreen && expanded ? cardReplyAction : null}
                avatar={messageUserAvatar}
                onClick={handleExpand}
                subheader={<Styled.MessagePreview>{expanded ? expandedHeaderTitle : text}</Styled.MessagePreview>}
                title={messageHeaderTitle}
            />
        ),
        [
            navigateToReplyScreen,
            cardReplyAction,
            messageUserAvatar,
            handleExpand,
            expanded,
            expandedHeaderTitle,
            text,
            messageHeaderTitle,
        ]
    );

    return (
        <Styled.MessageCard $isMostRecentMessage={isMostRecentMessage}>
            {messageHeader}
            <Collapse in={expanded} timeout="auto" unmountOnExit={true}>
                <Styled.MessageContent>{text}</Styled.MessageContent>
            </Collapse>
        </Styled.MessageCard>
    );
};

export { MessageCard };
