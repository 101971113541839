import { rem } from 'polished';
import styled from 'styled-components';

const AnalyteEntryLoader = styled.div`
    padding-bottom: ${rem(10)};
`;

const AnalyteNameLoader = styled.div`
    margin-bottom: ${rem(4)};
`;

const ContainerLoader = styled.div`
    padding: ${rem(16)};
`;

const ProviderInfoLoader = styled.div`
    display: flex;
    align-items: center;
    padding: ${rem(16)};
    margin-bottom: 0.8125rem;
`;

const ProviderNameLoader = styled.div`
    padding-left: ${rem(10)};
`;

const LabNameLoader = styled.div`
    margin-bottom: ${rem(8)};
`;

const LabLocationLoader = styled.div`
    margin-bottom: ${rem(10)};
`;

export {
    ContainerLoader,
    LabNameLoader,
    LabLocationLoader,
    ProviderInfoLoader,
    ProviderNameLoader,
    AnalyteEntryLoader,
    AnalyteNameLoader,
};
