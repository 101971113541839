import * as Sentry from '@sentry/react';
import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';

import { healthRecordsApi } from '../health-records-api-config';
import type { ImagingResult } from 'types';

interface ImagingResultsParameters {
    readonly enabled?: boolean;
}

const useImagingResults = (params: ImagingResultsParameters, ignoreCache?: boolean): UseQueryResult<readonly ImagingResult[]> => {
    const { enabled = true } = params;
    const path = `/imaging_results/`;
    const customHeaders = {};
    // eslint-disable-next-line functional/immutable-data
    if (ignoreCache) customHeaders['Cache-Control'] = 'no-cache';

    return useQuery(
        'imaging-results',
        async () => {
            const response = await healthRecordsApi.get<{ readonly imagingresults: readonly ImagingResult[] }>(path, {
                headers: customHeaders,
            });
            return response.data.imagingresults;
        },
        {
            enabled,
            onError: (error: unknown) => Sentry.captureException(error, { extra: { path } }),
        }
    );
};

export { useImagingResults };
