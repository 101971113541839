import { useUrlState } from '@village/tools';
import { Button } from '@village/ui';
import type { FC } from 'react';
import { useEffect, Fragment, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MedicationVirtualizedList } from './components/medication-virtualized-list';
import * as Styled from './styles';
import { Countly } from '../../modules/countly';
import { SearchField } from 'components/search-field';
import { useMedications } from 'data/hooks/use-medication-query';
import { useDebounce } from 'hooks/use-debounce';
import { useMessaging } from 'hooks/use-messaging';
import { useNativeControls } from 'hooks/use-native-controls';
import { MedicationsLoading } from 'pages/medications/medication-loading';
import { RoundedContainer } from 'pages/page-layouts';
import type { Medication } from 'types';

const debounceValue = 250;
const SelectPrescription: FC = () => {
    const navigate = useNavigate();
    const { search: queryParams } = useLocation();
    const { data, isLoading } = useMedications(false);
    const { setMessagingFields } = useMessaging();
    const [searchString, setSearchString] = useState<string>('');
    const [selectedMedication, setSelectedMedication] = useState<Medication>();
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const searchStringDebounced = useDebounce<string>(searchString, debounceValue);
    const [redirectToRouter] = useUrlState('redirect-to');

    useEffect(() => {
        Countly.addEvent({ key: 'viewSelectPrescription' });
    }, []);

    useEffect(() => {
        if (searchStringDebounced.length > 0) {
            Countly.addEvent({ key: 'viewSelectPrescription.search' });
        }
    }, [searchStringDebounced]);

    useEffect(() => {
        if (redirectToRouter) {
            setMessagingFields({
                messageInitiatedFrom: redirectToRouter,
            });
        }
    }, [redirectToRouter, setMessagingFields]);

    const content = useMemo(() => {
        if (isLoading) {
            return <MedicationsLoading />;
        }

        if (!data?.results.length) {
            return (
                <Styled.NoPrescriptions data-testid="no-prescriptions">
                    Active prescriptions will appear here.
                </Styled.NoPrescriptions>
            );
        }

        const filteredResults = data?.results.filter((medication) =>
            medication.medication_name.toLowerCase().includes(searchStringDebounced.toLowerCase())
        );
        return (
            <MedicationVirtualizedList
                handleSelectMedication={(medication) => {
                    Countly.addEvent({ key: 'viewSelectPrescription.clickList' });
                    setSelectedMedication(medication);
                }}
                medications={filteredResults}
                selectedMedicationId={selectedMedication?.medication_entry_id}
            />
        );
    }, [data?.results, isLoading, searchStringDebounced, selectedMedication?.medication_entry_id]);

    const selectClick = useCallback(() => {
        if (selectedMedication === undefined) {
            setShowWarning(true);
        }

        if (selectedMedication) {
            setMessagingFields({
                medicationName: selectedMedication.medication_name,
                messageType: 'PATIENTCASE_REFILL',
            });
            navigate(`/select-recipient${queryParams}&messageType=PATIENTCASE_REFILL`);
        }
    }, [navigate, selectedMedication, setMessagingFields, queryParams]);

    const selectPrescriptionUnlisted = useCallback(() => {
        setMessagingFields({
            defaultSubject: 'Prescriptions and refills',
            medicationName: 'Refill another prescription',
            messageType: 'PATIENTCASE_REFILL',
        });
        navigate(`/select-recipient${queryParams}&messageType=PATIENTCASE_REFILL`);

        Countly.addEvent({ key: 'viewSelectPrescription.renewAnotherRx' });
    }, [navigate, setMessagingFields, queryParams]);

    const headerText = useMemo(
        () => (
            <Fragment>
                Select Prescription
                <Styled.InfoText>Some prescription refills may require a consult by your provider.</Styled.InfoText>
            </Fragment>
        ),
        []
    );

    useNativeControls({
        ipcEvents: ['showControls'],
        source: 'messaging',
    });

    return (
        <RoundedContainer headerContent={headerText}>
            <Styled.Header>
                <Styled.SearchFieldContainer>
                    <SearchField
                        onClick={() => Countly.addEvent({ key: 'viewSelectPrescription.clickSearch' })}
                        placeholder="Search my prescriptions"
                        searchString={searchString}
                        setSearchString={setSearchString}
                    />
                </Styled.SearchFieldContainer>
            </Styled.Header>
            <Styled.ListTitleBlock>
                <Styled.TitleText1>Prescription not listed? </Styled.TitleText1>
                <Styled.TitleText2 onClick={selectPrescriptionUnlisted}>Renew another Rx</Styled.TitleText2>
            </Styled.ListTitleBlock>
            {showWarning ? (
                <Styled.WarningText data-testid="please-select-warning">
                    <Styled.DangerIcon name="danger" size={1} />
                    Please select a prescription
                </Styled.WarningText>
            ) : null}
            <Styled.ScrollableContainer>{content}</Styled.ScrollableContainer>
            {data && data.results.length > 0 ? (
                <Styled.Footer>
                    <Button data-testid="select-button" fullWidth={true} onClick={selectClick}>
                        Select
                    </Button>
                </Styled.Footer>
            ) : null}
        </RoundedContainer>
    );
};

export { SelectPrescription };
