import { rem } from 'polished';
import styled from 'styled-components';

const LabName = styled.span`
    font-weight: 600;
    font-size: ${rem(16)};
    line-height: 1.2;
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.color.shark};
`;

const LabDate = styled.div`
    font-size: ${rem(14)};
    line-height: 1.3;
    color: ${({ theme }) => theme.color.brightGray};
`;

const LabInfo = styled.div`
    display: grid;
    row-gap: 0.25rem;
`;

export { LabName, LabDate, LabInfo };
