import * as Sentry from '@sentry/react';

const getSessionStorage = <T = unknown>(key: string): T | null => {
    if (!key) return null;

    try {
        const sessionString = window.sessionStorage.getItem(key);
        return sessionString ? (JSON.parse(sessionString) as T) : null;
    } catch (error: unknown) {
        Sentry.captureException(error);
        return null;
    }
};

const setSessionStorage = (key: string, data: unknown): void => {
    if (typeof data === 'string') {
        window.sessionStorage.setItem(key, data);
    } else {
        window.sessionStorage.setItem(key, JSON.stringify(data));
    }
};

const removeSessionStorageItem = (key: string): void => {
    window.sessionStorage.removeItem(key);
};

export { getSessionStorage, setSessionStorage, removeSessionStorageItem };
