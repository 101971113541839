import { rem } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
    padding: ${rem(16)};
    border-bottom: 1px solid ${({ theme }) => theme.color.athensGray};
`;

const AnalyteContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const AnalyteInfo = styled.div`
    flex: 1;
`;

const AnalyteAbnormalInterpretation = styled.div`
    font-weight: 600;
    font-size: ${rem(14)};
    color: ${({ theme }) => theme.color.white};
    background: ${({ theme }) => theme.color.harleyDavidsonOrange};
    border-radius: ${rem(16)};
    padding: ${rem(4)} ${rem(12)};
`;

const AnalyteInterpretation = styled.div`
    font-weight: 600;
    font-size: ${rem(14)};
    color: ${({ theme }) => theme.color.brightGray};
`;

const AnalyteName = styled.div`
    font-weight: 600;
    font-size: ${rem(16)};
    margin-bottom: ${rem(4)};
    color: ${({ theme }) => theme.color.brightGray};
    line-height: 1.2;
`;

const AnalyteNote = styled.div`
    padding: ${rem(12)};
    border-radius: ${rem(4)};
    color: ${({ theme }) => theme.color.stormGray};
    margin-top: ${rem(12)};
    background: ${({ theme }) => theme.color.ghostWhite};
`;

const AnalyteValueUnitContainer = styled.div`
    display: flex;
    align-items: baseline;
    margin-bottom: ${rem(8)};
    line-height: 1;
`;

const AnalyteValue = styled.div`
    font-size: ${rem(22)};
    color: ${({ theme }) => theme.color.brightGray};
    margin-right: ${rem(4)};
`;

const AnalyteUnit = styled.div`
    font-size: ${rem(13)};
    color: ${({ theme }) => theme.color.slateGray};
`;

const ReferenceRange = styled.div`
    font-weight: normal;
    font-size: ${rem(12)};
    color: ${({ theme }) => theme.color.slateGray};
    line-height: 1.4;
`;

export {
    Container,
    AnalyteContainer,
    AnalyteInfo,
    AnalyteAbnormalInterpretation,
    AnalyteInterpretation,
    AnalyteName,
    AnalyteNote,
    AnalyteValue,
    AnalyteUnit,
    AnalyteValueUnitContainer,
    ReferenceRange,
};
