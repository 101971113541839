import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const SuggestedContainer = styled(Text).attrs(() => ({ type: 'sub2' }))`
    padding: ${rem(24)} ${rem(16)} 0 ${rem(16)};
    color: ${({ theme }) => theme.vui.colors.primary.navy};
    justify-content: space-between;
    display: flex;
`;

const Expand = styled.span`
    color: ${({ theme }) => theme.color.primaryBlue};
`;

const NoProvider = styled(Text).attrs(() => ({ type: 'sub1' }))`
    color: ${({ theme }) => theme.color.primaryBlue};
    padding: ${rem(16)};
`;

const LoaderContainer = styled.section`
    padding-top: 1rem;
`;

const OptionsContainer = styled.div`
    overflow: auto;
    padding: 0 1rem;
`;

export { SuggestedContainer, LoaderContainer, OptionsContainer, Expand, NoProvider };
