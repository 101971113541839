import { Text as VUIText } from '@village/ui';
import styled from 'styled-components';

const Container = styled.div`
    background-color: ${({ theme }) => theme.vui.colors.background.white};
    border: 0.5px solid ${({ theme }) => theme.vui.colors.border.default.default};
    padding: 0.75rem;
    gap: 0.75rem;
    border-radius: 8px;
    display: flex;
`;

const ContainerNoPharmacy = styled.div`
    align-items: center;
    background-color: ${({ theme }) => theme.vui.colors.secondary.whiteCatskill};
    padding: 0.75rem;
    border-radius: 8px;
`;

const BoxNoPharmacy = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Box = styled.div`
    display: flex;
    align-items: center;
`;

const TitleNoPharmacy = styled(VUIText).attrs(() => ({ type: 'sub1' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
    padding: 0.25rem 0;
    text-align: center;
`;

const TextNoPharmacy = styled(VUIText).attrs(() => ({ type: 'body2' }))`
    color: ${({ theme }) => theme.vui.colors.text.graySubtle};
    padding: 0.25rem 0;
    text-align: center;
`;

const Title = styled(VUIText).attrs(() => ({ type: 'caption' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
    margin: 0.25rem 0;
`;

const Text = styled(VUIText).attrs(() => ({ type: 'sub1' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
    margin: 0.25rem 0;
`;

const Text2 = styled(VUIText).attrs(() => ({ type: 'body2' }))`
    color: ${({ theme }) => theme.vui.colors.text.graySubtle};
    margin: 0.25rem 0;
`;

const FlexBox = styled.div`
    flex: 1;
`;

const ButtonContainer = styled.div`
    padding: 0.25rem 0;
`;

const IconCloud = styled.div`
    align-items: center;
    background-color: ${({ theme }) => theme.vui.colors.background.gray};
    padding: 0.75rem;
`;

export {
    Container,
    Box,
    Title,
    Text,
    Text2,
    ContainerNoPharmacy,
    FlexBox,
    IconCloud,
    ButtonContainer,
    TitleNoPharmacy,
    TextNoPharmacy,
    BoxNoPharmacy,
};
