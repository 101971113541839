import { rem } from 'polished';
import styled from 'styled-components';

const Header = styled.div`
    padding: 0.5rem 1rem 1rem;
    font-size: ${rem(22)};
    line-height: ${rem(28)};
    font-weight: 600;
    color: ${({ theme }) => theme.color.catalinaBlue};
`;

const Subheader = styled.div`
    padding: 0 ${rem(20)} ${rem(15)} ${rem(16)};
    font-size: ${rem(15)};
    color: ${({ theme }) => theme.vui.colors.primary.navy80};
    line-height: ${rem(18)};
`;

const CardContainer = styled.div`
    display: grid;
    row-gap: ${rem(16)};
    margin: ${rem(24)} ${rem(16)} ${rem(8)};
`;

export { Header, Subheader, CardContainer };
