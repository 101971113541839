import { useCallback, useRef } from 'react';

const useThrottle = (callback: () => void, delay = 0): (() => void) => {
    const lastCalledTimestamp = useRef(Date.now());

    return useCallback(() => {
        if (Date.now() - lastCalledTimestamp.current > delay) {
            callback();
            // eslint-disable-next-line functional/immutable-data
            lastCalledTimestamp.current = Date.now();
        }
    }, [callback, delay]);
};

export { useThrottle };
