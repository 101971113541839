import { Avatar, Box, TextareaAutosize } from '@material-ui/core';
import { Text } from '@village/ui';
import styled from 'styled-components';

import { Icon } from 'components/icon';

const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    overflow: scroll;
`;

const MessageInput = styled(TextareaAutosize)`
    width: 100%;
    border: none;
    padding: 1rem;
    color: ${({ theme }) => theme.color.brightGray};
    border-top: 1px solid ${({ theme }) => theme.color.athensGray};
    border-bottom: 1px solid ${({ theme }) => theme.color.athensGray};
    resize: none;
    &:focus {
        outline: none;
    }
    ::placeholder {
        color: ${({ theme }) => theme.color.shuttleGray};
    }
`;

const ReplyCard = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const MessagesList = styled.div`
    padding: 0 1rem;
`;

const MessageAvatar = styled(Avatar)`
    height: 2.5rem;
    width: 2.5rem;
    background-color: ${({ theme }) => theme.color.irisBlue};
`;

const DefaultProvider = styled(Icon)`
    background-color: ${({ theme }) => theme.color.white};
`;

const EmptyMessageError = styled.div`
    padding: 0.5rem 1rem;
    display: flex;
`;

const EmptyMessageErrorText = styled.p`
    padding-left: 0.5rem;
    font-size: 0.8rem;
    line-height: 1rem;
    color: ${({ theme }) => theme.color.errorRed};
`;

const ErrorMessageIcon = styled(Icon)`
    color: ${({ theme }) => theme.color.errorRed};
`;

const Sender = styled(Text)`
    color: ${({ theme }) => theme.color.brightGray};
`;

const Subject = styled(Text)`
    color: ${({ theme }) => theme.color.brightGray};
`;

const BorderCity = styled.div`
    border-top: 1px solid ${({ theme }) => theme.color.athensGray};
    border-bottom: 1px solid ${({ theme }) => theme.color.athensGray};
`;

export {
    Container,
    BorderCity,
    DefaultProvider,
    EmptyMessageError,
    EmptyMessageErrorText,
    ErrorMessageIcon,
    MessageAvatar,
    MessagesList,
    ReplyCard,
    MessageInput,
    Sender,
    Subject,
};
