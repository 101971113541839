import type { LegacyRef, FC } from 'react';
import { createRef, memo, useRef } from 'react';
import type { ListRowProps } from 'react-virtualized';
import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import type { CellMeasurerChildProps } from 'react-virtualized/dist/es/CellMeasurer';

import * as Styled from './styles';
import { TestResultCard } from './test-result-card';
import { MessageLoader } from 'pages/messaging/message-list/components/message-loader';
import type { TestResultWithMessageData } from 'types';

interface TestResultsVirtualizedListProps {
    readonly testResults: readonly TestResultWithMessageData[];
    readonly onScroll?: ({ clientHeight, scrollHeight, scrollTop }) => void;
    readonly onRowsRendered?: ({ overscanStartIndex, overscanStopIndex, startIndex, stopIndex }) => void;
    readonly isFetchingNextPage: boolean;
}

const TestResultsVirtualizedList: FC<TestResultsVirtualizedListProps> = memo(
    ({ testResults, onScroll, onRowsRendered, isFetchingNextPage }) => {
        const listRef = createRef<List>();

        const cache = useRef(
            new CellMeasurerCache({
                defaultHeight: 91,
                fixedWidth: true,
            })
        );

        const rowRenderer = ({ index, key, parent, style }: ListRowProps): React.ReactNode => (
            <CellMeasurer key={key} cache={cache.current} columnIndex={0} parent={parent} rowIndex={index}>
                {({ measure, registerChild }: CellMeasurerChildProps) => (
                    <div ref={registerChild as LegacyRef<HTMLDivElement>} style={style}>
                        {isFetchingNextPage && index === testResults.length ? (
                            <MessageLoader measureListItemHeight={measure} />
                        ) : (
                            <TestResultCard testResult={testResults[index]} />
                        )}
                    </div>
                )}
            </CellMeasurer>
        );

        return (
            <Styled.ListContainer>
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            ref={listRef}
                            deferredMeasurementCache={cache.current}
                            height={height}
                            onRowsRendered={onRowsRendered}
                            onScroll={onScroll}
                            rowCount={testResults.length + (isFetchingNextPage ? 1 : 0)}
                            rowHeight={cache.current.rowHeight}
                            rowRenderer={rowRenderer}
                            width={width}
                        />
                    )}
                </AutoSizer>
            </Styled.ListContainer>
        );
    }
);

export { TestResultsVirtualizedList };
