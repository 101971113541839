import { rem } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
    display: grid;
    padding: ${rem(16)} 0;
    row-gap: 1rem;
`;

export { Container };
