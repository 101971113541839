import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const MedicationName = styled(Text).attrs(() => ({ type: 'sub1' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
`;

const RefillsRemaining = styled(Text).attrs(() => ({ type: 'body2' }))`
    color: ${({ theme }) => theme.vui.colors.text.graySubtle};
`;

const MedicationInfo = styled.div`
    display: grid;
    row-gap: ${rem(4)};
`;

const Container = styled.div`
    display: flex;
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1rem;
`;

const IconCloud = styled.div`
    align-items: center;
    background-color: ${({ theme }) => theme.vui.colors.background.gray};
    padding: 0.75rem;
    gap: 0.75rem;
    border-radius: 8px;
`;

export { MedicationName, RefillsRemaining, MedicationInfo, Container, IconContainer, IconCloud };
