import * as Sentry from '@sentry/react';
import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';

import { healthRecordsApi } from '../health-records-api-config';
import type { ApiResponse } from 'types/api';
import type { LabResult } from 'types/lab';

interface GetLabsQueryParameters {
    readonly show_portal_only: boolean;
    readonly enabled?: boolean;
}

const useLabs = (queryParameters: GetLabsQueryParameters, ignoreCache?: boolean): UseQueryResult<ApiResponse<LabResult>> => {
    const { show_portal_only, enabled = true } = queryParameters;
    const path = `/labs/?show_portal_only=${show_portal_only.toString()}`;
    const customHeaders = {};
    // eslint-disable-next-line functional/immutable-data
    if (ignoreCache) customHeaders['Cache-Control'] = 'no-cache';

    return useQuery(
        'labs',
        async () => {
            const response = await healthRecordsApi.get<ApiResponse<LabResult>>(path, { headers: customHeaders });
            return response.data;
        },
        {
            enabled,
            onError: (error: unknown) => Sentry.captureException(error, { extra: { path } }),
        }
    );
};

export { useLabs };
