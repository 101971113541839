import { useContext } from 'react';

import type { DebuggingContextType } from 'contexts/debugging';
import { DebuggingContext } from 'contexts/debugging';

const useDebugging = (): DebuggingContextType => {
    const context = useContext(DebuggingContext);

    if (context === undefined) {
        throw new Error('useDebugging must be used within a DebuggingProvider');
    }

    return context;
};

export { useDebugging };
