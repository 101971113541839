import { useToggle } from '@village/tools';
import { Fragment, useCallback, useEffect } from 'react';
import type { FC, ReactNode } from 'react';

import { LengthWarning } from './length-warning';
import * as Styled from './styles';

export interface MessageTextAreaProps {
    readonly messageLengthLimit: number;
    readonly messageLengthWarning: number;
    readonly message: string;
    readonly onChangeMessage: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    readonly visitOption?: string;
    readonly messagePlaceholder: string;
    readonly sendTriggered: boolean;
    readonly hasProvider?: boolean;
    readonly minRows?: number;
    readonly maxRows?: number;
    readonly disabled?: boolean;
    readonly onFocus?: () => void;
    readonly autoFocus?: boolean;
    readonly headerComponent?: ReactNode;
    readonly setValidated: React.Dispatch<React.SetStateAction<boolean>>;
    readonly setSendTriggered: React.Dispatch<React.SetStateAction<boolean>>;
    readonly ref?: React.RefObject<HTMLTextAreaElement>;
}

export const appPrefTitle = 'Appt Preference';

export const MessageTextArea: FC<MessageTextAreaProps> = ({
    messageLengthLimit,
    messageLengthWarning,
    message,
    visitOption,
    onChangeMessage,
    messagePlaceholder,
    sendTriggered,
    hasProvider = false,
    minRows,
    maxRows,
    disabled = false,
    onFocus,
    autoFocus = false,
    headerComponent,
    setValidated,
    setSendTriggered,
    ref,
}) => {
    const { value: displayEditNote, switchOff: hideEditNote } = useToggle(true);
    const {
        value: displayMissingMessageWarning,
        switchOff: hideMissingMessageWarning,
        switchOn: showMissingMessageWarning,
    } = useToggle(false);

    const dismissNote = useCallback(() => {
        hideEditNote();
        hideMissingMessageWarning();
        setValidated(false);
        if (onFocus) onFocus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hideEditNote, hideMissingMessageWarning, onFocus]);

    useEffect(() => {
        if (sendTriggered) {
            if (message.trim() === '') {
                showMissingMessageWarning();
                setValidated(false);
            } else if (message.length > messageLengthLimit) {
                setValidated(false);
            } else {
                setValidated(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendTriggered]);

    useEffect(() => {
        if (sendTriggered) setSendTriggered(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendTriggered]);

    return (
        <Fragment>
            {displayEditNote ? (
                <Styled.Warning data-testid="edit-label">
                    <Styled.WarningIcon name="warning" />
                    Note: tap the message to edit
                </Styled.Warning>
            ) : null}
            <LengthWarning
                messageLength={message.length}
                messageLengthLimit={messageLengthLimit}
                messageLengthWarning={messageLengthWarning}
            />
            {displayMissingMessageWarning ? (
                <Styled.Warning data-testid="blank-message-warning" type="error">
                    <Styled.ErrorIcon name="danger" />
                    Please enter message before sending
                </Styled.Warning>
            ) : null}
            {headerComponent}
            <Styled.MessageContainer>
                {visitOption ? (
                    <Fragment>
                        <Styled.MessageWhenPreferences>
                            <Styled.PreferencesTitle>Message</Styled.PreferencesTitle>
                            <Styled.MessageInput
                                ref={ref}
                                autoFocus={autoFocus}
                                data-testid="message-input-field"
                                disabled={disabled}
                                maxRows={maxRows}
                                minRows={minRows}
                                onChange={onChangeMessage}
                                onFocus={dismissNote}
                                placeholder={messagePlaceholder}
                                // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                                style={{ overflow: 'auto' }}
                                value={message}
                            />
                        </Styled.MessageWhenPreferences>
                        {!hasProvider ? (
                            <Styled.MessageWhenPreferences data-testid="no-provider-preferences">
                                <Styled.PreferencesTitle>Provider</Styled.PreferencesTitle>
                                <Styled.PreferencesText>No preference</Styled.PreferencesText>
                            </Styled.MessageWhenPreferences>
                        ) : null}
                        <div data-testid="visit-options-section">
                            <Styled.PreferencesTitle>{appPrefTitle}</Styled.PreferencesTitle>
                            <Styled.PreferencesText>{visitOption ?? ''}</Styled.PreferencesText>
                        </div>
                    </Fragment>
                ) : (
                    <Styled.MessageInput
                        ref={ref}
                        autoFocus={autoFocus}
                        data-testid="message-input-field"
                        disabled={disabled}
                        maxRows={maxRows}
                        minRows={minRows}
                        onChange={onChangeMessage}
                        onFocus={dismissNote}
                        placeholder={messagePlaceholder}
                        // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                        style={{ overflow: 'auto' }}
                        value={message}
                    />
                )}
            </Styled.MessageContainer>
        </Fragment>
    );
};
