import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
    padding: ${rem(16)} 0;
    color: ${({ theme }) => theme.color.brightGray};
`;

const SidePadding = styled.div`
    padding: 0 ${rem(16)};
    margin: 0 auto;
`;

const SectionTitle = styled(Text).attrs(() => ({ type: 'h4' }))`
    margin: ${rem(32)} 0 ${rem(16)};
    font-weight: 600;
`;

const MessageInReview = styled.div`
    padding: ${rem(16)};
    border-radius: ${rem(4)};
    margin-top: ${rem(16)};
    background: ${({ theme }) => theme.color.catskillWhite};
`;

const ResultTitle = styled(Text).attrs(() => ({ tag: 'p', type: 'titleCaps' }))`
    padding: ${rem(12)} ${rem(16)};
    margin: ${rem(16)} 0;
    font-weight: 600;
    background: ${({ theme }) => theme.color.ghostWhite};
`;

const ViewDocumentWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const ListContainer = styled.div`
    margin-bottom: ${rem(16)};
`;

const SimplePadding = styled.div`
    padding: ${rem(16)};
`;

export { Container, SidePadding, SectionTitle, ViewDocumentWrapper, MessageInReview, ResultTitle, ListContainer, SimplePadding };
