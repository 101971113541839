import type { FC, PropsWithChildren } from 'react';

import * as Styled from './styles';
import { Icon } from '../icon';
import type { IconName } from 'components/icon';

interface Props {
    readonly iconName: IconName;
    readonly onClick: () => void;
}

const CardButton: FC<PropsWithChildren<Props>> = ({ iconName, onClick, children }) => (
    <Styled.Container data-testid="card-button" onClick={onClick}>
        <Styled.Icon>
            <Icon name={iconName} size={1} />
        </Styled.Icon>
        <Styled.TitleText2>{children}</Styled.TitleText2>
    </Styled.Container>
);

export { CardButton };
