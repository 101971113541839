import type { EncounterDetail } from 'types';

export const ENCOUNTER_DETAILS_SUCCESS_RESPONSE: EncounterDetail = {
    date: '2022-06-20',
    diagnoses: [
        {
            description: 'suspected COVID-19',
            diagnosisid: 11368367,
            note: null,
            ranking: 0,
        },
        {
            description: 'adult health examination',
            diagnosisid: 11368368,
            note: null,
            ranking: 1,
        },
        {
            description: 'administration of diphtheria, pertussis, and tetanus vaccine',
            diagnosisid: 11368369,
            note: null,
            ranking: 2,
        },
        {
            description: 'screening for malignant neoplasm of cervix',
            diagnosisid: 11368370,
            note: null,
            ranking: 3,
        },
    ],
    documents: [
        {
            createddate: '2022-06-21',
            discusseddate: null,
            discusseduser: null,
            documentclass: 'LABRESULT',
            documentid: 41186523,
            documentname: 'urinalysis, dipstick',
            providernote: null,
        },
    ],
    hpi: {
        hpi: null,
        hpitoros: null,
        lastmodfieddatetime: null,
        lastmodifiedbye: null,
        sectionnote: null,
        sectionnotelastmodifiedatetime: null,
        sectionnotelastmodifiedby: null,
        summarytext:
            "<div class='hpi_template_summary'>\n<div class=template_name>Cough</div>\n<div class=reportedby><span class=reportedby_heading>Reported by</span> patient.</div>\n<span class=component>Severity:</span> improving\n<br><span class=component>Timing:</span> better\n<br><span class=component>Context:</span> non-smoker\n<br><span class=component>Associated Symptoms:</span> no fever; no chills; no chest pain; no heartburn; no nausea; no vomiting; no edema; no agitation; no wheezing; no post nasal drip\n</div>\n",
        templatedata: null,
        templates: null,
    },
    id: 11880939,
    orders: [
        {
            diagnosis: 'screening for malignant neoplasm of cervix',
            diagnosissnomed: 171149006,
            orders: [
                {
                    assigneduser: 'jlara39 STAFF',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: 12410505,
                    clinicalprovidername: 'Village Medical - Laboratory',
                    createduser: 'ewechsler2',
                    dateordered: '2022-06-21T09:39:00',
                    declinedreasontext: null,
                    departmentid: 332,
                    description: 'pap, IG + CT/NG + HPV mRNA E6/E7',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-06-21',
                    lastmodifiedby: 'ewechsler2',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 41186515,
                    orderingprovider: 'jlara39',
                    ordertype: 'Lab',
                    ordertypeid: 353395,
                    priority: 2,
                    providerid: 1218,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
                {
                    assigneduser: 'jlara39 STAFF',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: 12410505,
                    clinicalprovidername: 'Village Medical - Laboratory',
                    createduser: 'ewechsler2',
                    dateordered: '2022-06-21T09:39:00',
                    declinedreasontext: null,
                    departmentid: 332,
                    description: 'pap, IG + HPV mRNA E6/E7 + reflex HPV (16+18+45)',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-06-21',
                    lastmodifiedby: 'ewechsler2',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 41186516,
                    orderingprovider: 'jlara39',
                    ordertype: 'Lab',
                    ordertypeid: 353002,
                    priority: 2,
                    providerid: 1218,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
            ],
        },
        {
            diagnosis: 'adult health examination',
            diagnosissnomed: 268565007,
            orders: [
                {
                    assigneduser: 'jlara39 STAFF',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: 12410505,
                    clinicalprovidername: 'Village Medical - Laboratory',
                    createduser: 'ewechsler2',
                    dateordered: '2022-06-21T09:39:00',
                    declinedreasontext: null,
                    departmentid: 332,
                    description: 'CBC w/ auto diff',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-06-21',
                    lastmodifiedby: 'ewechsler2',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 41186517,
                    orderingprovider: 'jlara39',
                    ordertype: 'Lab',
                    ordertypeid: 342093,
                    priority: 2,
                    providerid: 1218,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
                {
                    assigneduser: 'jlara39 STAFF',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: 12410505,
                    clinicalprovidername: 'Village Medical - Laboratory',
                    createduser: 'ewechsler2',
                    dateordered: '2022-06-21T09:39:00',
                    declinedreasontext: null,
                    departmentid: 332,
                    description: 'CMP, serum or plasma',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-06-21',
                    lastmodifiedby: 'ewechsler2',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 41186518,
                    orderingprovider: 'jlara39',
                    ordertype: 'Lab',
                    ordertypeid: 342223,
                    priority: 2,
                    providerid: 1218,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
                {
                    assigneduser: 'jlara39 STAFF',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: 12410505,
                    clinicalprovidername: 'Village Medical - Laboratory',
                    createduser: 'ewechsler2',
                    dateordered: '2022-06-21T09:39:00',
                    declinedreasontext: null,
                    departmentid: 332,
                    description: 'lipid panel, serum',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-06-21',
                    lastmodifiedby: 'ewechsler2',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 41186519,
                    orderingprovider: 'jlara39',
                    ordertype: 'Lab',
                    ordertypeid: 342286,
                    priority: 2,
                    providerid: 1218,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
                {
                    assigneduser: 'jlara39 STAFF',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: 12410505,
                    clinicalprovidername: 'Village Medical - Laboratory',
                    createduser: 'ewechsler2',
                    dateordered: '2022-06-21T09:39:00',
                    declinedreasontext: null,
                    departmentid: 332,
                    description: 'TSH, serum or plasma',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-06-21',
                    lastmodifiedby: 'ewechsler2',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 41186520,
                    orderingprovider: 'jlara39',
                    ordertype: 'Lab',
                    ordertypeid: 342241,
                    priority: 2,
                    providerid: 1218,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
                {
                    assigneduser: 'jlara39 STAFF',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: 14288799,
                    clinicalprovidername: 'Vm_hou_aldine (Wag)',
                    createduser: 'ewechsler2',
                    dateordered: '2022-06-21T09:39:00',
                    declinedreasontext: null,
                    departmentid: 332,
                    description: 'urinalysis, dipstick',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-06-21',
                    lastmodifiedby: 'ewechsler2',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 41186521,
                    orderingprovider: 'jlara39',
                    ordertype: 'Lab',
                    ordertypeid: 342226,
                    priority: 2,
                    providerid: 1218,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
            ],
        },
        {
            diagnosis: 'administration of diphtheria, pertussis, and tetanus vaccine',
            diagnosissnomed: 399014008,
            orders: [
                {
                    assigneduser: 'jlara39 STAFF',
                    class: 'VACCINE',
                    classdescription: 'vaccine',
                    clinicalprovider: null,
                    clinicalproviderid: null,
                    clinicalprovidername: null,
                    createduser: 'ewechsler2',
                    dateordered: '2022-06-21T09:39:00',
                    declinedreasontext: null,
                    departmentid: null,
                    description: 'Adacel (Tdap Adolesn/Adult)(PF)2 Lf-(2.5-5-3-5)-5 Lf/0.5 mL IM syringe',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-06-20',
                    lastmodifiedby: 'ewechsler2',
                    lastmodifieddateime: null,
                    ndc: '49281040089',
                    orderid: 41186522,
                    orderingprovider: 'jlara39',
                    ordertype: 'Vaccine',
                    ordertypeid: 269042,
                    priority: 2,
                    providerid: null,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
            ],
        },
        {
            diagnosis: 'suspected COVID-19',
            diagnosissnomed: 840544004,
            orders: [
                {
                    assigneduser: 'jlara39 STAFF',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: 12410505,
                    clinicalprovidername: 'Village Medical - Laboratory',
                    createduser: 'ewechsler2',
                    dateordered: '2022-06-21T09:37:00',
                    declinedreasontext: null,
                    departmentid: 332,
                    description: 'rapid SARS CoV + SARS CoV 2 Ag, QL IA, respiratory specimen',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-06-21',
                    lastmodifiedby: 'ewechsler2',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 41186512,
                    orderingprovider: 'jlara39',
                    ordertype: 'Lab',
                    ordertypeid: 437842,
                    priority: 2,
                    providerid: 1218,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
                {
                    assigneduser: 'ATHENAFAX',
                    class: 'PRESCRIPTION',
                    classdescription: 'prescription - new',
                    clinicalprovider: null,
                    clinicalproviderid: 10954430,
                    clinicalprovidername: 'Roberto Alvarez MD',
                    createduser: 'ewechsler2',
                    dateordered: '2022-06-21T09:38:00',
                    declinedreasontext: null,
                    departmentid: 332,
                    description: 'azithromycin 250 mg tablet',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-06-21',
                    lastmodifiedby: 'ewechsler2',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 41186513,
                    orderingprovider: 'jlara39',
                    ordertype: 'Prescription',
                    ordertypeid: 191623,
                    priority: 2,
                    providerid: 1218,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
                {
                    assigneduser: 'ATHENAFAX',
                    class: 'PRESCRIPTION',
                    classdescription: 'prescription - new',
                    clinicalprovider: null,
                    clinicalproviderid: 10954430,
                    clinicalprovidername: 'Roberto Alvarez MD',
                    createduser: 'ewechsler2',
                    dateordered: '2022-06-21T09:39:00',
                    declinedreasontext: null,
                    departmentid: 332,
                    description: 'ascorbic acid (vitamin C) 1,000 mg tablet',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-06-21',
                    lastmodifiedby: 'ewechsler2',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 41186514,
                    orderingprovider: 'jlara39',
                    ordertype: 'Prescription',
                    ordertypeid: 188668,
                    priority: 2,
                    providerid: 1218,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
            ],
        },
        {
            diagnosis: 'malignant melanoma',
            diagnosissnomed: 372244006,
            orders: [
                {
                    assigneduser: 'dpato',
                    class: 'SURGERY',
                    classdescription: 'surgical order',
                    clinicalprovider: null,
                    clinicalproviderid: null,
                    clinicalprovidername: null,
                    createduser: 'dwilhelm11',
                    dateordered: '2022-07-01T15:38:00',
                    declinedreasontext: null,
                    departmentid: null,
                    description: 'hysteroscopy, surgical, with biopsy of endometrium and/or polypectomy (SURG)',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-07-01',
                    lastmodifiedby: 'ATHENA',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 523373,
                    orderingprovider: 'dpato',
                    ordertype: 'Surgery',
                    ordertypeid: 261727,
                    priority: 2,
                    providerid: null,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
                {
                    assigneduser: 'dpato STAFF',
                    class: 'PRESCRIPTION',
                    classdescription: 'prescription - new',
                    clinicalprovider: null,
                    clinicalproviderid: null,
                    clinicalprovidername: null,
                    createduser: 'dwilhelm11',
                    dateordered: '2022-07-01T15:39:00',
                    declinedreasontext: null,
                    departmentid: null,
                    description: 'Xanax XR 3 mg tablet,extended release',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-07-01',
                    lastmodifiedby: 'dwilhelm11',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 523374,
                    orderingprovider: 'dpato',
                    ordertype: 'Prescription',
                    ordertypeid: 242257,
                    priority: 2,
                    providerid: null,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
                {
                    assigneduser: 'dpato',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: null,
                    clinicalprovidername: null,
                    createduser: 'dwilhelm11',
                    dateordered: '2022-07-01T15:39:00',
                    declinedreasontext: null,
                    departmentid: null,
                    description: 'optometrist referral',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-07-01',
                    lastmodifiedby: 'ATHENA',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 523375,
                    orderingprovider: 'dpato',
                    ordertype: 'Consult',
                    ordertypeid: 257768,
                    priority: 2,
                    providerid: null,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
                {
                    assigneduser: 'dpato',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: 11253752,
                    clinicalprovidername: 'Mainegeneral Medical Center Radiology (Waterville)',
                    createduser: 'dwilhelm11',
                    dateordered: '2022-07-01T15:40:00',
                    declinedreasontext: null,
                    departmentid: 1,
                    description: 'MRI, brain, w/o contrast',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-07-01',
                    lastmodifiedby: 'ATHENA',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 523376,
                    orderingprovider: 'dpato',
                    ordertype: 'Imaging',
                    ordertypeid: 394136,
                    priority: 2,
                    providerid: 94,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
                {
                    assigneduser: 'dpato',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: 11253752,
                    clinicalprovidername: 'Mainegeneral Medical Center Radiology (Waterville)',
                    createduser: 'dwilhelm11',
                    dateordered: '2022-07-01T15:41:00',
                    declinedreasontext: null,
                    departmentid: 1,
                    description: 'US, obstetric, 1st trimester',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-07-01',
                    lastmodifiedby: 'ATHENA',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 523378,
                    orderingprovider: 'dpato',
                    ordertype: 'Imaging',
                    ordertypeid: 392612,
                    priority: 2,
                    providerid: 94,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
                {
                    assigneduser: 'dpato',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: 11253752,
                    clinicalprovidername: 'Mainegeneral Medical Center Radiology (Waterville)',
                    createduser: 'dwilhelm11',
                    dateordered: '2022-07-01T15:42:00',
                    declinedreasontext: null,
                    departmentid: 1,
                    description: 'US, echocardiogram',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-07-01',
                    lastmodifiedby: 'ATHENA',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 523379,
                    orderingprovider: 'dpato',
                    ordertype: 'Imaging',
                    ordertypeid: 392148,
                    priority: 2,
                    providerid: 94,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
                {
                    assigneduser: 'dpato',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: 11123808,
                    clinicalprovidername: 'Athenacoordinator Test Receiver',
                    createduser: 'dwilhelm11',
                    dateordered: '2022-07-01T15:43:00',
                    declinedreasontext: null,
                    departmentid: 1,
                    description: 'cardiologist referral',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-07-01',
                    lastmodifiedby: 'ATHENA',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 523380,
                    orderingprovider: 'dpato',
                    ordertype: 'Consult',
                    ordertypeid: 257362,
                    priority: 2,
                    providerid: 94,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
            ],
        },
        {
            diagnosis: 'type 2 diabetes mellitus',
            diagnosissnomed: 44054006,
            orders: [
                {
                    assigneduser: 'dpato STAFF',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: null,
                    clinicalprovidername: null,
                    createduser: 'dwilhelm11',
                    dateordered: '2022-07-01T15:37:00',
                    declinedreasontext: null,
                    departmentid: null,
                    description: 'CBC',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-07-01',
                    lastmodifiedby: 'dwilhelm11',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 523366,
                    orderingprovider: 'dpato',
                    ordertype: 'Lab',
                    ordertypeid: 342116,
                    priority: 2,
                    providerid: null,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
                {
                    assigneduser: 'dpato STAFF',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: null,
                    clinicalprovidername: null,
                    createduser: 'dwilhelm11',
                    dateordered: '2022-07-01T15:37:00',
                    declinedreasontext: null,
                    departmentid: null,
                    description: 'ferritin, serum or plasma',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-07-01',
                    lastmodifiedby: 'dwilhelm11',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 523367,
                    orderingprovider: 'dpato',
                    ordertype: 'Lab',
                    ordertypeid: 342257,
                    priority: 2,
                    providerid: null,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
                {
                    assigneduser: 'dpato STAFF',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: null,
                    clinicalprovidername: null,
                    createduser: 'dwilhelm11',
                    dateordered: '2022-07-01T15:37:00',
                    declinedreasontext: null,
                    departmentid: null,
                    description: 'folate, serum',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-07-01',
                    lastmodifiedby: 'dwilhelm11',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 523368,
                    orderingprovider: 'dpato',
                    ordertype: 'Lab',
                    ordertypeid: 342082,
                    priority: 2,
                    providerid: null,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
                {
                    assigneduser: 'dpato STAFF',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: null,
                    clinicalprovidername: null,
                    createduser: 'dwilhelm11',
                    dateordered: '2022-07-01T15:37:00',
                    declinedreasontext: null,
                    departmentid: null,
                    description: 'iron + total iron-binding capacity (TIBC), serum',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-07-01',
                    lastmodifiedby: 'dwilhelm11',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 523369,
                    orderingprovider: 'dpato',
                    ordertype: 'Lab',
                    ordertypeid: 342272,
                    priority: 2,
                    providerid: null,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
                {
                    assigneduser: 'dpato STAFF',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: null,
                    clinicalprovidername: null,
                    createduser: 'dwilhelm11',
                    dateordered: '2022-07-01T15:37:00',
                    declinedreasontext: null,
                    departmentid: null,
                    description: 'retic count, blood',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-07-01',
                    lastmodifiedby: 'dwilhelm11',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 523370,
                    orderingprovider: 'dpato',
                    ordertype: 'Lab',
                    ordertypeid: 342289,
                    priority: 2,
                    providerid: null,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
                {
                    assigneduser: 'dpato STAFF',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: null,
                    clinicalprovidername: null,
                    createduser: 'dwilhelm11',
                    dateordered: '2022-07-01T15:37:00',
                    declinedreasontext: null,
                    departmentid: null,
                    description: 'vitamin B12, serum',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-07-01',
                    lastmodifiedby: 'dwilhelm11',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 523371,
                    orderingprovider: 'dpato',
                    ordertype: 'Lab',
                    ordertypeid: 342203,
                    priority: 2,
                    providerid: null,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
            ],
        },
        {
            diagnosis: 'essential hypertension',
            diagnosissnomed: 59621000,
            orders: [
                {
                    assigneduser: 'dpato',
                    class: 'ORDER',
                    classdescription: 'order',
                    clinicalprovider: null,
                    clinicalproviderid: null,
                    clinicalprovidername: null,
                    createduser: 'dwilhelm11',
                    dateordered: '2022-07-01T15:38:00',
                    declinedreasontext: null,
                    departmentid: null,
                    description: 'biopsy external ear (PROC)',
                    documentationonly: 'False',
                    earliestfilldate: null,
                    futuresubmitdate: '2022-07-01',
                    lastmodifiedby: 'ATHENA',
                    lastmodifieddateime: null,
                    ndc: null,
                    orderid: 523372,
                    orderingprovider: 'dpato',
                    ordertype: 'Procedure',
                    ordertypeid: 342643,
                    priority: 2,
                    providerid: null,
                    quantityvalue: null,
                    refills: null,
                    specimencollectionsite: null,
                },
            ],
        },
    ],
    patientgoals: {
        discussionnotes: '<div>Need to pump it up!</div>',
        patientinstructions: '<div>Do the things!</div>',
    },
    provider: {
        department_ids: [1, 2],
        displayname: 'Adejumo, Lara MD',
        entitytype: 'Person',
        firstname: 'Khadijah',
        lastname: 'Adejumo',
        npi: 1235216334,
        provider_image_url: 'https://cdn2.hubspot.net/hubfs/6509886/K%20Lara%20Adejumo.jpg',
        providerid: 119,
        providertype: '',
        providertypeid: 'MD',
        specialty: 'Family Medicine',
    },
    reasons: {
        encounterreasonnote: 'Patient denies having COVID',
        encounterreasons: [
            { encounterreasonid: 270, encounterreasontext: 'cough / congestion' },
            { encounterreasonid: 15, encounterreasontext: 'constipation' },
        ],
    },
    vitals: [
        {
            key: 'BLOODPRESSURE',
            ordering: 0,
            readings: [
                [
                    {
                        clinicalelementid: 'VITALS.BLOODPRESSURE.DIASTOLIC',
                        code: '8462-4',
                        codedescription: 'Diastolic blood pressure',
                        codeset: 'LOINC',
                        createdby: null,
                        createddate: null,
                        isgraphable: 'True',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: '80',
                        vitalid: 69323122,
                    },
                    {
                        clinicalelementid: 'VITALS.BLOODPRESSURE.SITE',
                        code: null,
                        codedescription: null,
                        codeset: null,
                        createdby: null,
                        createddate: null,
                        isgraphable: 'False',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: 'L arm',
                        vitalid: 69323125,
                    },
                    {
                        clinicalelementid: 'VITALS.BLOODPRESSURE.SYSTOLIC',
                        code: '8480-6',
                        codedescription: 'Systolic blood pressure',
                        codeset: 'LOINC',
                        createdby: null,
                        createddate: null,
                        isgraphable: 'True',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: '120',
                        vitalid: 69323121,
                    },
                    {
                        clinicalelementid: 'VITALS.BLOODPRESSURE.TYPE',
                        code: null,
                        codedescription: null,
                        codeset: null,
                        createdby: null,
                        createddate: null,
                        isgraphable: 'False',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: 'sitting',
                        vitalid: 69323123,
                    },
                    {
                        clinicalelementid: 'VITALS.BLOODPRESSURECUFFSIZE',
                        code: null,
                        codedescription: null,
                        codeset: null,
                        createdby: null,
                        createddate: null,
                        isgraphable: 'False',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: 'adult',
                        vitalid: 69323124,
                    },
                ],
            ],
        },
        {
            key: 'BMI',
            ordering: 1,
            readings: [
                [
                    {
                        clinicalelementid: 'VITALS.BMI',
                        code: '39156-5',
                        codedescription: 'Body mass index (BMI) [Ratio]',
                        codeset: 'LOINC',
                        createdby: null,
                        createddate: null,
                        isgraphable: 'True',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: '20.2',
                        vitalid: 69323137,
                    },
                ],
            ],
        },
        {
            key: 'BODYSURFACEAREA',
            ordering: 2,
            readings: [
                [
                    {
                        clinicalelementid: 'VITALS.BODYSURFACEAREA',
                        code: '3140-1',
                        codedescription: 'Body surface area Derived from formula',
                        codeset: 'LOINC',
                        createdby: null,
                        createddate: null,
                        isgraphable: 'True',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: '1.66',
                        vitalid: 69323140,
                    },
                ],
            ],
        },
        {
            key: 'HEIGHT',
            ordering: 3,
            readings: [
                [
                    {
                        clinicalelementid: 'VITALS.HEIGHT',
                        code: '8302-2',
                        codedescription: 'Body height',
                        codeset: 'LOINC',
                        createdby: null,
                        createddate: null,
                        isgraphable: 'True',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: '170.18',
                        vitalid: 69323139,
                    },
                    {
                        clinicalelementid: 'VITALS.HEIGHT.TYPE',
                        code: '8308-9',
                        codedescription: 'Body height --standing',
                        codeset: 'LOINC',
                        createdby: null,
                        createddate: null,
                        isgraphable: 'False',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: 'Standing',
                        vitalid: 69323138,
                    },
                ],
            ],
        },
        {
            key: 'NOTES',
            ordering: 4,
            readings: [
                [
                    {
                        clinicalelementid: 'VITALS.NOTES',
                        code: null,
                        codedescription: null,
                        codeset: null,
                        createdby: null,
                        createddate: null,
                        isgraphable: 'True',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: 'Making a note in vitals',
                        vitalid: 69323142,
                    },
                ],
            ],
        },
        {
            key: 'O2SATURATION',
            ordering: 5,
            readings: [
                [
                    {
                        clinicalelementid: 'VITALS.O2SATURATION',
                        code: '59408-5',
                        codedescription: 'Oxygen saturation in Arterial blood by Pulse oximetry',
                        codeset: 'LOINC',
                        createdby: null,
                        createddate: null,
                        isgraphable: 'True',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: '98',
                        vitalid: 69323134,
                    },
                    {
                        clinicalelementid: 'VITALS.O2SATURATION.AIRTYPE',
                        code: null,
                        codedescription: null,
                        codeset: null,
                        createdby: null,
                        createddate: null,
                        isgraphable: 'False',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: 'Room Air at Rest',
                        vitalid: 69323133,
                    },
                ],
            ],
        },
        {
            key: 'PAINSCALE',
            ordering: 6,
            readings: [
                [
                    {
                        clinicalelementid: 'VITALS.PAINSCALE',
                        code: null,
                        codedescription: null,
                        codeset: null,
                        createdby: null,
                        createddate: null,
                        isgraphable: 'True',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: '2',
                        vitalid: 69323132,
                    },
                    {
                        clinicalelementid: 'VITALS.PAINSCALETYPE',
                        code: null,
                        codedescription: null,
                        codeset: null,
                        createdby: null,
                        createddate: null,
                        isgraphable: 'False',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: 'Wong-Baker FACES',
                        vitalid: 69323131,
                    },
                ],
            ],
        },
        {
            key: 'PULSE',
            ordering: 7,
            readings: [
                [
                    {
                        clinicalelementid: 'VITALS.PULSE.RATE',
                        code: '8867-4',
                        codedescription: 'Heart rate',
                        codeset: 'LOINC',
                        createdby: null,
                        createddate: null,
                        isgraphable: 'True',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: '64',
                        vitalid: 69323127,
                    },
                    {
                        clinicalelementid: 'VITALS.PULSE.TYPE',
                        code: null,
                        codedescription: null,
                        codeset: null,
                        createdby: null,
                        createddate: null,
                        isgraphable: 'False',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: 'regular',
                        vitalid: 69323126,
                    },
                ],
            ],
        },
        {
            key: 'RESPIRATIONRATE',
            ordering: 8,
            readings: [
                [
                    {
                        clinicalelementid: 'VITALS.RESPIRATIONRATE',
                        code: '9279-1',
                        codedescription: 'Respiratory rate',
                        codeset: 'LOINC',
                        createdby: null,
                        createddate: null,
                        isgraphable: 'True',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: '15',
                        vitalid: 69323128,
                    },
                ],
            ],
        },
        {
            key: 'TEMPERATURE',
            ordering: 9,
            readings: [
                [
                    {
                        clinicalelementid: 'VITALS.TEMPERATURE',
                        code: '8310-5',
                        codedescription: 'Body temperature',
                        codeset: 'LOINC',
                        createdby: null,
                        createddate: null,
                        isgraphable: 'True',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: '98.8',
                        vitalid: 69323129,
                    },
                    {
                        clinicalelementid: 'VITALS.TEMPERATURE.TYPE',
                        code: null,
                        codedescription: null,
                        codeset: null,
                        createdby: null,
                        createddate: null,
                        isgraphable: 'False',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: 'oral',
                        vitalid: 69323130,
                    },
                ],
            ],
        },
        {
            key: 'WEIGHT',
            ordering: 13,
            readings: [
                [
                    {
                        clinicalelementid: 'VITALS.WEIGHT',
                        code: '29463-7',
                        codedescription: 'Body weight',
                        codeset: 'LOINC',
                        createdby: null,
                        createddate: null,
                        isgraphable: 'True',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: '58513.42',
                        vitalid: 69323119,
                    },
                    {
                        clinicalelementid: 'VITALS.WEIGHT.TYPE',
                        code: '8350-1',
                        codedescription: 'Body weight Measured --with clothes',
                        codeset: 'LOINC',
                        createdby: null,
                        createddate: null,
                        isgraphable: 'False',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: 'With clothes',
                        vitalid: 69323118,
                    },
                ],
            ],
        },
        {
            key: 'HEADCIRCUMFERENCE',
            ordering: 14,
            readings: [
                [
                    {
                        clinicalelementid: 'VITALS.HEADCIRCUMFERENCE',
                        code: '8287-5',
                        codedescription: 'Head Occipital-frontal circumference by Tape measure',
                        codeset: 'LOINC',
                        createdby: null,
                        createddate: null,
                        isgraphable: 'True',
                        readingid: 0,
                        readingtaken: '2022-06-20',
                        source: 'ENCOUNTER',
                        sourceid: 11880939,
                        unit: null,
                        value: '55.88',
                        vitalid: 69323141,
                    },
                ],
            ],
        },
    ],
};
