import { QuestionAboutPage } from '../pages/question-about-page';
import { SuggestedProvidersPage } from '../pages/suggested-providers';
import { VisitOptionsPage } from '../pages/visit-options';
import { VisitReasonPage } from '../pages/visit-reason';
import type { RouteConfig } from '../types/scheduling-routes';

const schedulingRoutesMap: readonly RouteConfig[] = [
    {
        component: QuestionAboutPage,
        privateRoute: true,
        route: '/scheduling-question-about',
    },
    {
        component: VisitOptionsPage,
        privateRoute: true,
        route: '/scheduling/visit-options',
    },
    {
        component: VisitReasonPage,
        privateRoute: true,
        route: '/scheduling/visit-reasons',
    },
    {
        component: SuggestedProvidersPage,
        privateRoute: true,
        route: '/scheduling/suggested-providers',
    },
];

export { schedulingRoutesMap };
