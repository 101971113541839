// eslint-disable-next-line unicorn/prefer-node-protocol
import { Buffer } from 'buffer';
import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';

import { healthRecordsApi } from '../health-records-api-config';

interface DocumentPage {
    readonly page_id: number;
}

interface DocumentPageParameters {
    readonly document_pages: readonly DocumentPage[];
    readonly labResultId: string;
    readonly enabled?: boolean;
}

const getLabDocuments = async ({ document_pages, labResultId }: DocumentPageParameters): Promise<readonly string[]> => {
    const queries = document_pages.map(async (document) => {
        const path = `/labs/${labResultId}/pages/${document.page_id}`;
        return healthRecordsApi.get<Iterable<number>>(path, {
            responseType: 'arraybuffer',
        });
    });

    const responses = await Promise.all(queries);

    return responses.map((response) => {
        const bytes = new Uint8Array(response.data);
        const strings = bytes.reduce((payload, byte) => payload + String.fromCodePoint(byte), '');
        const imageBase64 = Buffer.from(strings, 'base64').toString('base64');
        return `data:image/png;base64,${imageBase64}`;
    });
};

const useLabDocuments = ({
    document_pages,
    labResultId,
    enabled = true,
}: DocumentPageParameters): UseQueryResult<readonly string[]> =>
    useQuery(['lab-document-page', labResultId], async () => getLabDocuments({ document_pages, labResultId }), {
        enabled,
    });

export type { DocumentPage };
export { useLabDocuments };
