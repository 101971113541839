import type { FC } from 'react';

import * as Styled from './styles';
import type { Message } from 'types/message';
import { formatDate } from 'utils';

interface MessageEntryProps {
    readonly message: Message;
}

const MessageEntry: FC<MessageEntryProps> = ({ message }) => {
    const { timestamp, text, from, to } = message;

    return (
        <Styled.Container>
            <Styled.MessageContents>
                <Styled.MessageHeader>
                    <Styled.InlineText>
                        <Styled.Sender type="sub2">{from?.displayname}</Styled.Sender>
                        <Styled.GrayText type="caption">{formatDate(timestamp * 1000, 'shortDateWithTime')}</Styled.GrayText>
                    </Styled.InlineText>
                    <Styled.GrayText type="body2">To: {to?.displayname}</Styled.GrayText>
                </Styled.MessageHeader>
                <Styled.MessageText type="body1">{text}</Styled.MessageText>
            </Styled.MessageContents>
        </Styled.Container>
    );
};

export { MessageEntry };
