import { Avatar, Card, CardContent } from '@material-ui/core';
import styled from 'styled-components';

import { Icon } from 'components/icon';

interface Props {
    readonly $isMostRecentMessage: boolean;
}

const MessageContent = styled(CardContent)`
    font-size: 0.875rem;
    line-height: 1.25;
    white-space: pre-line;
    color: ${({ theme }) => theme.color.brightGray};
`;

const MessageAvatar = styled(Avatar)`
    font-size: 1.125rem;
    height: 2.5rem;
    width: 2.5rem;
    background-color: ${({ theme }) => theme.color.irisBlue};
`;

const MessageCard = styled(Card)<Props>`
    border-radius: 0;
    box-shadow: none;
    border-bottom: ${({ $isMostRecentMessage, theme }) =>
        $isMostRecentMessage ? 'none' : `0.5px solid ${theme.color.platinum}`};
`;

const MessagePreview = styled.p`
    max-width: 40vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MessageTitle = styled.div`
    display: flex;
`;

const MessageSenderTitle = styled.div`
    font-weight: 600;
    line-height: 1.25;
    font-size: 0.813rem;
`;

const MessageDate = styled.div`
    font-size: 0.75rem;
    text-align: center;
    padding-left: 0.5rem;
    color: ${({ theme }) => theme.color.shuttleGray};
`;

const DefaultProvider = styled(Icon)`
    width: 2.5rem;
    height: 2.5rem;
    background-color: ${({ theme }) => theme.color.white};
`;

const MessageBody = styled.div`
    display: grid;
    grid-row-gap: 5rem;
`;

const LabResultButtonIcon = styled(Icon)`
    margin-top: 0.5rem;
`;

const ReplyButtonIcon = styled(Icon)`
    margin-right: 0.5rem;
`;

export {
    DefaultProvider,
    LabResultButtonIcon,
    MessageAvatar,
    MessageBody,
    MessageCard,
    MessageContent,
    MessageDate,
    MessagePreview,
    MessageSenderTitle,
    MessageTitle,
    ReplyButtonIcon,
};
