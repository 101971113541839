import styled from 'styled-components';

interface ContainerProps {
    readonly iconSize: number;
}

const CenterContent = styled.div<ContainerProps>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(0, -${(props) => props.iconSize}rem);
`;

const TransformContainer = styled.div`
    position: absolute;
    transform: scale(0.66);
`;

const Root = styled.div`
    user-select: none;
    height: 100%;
    width: 100%;
`;

const DraggableContainer = styled.div`
    height: 100%;
    width: 100%;
`;

export { DraggableContainer, CenterContent, Root, TransformContainer };
