import styled from 'styled-components';

const ChatContainer = styled.div`
    padding: 1rem;
`;

const LoaderContainer = styled.section`
    padding: 1rem;
`;

const LabNameLoader = styled.div`
    padding-bottom: 0.625rem;
`;

const LabDateLoader = styled.div`
    margin-bottom: 0.25rem;
`;

export { ChatContainer, LoaderContainer, LabNameLoader, LabDateLoader };
