import type { ApiResponse, MessageThread, MockResponse } from 'types';

const MESSAGES_SUCCESS_RESPONSE: MockResponse<ApiResponse<MessageThread>> = {
    d: {
        __count: 8,
        __metadata: { identity_match_strategy: 'CdmPersonStrategy' },
        __paging: { next: '/message/?source=provider&folder=inbox&limit=10&offset=10' },
        results: [
            {
                message_thread_id: 1,
                messages: [
                    {
                        displaydate: '03/30/2021  1:53 PM',
                        from: {
                            displayname: 'Pasquale Lynch',
                            providerid: null,
                            username: 'PORTAL',
                        },
                        isreply: true,
                        text: 'testing 1234567890',
                        timestamp: 1617877490,
                        to: {
                            displayname: 'angela test',
                            providerid: null,
                            username: 'atest51',
                        },
                        unread: true,
                    },
                ],
                source: 'provider',
                subject: 'Blurry Vision',
            },
            {
                message_thread_id: 2,
                messages: [
                    {
                        displaydate: '03/31/2021  7:56 PM',
                        from: {
                            displayname: 'Mai Somphet, MD',
                            providerid: 21,
                            username: null,
                        },
                        isreply: false,
                        text: 'The results of your CBC blood work have returned from the lab. The results fell within normal ranges and no additional testing is needed at this time. If you have any questions regarding this test please contact our office.',
                        timestamp: 1617963890,
                        to: {
                            displayname: 'Test User',
                            providerid: null,
                            username: 'testuser',
                        },
                        unread: true,
                    },
                ],
                source: 'labresult',
                subject: 'CBC',
            },
            {
                message_thread_id: 632623,
                messages: [
                    {
                        displaydate: '03/30/2021 11:20 AM',
                        from: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        isreply: false,
                        text: 'Example text',
                        timestamp: 1607117645,
                        to: {
                            displayname: 'Fred Franklin',
                            username: 'PORTAL',
                        },
                        unread: false,
                    },
                ],
                source: 'provider',
                subject: 'Watery eyes',
            },
            {
                message_thread_id: 233424,
                messages: [
                    {
                        displaydate: '03/30/2021 11:20 AM',
                        from: {
                            displayname: 'Marry Higgins',
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'Lorem ipsum',
                        timestamp: 1601963890,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                ],
                source: 'provider',
                subject: 'Need medication',
            },
            {
                message_thread_id: 23346624,
                messages: [
                    {
                        displaydate: '03/30/2021 11:20 AM',
                        from: {
                            displayname: 'Peter Parker',
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'Lorem ipsum',
                        timestamp: 1601963890,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                ],
                source: 'provider',
                subject: 'Need medication',
            },
            {
                message_thread_id: 23311424,
                messages: [
                    {
                        displaydate: '03/30/2021 11:20 AM',
                        from: {
                            displayname: 'John Doe',
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'Lorem ipsum',
                        timestamp: 1601963890,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                ],
                source: 'provider',
                subject: 'Need medication',
            },
            {
                message_thread_id: 2433424,
                messages: [
                    {
                        displaydate: '03/30/2021 11:20 AM',
                        from: {
                            displayname: 'Milly Vanilly',
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'Lorem ipsum',
                        timestamp: 1601963890,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                ],
                source: 'provider',
                subject: 'Need medication',
            },
            {
                message_thread_id: 2332424,
                messages: [
                    {
                        displaydate: '03/30/2021 11:20 AM',
                        from: {
                            displayname: 'Max Payne',
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'Lorem ipsum',
                        timestamp: 1601963890,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                ],
                source: 'provider',
                subject: 'Need medication',
            },
        ],
    },
};

const MESSAGES_SUCCESS_RESPONSE_FETCH_ADDITIONAL: MockResponse<ApiResponse<MessageThread>> = {
    d: {
        __count: 8,
        __metadata: { identity_match_strategy: 'CdmPersonStrategy' },
        __paging: { next: null },
        results: [
            {
                message_thread_id: 6256948,
                messages: [
                    {
                        displaydate: '03/30/2021 11:20 AM',
                        from: {
                            displayname: 'Tom Pearsons',
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'Are you available at some time',
                        timestamp: 1607963890,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                ],
                source: 'provider',
                subject: 'Appointment',
            },
            {
                message_thread_id: 35235235,
                messages: [
                    {
                        displaydate: '03/30/2021 11:20 AM',
                        from: {
                            displayname: 'Jenny Stamps',
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'Can we do a video meet',
                        timestamp: 1597517645,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                ],
                source: 'provider',
                subject: 'Pain in chest',
            },
            {
                message_thread_id: 1234568,
                messages: [
                    {
                        displaydate: '03/30/2021 11:20 AM',
                        from: {
                            displayname: 'Penny Stamps',
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'Can we do a video meet',
                        timestamp: 1597517645,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                ],
                source: 'provider',
                subject: 'Pain in chest',
            },
            {
                message_thread_id: 123459,
                messages: [
                    {
                        displaydate: '03/30/2021 11:20 AM',
                        from: {
                            displayname: 'Jammy Stamps',
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'Can we do a video meet',
                        timestamp: 1597517645,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                ],
                source: 'provider',
                subject: 'Pain in chest',
            },
        ],
    },
};

export { MESSAGES_SUCCESS_RESPONSE, MESSAGES_SUCCESS_RESPONSE_FETCH_ADDITIONAL };
