import { useCallback, useEffect, useMemo, useState } from 'react';
import type { FC, PropsWithChildren } from 'react';
import validVersion from 'semver/functions/valid';

import { FeatureFlagsContext } from './feature-flags-context';
import type { FeatureFlagsContextType } from './feature-flags-context';
import type { FeatureFlagBooleans } from 'consts';
import { calculateFeatureFlags, calculateDefaultFeatureFlags, defaultFeatureFlags, initialFeatureFlags } from 'consts';
import { useNativeIpc } from 'hooks/use-native-ipc';
import { appConfig } from 'modules/config';
import type { MessageBase } from 'modules/ipc/types';

const FeatureFlagsProvider: FC<PropsWithChildren> = ({ children }) => {
    const [featureFlags, setFeatureFlags] = useState<FeatureFlagBooleans>(() =>
        calculateDefaultFeatureFlags(defaultFeatureFlags)
    );

    const hasFeature: FeatureFlagsContextType['hasFeature'] = useCallback(
        (featureKey) => (featureFlags ? featureFlags[featureKey] : false),
        [featureFlags]
    );

    const contextValue = useMemo(
        () => ({
            hasFeature,
        }),
        [hasFeature]
    );

    const applyFeatureFlags = useCallback((nativeAppVersion: string) => {
        if (nativeAppVersion == null || !validVersion(nativeAppVersion) || appConfig.platform === 'web') {
            setFeatureFlags(calculateDefaultFeatureFlags(defaultFeatureFlags));
        } else {
            setFeatureFlags(calculateFeatureFlags(initialFeatureFlags, nativeAppVersion));
        }
    }, []);

    useEffect(() => {
        const nativeAppVersion = window.sessionStorage.getItem('nativeAppVersion');
        if (nativeAppVersion) applyFeatureFlags(nativeAppVersion);
    }, [applyFeatureFlags]);

    const listenToInitEvent = useCallback(
        (event: CustomEvent<MessageBase<{ readonly version: string }>>) => {
            const nativeAppVersionFromStorage = window.sessionStorage.getItem('nativeAppVersion');
            if (nativeAppVersionFromStorage) return;
            const nativeAppVersion = event.detail.body?.version;
            if (nativeAppVersion) applyFeatureFlags(nativeAppVersion);
        },
        [applyFeatureFlags]
    );

    const updateFeatureFlag = useCallback(
        (event: CustomEvent<MessageBase<{ readonly featureFlagName: string; readonly featureFlagState: boolean }>>) => {
            if (event.detail.body) {
                const featureFlagData = event.detail.body;
                setFeatureFlags((currentFeatureFlags) => ({
                    ...currentFeatureFlags,
                    [featureFlagData.featureFlagName]: featureFlagData.featureFlagState,
                }));
            }
        },
        []
    );

    useNativeIpc('onInit', listenToInitEvent as EventListener);
    useNativeIpc('setFeatureFlag', updateFeatureFlag as EventListener);

    return <FeatureFlagsContext.Provider value={contextValue}>{children}</FeatureFlagsContext.Provider>;
};

export { FeatureFlagsProvider };
