import type { HTMLAttributes, ReactNode, FC, PropsWithChildren } from 'react';

import * as Styled from './styles';

interface RoundedContainerProps extends HTMLAttributes<HTMLDivElement> {
    readonly headerContent?: ReactNode;
}

const RoundedContainer: FC<PropsWithChildren<RoundedContainerProps>> = ({ headerContent, children }) => (
    <Styled.Container>
        {headerContent ? <Styled.Header>{headerContent}</Styled.Header> : null}
        <Styled.RoundedContainer>{children}</Styled.RoundedContainer>
    </Styled.Container>
);

export { RoundedContainer };
