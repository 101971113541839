import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';

import { identityApi } from '../identity-api-config';

interface IdentitySearch {
    readonly valid: boolean;
}

const useIdentitySearch = (enabled = true): UseQueryResult<IdentitySearch> =>
    useQuery(
        'identity-search',
        async () => {
            const response = await identityApi.post<IdentitySearch>(`/users/search/athena`, {
                check_validity: true,
            });

            return response.data;
        },
        { enabled }
    );

export { useIdentitySearch };
