import type { SchedulingCardProps } from '../types/scheduling-card-props';

export const schedulingQuestionAbout: readonly SchedulingCardProps[] = [
    {
        iconName: 'scheduling',
        ipcEvent: 'openSchedulingDrawer',
        text: 'Scheduling a new visit',
    },
    {
        iconName: 'other',
        nextStep: 'COMPOSE_MESSAGE',
        text: 'Other',
    },
];
