import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { rem } from 'polished';
import type { FC } from 'react';

import * as Styled from './styles';

const LettersLoading: FC = () => (
    <Styled.LoaderContainer data-testid="letters-loading">
        {Array.from({ length: 6 }, (_, index) => index + 1).map((item) => (
            <Box key={item} pb={rem(30)}>
                <Box mb={1} width="50%">
                    <Skeleton animation="wave" height={8} variant="rect" />
                </Box>
                <Skeleton animation="wave" height={34} variant="rect" />
            </Box>
        ))}
    </Styled.LoaderContainer>
);

export { LettersLoading };
