import * as Sentry from '@sentry/react';

import type { AppConfig } from 'types/config';

const initializeSentry = (appConfig: AppConfig): void => {
    Sentry.init({
        dsn: appConfig.sentryDsn,
        environment: appConfig.environment,
        release: `health-records@${appConfig.version}`,
    });
};

export { initializeSentry };
