import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import type { FC } from 'react';

import * as Styled from './styles';

const CareSummariesLoading: FC = () => (
    <Box data-testid="care-summaries-loading" display="flex" flexDirection="column" height="100%" padding="1rem">
        {Array.from({ length: 5 }, (_, index) => index + 1).map((item) => (
            <Styled.LoadingItem key={item}>
                <Styled.SkeletonProviderPhoto animation="wave" height="3.5rem" variant="circle" width="3.5rem" />
                <Styled.TextSkeleton>
                    <Skeleton animation="wave" variant="text" width="50%" />
                    <Skeleton animation="wave" variant="text" width="80%" />
                    <Skeleton animation="wave" variant="text" width="80%" />
                </Styled.TextSkeleton>
            </Styled.LoadingItem>
        ))}
    </Box>
);

export { CareSummariesLoading };
