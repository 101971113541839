import parsePhoneNumber from 'libphonenumber-js';
import type { FC } from 'react';

import * as Styled from './styles';
import type { Pharmacy, PharmacyFacility } from 'types';

interface Props {
    readonly preferredPharmacy?: Pharmacy;
    readonly medicationPharmacy?: PharmacyFacility | null;
}

const PharmacyDetails: FC<Props> = ({ preferredPharmacy, medicationPharmacy }) => {
    const showMedicationPharmacy = !!medicationPharmacy;

    const phonenumber = showMedicationPharmacy ? medicationPharmacy?.phonenumber : preferredPharmacy?.phonenumber;

    const formattedPhoneNumber = phonenumber ? parsePhoneNumber(phonenumber, 'US')?.format('NATIONAL') : null;

    return (
        <Styled.Container>
            {formattedPhoneNumber ? (
                <Styled.FlexRow>
                    <Styled.PhoneIcon size="md" />
                    <Styled.FlexColumn>
                        <Styled.BoldText>Phone number</Styled.BoldText>
                        <Styled.Text>{formattedPhoneNumber}</Styled.Text>
                    </Styled.FlexColumn>
                </Styled.FlexRow>
            ) : null}
            <Styled.FlexRow>
                <Styled.InfoIcon size="md" />
                <Styled.FlexColumn>
                    <Styled.BoldText>Pharmacy information</Styled.BoldText>
                    <Styled.Text>
                        This particular medication was sent to the pharmacy listed here which may differ from your selected
                        preferred pharmacy
                        <br />
                    </Styled.Text>
                </Styled.FlexColumn>
            </Styled.FlexRow>
            <Styled.FlexRow>
                <Styled.HouseArrowIcon name="house-arrow" size={1.5} />
                <Styled.FlexColumn>
                    <Styled.BoldText>Prescription transfers</Styled.BoldText>
                    <Styled.Text>
                        To speed up the process of transferring your medication to a different pharmacy, just get in touch with
                        the new pharmacy and provide them with the address and phone number of your current pharmacy.
                        <br />
                    </Styled.Text>
                </Styled.FlexColumn>
            </Styled.FlexRow>
        </Styled.Container>
    );
};

export { PharmacyDetails };
