import type { FC } from 'react';

import { ImagingResultEntryCard } from '../image-result-entry';
import { LabEntryCard } from '../lab-entry';
import type { ImagingResultWithMessageData, LabResultWithMessageData, TestResultWithMessageData } from 'types';
import { getTestResultType } from 'utils/test-results';

interface Props {
    readonly testResult: TestResultWithMessageData;
}

const TestResultCard: FC<Props> = ({ testResult }) => {
    const testResultType = getTestResultType(testResult);
    if (testResultType === 'labresult') return <LabEntryCard lab={testResult as LabResultWithMessageData} />;
    if (testResultType === 'imagingresult')
        return <ImagingResultEntryCard imagingResult={testResult as ImagingResultWithMessageData} />;
    return null;
};

export { TestResultCard };
