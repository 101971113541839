import type { SchedulingRoute } from '../types/scheduling-routes';
import type { SchedulingStep } from '../types/scheduling-step';

const schedulingStepsRoutes: Record<SchedulingStep, SchedulingRoute> = {
    COMPOSE_MESSAGE: '/compose-message',
    QUESTION_ABOUT: '/scheduling-question-about',
    SUGGESTED_PROVIDERS: '/scheduling/suggested-providers',
    VISIT_OPTIONS: '/scheduling/visit-options',
    VISIT_REASONS: '/scheduling/visit-reasons',
};

const schedulingStepOrder: readonly SchedulingStep[] = [
    'QUESTION_ABOUT',
    'VISIT_REASONS',
    'SUGGESTED_PROVIDERS',
    'VISIT_OPTIONS',
    'COMPOSE_MESSAGE',
];

export { schedulingStepsRoutes, schedulingStepOrder };
