import { useContext } from 'react';

import type { SchedulingContextType } from 'pages/scheduling-flow/context';
import { SchedulingContext } from 'pages/scheduling-flow/context';

const useScheduling = (): SchedulingContextType => {
    const context = useContext(SchedulingContext);

    if (context === undefined) {
        throw new Error('useScheduling must be used within a SchedulingContextProvider');
    }

    return context;
};

export { useScheduling };
