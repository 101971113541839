import type { FC } from 'react';
import { memo } from 'react';

import { Messaging } from './messaging-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <Messaging />,
};

const MessagingPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Messaging" />);

export { MessagingPage };
