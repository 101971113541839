import { memo } from 'react';
import type { FC } from 'react';

import { VisitReason } from './visit-reason-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <VisitReason />,
};

const VisitReasonPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Visit Reason" />);

export { VisitReasonPage };
