import { Amplify } from 'aws-amplify';

import type { CachedCognitoAuth } from './types';
import type { CognitoConfig } from 'types';
import type { AppConfig } from 'types/config';
import { setSessionStorage } from 'utils';

const cacheCognitoTokens = (cachedAuth: CachedCognitoAuth, config: CognitoConfig): void => {
    const { lastAuthUser, idToken, accessToken, refreshToken } = cachedAuth;

    if (!lastAuthUser || !idToken || !accessToken || !refreshToken) return;

    const keyPrefix = `CognitoIdentityServiceProvider.${config.aws_user_pools_web_client_id}`;

    setSessionStorage(`${keyPrefix}.${lastAuthUser}.idToken`, idToken);
    setSessionStorage(`${keyPrefix}.${lastAuthUser}.accessToken`, accessToken);
    setSessionStorage(`${keyPrefix}.${lastAuthUser}.refreshToken`, refreshToken);
    setSessionStorage(`${keyPrefix}.LastAuthUser`, lastAuthUser);
};

const initializeCognito = (appConfig: AppConfig): void => {
    Amplify.configure({ ...appConfig.cognito, storage: window.sessionStorage });
};

export { cacheCognitoTokens, initializeCognito };
