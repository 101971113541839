import { Text } from '@village/ui';
import styled from 'styled-components';

const Container = styled.div`
    text-align: center;
    align-items: center;
    padding: 0 1rem;
    margin-top: 8.125rem;
`;

const Title = styled(Text).attrs(() => ({ type: 'h3' }))`
    color: ${({ theme }) => theme.color.brightGray};
    padding: 1.25rem 1.5rem 0.5rem;
`;

const Description = styled(Text).attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.vui.colors.neutral.gray80};
`;

export { Container, Title, Description };
