import axios from 'axios';
import type { AxiosResponseTransformer } from 'axios';

import { TIMEOUT_MS } from './constants';
import { mockHealthRecords } from 'data/mocks/mock-adapter';
import { appConfig } from 'modules/config';
import type { ApiResponse } from 'types/api';

const healthRecordsApi = axios.create({
    baseURL: appConfig.healthRecordsApiBaseUrl,
    timeout: TIMEOUT_MS,
    transformResponse: ([] as readonly AxiosResponseTransformer[]).concat(
        axios.defaults.transformResponse ?? [],
        // Custom response transformer to handle OData data structure { d: (...) }.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (data: { readonly d: ApiResponse<any> }) => data?.d ?? data
    ),
});

if (appConfig.useMocks) {
    mockHealthRecords(healthRecordsApi);
}

export { healthRecordsApi };
