const applePlatforms = new Set([
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
    // TODO: MacIntel was added for development purpose only, remove later.
    'MacIntel',
]);

const getPlatform = (): 'Android' | 'iOS' => {
    if (applePlatforms.has(navigator.platform)) {
        return 'iOS';
    }

    return 'Android';
};

export { getPlatform };
