import { Tab, Tabs } from '@village/ui';
import type { FC } from 'react';
import { useEffect, useCallback, useMemo } from 'react';
import type { QueryObserverResult, RefetchOptions } from 'react-query';

import * as Styled from './styles';
import type { UnreadMessageCount } from 'data/hooks/use-message-unread-count';
import type { MessagingPages } from 'pages/messaging/types';

interface Props {
    readonly activeTab: MessagingPages;
    readonly setActiveTab: CallableFunction;
    readonly unreadCount?: UnreadMessageCount;
    readonly refetchUnreadCount: (options?: RefetchOptions) => Promise<QueryObserverResult<UnreadMessageCount>>;
}

const TopNav: FC<Props> = ({ activeTab, setActiveTab, unreadCount, refetchUnreadCount }) => {
    const handleChange = useCallback(
        async (_event: React.ChangeEvent<unknown>, newValue: number | string): Promise<void> => {
            setActiveTab(newValue);
            await refetchUnreadCount();
        },
        [refetchUnreadCount, setActiveTab]
    );

    const handleVisibilityChange = useCallback(() => {
        if (!document.hidden) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            refetchUnreadCount();
        }
    }, [refetchUnreadCount]);

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange, false);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [handleVisibilityChange]);

    const labelInbox = useMemo(
        () => (
            <Styled.TabLabel>
                <Styled.TabIcon name="inbox" size={1.25} />
                Inbox {unreadCount?.unread_count ? `(${unreadCount.unread_count.toString()})` : null}
            </Styled.TabLabel>
        ),
        [unreadCount]
    );

    const labelSent = useMemo(
        () => (
            <Styled.TabLabel>
                <Styled.TabIcon name="sent" size={1.25} />
                Sent
            </Styled.TabLabel>
        ),
        []
    );

    return (
        <Styled.Container>
            <Tabs onChange={handleChange} value={activeTab}>
                <Tab label={labelInbox} value="inbox" />
                <Tab label={labelSent} value="sent" />
            </Tabs>
        </Styled.Container>
    );
};

export { TopNav };
