import ReactDOM from 'react-dom/client';

import { App } from 'app';
import { initializeCognito } from 'modules/auth';
import { appConfig } from 'modules/config';
import { initCountly } from 'modules/countly';
import { initDatadog } from 'modules/datadog';
import { initializeIpc } from 'modules/ipc';
import { initializeSentry } from 'modules/sentry';

// Sentry configuration should happen as early as possible, keep it at the top.
initializeSentry(appConfig);
initializeCognito(appConfig);
initCountly();
initDatadog();
initializeIpc();

const rootElement = document.querySelector('#root');

if (rootElement) {
    const wrapper = ReactDOM.createRoot(rootElement);
    wrapper.render(<App />);
}
