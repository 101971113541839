import type { ApiResponse, MessageThread, MockResponse } from 'types';

const MESSAGE_LAB_RESULT_THREAD_SUCCESS_RESPONSE: MockResponse<ApiResponse<MessageThread>> = {
    d: {
        __metadata: {
            identity_match_strategy: 'CdmPersonStrategy',
        },
        results: [
            {
                associated_message_thread_id: 2,
                message_thread_id: 2,
                messages: [
                    {
                        displaydate: '03/08/2021  12:22 PM',
                        from: {
                            displayname: 'Adejumo, Lara MD',
                            providerid: 119,
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'The results of your CBC blood work have returned from the lab. The results fell within normal ranges and no additional testing is needed at this time. If you have any questions regarding this test please contact our office.',
                        timestamp: 1615231375,
                        // TODO: check if live messages have the TO field filled in!!!!
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                ],
                source: 'labresult',
                subject: 'CBC',
            },
        ],
    },
};

const MESSAGE_LAB_RESULT_THREAD_SUCCESS_RESPONSE_2: MockResponse<ApiResponse<MessageThread>> = {
    d: {
        __metadata: {
            identity_match_strategy: 'CdmPersonStrategy',
        },
        results: [
            {
                associated_message_thread_id: 2,
                message_thread_id: 2,
                messages: [
                    {
                        displaydate: '03/08/2021  10:22 PM',
                        from: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        isreply: false,
                        text: 'Test message #10',
                        timestamp: 1615239375,
                        to: {
                            displayname: 'Adejumo, Lara MD',
                            providerid: 119,
                            username: 'PORTAL',
                        },
                        unread: true,
                    },
                    {
                        displaydate: '03/08/2021  19:22 PM',
                        from: {
                            displayname: 'Adejumo, Lara MD',
                            providerid: 119,
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'Test message #9',
                        timestamp: 1615231375,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: true,
                    },
                    {
                        displaydate: '03/08/2021  18:22 PM',
                        from: {
                            displayname: 'Adejumo, Lara MD',
                            providerid: 119,
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'Test message #8',
                        timestamp: 1615238375,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: true,
                    },
                    {
                        displaydate: '03/08/2021  17:22 PM',
                        from: {
                            displayname: 'Adejumo, Lara MD',
                            providerid: 119,
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'Test message #7',
                        timestamp: 1615237375,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                    {
                        displaydate: '03/08/2021  16:22 PM',
                        from: {
                            displayname: 'Adejumo, Lara MD',
                            providerid: 119,
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'Test message #6',
                        timestamp: 1615236375,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                    {
                        displaydate: '03/08/2021  15:22 PM',
                        from: {
                            displayname: 'Adejumo, Lara MD',
                            providerid: 119,
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'Test message #5',
                        timestamp: 1615235375,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                    {
                        displaydate: '03/08/2021  14:22 PM',
                        from: {
                            displayname: 'Adejumo, Lara MD',
                            providerid: 119,
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'Test message #4',
                        timestamp: 1615234375,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                    {
                        displaydate: '03/08/2021  13:22 PM',
                        from: {
                            displayname: 'Adejumo, Lara MD',
                            providerid: 119,
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'Test message #3',
                        timestamp: 1615233375,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                    {
                        displaydate: '03/31/2021  7:56 PM',
                        from: {
                            displayname: 'Adejumo, Lara MD',
                            providerid: 119,
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'This is a test message',
                        timestamp: 1617231375,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                    {
                        displaydate: '03/08/2021  12:22 PM',
                        from: {
                            displayname: 'Adejumo, Lara MD',
                            providerid: 119,
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'The results of your CBC blood work have returned from the lab. The results fell within normal ranges and no additional testing is needed at this time. If you have any questions regarding this test please contact our office.',
                        timestamp: 1615232375,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                ],
                source: 'labresult',
                subject: 'CBC',
            },
        ],
    },
};

const MESSAGE_LAB_RESULT_NO_THREAD: MockResponse<ApiResponse<MessageThread>> = {
    d: {
        __metadata: {
            identity_match_strategy: 'CdmPersonStrategy',
        },
        results: [
            {
                associated_message_thread_id: null,
                message_thread_id: 5,
                messages: [
                    {
                        displaydate: '03/31/2021  7:56 PM',
                        from: {
                            displayname: 'Adejumo, Lara MD',
                            providerid: 119,
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'This is a test message',
                        timestamp: 1617231375,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                ],
                source: 'labresult',
                subject: 'No associated thread',
            },
        ],
    },
};

export { MESSAGE_LAB_RESULT_THREAD_SUCCESS_RESPONSE, MESSAGE_LAB_RESULT_THREAD_SUCCESS_RESPONSE_2, MESSAGE_LAB_RESULT_NO_THREAD };
