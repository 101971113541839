/* eslint-disable functional/immutable-data */
/* eslint-disable no-param-reassign */
const replaceImgs = (htmlString: string, logoPath: string): string => {
    // Create absolute url for logo
    const LOGO_ID = 'villagemd-logo';
    const LOGO_URL = `${window.location.origin}${logoPath}`;

    // Create a temporary div element to parse the HTML string
    const temporaryDiv = document.createElement('div');
    temporaryDiv.innerHTML = htmlString;

    // Find all images
    const images = temporaryDiv.querySelectorAll('img');

    // Loop through images and modify the src attribute based on the condition
    images.forEach((img) => {
        if (img.src.includes('TYPE=LETTERHEAD')) {
            img.id = LOGO_ID;
            img.src = LOGO_URL;
        } else {
            img.remove(); // Remove other images
        }
    });

    // Get the modified HTML string
    return temporaryDiv.innerHTML;
};
/* eslint-enable functional/immutable-data */
/* eslint-enable no-param-reassign */

const prepareFileName = (fileName: string): string => fileName.replace(/\s|\//g, '_');

export { replaceImgs, prepareFileName };
