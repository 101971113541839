import { memo } from 'react';
import type { FC } from 'react';

import { MedicationDetailsContent } from './medication-details-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <MedicationDetailsContent />,
};

const MedicationDetailsPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Medication details" />);

export { MedicationDetailsPage };
