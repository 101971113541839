import { defaultVillageUiTheme } from '@village/ui';

type ChatType = 'primary' | 'secondary';
type ChatColorProperties = 'background' | 'border';

export interface ChatTheme {
    readonly variant: Record<ChatType, Record<ChatColorProperties, string>>;
}

export const chatTheme: ChatTheme = {
    variant: {
        primary: {
            background: defaultVillageUiTheme.vui.colors.background.blue,
            border: defaultVillageUiTheme.vui.colors.background.blue,
        },
        secondary: {
            background: 'transparent',
            border: defaultVillageUiTheme.vui.colors.background.blue,
        },
    },
};
