import { Skeleton } from '@material-ui/lab';
import type { FC } from 'react';

import * as Styled from './styles';

const TestResultsLoading: FC = () => (
    <Styled.LoaderContainer data-testid="test-results-loading">
        {Array.from({ length: 6 }, (_, index) => index + 1).map((item) => (
            <Styled.TestResultNameLoader key={item}>
                <Styled.TestResultDateLoader>
                    <Skeleton animation="wave" variant="text" />
                </Styled.TestResultDateLoader>
                <Skeleton animation="wave" variant="text" width="25%" />
            </Styled.TestResultNameLoader>
        ))}
    </Styled.LoaderContainer>
);

export { TestResultsLoading };
