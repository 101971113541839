import { rem } from 'polished';
import styled from 'styled-components';

const Header = styled.div`
    padding: 0.5rem 1rem 1rem;
    font-size: ${rem(22)};
    line-height: ${rem(28)};
    font-weight: 600;
    color: ${({ theme }) => theme.color.catalinaBlue};
`;

const Container = styled.div`
    background-color: ${({ theme }) => theme.color.catskillWhite};
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const RoundedContainer = styled.div`
    padding: 0;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: ${rem(24)} ${rem(24)} 0 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
`;

export { Container, Header, RoundedContainer };
