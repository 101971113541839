import type { Encounter } from 'types';

export const ENCOUNTERS_SUCCESS_RESPONSE: readonly Encounter[] = [
    {
        date: '2021-01-02',
        id: 9876654,
        provider: {
            department_ids: [1, 2],
            displayname: 'Chris, Pedroza MD',
            entitytype: 'Person',
            firstname: 'Chris',
            lastname: 'Pedroza',
            npi: 3215554579,
            provider_image_url: 'https://cdn2.hubspot.net/hubfs/6509886/Dr.%20Pedroza%20094FF.jpg',
            providerid: 120,
            providertype: '',
            providertypeid: 'MD',
            specialty: 'Family Medicine',
        },
    },
    {
        date: '2021-07-05',
        id: 9834987,
        provider: {
            department_ids: [1, 2],
            displayname: '',
            entitytype: 'Person',
            firstname: 'Stephanie',
            lastname: 'Wiman',
            npi: 1932531779,
            provider_image_url: 'https://cdn2.hubspot.net/hubfs/6509886/Stephanie%20Wiman.jpg',
            providerid: 16,
            providertype: '',
            providertypeid: 'MD',
            specialty: 'Family Medicine',
        },
    },
    {
        date: '2021-12-12',
        id: 2376523,
        provider: {
            department_ids: [1, 2],
            displayname: '',
            entitytype: 'Person',
            firstname: 'Matt',
            lastname: 'Perkins',
            npi: 6458445131,
            provider_image_url: '',
            providerid: 124,
            providertype: '',
            providertypeid: 'MD',
            specialty: 'Family Medicine',
        },
    },
];
