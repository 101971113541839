import type { Department } from 'types';

const departments: readonly Department[] = [
    {
        about: '',
        address: '8810 Antoine Dr.',
        address2: '',
        city: 'houston',
        departmentid: 58,
        fax: '',
        formattedaddress: '',
        hours: null,
        interiorimageurl: '',
        latitude: 3.142,
        longitude: 3.142,
        patientdepartmentname: '',
        phone: '',
        providerlist: [2, 4, 6],
        state: 'TX',
        thumbnailimageurl: '',
        timezone: 4,
        timezonename: 'America/Chicago',
        zip: '77088',
    },
    {
        about: '',
        address: '6122 Broadway St.',
        address2: 'Suite 100',
        city: 'pearland',
        departmentid: 59,
        fax: '',
        formattedaddress: '',
        hours: null,
        interiorimageurl: '',
        latitude: 3.142,
        longitude: 3.142,
        patientdepartmentname: '',
        phone: '',
        providerlist: [1, 3, 7],
        state: 'TX',
        thumbnailimageurl: '',
        timezone: 4,
        timezonename: 'America/Chicago',
        zip: '77581',
    },
];

const DEPARTMENTS_SUCCESS_RESPONSE = {
    d: {
        __metadata: {
            identity_match_strategy: 'EmpiStrategy',
        },
        results: departments,
    },
};

export { DEPARTMENTS_SUCCESS_RESPONSE };
