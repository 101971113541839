import { useContext } from 'react';

import { FeatureFlagsContext } from '../contexts/feature-flags';
import type { FeatureFlagsContextType } from '../contexts/feature-flags';

const useFeatureFlags = (): FeatureFlagsContextType => {
    const context = useContext(FeatureFlagsContext);

    if (context === undefined) {
        throw new Error('useFeatureFlags must be used within a FeatureFlagsProvider');
    }

    return context;
};

export { useFeatureFlags };
