import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const OuterContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
`;

const InnerContainer = styled.div`
    flex: 1;
`;

const NoLabs = styled.div`
    text-align: center;
    margin-top: ${rem(22)};
`;

const RecentLabs = styled(Text).attrs(() => ({ type: 'titleCaps' }))`
    padding-bottom: 1rem;
`;

const CardButtonContainer = styled.div`
    padding: ${rem(16)};
`;

export { NoLabs, OuterContainer, InnerContainer, RecentLabs, CardButtonContainer };
