import { CloudQuestionIcon } from '@village/icons';
import { useCallback } from 'react';
import type { FC } from 'react';

import * as Styled from './styles';
import type { ChatWithUsProps } from './types';
import { sendToNative } from 'modules/ipc';

const ChatWithUs: FC<ChatWithUsProps> = ({ variant = 'primary' }) => {
    const sendChatIpc = useCallback((): void => {
        sendToNative('openChat', 'health-records');
    }, []);

    return (
        <Styled.Chat $variant={variant} data-testid="chat-with-us" onClick={sendChatIpc}>
            <CloudQuestionIcon size="lg" />
            <Styled.ContactUs>
                <Styled.HelpText>Have questions or need help?</Styled.HelpText>
                <Styled.ChatText>Chat with us</Styled.ChatText>
            </Styled.ContactUs>
        </Styled.Chat>
    );
};

export { ChatWithUs };
