import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import type { FC } from 'react';

import { Page } from 'pages/page-layouts';

const VaccineDetailsLoading: FC = () => (
    <Page.Padding data-testid="vaccine-details-loading">
        <Box pb={1.25}>
            <Box mb={0.5}>
                <Skeleton height={36} variant="rect" />
            </Box>
        </Box>
        <Box pb={1.25}>
            <Box mb={0.5} width="60%">
                <Skeleton variant="text" />
            </Box>
        </Box>
        <Box pb={1.25}>
            <Box mb={0.5} width="40%">
                <Skeleton variant="text" />
            </Box>
        </Box>
        <Box pb={1.25}>
            <Box mb={0.5}>
                <Skeleton height={36} variant="rect" />
            </Box>
        </Box>
        {Array.from({ length: 5 }, (_, index) => index + 1).map((item) => (
            <Box key={item} pb={1.25}>
                <Skeleton height={36} variant="rect" />
            </Box>
        ))}
    </Page.Padding>
);

export { VaccineDetailsLoading };
