import type { FC, PropsWithChildren } from 'react';
import { useEffect, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SchedulingContext } from './scheduling-context';
import { schedulingStepOrder, schedulingStepsRoutes } from '../const/scheduling-steps';
import type { SchedulingStep } from '../types/scheduling-step';

const SchedulingContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [currentStep, setCurrentStep] = useState<SchedulingStep>(schedulingStepOrder[0]);

    const goToNextStep = useCallback(() => {
        const nextStep = schedulingStepOrder[schedulingStepOrder.indexOf(currentStep) + 1];
        if (nextStep !== undefined) {
            navigate(schedulingStepsRoutes[nextStep]);
            setCurrentStep(nextStep);
        }
    }, [currentStep, navigate]);

    const goToStep = useCallback(
        (step: SchedulingStep) => {
            navigate(schedulingStepsRoutes[step]);
            setCurrentStep(step);
        },
        [navigate]
    );

    const schedulingContextValue = useMemo(
        () => ({
            currentStep,
            goToNextStep,
            goToStep,
        }),
        [currentStep, goToNextStep, goToStep]
    );
    useEffect(() => {
        const syncedStep = Object.entries(schedulingStepsRoutes).find(([, value]) => value.includes(location.pathname));

        if (syncedStep) {
            setCurrentStep(syncedStep[0] as SchedulingStep);
        }
    }, [location]);

    return <SchedulingContext.Provider value={schedulingContextValue}>{children}</SchedulingContext.Provider>;
};

export { SchedulingContextProvider };
