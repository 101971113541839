import { defaultVillageUiTheme } from '@village/ui';
import { rem } from 'polished';
import type { DefaultTheme } from 'styled-components';

export const color: DefaultTheme['color'] = {
    androidBlue: '#387F9F',
    androidGrey: '#636A74',
    athensGray: '#E2E4E8',
    brightGray: '#373D45',
    bronzeOrange: '#CF7A3B',
    catalinaBlue: '#17335B',
    catskillWhite: '#F2F8F9',
    cremieOrange: '#ffeddf',
    errorRed: '#D90012',
    flashWhite: '#F0F2F3',
    ghostWhite: '#F8F8F9',
    greenLight: '#D3E8EC',
    harleyDavidsonOrange: '#D24318',
    hotPink: '#BD5A85',
    irisBlue: '#00B7C7',
    lightBlue: '#87CEFA',
    lightGray: '#abb1b9',
    lightOrange: '#FFF7F0',
    lightRed: '#FCE6E8',
    manatee: '#9299A2',
    midnightBlue: '#191970',
    navyBlue: '#455C7C',
    pacificBlue: '#00A8C6',
    platinum: '#E3E7EB',
    primaryBlue: '#0080A3',
    shark: '#24282E',
    shuttleGray: '#626A74',
    slateGray: '#7A818B',
    stormGray: '#6F7784',
    transparentGray: '#808080',
    trout: '#4C535C',
    venetianRed: '#D90012',
    white: '#ffffff',
};

export const globalBaseFontSize = 16;

export const theme: DefaultTheme = {
    ...defaultVillageUiTheme,
    color,
    overrides: {
        MuiRadio: {
            checked: {
                color: color.pacificBlue,
            },
            root: {
                color: color.pacificBlue,
            },
        },
    },
    page: {
        pagePaddingBlock: rem(24),
        pagePaddingInline: rem(16),
    },
};
