const MESSAGE_TYPE_SUCCESS_RESPONSE = {
    d: {
        __metadata: {
            identity_match_strategy: 'EmpiStrategy',
        },
        results: [
            {
                order: 1,
                text: 'Medical question',
                value: 'PATIENTCASE_CLINICALQUESTION',
            },
            {
                order: 2,
                text: 'Appointments and scheduling',
                value: 'PATIENTCASE_APPOINTMENT',
            },
            {
                order: 3,
                text: 'Prescriptions and refills',
                value: 'PATIENTCASE_REFILL',
            },
            {
                order: 4,
                text: 'Billing and payments',
                value: 'PATIENTCASE_BILLING',
            },
            {
                order: 5,
                text: 'My profile',
                value: 'PATIENTCASE_ADMINISTRATIVE',
            },
            {
                order: 6,
                text: 'Insurance',
                value: 'PATIENTCASE_BILLING',
            },
            {
                order: 7,
                text: 'Other',
                value: 'PATIENTCASE_OTHER',
            },
        ],
    },
};

export { MESSAGE_TYPE_SUCCESS_RESPONSE };
