import { useMutation } from 'react-query';
import type { UseMutationResult } from 'react-query';

import { messagingApi } from '../messaging-api-config';
import type { MessageTypeKeys } from 'types';

interface ApiResponse {
    readonly message_thread_id: number;
}

interface MessagePayload {
    readonly associated_lab_result_id?: Nullable<number>;
    readonly associated_imaging_result_id?: Nullable<number>;
    readonly day_of_week?: Nullable<readonly number[]>;
    readonly department_id: Nullable<number>;
    readonly message_type: MessageTypeKeys;
    readonly subject: Nullable<string>;
    readonly text: string;
    readonly time_of_day?: 'AM' | 'EV' | 'PM' | null;
    readonly provider_id: Nullable<number>;
}

const useCreateNewMessage = (): UseMutationResult<ApiResponse> =>
    useMutation(async (messageData) => {
        const response = await messagingApi.post(`/message/`, messageData);
        return response.data;
    });

export { useCreateNewMessage };
export type { MessagePayload };
