import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import { GlobalStyles as VillageGlobalStyles } from '@village/ui';
import type { AxiosError } from 'axios';
import type { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { AppErrorBoundary } from 'components/error-boundary';
import { AppContextProvider } from 'contexts';
import { AuthProvider } from 'contexts/auth';
import { MUIGlobalStyles } from 'global-style';
import { appConfig } from 'modules/config';
import { SchedulingContextProvider } from 'pages/scheduling-flow/context';
import { AppRouter } from 'routes/app-router';
import { theme } from 'theme';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            /**
             * A retry is a mechanism that monitors a request,
             * and on the detection of failure automatically fires a repeat of the request.
             * A retry of a request should only be considered when there is a chance of success,
             * for example in response to error codes 500 (Internal Server Error) and 503 (Service Unavailable).
             * Attempting to retry on any and every error is inadvisable
             * as it wastes resources and can produce unexpected results.
             * Read more:
             * - https://denalibalser.medium.com/best-practices-for-retry-685bf58de797
             * - https://medium.com/capital-one-tech/3-best-practices-for-api-clients-feef9ec97f1a
             */
            retry: (failureCount, error) => {
                const axiosError = error as AxiosError | undefined;
                const errorStatus = axiosError?.response?.status;
                return failureCount < 2 && !!errorStatus && (errorStatus === 429 || errorStatus >= 500);
            },
            useErrorBoundary: true,
        },
    },
});

const App: FC = () => (
    <QueryClientProvider client={queryClient}>
        <StylesProvider injectFirst={true}>
            <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                    <VillageGlobalStyles />
                    <BrowserRouter basename={appConfig.basename}>
                        <AppContextProvider>
                            <MUIGlobalStyles>
                                <AppErrorBoundary>
                                    <AuthProvider>
                                        <SchedulingContextProvider>
                                            <AppRouter />
                                        </SchedulingContextProvider>
                                    </AuthProvider>
                                </AppErrorBoundary>
                            </MUIGlobalStyles>
                        </AppContextProvider>
                    </BrowserRouter>
                </ThemeProvider>
            </MuiThemeProvider>
        </StylesProvider>
    </QueryClientProvider>
);

export { App };
