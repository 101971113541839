import { List } from '@village/ui';
import { Fragment, useEffect, useMemo } from 'react';
import type { FC } from 'react';

import { LabsLoading } from './labs-loading';
import * as Styled from './styles';
import { Banner } from 'components/banner';
import { ChatWithUs } from 'components/chat-with-us';
import { NoData } from 'components/no-data';
import { useLabs } from 'data/hooks/use-labs-query';
import { useFeatureFlags } from 'hooks';
import { useNativeControls } from 'hooks/use-native-controls';
import { appConfig } from 'modules/config';
import { Countly } from 'modules/countly';
import { LabEntry } from 'pages/labs/components/lab-entry';

const LabsContent: FC = () => {
    const { hasFeature } = useFeatureFlags();
    const { data, isLoading } = useLabs({ show_portal_only: true });
    const metadata = data?.__metadata;
    const matchStrategy = metadata !== undefined ? metadata.identity_match_strategy : '';
    const bannerMessage = `Athena Patient Matched Using: ${matchStrategy}`;
    const banner = useMemo(() => (appConfig.showBanner ? <Banner message={bannerMessage} /> : null), [bannerMessage]);

    useNativeControls({
        ipcEvents: ['showBackButton'],
        source: 'health-records',
        title: 'Lab Results',
    });

    useEffect(() => {
        if (data) {
            Countly.addEvent({
                key: 'viewLabs',
                segmentation: { noData: data?.results.length === 0 },
            });
        }
    }, [data, data?.results.length]);

    if (isLoading) {
        return <LabsLoading />;
    }

    if (!data?.results.length) {
        return (
            <Fragment>
                {banner}
                <NoData iconName="lab-result" title="No Lab Results" />
            </Fragment>
        );
    }

    return (
        <Fragment>
            {banner}
            {hasFeature('chatLabs') && (
                <Styled.ChatContainer>
                    <ChatWithUs />
                </Styled.ChatContainer>
            )}
            <List>
                {data.results.map((lab) => (
                    <LabEntry key={lab.lab_result_id} lab={lab} />
                ))}
            </List>
        </Fragment>
    );
};

export { LabsContent };
