import type { FC } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Styled from './styles';
import { Icon } from 'components/icon';
import type { VaccineInfo } from 'types/vaccine-history';
import { formatDate } from 'utils';

interface Props {
    readonly vaccine: VaccineInfo;
}

const VaccineEntry: FC<Props> = ({ vaccine }) => {
    const navigate = useNavigate();
    const { genusname, vaccineid, administerdate } = vaccine;

    const goToVaccineDetail = useCallback(() => {
        navigate(`/vaccine-details/${vaccineid}`);
    }, [navigate, vaccineid]);

    return (
        <Styled.ListItem icon={<Icon name="needle" size={1.5} />} onClick={goToVaccineDetail}>
            <Styled.VaccineContainer key={vaccineid}>
                <Styled.VaccineName>{genusname}</Styled.VaccineName>
                <Styled.ApplicationDate>{formatDate(administerdate, 'fullDate')}</Styled.ApplicationDate>
            </Styled.VaccineContainer>
        </Styled.ListItem>
    );
};

export { VaccineEntry };
