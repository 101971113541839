import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

import { Icon } from 'components/icon';
import { ProviderImage } from 'components/provider-image';

const Container = styled.div`
    padding: ${rem(16)};
    border-radius: ${rem(4)};
    margin-top: ${rem(16)};
    background: ${({ theme }) => theme.color.catskillWhite};
`;

const ProviderInfo = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${rem(13)};
`;

const ProviderName = styled(Text).attrs(() => ({ type: 'sub1' }))`
    color: ${({ theme }) => theme.color.catalinaBlue};
`;

const ProviderSpecialty = styled(Text).attrs(() => ({ type: 'body2' }))`
    color: ${({ theme }) => theme.color.shuttleGray};
    margin-top: ${rem(2)};
`;

const ProviderNoteHeader = styled.div`
    font-weight: 600;
    font-size: ${rem(16)};
    color: ${({ theme }) => theme.color.catalinaBlue};
    margin-bottom: ${rem(8)};
`;

const Note = styled(Text).attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.color.brightGray};
`;

const HaveAQuestion = styled(Text).attrs(() => ({ type: 'body2' }))`
    color: ${({ theme }) => theme.color.brightGray};
`;

const ProviderIcon = styled(ProviderImage)`
    margin-right: ${rem(12)};
`;

const DefaultProviderIcon = styled(Icon)`
    margin-right: ${rem(12)};
`;

const SendMessage = styled.div`
    position: relative;
    display: grid;
    justify-content: flex-start;
    gap: ${rem(8)};
    margin-top: ${rem(20)};
    padding-top: ${rem(8)};

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 3.25rem;
        border-top: 2px solid ${({ theme }) => theme.color.athensGray};
    }
`;

export {
    Container,
    ProviderInfo,
    ProviderName,
    ProviderSpecialty,
    ProviderNoteHeader,
    Note,
    ProviderIcon,
    DefaultProviderIcon,
    SendMessage,
    HaveAQuestion,
};
