import type { ImagingResult } from 'types';

const IMAGING_RESULTS_SUCCESS_RESPONSE: { readonly imagingresults: readonly ImagingResult[] } = {
    imagingresults: [
        {
            appointmentid: null,
            assignedto: null,
            createddate: '2010-07-27',
            createddatetime: '2010-07-27T13:36:45-04:00',
            createduser: 'dfenick',
            deleteddatetime: null,
            departmentid: '1',
            documentclass: 'IMAGINGRESULT',
            documentdescription: 'imaging/diagnostic result',
            documentroute: 'FAX',
            documentsource: 'UPLOAD',
            documentsubclass: null,
            documenttypeid: null,
            encounterid: null,
            entitytype: null,
            externalaccessionid: null,
            externalnote:
                "Deirdre, you can see the break in the enclosed image.  I'll see you back in the office for next week's follow up.",
            facilityid: null,
            fileextension: null,
            filesize: null,
            imagingresultid: 9065,
            internalaccessionid: null,
            internalnote: null,
            interpretation: null,
            interpretationtemplate: null,
            lastmodifieddatetime: '2013-07-26T05:41:02-04:00',
            observationdatetime: null,
            ordergenusname: null,
            ordertype: null,
            originalfilename: null,
            patientid: 4,
            patientnote:
                "Deirdre, you can see the break in the enclosed image.  I'll see you back in the office for next week's follow up.",
            portalpublisheddatetime: '2010-07-27T13:38:30-04:00',
            priority: '2',
            providerid: null,
            receivedfacilityordercode: null,
            status: 'CLOSED',
            subject: null,
            test_result_name: 'Broken femur',
            tietoorderid: null,
        },
        {
            appointmentid: null,
            assignedto: null,
            createddate: '2010-07-27',
            createddatetime: '2010-07-27T13:42:11-04:00',
            createduser: 'dfenick',
            deleteddatetime: null,
            departmentid: '1',
            documentclass: 'IMAGINGRESULT',
            documentdescription: 'imaging/diagnostic result',
            documentroute: 'FAX',
            documentsource: 'UPLOAD',
            documentsubclass: null,
            documenttypeid: null,
            encounterid: null,
            entitytype: null,
            externalaccessionid: null,
            externalnote: 'Broken Knee Image',
            facilityid: null,
            fileextension: null,
            filesize: null,
            imagingresultid: 9066,
            internalaccessionid: null,
            internalnote: null,
            interpretation: null,
            interpretationtemplate: null,
            lastmodifieddatetime: '2013-07-26T05:41:02-04:00',
            observationdatetime: null,
            ordergenusname: null,
            ordertype: null,
            originalfilename: null,
            patientid: 4,
            patientnote: 'Broken Knee Image',
            portalpublisheddatetime: '2010-07-27T13:42:59-04:00',
            priority: '2',
            providerid: null,
            receivedfacilityordercode: null,
            status: 'CLOSED',
            subject: null,
            test_result_name: 'Broken Knee Image',
            tietoorderid: null,
        },
        {
            appointmentid: null,
            assignedto: null,
            createddate: '2010-07-27',
            createddatetime: '2010-07-27T13:50:08-04:00',
            createduser: 'dfenick',
            deleteddatetime: null,
            departmentid: '1',
            documentclass: 'IMAGINGRESULT',
            documentdescription: 'imaging/diagnostic result',
            documentroute: 'FAX',
            documentsource: 'UPLOAD',
            documentsubclass: null,
            documenttypeid: null,
            encounterid: null,
            entitytype: null,
            externalaccessionid: null,
            externalnote: 'Knee break image',
            facilityid: null,
            fileextension: null,
            filesize: null,
            imagingresultid: 9067,
            internalaccessionid: null,
            internalnote: null,
            interpretation: null,
            interpretationtemplate: null,
            lastmodifieddatetime: '2013-07-26T05:41:02-04:00',
            observationdatetime: null,
            ordergenusname: null,
            ordertype: null,
            originalfilename: null,
            patientid: 4,
            patientnote: 'Knee break image',
            portalpublisheddatetime: '2010-07-27T13:50:48-04:00',
            priority: '2',
            providerid: null,
            receivedfacilityordercode: null,
            status: 'CLOSED',
            subject: null,
            test_result_name: 'Knee break image',
            tietoorderid: null,
        },
    ],
};

export { IMAGING_RESULTS_SUCCESS_RESPONSE };
