import { ListItem } from '@village/ui';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Styled from './styles';
import { Icon } from 'components/icon';
import type { Medication } from 'types/medication';
import { formatDate } from 'utils';

interface Props {
    readonly medication: Medication;
}

const MedicationEntry: FC<Props> = ({ medication }) => {
    const navigate = useNavigate();
    const { medication_entry_id, medication_name, date_prescribed } = medication;
    const formatedDate = date_prescribed ? formatDate(date_prescribed, 'fullDate') : null;

    const goToMedicationDetail = useCallback(() => {
        navigate(`/medication/${medication_entry_id}`);
    }, [navigate, medication_entry_id]);

    return (
        <ListItem onClick={goToMedicationDetail}>
            <Styled.Container>
                <Styled.IconContainer>
                    <Styled.IconCloud>
                        <Icon name="medication" size={1.5} />
                    </Styled.IconCloud>
                </Styled.IconContainer>
                <Styled.MedicationInfo>
                    <Styled.MedicationName>{medication_name}</Styled.MedicationName>
                    {formatedDate ? <Styled.RefillsRemaining>Added {formatedDate}</Styled.RefillsRemaining> : null}
                </Styled.MedicationInfo>
            </Styled.Container>
        </ListItem>
    );
};

export { MedicationEntry };
