import { Text } from '@village/ui';
import styled from 'styled-components';

const Contanier = styled.div`
    padding-top: 1rem;
`;

const ButtonCardPrimaryText = styled(Text).attrs(() => ({ type: 'sub1' }))`
    color: ${({ theme }) => theme.vui.colors.neutral.gray80};
`;

const ButtonCardContent = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.vui.colors.neutral.gray60};
`;

const Date = styled(Text).attrs(() => ({ type: 'body2' }))`
    font-style: italic;
`;

export { Contanier, ButtonCardPrimaryText, ButtonCardContent, Date };
