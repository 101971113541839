import styled from 'styled-components';

const AdditionalSpinnerContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 5rem;
`;

export { AdditionalSpinnerContainer };
