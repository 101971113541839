import { useMemo } from 'react';

import { useImagingResult } from './use-imaging-result-query';
import { useLabResult } from './use-lab-result-query';
import type { TestResultDetails, TestResultType } from 'types';

interface Parameters {
    readonly testResultId: number | string | undefined;
    readonly testResultType: TestResultType | undefined;
}

const useIndividualTestResult = (
    params: Parameters,
    ignoreCache?: boolean
): {
    readonly testResult: TestResultDetails | undefined;
    readonly isFetching: boolean;
} => {
    const { testResultId, testResultType } = params;
    const { data: labResult, isFetching: isLabResultFetching } = useLabResult(
        {
            enabled: testResultType === 'labresult',
            labResultId: Number(testResultId),
        },
        ignoreCache
    );
    const { data: imagingResult, isFetching: isImagingResultFetching } = useImagingResult(
        {
            enabled: testResultType === 'imagingresult',
            imagingResultId: Number(testResultId),
        },
        ignoreCache
    );

    return useMemo(() => {
        if (isLabResultFetching || isImagingResultFetching) {
            return {
                isFetching: true,
                testResult: undefined,
            };
        }

        return {
            isFetching: false,
            testResult: labResult ?? imagingResult,
        };
    }, [labResult, imagingResult, isLabResultFetching, isImagingResultFetching]);
};

export { useIndividualTestResult };
