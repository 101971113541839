import { useCallback, useEffect, useMemo, useState } from 'react';
import type { FC, PropsWithChildren } from 'react';

import { DebuggingContext } from './debugging-context';
import type { MessageBase } from 'modules/ipc/types';

const DebuggingProvider: FC<PropsWithChildren> = ({ children }) => {
    const [eventsList, setEventsList] = useState<readonly string[]>([]);
    const [debugEnabled, setDebugEnabled] = useState(false);

    const addEvent = useCallback((newEvent: string) => {
        setEventsList((state) => state.concat(newEvent));
    }, []);

    useEffect(() => {
        window.addEventListener('message', ({ origin, data }: MessageEvent<MessageBase>) => {
            if (origin === window.origin) {
                addEvent(JSON.stringify(data));
            }
            if (data.event === 'enableDebug') setDebugEnabled(true);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const debuggingContextValue = useMemo(
        () => ({
            addEvent,
            debugEnabled,
            state: eventsList,
        }),
        [addEvent, eventsList, debugEnabled]
    );

    return <DebuggingContext.Provider value={debuggingContextValue}>{children}</DebuggingContext.Provider>;
};

export { DebuggingProvider };
