import { ButtonCard } from '@village/ui';
import type { FC } from 'react';
import { Fragment, useEffect, useCallback } from 'react';

import { AppointmentEntry } from './components/upcoming-visit-card';
import { QuestionAboutLoading } from './question-about-loading';
import * as Styled from './styles';
import { Icon } from 'components/icon';
import { useDepartments } from 'data/hooks/use-departments';
import { useUpcomingAppointments } from 'data/hooks/use-upcoming-appointments';
import { useMessaging } from 'hooks/use-messaging';
import { useNativeControls } from 'hooks/use-native-controls';
import { useNativeIpc } from 'hooks/use-native-ipc';
import { Countly } from 'modules/countly';
import { sendToNative } from 'modules/ipc';
import type { WebToNativeIpcEvents } from 'modules/ipc/types';
import { schedulingQuestionAbout } from 'pages/scheduling-flow/const/scheduling-question-about';
import { useScheduling } from 'pages/scheduling-flow/hooks/use-scheduling';
import type { SchedulingStep } from 'pages/scheduling-flow/types/scheduling-step';
import type { Appointment } from 'types';

const QuestionAboutContent: FC = () => {
    const { data: upcomingAppointments, isLoading: isLoadingVisits } = useUpcomingAppointments({ cacheTime: 0 });
    const { data: departmentsResponse, isLoading: isLoadingDepartments } = useDepartments();
    const { goToStep, goToNextStep } = useScheduling();
    const { setMessagingFields } = useMessaging();
    const handleSchedulingQuestionClicked = useCallback(
        (nextStep: SchedulingStep | undefined, ipcEvent: WebToNativeIpcEvents | undefined) => () => {
            setMessagingFields({
                messageSubject: 'Scheduling question',
            });
            if (ipcEvent) {
                sendToNative(ipcEvent, 'messaging');
            }
            if (nextStep) {
                goToStep(nextStep);
            }
        },
        [goToStep, setMessagingFields]
    );

    useNativeControls({
        ipcEvents: ['showControls', 'hideBottomNavBar'],
        source: 'messaging',
    });

    useEffect(() => {
        Countly.addEvent({
            key: 'viewQuestionAboutPage',
            segmentation: {
                flow: 'scheduling',
                messagingVersion: 2,
                source: 'messaging',
            },
        });
    }, []);

    const continueScheduling = useCallback(() => {
        setMessagingFields({
            messageSubject: 'New appointment request',
        });
        goToNextStep();
    }, [goToNextStep, setMessagingFields]);

    useNativeIpc('continueSchedulingFlow', continueScheduling);

    return (
        <Styled.Container>
            <Styled.Header>What is your question about?</Styled.Header>
            <Styled.RoundedContainer>
                <Styled.SuggestedContainer>Upcoming visit(s):</Styled.SuggestedContainer>
                <Styled.OptionsContainer>
                    {isLoadingVisits || isLoadingDepartments ? (
                        <QuestionAboutLoading />
                    ) : (
                        <Fragment>
                            {upcomingAppointments?.map((appointment: Appointment) => (
                                <AppointmentEntry
                                    key={appointment.appointmentid}
                                    appointment={appointment}
                                    departments={departmentsResponse}
                                />
                            ))}
                            {upcomingAppointments?.length !== 0 && <Styled.Delimiter />}
                            {schedulingQuestionAbout.map(({ iconName, text, nextStep, ipcEvent }, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Styled.ButtonCardContainer key={index}>
                                    <ButtonCard
                                        onClick={handleSchedulingQuestionClicked(nextStep, ipcEvent)}
                                        picture={<Icon name={iconName} />}
                                        primaryText={text}
                                    />
                                </Styled.ButtonCardContainer>
                            ))}
                        </Fragment>
                    )}
                </Styled.OptionsContainer>
            </Styled.RoundedContainer>
        </Styled.Container>
    );
};

export { QuestionAboutContent };
