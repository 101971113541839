import { ButtonCard } from '@village/ui';
import React, { useCallback, useMemo } from 'react';

import * as Styled from './styles';
import { Icon } from 'components/icon';
import { ProviderImage } from 'components/provider-image';
import { useMessaging } from 'hooks/use-messaging';
import { useScheduling } from 'pages/scheduling-flow/hooks/use-scheduling';
import type { ApiResponse, Appointment, Department } from 'types';
import { getDepartmentTimezone } from 'utils';
import { formatDateWithTimezone, getDateUTC } from 'utils/date';

interface Props {
    readonly appointment: Appointment;
    readonly departments: ApiResponse<Department> | undefined;
}

const AppointmentEntry: React.FC<Props> = ({ appointment, departments }) => {
    const { goToStep } = useScheduling();
    const { setMessagingFields } = useMessaging();
    const { providerimageurl, providername, providerspecialty, utcstarttime, departmentid, providernpi, providerid, timezone } =
        appointment;

    const appointmentTimezone = useMemo(
        () => getDepartmentTimezone(departmentid, departments?.results ?? []) ?? timezone,
        [departmentid, departments?.results, timezone]
    );

    const appointmentTime = formatDateWithTimezone(getDateUTC(utcstarttime), 'dateWithTimezone', appointmentTimezone);

    const image = useMemo(
        () => (providerimageurl ? <ProviderImage alt={providername} src={providerimageurl} /> : <Icon name="default-provider" />),
        [providerimageurl, providername]
    );

    const goToComposeMessage = useCallback((): void => {
        setMessagingFields({
            messageSubject: `Upcoming visit${appointmentTime ? ` - ${appointmentTime}` : ''}`,
            provider: {
                department_ids: [departmentid],
                displayname: providername,
                entitytype: null,
                firstname: providername,
                image: {
                    height: null,
                    url: providerimageurl,
                    width: null,
                },
                lastname: '',
                npi: providernpi ?? null,
                provider_image_url: providerimageurl,
                providerid,
                providertype: null,
                providertypeid: null,
                specialty: providerspecialty,
            },
        });
        goToStep('COMPOSE_MESSAGE');
    }, [
        setMessagingFields,
        appointmentTime,
        departmentid,
        providername,
        providerimageurl,
        providernpi,
        providerid,
        providerspecialty,
        goToStep,
    ]);

    return (
        <Styled.Container>
            <ButtonCard
                onClick={goToComposeMessage}
                picture={image}
                pictureType="circle"
                primaryText={`${appointmentTime ?? ''} CST`}
            >
                {providername ? `${providername}, ` : null}
                {providerspecialty}
            </ButtonCard>
        </Styled.Container>
    );
};

export { AppointmentEntry };
