import { rem } from 'polished';
import styled from 'styled-components';

const Header = styled.div`
    padding: 0.5rem 1rem 1rem;
    font-size: ${rem(22)};
    line-height: ${rem(28)};
    font-weight: 600;
    color: ${({ theme }) => theme.color.catalinaBlue};
`;

const Container = styled.div`
    background-color: ${({ theme }) => theme.color.catskillWhite};
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const LoaderContainer = styled.section`
    padding-top: 1rem;
`;

const RoundedContainer = styled.div`
    padding: 1rem 0;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: ${rem(24)} ${rem(24)} 0 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
`;

const OptionsContainer = styled.div`
    overflow: auto;
    padding: 0 1rem;
`;

const Delimiter = styled.div`
    padding-top: 1rem;
    border-bottom: 0.5px solid ${({ theme }) => theme.vui.colors.secondary.ghost};
`;

const SuggestedContainer = styled.div`
    padding: 0.5rem 1rem 0;
    color: ${({ theme }) => theme.vui.colors.primary.navy};
    font-weight: 600;
    font-size: ${rem(13)};
    line-height: ${rem(16)};
`;

const ButtonCardContainer = styled.div`
    padding-top: 1rem;
`;

export {
    ButtonCardContainer,
    Delimiter,
    Container,
    Header,
    SuggestedContainer,
    LoaderContainer,
    RoundedContainer,
    OptionsContainer,
};
