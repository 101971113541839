import styled from 'styled-components';

const Container = styled.section`
    display: grid;
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
    grid-row-gap: 1rem;
    width: 100%;
`;

const SimpleCentered = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export { Container, SimpleCentered };
