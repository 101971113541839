import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { UseMutationResult, UseQueryResult } from 'react-query';

import { healthRecordsApi } from '../health-records-api-config';
import type { ApiResponse } from 'types/api';
import type { Pharmacy } from 'types/pharmacy';

interface ReplyResponse {
    readonly success: boolean;
    readonly errormessage?: string;
    readonly detailedmessage?: string;
    readonly error?: boolean;
}

const useGetPreferredPharmacy = (enabled = true): UseQueryResult<Pharmacy> =>
    useQuery(
        'get-pharmacy-preferred',
        async () => {
            const response = await healthRecordsApi.get<ApiResponse<Pharmacy>>('/pharmacies/preferred');
            return response.data.results[0];
        },
        { enabled }
    );

const useSetPreferredPharmacy = (): UseMutationResult<ReplyResponse, unknown, number> => {
    const queryClient = useQueryClient();
    return useMutation(
        'set-pharmacy-preferred',
        async (ncpdpid) => {
            const response = await healthRecordsApi.put<ReplyResponse>('/pharmacies/preferred', {
                ncpdp_id: ncpdpid,
            });
            return response.data;
        },
        {
            onSuccess: () => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                queryClient.invalidateQueries('get-pharmacy-preferred');
            },
        }
    );
};
const useDeletePreferredPharmacy = (): UseMutationResult<ReplyResponse, unknown, number> =>
    useMutation('delete-pharmacy-preferred', async (clinicalproviderid) => {
        const response = await healthRecordsApi.delete<ReplyResponse>(
            `/pharmacies/preferred?clinical_provider_id=${clinicalproviderid}`
        );
        return response.data;
    });

export { useGetPreferredPharmacy, useSetPreferredPharmacy, useDeletePreferredPharmacy };
