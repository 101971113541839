import type { Dispatch } from 'react';
import { createContext } from 'react';

import type { MessagingState } from 'types';

export interface MessagingContextType {
    readonly messagingState: MessagingState;
    readonly setMessagingFields: Dispatch<Partial<MessagingState>>;
    readonly resetMessagingState: Dispatch<void>;
}

const MessagingContext = createContext<MessagingContextType | undefined>(undefined);

export { MessagingContext };
