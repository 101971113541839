import axios from 'axios';
import type { AxiosResponseTransformer } from 'axios';

import { TIMEOUT_MS } from './constants';
import { mockMessaging } from 'data/mocks/mock-adapter';
import { appConfig } from 'modules/config';
import type { ApiResponse } from 'types/api';

const messagingApi = axios.create({
    baseURL: appConfig.messagingApiBaseUrl,
    timeout: TIMEOUT_MS,
    transformResponse: ([] as readonly AxiosResponseTransformer[]).concat(
        axios.defaults.transformResponse ?? [],
        // The messaging API returns OData structures if we are dealing with
        // multiple entities as the results. For responses that do not return
        // multiple entities, we are passing the response as-is.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (data: { readonly d: ApiResponse<any> }) => data?.d ?? data
    ),
});

if (appConfig.useMocks) {
    mockMessaging(messagingApi);
}

export { messagingApi };
