import { useContext } from 'react';

import { FontSizeContext } from 'contexts/font-size';

const useFontSize = (): number => {
    const context = useContext(FontSizeContext);
    if (context === undefined) {
        throw new Error('useFontSize must be used within a FontSizeProvider');
    }

    return context;
};

export { useFontSize };
