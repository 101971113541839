import { Text } from '@village/ui';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    background-color: ${({ theme }) => theme.vui.colors.secondary.whiteCatskill};
    text-align: center;
`;

const ErrorMessage = styled(Text).attrs(() => ({ type: 'h3' }))`
    color: ${({ theme }) => theme.vui.colors.neutral.gray80};
    margin-top: 1rem;
    margin-bottom: 0.5rem;
`;

const Hint = styled(Text).attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.vui.colors.neutral.gray80};
    margin-bottom: 2rem;
`;

export { Container, ErrorMessage, Hint };
