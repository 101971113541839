import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import type { FC } from 'react';

import * as Styled from './styles';

const QuestionAboutLoading: FC = () => (
    <Styled.LoaderContainer data-testid="question-about-loading">
        {Array.from({ length: 4 }, (_, index) => index + 1).map((item) => (
            <Box key={item} pb={2}>
                <Skeleton animation="wave" height={92} variant="rect" />
            </Box>
        ))}
    </Styled.LoaderContainer>
);

export { QuestionAboutLoading };
