import { ButtonCard } from '@village/ui';
import { useMemo, Fragment, useEffect } from 'react';
import type { FC } from 'react';

import * as Styled from './styles';
import { Icon } from 'components/icon';
import { useMessaging } from 'hooks/use-messaging';
import { useNativeControls } from 'hooks/use-native-controls';
import { Countly } from 'modules/countly';
import { RoundedContainer } from 'pages/page-layouts';
import { useScheduling } from 'pages/scheduling-flow/hooks/use-scheduling';

const VisitOptions: FC = () => {
    const { setMessagingFields } = useMessaging();
    const { goToNextStep } = useScheduling();

    const goToComposeMessage = (option: string) => (): void => {
        setMessagingFields({
            visitOption: option,
        });
        goToNextStep();
    };

    useEffect(() => {
        setMessagingFields({
            visitOption: undefined,
        });
    }, [setMessagingFields]);

    useNativeControls({
        ipcEvents: ['showControls', 'hideBottomNavBar'],
        source: 'messaging',
    });

    useEffect(() => {
        Countly.addEvent({
            key: 'viewVisitOptionsPage',
            segmentation: {
                flow: 'scheduling',
                messagingVersion: 2,
                source: 'messaging',
            },
        });
    }, []);

    const officeIcon = useMemo(() => <Icon name="office" size={2} />, []);
    const videoIcon = useMemo(() => <Icon name="video" size={2} />, []);

    const headerText = (
        <Fragment>
            <Styled.Header>How do you prefer to be seen?</Styled.Header>
            <Styled.Subheader>Please note that not all appointment reasons can be seen as a video visit.</Styled.Subheader>
        </Fragment>
    );

    return (
        <RoundedContainer headerContent={headerText}>
            <Styled.CardContainer>
                <ButtonCard onClick={goToComposeMessage('in-office')} picture={officeIcon} primaryText="In-office" />
                <ButtonCard onClick={goToComposeMessage('video')} picture={videoIcon} primaryText="Video" />
                <ButtonCard onClick={goToComposeMessage('no preference')} primaryText="No preference" />
            </Styled.CardContainer>
        </RoundedContainer>
    );
};

export { VisitOptions };
