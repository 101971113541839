import { Spinner } from '@village/ui';
import type { FC } from 'react';

import * as Styled from './styles';

const DocumentPageLoading: FC = () => (
    <Styled.Container data-testid="documents-loading">
        <Styled.SpinnerContainer data-testid="centered-spinner">
            <Spinner />
        </Styled.SpinnerContainer>
        <Styled.LoadingTextContainer>Loading document</Styled.LoadingTextContainer>
    </Styled.Container>
);

export { DocumentPageLoading };
