import { getProviderDisplayName } from './provider';
import type { Provider } from 'types';

const formatEncounterProvider = (provider: Provider): Provider => ({
    department_ids: provider.department_ids,
    displayname: getProviderDisplayName(provider),
    entitytype: provider.entitytype,
    firstname: provider.firstname,
    image: provider.provider_image_url
        ? {
              height: null,
              url: provider.provider_image_url,
              width: null,
          }
        : undefined,
    lastname: provider.lastname,
    npi: provider.npi,
    provider_image_url: provider.provider_image_url,
    providerid: provider.providerid,
    providertype: null,
    providertypeid: null,
    recentencountersdate: undefined,
    specialty: provider.specialty,
});

export { formatEncounterProvider };
