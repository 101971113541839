// eslint-disable-next-line unicorn/prefer-node-protocol
import { Buffer } from 'buffer';
import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';

import { healthRecordsApi } from '../health-records-api-config';
import type { ImagingResultDetails } from 'types';

interface DocumentPageParameters {
    readonly pages: ImagingResultDetails['pages'];
    readonly imagingResultId: string;
    readonly enabled?: boolean;
}

const getImagingDocuments = async ({ pages, imagingResultId }: DocumentPageParameters): Promise<readonly string[]> => {
    const queries = pages.map(async ({ pageid }) => {
        const path = `/imaging_results/${imagingResultId}/pages/${pageid}`;
        return healthRecordsApi.get<Iterable<number>>(path, {
            responseType: 'arraybuffer',
        });
    });

    const responses = await Promise.all(queries);

    return responses.map((response) => {
        const bytes = new Uint8Array(response.data);
        const strings = bytes.reduce((payload, byte) => payload + String.fromCodePoint(byte), '');
        const imageBase64 = Buffer.from(strings, 'base64').toString('base64');
        return `data:image/png;base64,${imageBase64}`;
    });
};

const useImagingResultDocuments = ({
    pages,
    imagingResultId,
    enabled = true,
}: DocumentPageParameters): UseQueryResult<readonly string[]> =>
    useQuery(['imaging-documents', imagingResultId, pages], async () => getImagingDocuments({ imagingResultId, pages }), {
        enabled,
    });

export { useImagingResultDocuments };
