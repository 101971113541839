import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const LoaderContainer = styled.section`
    padding-top: 1rem;
`;

const OptionsContainer = styled.div`
    overflow: auto;
    padding: 0 1rem;
`;

const SuggestedContainer = styled(Text).attrs(() => ({ type: 'sub2' }))`
    padding: ${rem(24)} ${rem(16)} 0 ${rem(16)};
    color: ${({ theme }) => theme.vui.colors.primary.navy};
`;

export { SuggestedContainer, LoaderContainer, OptionsContainer };
