import * as Sentry from '@sentry/react';
import { Button } from '@village/ui';
import type { FC } from 'react';
import { useCallback } from 'react';

import * as Styled from './styles';
import { Icon } from '../icon';
import { sendToNative } from 'modules/ipc';

const NotFoundPage: FC = () => {
    const navigateBack = useCallback(() => {
        Sentry.captureException('404 - page not found', {
            tags: {
                section: 'messaging',
            },
        });

        sendToNative('backButtonClicked');
    }, []);

    return (
        <Styled.Container>
            <Icon name="band-aid" size={4} />
            <Styled.ErrorMessage>
                <p>Oops!</p>
                This page is missing
            </Styled.ErrorMessage>
            <Styled.Hint>We’re working on fixing the issue. If the issue persists, please call us.</Styled.Hint>
            <Button fullWidth={true} onClick={navigateBack}>
                Go back
            </Button>
        </Styled.Container>
    );
};

export { NotFoundPage };
