import { ListItem, List, Text } from '@village/ui';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CareSummariesLoading } from './care-summaries-loading';
import * as Styled from './styles';
import { Icon } from 'components/icon';
import { NoData } from 'components/no-data';
import { ProviderImage } from 'components/provider-image';
import { useEncounters } from 'data/hooks/use-encounters-query';
import { useNativeControls } from 'hooks/use-native-controls';
import { Countly } from 'modules/countly';
import { formatDate, getDateWithoutTimezone } from 'utils/date';

const CareSummariesContent: FC = () => {
    const navigate = useNavigate();
    const { data: encountersResponse, isLoading, isFetched } = useEncounters();

    const goToCareSummary = (appointmentId: number) => (): void => {
        navigate(`/post-visit-summary/${appointmentId}`);
    };

    useNativeControls({
        ipcEvents: ['showBackButton'],
        source: 'health-records',
        title: 'Care Summaries',
    });

    useEffect(() => {
        if (encountersResponse) {
            Countly.addEvent({
                key: 'viewCareSummaries',
                segmentation: { careSummariesCount: encountersResponse.length },
            });
        }
    }, [encountersResponse]);

    if (isLoading) {
        return <CareSummariesLoading />;
    }

    if (isFetched && (!encountersResponse || encountersResponse.length === 0)) {
        return <NoData iconName="scheduling" title="No Care Summaries" />;
    }

    return (
        <List>
            {encountersResponse?.map((encounter) => (
                <ListItem
                    key={encounter.id}
                    data-testid="care-summary"
                    onClick={goToCareSummary(encounter.id)}
                    picture={
                        encounter.provider.provider_image_url ? (
                            <ProviderImage
                                $size={3.5}
                                alt={encounter.provider.displayname ?? ''}
                                src={encounter.provider.provider_image_url}
                            />
                        ) : (
                            <Icon name="icProvider" size={3.5} />
                        )
                    }
                >
                    <Styled.DateTimeWrapper>
                        {encounter.date ? formatDate(getDateWithoutTimezone(encounter.date), 'fullDate') : '(Date not found)'}
                    </Styled.DateTimeWrapper>
                    <Styled.ProviderInfoText>
                        <Text type="body1">{encounter.provider.displayname}</Text>
                    </Styled.ProviderInfoText>
                </ListItem>
            ))}
        </List>
    );
};

export { CareSummariesContent };
