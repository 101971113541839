import { useContext } from 'react';

import type { MessagingContextType } from 'contexts/messaging';
import { MessagingContext } from 'contexts/messaging';

const useMessaging = (): MessagingContextType => {
    const context = useContext(MessagingContext);

    if (context === undefined) {
        throw new Error('useMessaging must be used within a MessagingProvider');
    }

    return context;
};

export { useMessaging };
