import { rem } from 'polished';
import styled from 'styled-components';

const Header = styled.div`
    padding: ${rem(8)} ${rem(16)} ${rem(16)};
    font-size: ${rem(22)};
    line-height: ${rem(28)};
    font-weight: 600;
    color: ${({ theme }) => theme.color.catalinaBlue};
`;

const Subheader = styled.div`
    padding: 0 ${rem(20)} ${rem(15)} ${rem(16)};
    font-size: ${rem(15)};
    color: ${({ theme }) => theme.color.navyBlue};
    line-height: ${rem(18)};
`;

const Description = styled.div`
    padding: ${rem(24)} ${rem(16)};
    flex: 1;
`;

const Next = styled.div`
    width: 100%;
    padding: ${rem(4)} ${rem(24)};
`;

const Skip = styled.div`
    width: 100%;
    color: ${({ theme }) => theme.color.primaryBlue};
    padding: ${rem(24)};
    font-size: ${rem(17)};
    text-align: center;
`;

export { Header, Subheader, Description, Next, Skip };
