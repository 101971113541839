import { Text, ListItem as VuiListItem } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const LetterContainer = styled.article`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

const LetterName = styled(Text).attrs({ type: 'sub1' })`
    font-weight: 600;
    font-size: ${rem(16)};
    line-height: 1.4;
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
    text-transform: capitalize;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
`;

const LetterDate = styled(Text).attrs({ type: 'body2' })`
    color: ${({ theme }) => theme.vui.colors.text.graySubtle};
`;

const ListItem = styled(VuiListItem)`
    width: 100%;
    gap: ${rem(16)};
    svg {
        margin: 0;
    }
    div:first-child {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${rem(48)};
        width: ${rem(48)};
        border-radius: 12px;
        background-color: ${({ theme }) => theme.vui.colors.surface.lightGray};
    }
    div:nth-child(2) {
        width: 0;
    }
    hr {
        width: calc(100% - ${({ theme }) => theme.page.pagePaddingInline});
        height: 0;
        border-top: thin solid ${({ theme }) => theme.vui.colors.border.divider.default};
    }
`;

const LoaderContainer = styled.section`
    padding: ${rem(30)} ${rem(16)};
`;

export { LetterDate, LetterContainer, LetterName, ListItem, LoaderContainer };
