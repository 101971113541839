import { Text } from '@village/ui';
import type { FC } from 'react';

import * as Styled from './styles';
import type { EncounterDetail } from 'types';
import { formatDate, getDateWithoutTimezone } from 'utils/date';

interface Props {
    readonly encounter: EncounterDetail;
}

const VisitSummary: FC<Props> = ({ encounter }) => {
    const { date } = encounter;

    return (
        <div>
            <Styled.Title>Care Summary</Styled.Title>
            <Styled.VisitSummaryInfo>
                <Text type="body1">{date ? formatDate(getDateWithoutTimezone(date), 'fullDate') : '(Date not found)'}</Text>
            </Styled.VisitSummaryInfo>
        </div>
    );
};

export { VisitSummary };
