import * as Sentry from '@sentry/react';
import { Cognito } from '@village/tools';
import type { CognitoUser, UserData } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { FC, PropsWithChildren } from 'react';
import { useQueryClient } from 'react-query';

import { AuthActionsContext, AuthStateContext } from './auth-context';
import type { AuthState } from './types';
import { useDebugging } from 'hooks/use-debugging';
import { useNativeIpc } from 'hooks/use-native-ipc';
import { cacheCognitoTokens } from 'modules/auth';
import type { CachedCognitoAuth } from 'modules/auth/types';
import { appConfig } from 'modules/config';
import { Countly } from 'modules/countly';
import { sendToNative } from 'modules/ipc';
import { getSessionStorage, removeSessionStorageItem } from 'utils';

const defaultState: AuthState = {
    authUser: null,
    error: undefined,
    isLoggedIn: false,
    // Default isLoggingIn to true because...
    // we assume the app will try to auto-login by default, if login fails we redirect to the login form
    // 1. When native app loads health-records as a WebView, native will send `autoSignIn` event to trigger auto-login
    // 2. In case we still have a valid cognito session
    isLoggingIn: true,
    isLoggingOut: false,
};

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
    const queryClient = useQueryClient();
    const [authState, setAuthState] = useState<AuthState>(defaultState);
    const { addEvent } = useDebugging();
    const login = useCallback(async (username: string, password: string) => {
        setAuthState((state) => ({ ...state, isLoggingIn: true }));
        await Auth.signIn(username, password);
    }, []);

    const logout = useCallback(async () => {
        setAuthState((state) => ({ ...state, isLoggingOut: true }));
        await Auth.signOut();
    }, []);

    const onAutoLoginSuccess = useCallback((user: CognitoUser) => {
        setAuthState(() => ({
            ...defaultState,
            authUser: user,
            isLoggedIn: true,
            isLoggingIn: false,
        }));

        sendToNative('autoSignInSuccess');
    }, []);

    const onAutoLoginFailure = useCallback(() => {
        setAuthState(() => ({
            ...defaultState,
            error: 'Could not authenticate user',
            isLoggingIn: false,
        }));

        sendToNative('autoSignInFailure');
    }, []);

    const handleAuth = useCallback(() => {
        addEvent('Handling Auth');
        const authFromStorage = getSessionStorage<CachedCognitoAuth>('vmdCognito');
        addEvent(`authFromStorage: /n
           accessToken: ${JSON.stringify(authFromStorage?.accessToken.slice(0, 4))} /n
           idToken: ${JSON.stringify(authFromStorage?.idToken.slice(0, 4))} /n
           refreshToken: ${JSON.stringify(authFromStorage?.refreshToken.slice(0, 4))} /n
           lastAuthUser: ${JSON.stringify(authFromStorage?.lastAuthUser.slice(0, 4))} /n
           `);
        if (authFromStorage) {
            cacheCognitoTokens(authFromStorage, appConfig.cognito);
            removeSessionStorageItem('vmdCognito');
            // if bypassCache is set to true after initial login,
            // a refresh token error is raised
            Auth.currentAuthenticatedUser({ bypassCache: false }).then(onAutoLoginSuccess).catch(onAutoLoginFailure);
        }
    }, [addEvent, onAutoLoginSuccess, onAutoLoginFailure]);

    const autoSignIn = useCallback(() => {
        addEvent('Calling autoSignIn');
        // This check protects us from scenarios where autoSignIn gets fired multiple times
        // leading to isLoggingIn= true after successfully logging in.
        if (authState.isLoggedIn) {
            return;
        }
        handleAuth();
    }, [addEvent, authState.isLoggedIn, handleAuth]);

    useEffect(() => {
        Hub.listen('auth', (data) => {
            // eslint-disable-next-line default-case
            switch (data.payload.event) {
                case 'signIn':
                    setAuthState(() => ({
                        ...defaultState,
                        authUser: data.payload.data,
                        isLoggedIn: true,
                        isLoggingIn: false,
                    }));
                    sendToNative('signInSuccess');
                    break;
                case 'signOut':
                case 'signIn_failure':
                    // Invalidate queries cache on user logout
                    // to avoid any possible conflicts of users data.
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    queryClient.invalidateQueries();
                    setAuthState(() => ({ ...defaultState, isLoggingIn: false }));
                    sendToNative(data.payload.event === 'signIn_failure' ? 'signInFailure' : 'signOut');
                    break;
            }
        });
    }, [queryClient]);

    useNativeIpc('autoSignIn', autoSignIn);
    useNativeIpc('tokenRefreshed', handleAuth);

    useEffect(() => {
        // Want to protect against browser refresh leading to isLoggedIn = false by default
        // despite the user still having valid cognito tokens in sessionStorage
        if (appConfig.platform === 'web') {
            // on refresh want to check if user authenticated through amplify
            Auth.currentAuthenticatedUser({ bypassCache: true }).then(onAutoLoginSuccess).catch(onAutoLoginFailure);
        }
    }, [onAutoLoginSuccess, onAutoLoginFailure]);

    useEffect(() => {
        // TODO: Add `!window.Cypress` condition when e2e tests are added
        if (authState.authUser) {
            authState.authUser.getUserData((error: Error | undefined, userData: UserData | undefined) => {
                if (error) {
                    Sentry.captureException(error);
                }

                if (userData) {
                    const cognitoUserData = Cognito.getUserDetails(userData);
                    if (cognitoUserData.id) {
                        Countly.disableOfflineMode(cognitoUserData.id);
                    }
                }
            });
        }
    }, [authState.authUser]);

    // Currently the autoSignIn event is not getting fired consistently from the native apps
    // This is a temporary fix to avoid our WebViews from infinite spinner
    useEffect(() => {
        if (appConfig.platform !== 'web') {
            autoSignIn();
        }
    }, [autoSignIn]);

    const authActions = useMemo(() => ({ login, logout }), [login, logout]);

    return (
        <AuthStateContext.Provider value={authState}>
            <AuthActionsContext.Provider value={authActions}>{children}</AuthActionsContext.Provider>
        </AuthStateContext.Provider>
    );
};

export { AuthProvider };
