import { combineComponents } from './combine-context-provider';
import { DebuggingProvider } from './debugging';
import { FeatureFlagsProvider } from './feature-flags';
import { FontSizeProvider } from './font-size';
import { MessagingProvider } from './messaging';
import { NavHeaderProvider } from './nav-header';

const providers = [FeatureFlagsProvider, FontSizeProvider, MessagingProvider, NavHeaderProvider, DebuggingProvider];

export const AppContextProvider = combineComponents(...providers);
