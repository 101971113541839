import { useContext } from 'react';

import type { AuthState } from 'contexts/auth';
import { AuthStateContext } from 'contexts/auth';

const useAuthState = (): AuthState => {
    const context = useContext(AuthStateContext);

    if (context === undefined) {
        throw new Error('useAuthState must be used within a AuthProvider');
    }

    return context;
};

export { useAuthState };
