import { memo } from 'react';
import type { FC } from 'react';

import { SelectMessageTypesContent } from './select-message-type-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <SelectMessageTypesContent />,
};

const SelectMessageTypePage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Select message type" />);

export { SelectMessageTypePage };
