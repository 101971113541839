const cmToFeet = (input: number): { readonly feet: number; readonly inches: number } => {
    const realFeet = (input * 0.3937) / 12;
    const feet = Math.floor(realFeet);
    const inches = Math.round((realFeet - feet) * 12);
    return { feet, inches };
};

const gramToPound = (input: number): string => (input * 0.00220462).toFixed(2);

export { cmToFeet, gramToPound };
