import { memo } from 'react';
import type { FC } from 'react';

import { SelectRecipientContent } from './select-recipient-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <SelectRecipientContent />,
};

const SelectRecipientPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Select message recipient" />);

export { SelectRecipientPage };
