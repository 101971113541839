import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import type { FC } from 'react';

import * as Styled from './styles';

const MedicationsLoading: FC = () => (
    <Styled.LoaderContainer data-testid="medications-loading">
        {Array.from({ length: 6 }, (_, index) => index + 1).map((item) => (
            <Box key={item} pb={1.25}>
                <Box mb={0.5} width="50%">
                    <Skeleton variant="text" />
                </Box>
                <Skeleton animation="wave" height={36} variant="rect" />
            </Box>
        ))}
    </Styled.LoaderContainer>
);

export { MedicationsLoading };
