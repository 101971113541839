import { createContext } from 'react';

import type { SchedulingStep } from '../types/scheduling-step';

export interface SchedulingContextType {
    readonly currentStep: SchedulingStep;
    readonly goToNextStep: () => void;
    readonly goToStep: (step: SchedulingStep) => void;
}

const SchedulingContext = createContext<SchedulingContextType | undefined>(undefined);

export { SchedulingContext };
