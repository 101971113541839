import type { FC } from 'react';
import { memo } from 'react';

import { SelectPrescription } from './select-prescription-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <SelectPrescription />,
};

const SelectPrescriptionPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Select prescription" />);

export { SelectPrescriptionPage };
