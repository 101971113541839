import { Input } from '@village/ui';
import { memo, useCallback } from 'react';
import type { FC } from 'react';

interface Props {
    readonly searchString: string;
    readonly setSearchString: React.Dispatch<React.SetStateAction<string>>;
    readonly placeholder?: string;
    readonly onClick?: () => void;
}

const SearchField: FC<Props> = memo(({ placeholder = 'Search', searchString, setSearchString, onClick }) => {
    const searchFieldChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchString(event.target.value);
        },
        [setSearchString]
    );

    return (
        <Input
            data-testid="search-field"
            fullWidth={true}
            onChange={searchFieldChange}
            onClick={onClick}
            placeholder={placeholder}
            type="search"
            value={searchString}
        />
    );
});

export { SearchField };
