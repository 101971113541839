import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
    padding-bottom: ${rem(16)};
`;

const Title = styled(Text).attrs(() => ({ type: 'h3' }))`
    color: ${({ theme }) => theme.vui.colors.neutral.gray90};
    margin-bottom: ${rem(4)};
`;

const VisitSummaryInfo = styled.div`
    display: grid;
    row-gap: ${rem(4)};
    color: ${({ theme }) => theme.vui.colors.text.grayMuted};
`;

export { Container, Title, VisitSummaryInfo };
