import type { Medication } from 'types';

const MEDICATIONS_SUCCESS_RESPONSE = {
    d: {
        __metadata: {
            identity_match_strategy: 'SomeStrategy',
        },
        results: [
            {
                date_prescribed: '2020-08-13',
                entered_by: 'KIMBERLY HILL, DNP',
                instructions: 'Take 15 mL every 3 hours by oral route as needed.',
                medication_entry_id: 'C17808687',
                medication_name: 'Lidocaine Viscous 2 % mucosal solution',
                medication_type: 'ACTIVE',
                pharmacy_facility: {
                    address: '4600 N. Hanley Road',
                    city: 'St Louis',
                    distance: '11',
                    facilityid: 0,
                    faxnumber: '8664701744',
                    name: 'Essds Pharmacy',
                    ncpdpid: '12292362',
                    pharmacytype: 'MAILORDER',
                    phonenumber: '8669973688',
                    state: 'MO',
                    zipcode: '63134',
                },
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: 90,
                quantity_unit: 'pills',
                refills_allowed: null,
                stop_date: null,
            },
            {
                date_prescribed: '2020-10-11',
                entered_by: 'KIMBERLY HILL, DNP',
                instructions: 'Take 1 tablet by mouth three times a day ( 10am, 12pm, and 6pm)',
                medication_entry_id: 'C19222210',
                medication_name: 'gabapentin 800 mg tablet',
                medication_type: 'HISTORICAL',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: 50,
                quantity_unit: 'tablets',
                refills_allowed: 3,
                stop_date: null,
            },
            {
                date_prescribed: null,
                entered_by: 'Caremark',
                instructions: null,
                medication_entry_id: 'H8070275',
                medication_name: 'propranolol 60 mg tablet',
                medication_type: 'ACTIVE',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: 45,
                quantity_unit: 'tablets',
                refills_allowed: 2,
                stop_date: null,
            },
            {
                date_prescribed: '2020-09-15',
                entered_by: 'Stephanie E Wiman MD',
                instructions: 'Take 1 tablet every 6 hours by oral route.',
                medication_entry_id: 'C18550293',
                medication_name: 'hydrocodone 10 mg-acetaminophen 325 mg tablet',
                medication_type: 'ACTIVE',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: 12,
                quantity_unit: 'tablets',
                refills_allowed: null,
                stop_date: null,
            },
            {
                date_prescribed: null,
                entered_by: 'Diana Lopez',
                instructions: 'Take 1 capsule by mouth every morning at 10am',
                medication_entry_id: 'H7403128',
                medication_name: 'omeprazole 40 mg capsule,delayed release',
                medication_type: 'ACTIVE',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: 20,
                quantity_unit: 'capsules',
                refills_allowed: 1,
                stop_date: null,
            },
            {
                date_prescribed: '2020-10-06',
                entered_by: 'Stephanie E Wiman MD',
                instructions: 'Take 1 tablet 3 times a day by oral route as needed.',
                medication_entry_id: 'C19057169',
                medication_name: 'tramadol 50 mg tablet',
                medication_type: 'ACTIVE',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: 20,
                quantity_unit: 'tablets',
                refills_allowed: 5,
                stop_date: null,
            },
            {
                date_prescribed: null,
                entered_by: 'KIMBERLY HILL, DNP',
                instructions: 'Take 1 tablet every 12 hours by oral route for 7 days.',
                medication_entry_id: 'H7944594',
                medication_name: 'amoxicillin 875 mg tablet',
                medication_type: 'HISTORICAL',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: 14,
                quantity_unit: 'tablets',
                refills_allowed: null,
                stop_date: null,
            },
            {
                date_prescribed: null,
                entered_by: 'Caremark',
                instructions: null,
                medication_entry_id: 'H8070279',
                medication_name: 'trazodone 100 mg tablet',
                medication_type: 'ACTIVE',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: 20,
                quantity_unit: 'tablets',
                refills_allowed: null,
                stop_date: null,
            },
            {
                date_prescribed: null,
                entered_by: 'Caremark',
                instructions: null,
                medication_entry_id: 'H8070277',
                medication_name: 'metformin 1,000 mg tablet',
                medication_type: 'ACTIVE',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: 100,
                quantity_unit: 'tablets',
                refills_allowed: null,
                stop_date: null,
            },
            {
                date_prescribed: '2020-03-27',
                entered_by: 'Stephanie E Wiman MD',
                instructions: 'APPLY TO THE AFFECTED AREA(S) BY TOPICAL ROUTE 2 TIMES PER DAY',
                medication_entry_id: 'C15517598',
                medication_name: 'nystatin 100,000 unit/gram topical cream',
                medication_type: 'ACTIVE',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: null,
                quantity_unit: null,
                refills_allowed: null,
                stop_date: null,
            },
            {
                date_prescribed: '2020-09-25',
                entered_by: 'KIMBERLY HILL, DNP',
                instructions: 'TAKE 1 TABLET BY MOUTH TWICE DAILY ( 10am and 6pm)',
                medication_entry_id: 'C18811345',
                medication_name: 'glimepiride 4 mg tablet',
                medication_type: 'HISTORICAL',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: 60,
                quantity_unit: 'tablets',
                refills_allowed: 10,
                stop_date: null,
            },
            {
                date_prescribed: '2020-08-27',
                entered_by: 'Stephanie E Wiman MD',
                instructions: 'TOME UNA TABLETA DOS VECES AL DIA CUANDO SEA NECESARIO',
                medication_entry_id: 'C18072654',
                medication_name: 'clonazepam 2 mg tablet',
                medication_type: 'ACTIVE',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: 10,
                quantity_unit: 'tablets',
                refills_allowed: 1,
                stop_date: null,
            },
            {
                date_prescribed: null,
                entered_by: 'Caremark',
                instructions: null,
                medication_entry_id: 'H7747435',
                medication_name: 'nitroglycerin 0.4 mg sublingual tablet',
                medication_type: 'ACTIVE',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: 20,
                quantity_unit: 'tablets',
                refills_allowed: null,
                stop_date: null,
            },
            {
                date_prescribed: null,
                entered_by: 'Diana Lopez',
                instructions: 'Take 1 tablet every day by oral route in the morning for 30 days.',
                medication_entry_id: 'H7402830',
                medication_name: 'Aspir-81 mg tablet,delayed release',
                medication_type: 'ACTIVE',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: 150,
                quantity_unit: 'tablets',
                refills_allowed: null,
                stop_date: null,
            },
            {
                date_prescribed: null,
                entered_by: 'Caremark',
                instructions: null,
                medication_entry_id: 'H7441917',
                medication_name: 'triamcinolone acetonide 0.1 % lotion',
                medication_type: 'ACTIVE',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: null,
                quantity_unit: null,
                refills_allowed: null,
                stop_date: null,
            },
            {
                date_prescribed: null,
                entered_by: 'Mai Le Somphet MD',
                instructions: ' 2 puff(s)s by inhalation route.',
                medication_entry_id: 'H4344392',
                medication_name: 'albuterol (refill) 90 mcg/actuation aerosol inhaler',
                medication_type: 'ACTIVE',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: null,
                quantity_unit: null,
                refills_allowed: 2,
                stop_date: null,
            },
            {
                date_prescribed: '2020-08-31',
                entered_by: 'Stephanie E Wiman MD',
                instructions: 'Take 1 tablet every day by oral route in the morning.',
                medication_entry_id: 'C18205977',
                medication_name: 'amlodipine 10 mg-atorvastatin 10 mg tablet',
                medication_type: 'HISTORICAL',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: 20,
                quantity_unit: 'tablets',
                refills_allowed: 4,
                stop_date: null,
            },
            {
                date_prescribed: null,
                entered_by: 'Caremark',
                instructions: null,
                medication_entry_id: 'H8070276',
                medication_name: 'losartan 100 mg-hydrochlorothiazide 12.5 mg tablet',
                medication_type: 'ACTIVE',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: 20,
                quantity_unit: 'tablets',
                refills_allowed: null,
                stop_date: null,
            },
            {
                date_prescribed: null,
                entered_by: 'Mai Le Somphet MD',
                instructions: ' 2 puff(s)s by inhalation route.',
                medication_entry_id: 'H4344386',
                medication_name: 'ProAir HFA 90 mcg/actuation aerosol inhaler',
                medication_type: 'ACTIVE',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: null,
                quantity_unit: null,
                refills_allowed: null,
                stop_date: null,
            },
            {
                date_prescribed: null,
                entered_by: 'Caremark',
                instructions: null,
                medication_entry_id: 'H8070282',
                medication_name: 'Trulicity 0.75 mg/0.5 mL subcutaneous pen injector',
                medication_type: 'HISTORICAL',
                pharmacy_facility: null,
                pharmacy_name: null,
                pharmacy_ncpdp_id: null,
                quantity: null,
                quantity_unit: null,
                refills_allowed: null,
                stop_date: null,
            },
        ] as readonly Medication[],
    },
};

export { MEDICATIONS_SUCCESS_RESPONSE };
