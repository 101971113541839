import type { FC } from 'react';
import { useCallback } from 'react';

import * as Styled from './styles';
import vmdLogo from 'assets/images/logo-village-medical.svg';
import { Icon } from 'components/icon';
import { Countly } from 'modules/countly';
import { sendToNative } from 'modules/ipc';
import type { LetterDetails } from 'types/letter-details';
import { formatDate } from 'utils';
import { replaceImgs, prepareFileName } from 'utils/letter';

interface Props {
    readonly letter: LetterDetails;
}

const LettersEntry: FC<Props> = ({ letter }) => {
    const { paperform_name, letter_id, created_date, html, letter_text } = letter;

    const getFileName = useCallback((fileName: string) => `${prepareFileName(fileName)}.pdf`, []);
    const letterHtml = html ?? letter_text ?? '';

    const goToDocumentDetail = useCallback(() => {
        const parsedHtml = replaceImgs(letterHtml, vmdLogo);
        Countly.addEvent({ key: 'viewIndividualLetter' });
        sendToNative('viewDocumentHTMLAsPDF', 'health-records', {
            html: parsedHtml,
            id: letter_id,
            name: getFileName(paperform_name),
            type: 'letter',
        });
    }, [getFileName, letterHtml, letter_id, paperform_name]);

    return (
        <Styled.ListItem data-testid="letter-entry-item" icon={<Icon name="file" size={1.5} />} onClick={goToDocumentDetail}>
            <Styled.LetterContainer key={letter_id}>
                <Styled.LetterName>{paperform_name}</Styled.LetterName>
                {created_date ? <Styled.LetterDate>{formatDate(created_date, 'fullDate')}</Styled.LetterDate> : null}
            </Styled.LetterContainer>
        </Styled.ListItem>
    );
};

export { LettersEntry };
