import type { ComponentProps, FC, PropsWithChildren } from 'react';

export const combineComponents = (...components: readonly FC<PropsWithChildren>[]): FC<PropsWithChildren> =>
    components.reduce(
        (AccumulatedComponents, CurrentComponent) =>
            ({ children }: ComponentProps<FC<PropsWithChildren>>): JSX.Element =>
                (
                    <AccumulatedComponents>
                        <CurrentComponent>{children}</CurrentComponent>
                    </AccumulatedComponents>
                )
    );
