import type { LoadScriptProps } from '@react-google-maps/api';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useState, type FC, useEffect, useCallback } from 'react';

import { appConfig } from 'modules/config';
import { Countly } from 'modules/countly';

interface Props {
    readonly placeName?: string;
    readonly locationAddress: string;
    readonly phoneNumber?: string;
}
const containerStyle = {
    height: '100%',
    width: '100%',
};

const center = {
    lat: 40.6,
    lng: -99.24,
} as unknown as google.maps.LatLng;

const defaultMapOptions = {
    fullscreenControl: false,
};

const googleMapsLibraries: LoadScriptProps['libraries'] = ['places'];

const GoogleMapsPreview: FC<Props> = ({ placeName, locationAddress, phoneNumber }) => {
    const [coords, setCoords] = useState(center);
    const [loaded, setLoaded] = useState(false);
    const [map, setMap] = useState<google.maps.Map | null>(null);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: appConfig.googleApiKey,
        id: 'google-map-script',
        libraries: googleMapsLibraries,
    });

    const onMapLoad = useCallback((googleMap: google.maps.Map): void => {
        setMap(googleMap);
    }, []);

    useEffect(() => {
        if (!map) return;

        const service = new google.maps.places.PlacesService(map);

        const findPlaceFromFullQuery = (fallbackFindMethod: () => void): void => {
            if (!placeName) {
                fallbackFindMethod();
                return;
            }

            const request = {
                fields: ['name', 'geometry'],
                query: `${placeName}, ${locationAddress}`,
            };

            service.findPlaceFromQuery(request, (results, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && results && results.length > 0) {
                    const placeResult: google.maps.places.PlaceResult = results[0];
                    const placeLocation = placeResult?.geometry?.location;
                    if (placeLocation) {
                        setCoords(placeLocation);
                        setLoaded(true);
                    }
                } else {
                    fallbackFindMethod();
                }
            });
        };

        const findPlaceFromPhoneNumber = (fallbackFindMethod: () => void): void => {
            if (!phoneNumber) {
                fallbackFindMethod();
                return;
            }

            const findPlaceFromPhoneNumberRequest = {
                fields: ['name', 'geometry'],
                phoneNumber,
            };

            service.findPlaceFromPhoneNumber(findPlaceFromPhoneNumberRequest, (results, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && results && results.length > 0) {
                    const placeResult: google.maps.places.PlaceResult = results[0];
                    const placeLocation = placeResult?.geometry?.location;
                    if (placeLocation) {
                        setCoords(placeLocation);
                        setLoaded(true);
                    } else {
                        fallbackFindMethod();
                    }
                }
            });
        };

        const findPlaceFromAddress = (fallbackFindMethod: () => void): void => {
            const request = {
                fields: ['name', 'geometry'],
                query: locationAddress,
            };

            service.findPlaceFromQuery(request, (results, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && results && results.length > 0) {
                    const placeResult: google.maps.places.PlaceResult = results[0];
                    const placeLocation = placeResult?.geometry?.location;
                    if (placeLocation) {
                        setCoords(placeLocation);
                        setLoaded(true);
                    }
                } else {
                    fallbackFindMethod();
                }
            });
        };

        const handlePlaceNotFound = (): void => {
            // If no results were found we add the Countly event
            Countly.addEvent({
                key: 'googleMapsFailedLocation',
                segmentation: { pharmacyAddress: locationAddress },
            });
        };

        findPlaceFromFullQuery(() => findPlaceFromPhoneNumber(() => findPlaceFromAddress(handlePlaceNotFound)));
    }, [placeName, locationAddress, phoneNumber, map]);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    return isLoaded ? (
        <span data-testid="google-maps">
            <GoogleMap
                center={coords}
                clickableIcons={false}
                mapContainerStyle={containerStyle}
                onLoad={onMapLoad}
                onUnmount={onUnmount}
                options={defaultMapOptions}
                zoom={loaded ? 15 : 1}
            >
                {loaded ? <MarkerF position={coords} /> : null}
            </GoogleMap>
        </span>
    ) : null;
};

export { GoogleMapsPreview };
