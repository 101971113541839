import { memo } from 'react';
import type { FC } from 'react';

import * as Styled from './styles';

interface Props {
    readonly message: string;
}

const Banner: FC<Props> = memo(({ message }) => (
    <div data-testid="match-strategy-banner">
        <Styled.BaseBanner>{message}</Styled.BaseBanner>
    </div>
));

export { Banner };
