import { memo } from 'react';
import type { FC } from 'react';

import { CareSummaryContent } from './care-summary-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <CareSummaryContent />,
};

const CareSummaryPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Care Summary" />);

export { CareSummaryPage };
