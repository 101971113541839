import { rem } from 'polished';
import styled from 'styled-components';

const Footer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    border-top: 1px solid ${({ theme }) => theme.color.platinum};
    padding: ${rem(16)};
`;

const InnerContainer = styled.div`
    overflow: auto;
    flex: 1;
`;

export { Footer, InnerContainer };
