import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

import { Icon } from 'components/icon';

const Header = styled.div`
    padding: 1rem;
    font-size: ${rem(22)};
    line-height: 1;
    font-weight: 600;
    color: ${({ theme }) => theme.color.androidGrey};
    background-color: ${({ theme }) => theme.vui.colors.background.blue};
    min-height: ${rem(52)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${rem(16)};
`;

const MainContainer = styled.div`
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const ChildrenContainer = styled.div`
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
`;

const SendIcon = styled(Icon)`
    color: ${({ theme }) => theme.color.androidBlue};
`;

const HeaderText = styled.div`
    color: ${({ theme }) => theme.color.brightGray};
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'segoe ui', 'helvetica neue', helvetica, Cantarell,
        Ubuntu, roboto, noto, arial, sans-serif;
`;

const IconBox = styled.div`
    min-width: 1.5rem;
`;

const TextButton = styled(Text).attrs(() => ({ type: 'h4' }))`
    color: ${({ theme }) => theme.color.androidBlue};
`;

export { MainContainer, Header, ChildrenContainer, SendIcon, HeaderText, IconBox, TextButton };
