import * as Sentry from '@sentry/react';
import { format, isToday, isYesterday, parse } from 'date-fns';
import { utcToZonedTime, format as formatTz } from 'date-fns-tz';

import type { Appointment } from 'types';

const dateFormats = {
    dateWithTimezone: 'EEE, MMM d, h:mm aa',
    dbDate: 'yyyy-MM-dd',
    fullDate: 'MMM d, yyyy',
    shortDate: 'MMM d',
    shortDateWithTime: 'MMM d, h:mm aa',
    shortDayWithFullDate: 'EEEE MMM d, yyyy',
    shortMonthDateWithTimezone: 'EEE, MMM d, h:mm aa zzz',
};

type DateFormatName = keyof typeof dateFormats;

const formatDate = (date: Date | number | string, formatName: DateFormatName): string =>
    format(new Date(date), dateFormats[formatName]);

const getDateTag = (timestamp: number, dateFormat: DateFormatName): string => {
    const dateObjectMessage = new Date(timestamp * 1000);
    if (isToday(dateObjectMessage)) return 'Today';
    if (isYesterday(dateObjectMessage)) return 'Yesterday';
    return `${formatDate(dateObjectMessage, dateFormat)}`;
};

/**
 * Formats a Date object according to the custom `dateFormats` object
 * @param {string} date - Expected as: `YYYY-MM-DDTHH:MM:SS` example: `2021-07-16T13:00:00`
 * @param {string} formatName - Defined in custom `dateFormats` object
 * @param {string} timeZone - IANA formatted timezone name, e.g. `America/New_York`
 * @returns {string}
 */
const formatDateWithTimezone = (date: Date, formatName: DateFormatName, timeZone?: string): string | undefined => {
    if (date && formatName && timeZone) {
        try {
            const zonedDateTime = utcToZonedTime(date, timeZone);
            return formatTz(zonedDateTime, dateFormats[formatName], { timeZone });
        } catch (error: unknown) {
            Sentry.captureException(error);
        }
    }

    return undefined;
};

const getDateWithoutTimezone = (isoDate: string): Date => {
    const year = Number(isoDate.slice(0, 4));
    const month = Number(isoDate.slice(5, 7)) - 1;
    const day = Number(isoDate.slice(8, 10));

    return new Date(year, month, day);
};

const getDateUTC = (date: Appointment['utcstarttime']): Date =>
    new Date(
        Date.UTC(
            Number(date.slice(0, 4)), // Year
            Number(date.slice(5, 7)) - 1, // Month (months are parsed as 0-11)
            Number(date.slice(8, 10)), // Day
            Number(date.slice(11, 13)), // Hours
            Number(date.slice(14, 16)) // Minutes
        )
    );

const parseDate = (date: string, formatStyle: string): Date => parse(date, formatStyle, new Date());

export { formatDateWithTimezone, formatDate, getDateTag, parseDate, getDateUTC, getDateWithoutTimezone };
