import type { FC } from 'react';
import { memo } from 'react';

import { ComposeMessage } from './compose-message-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <ComposeMessage />,
};

const ComposeMessagePage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Compose message" />);

export { ComposeMessagePage };
