import { memo } from 'react';
import type { FC } from 'react';

import { VaccineDetailsContent } from './vaccine-details-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <VaccineDetailsContent />,
};

const VaccineDetailsPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Vaccine Details" />);

export { VaccineDetailsPage };
