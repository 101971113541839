import type { FC } from 'react';
import { memo } from 'react';

import { TestResultPreview } from './test-result-preview';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <TestResultPreview />,
};

const TestResultPreviewPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Select test result" />);

export { TestResultPreviewPage };
