import { Datadog } from '@village/tools';
import type { UserData } from 'amazon-cognito-identity-js';

import { getUserDetails } from './cognito';
import { appConfig } from './config';

const { initLogs } = Datadog.setupDatadogLogs();

const { datadogRum, initRum } = Datadog.setupDatadogRum();

const initDatadog = (): void => {
    if (appConfig.datadog.logsClientToken) {
        initLogs({
            clientToken: appConfig.datadog.logsClientToken ?? '',
            env: appConfig.environment,
            service: 'health-records-web',
            version: appConfig.version,
        });
    }

    if (appConfig.datadog.rumApplicationId && appConfig.datadog.rumClientToken) {
        initRum({
            applicationId: appConfig.datadog.rumApplicationId ?? '',
            clientToken: appConfig.datadog.rumClientToken ?? '',
            env: appConfig.environment,
            service: 'patx:health-records:rum',
            version: appConfig.version,
        });
    }
};

const setDatadogUserDetails = (userData: UserData): void => {
    const { firstName, lastName, email, id } = getUserDetails(userData);

    datadogRum.setUser({
        email,
        id,
        name: firstName && lastName ? `${firstName} ${lastName}` : undefined,
    });
};

export { initDatadog, setDatadogUserDetails };
