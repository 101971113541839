import { fillInImagingResult } from 'data/hooks/helper-functions';
import type { ImagingResultDetails, TestResultDetails } from 'types';

const IMAGING_RESULT_SUCCESS_RESPONSE: ImagingResultDetails = {
    actionnote: 'Patient reviewed message on patient portal.',
    alarmdays: null,
    assignedto: null,
    createddate: '2010-07-27',
    createddatetime: '2010-07-27T13:36:45-04:00',
    createduser: 'dfenick',
    departmentid: 1,
    description: 'imaging/diagnostic result',
    documentdata: null,
    documentsource: 'UPLOAD',
    documenttypeid: null,
    entitytype: null,
    externalaccessionid: null,
    facilityid: null,
    imagingresultid: '9065',
    internalaccessionid: null,
    internalnote: null,
    interpretation: null,
    interpretationtemplate: null,
    isconfidential: null,
    lastmodifieddate: '2013-07-26',
    lastmodifieddatetime: '2013-07-26T05:41:02-04:00',
    lastmodifieduser: 'ATHENA',
    observationdate: null,
    observationdatetime: null,
    ordertype: null,
    originaldocument: {
        contenttype: 'image/bmp',
        href: 'https://api.preview.platform.athenahealth.com/v1/19591/patients/4/documents/imagingresult/9065/originaldocument',
    },
    pages: [
        {
            contenttype: 'image/bmp',
            href: 'https://api.preview.platform.athenahealth.com/v1/19591/patients/4/documents/imagingresult/9065/pages/1',
            pageid: '1',
            pageordering: '1',
        },
    ],
    patientnote: 'Deirdre, you can see the break in the enclosed image.',
    portalpublisheddatetime: '2010-07-27T13:38:30-04:00',
    priority: '2',
    provider: {
        department_ids: undefined,
        displayname: 'Adejumo, Lara MD',
        entitytype: 'Person',
        firstname: 'Lara',
        image: undefined,
        lastname: 'Adejumo',
        npi: null,
        provider_image_url: null,
        providerid: 85,
        providertype: 'MD',
        providertypeid: '1',
        recentencountersdate: undefined,
        specialty: 'Internal Medicine',
    },
    providerid: null,
    providerusername: 'ahoward1',
    receivedfacilityordercode: null,
    resultstatus: null,
    status: 'CLOSED',
    test_result_name: 'Broken femur',
    tiedtoprocedureorsurgery: null,
    tietoorderid: null,
};

const IMAGING_RESULT_IN_REVIEW_SUCCESS_RESPONSE: ImagingResultDetails = {
    actionnote: 'Patient reviewed message on patient portal.',
    alarmdays: null,
    assignedto: null,
    createddate: '2010-07-27',
    createddatetime: '2010-07-27T13:36:45-04:00',
    createduser: 'dfenick',
    departmentid: 1,
    description: 'imaging/diagnostic result',
    documentdata: null,
    documentsource: 'UPLOAD',
    documenttypeid: null,
    entitytype: null,
    externalaccessionid: null,
    facilityid: null,
    imagingresultid: '9065',
    internalaccessionid: null,
    internalnote: null,
    interpretation: null,
    interpretationtemplate: null,
    isconfidential: null,
    lastmodifieddate: '2013-07-26',
    lastmodifieddatetime: '2013-07-26T05:41:02-04:00',
    lastmodifieduser: 'ATHENA',
    observationdate: null,
    observationdatetime: null,
    ordertype: null,
    originaldocument: {
        contenttype: 'image/bmp',
        href: 'https://api.preview.platform.athenahealth.com/v1/19591/patients/4/documents/imagingresult/9065/originaldocument',
    },
    pages: [
        {
            contenttype: 'image/bmp',
            href: 'https://api.preview.platform.athenahealth.com/v1/19591/patients/4/documents/imagingresult/9065/pages/1',
            pageid: '1',
            pageordering: '1',
        },
    ],
    patientnote: 'Deirdre, you can see the break in the enclosed image.',
    portalpublisheddatetime: '2010-07-27T13:38:30-04:00',
    priority: '2',
    provider: {
        department_ids: undefined,
        displayname: 'Adejumo, Lara MD',
        entitytype: 'Person',
        firstname: 'Lara',
        image: undefined,
        lastname: 'Adejumo',
        npi: null,
        provider_image_url: null,
        providerid: 85,
        providertype: 'MD',
        providertypeid: '1',
        recentencountersdate: undefined,
        specialty: 'Internal Medicine',
    },
    providerid: null,
    providerusername: 'ahoward1',
    receivedfacilityordercode: null,
    resultstatus: null,
    status: 'REVIEW',
    test_result_name: 'Broken femur',
    tiedtoprocedureorsurgery: null,
    tietoorderid: null,
};

const IMAGING_RESULT_SUCCESS_RESPONSE_FILLED: TestResultDetails = fillInImagingResult(IMAGING_RESULT_SUCCESS_RESPONSE);

export { IMAGING_RESULT_SUCCESS_RESPONSE, IMAGING_RESULT_IN_REVIEW_SUCCESS_RESPONSE, IMAGING_RESULT_SUCCESS_RESPONSE_FILLED };
