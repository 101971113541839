import { memo } from 'react';
import type { FC } from 'react';

import { LoginContent } from './login-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <LoginContent />,
};

const LoginPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Login" />);

export { LoginPage };
