import styled from 'styled-components';

const BaseBanner = styled.div`
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 3px;
    text-align: center;

    background-color: ${({ theme }) => theme.color.lightBlue};
    color: ${({ theme }) => theme.color.midnightBlue};
    border: 2px solid ${({ theme }) => theme.color.midnightBlue};
`;

export { BaseBanner };
