import { useFeatureFlags } from 'hooks';
import type { MessageSource, TestResult, TestResultFeatureFlag, TestResultType } from 'types';

const featureFlagToSourceMap: Record<TestResultFeatureFlag, TestResultType> = {
    imagingResultsSendMessage: 'imagingresult',
    labsSendMessage: 'labresult',
};

/**
 * Maps feature flags to messaging sources
 * @param {TestResultFeatureFlag[]} featureFlags - array of feature flags
 * @return - An array of messaging sources acompanying the given feature flags, for each feature flag that was true
 */
const useSourceByFeatureFlag = (
    featureFlags: readonly TestResultFeatureFlag[],
    includeSources?: readonly MessageSource[] // adds the given sources to the returned source array
): readonly MessageSource[] => {
    const { hasFeature } = useFeatureFlags();

    const enabledMessageSources = featureFlags.filter((featureFlag) => hasFeature(featureFlag));
    const messageSources = enabledMessageSources.map((featureFlag) => featureFlagToSourceMap[featureFlag]);
    const withIncludedSources = includeSources ? [...messageSources, ...includeSources] : messageSources;

    return withIncludedSources;
};

const useTestResultsByFeatureFlag = (
    featureFlags: readonly TestResultFeatureFlag[],
    includeSources?: readonly TestResultType[]
): readonly TestResultType[] => useSourceByFeatureFlag(featureFlags, includeSources) as readonly TestResultType[];

const getTestResultType = (testResult: TestResult): TestResultType | undefined => {
    if ('lab_result_id' in testResult) return 'labresult';
    if ('imagingresultid' in testResult) return 'imagingresult';
    return undefined;
};

export { useSourceByFeatureFlag, useTestResultsByFeatureFlag, getTestResultType };
