import type { ImagingResultDetails, LabResultDetails, TestResultDetails, TestResultType } from 'types';

const fillInImagingResult = (imagingResult: ImagingResultDetails): TestResultDetails => ({
    description: imagingResult.description,
    documentURL: `/individual-test-result/${'imagingresult' as TestResultType}/${imagingResult.imagingresultid}/documents`,
    enableMessagingFeatureFlag: 'imagingResultsSendMessage',
    hasDocument: imagingResult.pages.length > 0,
    iconName: 'radiology',
    imagingResult,
    isRead: imagingResult.status === 'CLOSED',
    lastmodifieddatetime: imagingResult.lastmodifieddatetime,
    messageSubject: `${imagingResult?.test_result_name ?? ''} ${imagingResult?.imagingresultid ?? ''}`,
    messageTypeKey: 'PATIENTCASE_IMAGING_RESULTS',
    navigateToCountlyKey: 'viewImagingResultPage',
    note: imagingResult.patientnote,
    provider: imagingResult.provider,
    providerid: imagingResult.providerid,
    testResultId: imagingResult.imagingresultid,
    testResultName: imagingResult.test_result_name,
    type: 'imagingresult',
});

const fillInLabResult = (labResult: LabResultDetails): TestResultDetails => ({
    description: labResult.lab_test_name ?? '',
    documentURL: `/individual-test-result/${'labresult' as TestResultType}/${labResult.lab_result_id}/documents`,
    enableMessagingFeatureFlag: 'labsSendMessage',
    hasDocument: labResult.document_pages?.length > 0,
    iconName: 'lab',
    isRead: labResult.status === 'CLOSED',
    labResult,
    lastmodifieddatetime: labResult.lab_result_datetime,
    messageSubject: `${labResult?.lab_test_name ?? ''} ${labResult?.lab_result_id ?? ''}`,
    messageTypeKey: 'PATIENTCASE_LABS',
    navigateToCountlyKey: 'viewIndividualLab',
    note: labResult.lab_result_note ?? undefined,
    provider: labResult?.provider,
    providerid: labResult?.provider_id,
    sendMessageCountlyKey: 'sendMessageFromLabResult',
    testResultId: labResult.lab_result_id,
    testResultName: labResult.lab_test_name,
    type: 'labresult',
});

export { fillInImagingResult, fillInLabResult };
