import { Button } from '@village/ui';
import { Fragment } from 'react';
import type { FC } from 'react';

import * as Styled from './styles';
import { Icon } from '../icon';

const resetIcon = <Icon name="replay" size={1} />;

interface Props {
    readonly error: Error;
    readonly resetErrorBoundary: () => void;
}

const Fallback: FC<Props> = ({ error, resetErrorBoundary }) => {
    const errorMessage = error.message.toLowerCase();
    return (
        <Styled.ErrorBoundaryContainer>
            <Styled.ErrorBoundaryWrapper>
                {/* Using 'network error' because status code is unavailable, excluding axios 'network errors' */}
                {errorMessage.includes('network error') && !errorMessage.includes('axios') ? (
                    <Fragment>
                        <Icon name="no-internet" size={4} />
                        <Styled.ErrorMessage>
                            <span>Oops!</span>
                            <span>No network connection found</span>
                        </Styled.ErrorMessage>
                        <Styled.Hint>Check your connection and try again</Styled.Hint>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Icon name="band-aid" size={4} />
                        <Styled.ErrorMessage>
                            <span>Oops!</span>
                            <span>Something went wrong</span>
                        </Styled.ErrorMessage>
                        <Styled.Hint>We’re working on fixing the issue. If the issue persists, please call us.</Styled.Hint>
                    </Fragment>
                )}
                <Button fullWidth={true} onClick={resetErrorBoundary} startIcon={resetIcon}>
                    Retry
                </Button>
            </Styled.ErrorBoundaryWrapper>
        </Styled.ErrorBoundaryContainer>
    );
};

export { Fallback };
