import { useEffect } from 'react';
import type { FC } from 'react';

import { VaccineEntry } from './components/vaccine-entry';
import { VaccineHistoryLoading } from './components/vaccine-history-loading';
import * as Styled from './styles';
import { ChatWithUs } from 'components/chat-with-us';
import { NoData } from 'components/no-data';
import { useVaccineHistory } from 'data/hooks/use-vaccine-history';
import { useNativeControls } from 'hooks/use-native-controls';
import { Countly } from 'modules/countly';
import { Page } from 'pages/page-layouts';

const VaccineResultsContent: FC = () => {
    const { data: vaccineHistoryData, isLoading, isFetched } = useVaccineHistory();

    useNativeControls({
        ipcEvents: ['showBackButton'],
        source: 'health-records',
        title: 'Immunizations',
    });

    useEffect(() => {
        Countly.addEvent({ key: 'viewImmunizations' });
    }, []);

    if (isLoading) {
        return <VaccineHistoryLoading />;
    }

    if (isFetched && !vaccineHistoryData?.results.length) {
        return <NoData iconName="medications" title="No Vaccine History" />;
    }
    return (
        <Page.Padding $hasBlockPadding={false}>
            <Styled.ChatWithUsWrapper>
                <ChatWithUs />
            </Styled.ChatWithUsWrapper>
            <Page.FullBleed>
                {vaccineHistoryData?.results.map((vaccine) => (
                    <VaccineEntry key={vaccine.vaccineid} vaccine={vaccine} />
                ))}
            </Page.FullBleed>
        </Page.Padding>
    );
};

export { VaccineResultsContent };
