import { memo } from 'react';
import type { FC } from 'react';

import { TestDocumentsContent } from './test-documents-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <TestDocumentsContent />,
};

const TestResultDocumentsPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Test results documents" />);

export { TestResultDocumentsPage };
