import type { Appointment } from 'types';

const appointments: readonly Appointment[] = [
    {
        appointmentid: 1,
        appointmentstatus: 'f',
        date: '01/29/2020',
        departmentid: 58,
        duration: 30,
        providerid: 1,
        providerimageurl: 'https://cdn2.hubspot.net/hubfs/6509886/K%20Lara%20Adejumo.jpg',
        providername: 'Adejumo Lara',
        providerspecialty: 'Family Medicine',
        starttime: '13:00',
        utcstarttime: '2030-01-29T13:00:00',
    },
    {
        appointmentid: 2,
        appointmentstatus: 'f',
        date: '01/11/2020',
        departmentid: 59,
        duration: 30,
        providerid: 1,
        providerimageurl: 'https://cdn2.hubspot.net/hubfs/6509886/Dr.%20Pedroza%20094FF.jpg',
        providername: 'Chris Pedroza',
        providerspecialty: 'Family Medicine',
        starttime: '14:00',
        utcstarttime: '2020-01-11T14:00:00',
    },
];

const UPCOMING_APPOINTMENTS_RESPONSE = {
    d: {
        __metadata: {
            identity_match_strategy: 'EmpiStrategy',
        },
        results: appointments,
    },
};

export { UPCOMING_APPOINTMENTS_RESPONSE };
