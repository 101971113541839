import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
    padding: ${rem(16)};
    color: ${({ theme }) => theme.vui.colors.text.navyDefault};
`;

const SidePadding = styled.div`
    padding: 0 ${rem(16)};
`;

const SectionTitle = styled(Text).attrs(() => ({ type: 'h4' }))`
    margin: ${rem(32)} 0 ${rem(16)};
    font-weight: 600;
`;

const Grid = styled.div`
    display: grid;
    gap: ${rem(16)};
`;

export { Container, SidePadding, SectionTitle, Grid };
