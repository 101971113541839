import { useUrlState } from '@village/tools';
import { List } from '@village/ui';
import { Fragment, useEffect } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Styled from './styles';
import { TestResultsLoading } from './test-results-loading';
import { Banner } from 'components/banner';
import { ChatWithUs } from 'components/chat-with-us';
import { Icon } from 'components/icon';
import { NoData } from 'components/no-data';
import { useTestResults } from 'data/hooks/use-test-results';
import { useFeatureFlags } from 'hooks';
import { useNativeControls } from 'hooks/use-native-controls';
import { appConfig } from 'modules/config';
import { Countly } from 'modules/countly';
import { formatDate } from 'utils';

const TestResultsContent: FC = () => {
    const { hasFeature } = useFeatureFlags();
    const navigate = useNavigate();
    const [origin] = useUrlState('origin');
    const ignoreCache = origin === 'notification';
    const { testResults, isFetching, metadata } = useTestResults(['imagingresult', 'labresult'], ignoreCache);

    const matchStrategy = metadata !== undefined ? metadata.identity_match_strategy : '';
    const bannerMessage = `Athena Patient Matched Using: ${matchStrategy}`;

    useEffect(() => {
        if (!isFetching) {
            Countly.addEvent({
                key: 'viewTestResults',
                segmentation: { noData: testResults.length === 0 },
            });
        }
    }, [isFetching, testResults.length]);

    useNativeControls({
        ipcEvents: ['showBackButton'],
        source: 'health-records',
        title: 'Test Results',
    });

    const goToLabResults = (labResultId: number): void => {
        navigate(`/individual-test-result/labresult/${labResultId}`);
    };

    const goToImagingResult = (imagingResultId: number): void => {
        navigate(`/individual-test-result/imagingresult/${imagingResultId}`);
    };

    if (isFetching) {
        return <TestResultsLoading />;
    }

    if (testResults.length === 0) {
        return (
            <Fragment>
                {appConfig.showBanner ? <Banner message={bannerMessage} /> : null}
                <NoData iconName="lab-result" title="No Test Results" />
            </Fragment>
        );
    }

    return (
        <Fragment>
            {appConfig.showBanner ? <Banner message={bannerMessage} /> : null}
            {hasFeature('chatLabs') && (
                <Styled.ChatContainer>
                    <ChatWithUs />
                </Styled.ChatContainer>
            )}
            <List data-testid="test-results-list">
                {testResults.map((result) =>
                    'lab_result_id' in result ? (
                        <Styled.ListItem
                            key={result.lab_result_id}
                            data-testid="lab-result"
                            icon={<Icon name="lab" size={1.5} />}
                            onClick={() => goToLabResults(result.lab_result_id)}
                        >
                            <Styled.TestInfo>
                                <Styled.TestName>{result.lab_test_name}</Styled.TestName>
                                <Styled.TestDate>
                                    {result.lab_result_datetime ? formatDate(result.lab_result_datetime, 'fullDate') : null}
                                </Styled.TestDate>
                            </Styled.TestInfo>
                        </Styled.ListItem>
                    ) : (
                        <Styled.ListItem
                            key={result.imagingresultid}
                            data-testid="imaging-result"
                            icon={<Icon name="radiology" size={1.5} />}
                            onClick={() => goToImagingResult(result.imagingresultid)}
                        >
                            <Styled.TestInfo>
                                <Styled.TestName>{result.test_result_name}</Styled.TestName>
                                <Styled.TestDate>
                                    {result.lastmodifieddatetime ? formatDate(result.lastmodifieddatetime, 'fullDate') : null}
                                </Styled.TestDate>
                            </Styled.TestInfo>
                        </Styled.ListItem>
                    )
                )}
            </List>
        </Fragment>
    );
};

export { TestResultsContent };
