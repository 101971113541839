import { useToggle } from '@village/tools';
import { useEffect } from 'react';
import type { FC } from 'react';

import * as Styled from './styles';
import { Countly } from 'modules/countly';

interface Props {
    readonly analyteId: number;
    readonly analyteName: string;
    readonly content: string;
}

const CONTENT_CHAR_LIMIT = 140;

const LabAnalyteNote: FC<Props> = ({ analyteId, analyteName, content }) => {
    const { value: showAll, toggle } = useToggle(false);

    useEffect(() => {
        if (showAll) {
            Countly.addEvent({
                key: 'viewMoreAnalyteDetail',
                segmentation: { analyteId, analyteName },
            });
        }
    }, [showAll, analyteId, analyteName]);

    const trimmedContent = content.slice(0, CONTENT_CHAR_LIMIT);

    return (
        <Styled.AnalyteNoteContainer>
            <Styled.AnalyteNote>{showAll ? content : trimmedContent}</Styled.AnalyteNote>
            {content.length > CONTENT_CHAR_LIMIT ? (
                <Styled.ToggleContainer onClick={toggle}>
                    <Styled.ToggleText>View {showAll ? 'Less' : 'More'}</Styled.ToggleText>
                    <Styled.ChevronDown name={showAll ? 'chevron-up' : 'chevron-down'} size={1} />
                </Styled.ToggleContainer>
            ) : null}
        </Styled.AnalyteNoteContainer>
    );
};

export { LabAnalyteNote };
