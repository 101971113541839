import { TextareaAutosize } from '@material-ui/core';
import { rem } from 'polished';
import styled from 'styled-components';

import { Icon } from 'components/icon';

interface WarningProps {
    readonly type?: string;
}

interface TitleProps {
    readonly type?: string;
}

const PreferencesTitle = styled.div<TitleProps>`
    display: block;
    font-size: ${rem(16)};
    font-weight: 600;
    padding: 0 ${({ type }) => (type === 'message' ? rem(16) : 0)};
    color: ${({ theme }) => theme.color.brightGray};
`;

const PreferencesText = styled.p`
    font-size: ${rem(16)};
    color: ${({ theme }) => theme.vui.colors.neutral.gray80};
`;

const Warning = styled.div<WarningProps>`
    display: flex;
    align-items: center;
    width: 100%;
    padding: ${rem(9)} ${rem(15)};
    color: ${({ theme }) => theme.color.brightGray};
    background-color: ${({ theme, type }) => {
        if (type === 'warning') {
            return theme.color.catskillWhite;
        }
        if (type === 'error') {
            return theme.color.lightRed;
        }
        return theme.color.lightOrange;
    }};
    font-size: ${rem(14)};
    font-weight: 400;
    line-height: ${rem(17)};
`;

const ErrorIcon = styled(Icon)`
    height: ${rem(14.88)};
    width: ${rem(17)};
    margin-right: ${rem(8)};
    color: ${({ theme }) => theme.color.errorRed};
`;

const WarningIcon = styled(Icon)`
    height: ${rem(14.88)};
    width: ${rem(17)};
    margin-right: ${rem(8)};
    color: ${({ theme }) => theme.color.trout};
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${rem(16)};
    flex: 1;
    overflow: auto;
`;

const MessageInput = styled(TextareaAutosize)`
    width: 100%;
    border: none;
    color: ${({ theme }) => theme.color.brightGray};
    resize: none;
    &:focus {
        outline: none;
    }
    ::placeholder {
        color: ${({ theme }) => theme.color.shuttleGray};
    }
`;

const MessageWhenPreferences = styled.div`
    margin-bottom: 2rem;
`;

export {
    Warning,
    ErrorIcon,
    WarningIcon,
    PreferencesTitle,
    PreferencesText,
    MessageContainer,
    MessageInput,
    MessageWhenPreferences,
};
