import { Skeleton } from '@material-ui/lab';
import { ListItem as VuiListItem, Text } from '@village/ui';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Container = styled.div<{ readonly $noAppointments: boolean }>`
    display: grid;
    gap: ${rem(16)};
    margin-bottom: ${rem(40)};
    ${({ $noAppointments }) =>
        $noAppointments &&
        css`
            padding-bottom: ${rem(40)};
            border-bottom: 1px solid ${({ theme }) => theme.color.athensGray};
        `}
    ${({ $noAppointments }) =>
        !$noAppointments &&
        css`
            margin-bottom: ${rem(16)};
        `}
`;

const Paragraph = styled(Text).attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.color.brightGray};
`;

const DateTimeWrapper = styled(Text).attrs(() => ({ type: 'sub1' }))`
    color: ${({ theme }) => theme.vui.colors.neutral.gray80};
    margin-bottom: ${rem(4)};
`;

const ProviderInfoText = styled(Text).attrs(() => ({ type: 'body2' }))`
    width: 100%;
    color: ${({ theme }) => theme.vui.colors.neutral.gray60};
`;

const LoadingItem = styled.div`
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.vui.colors.general.white};
    padding: ${rem(24)} 0 ${rem(16)};
`;

const TextSkeleton = styled.div`
    margin-left: ${rem(16)};
    width: 85%;
`;

const SkeletonProviderPhoto = styled(Skeleton)`
    min-width: ${rem(56)};
`;

const ListItem = styled(VuiListItem)`
    padding: ${rem(16)} 0;
`;

export { Container, Paragraph, DateTimeWrapper, ProviderInfoText, LoadingItem, TextSkeleton, SkeletonProviderPhoto, ListItem };
