import { memo } from 'react';
import type { FC } from 'react';

import { UnverifiedUserContent } from './unverified-user-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <UnverifiedUserContent />,
};

const UnverifiedUserPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Unverified" />);

export { UnverifiedUserPage };
