import styled from 'styled-components';

const RootContainer = styled.section`
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    word-break: break-all;
`;

const PageContainer = styled.section`
    height: 50%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    word-break: break-all;
`;

const DebugContainer = styled.section`
    height: 50%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    word-break: break-all;
    border-top: 2px solid red;
    overflow: auto;
`;

const EventContainer = styled.section`
    word-break: break-all;
    border-bottom: 1px solid grey;
    display: flex;
    align-items: center;
`;

const MinimalPadding = styled.div`
    padding: 0.4rem;
    flex: 1;
    white-space: nowrap;
`;

export { RootContainer, PageContainer, DebugContainer, EventContainer, MinimalPadding };
