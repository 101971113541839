import styled from 'styled-components';

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const SpinnerContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export { Container, SpinnerContainer };
