import styled from 'styled-components';

const Container = styled.section`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const ContentContainer = styled.section`
    overflow: hidden;
    height: 100%;
    flex: 1;
`;

export { Container, ContentContainer };
