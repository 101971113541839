import Radio from '@material-ui/core/Radio';
import { ListItem } from '@village/ui';
import { useCallback, useEffect, memo } from 'react';

import * as Styled from './styles';
import { useFontSize } from 'hooks';
import type { Medication } from 'types';

interface Props {
    readonly medication: Medication;
    readonly selectedMedicationId?: string;
    readonly handleSelectMedication: (selectedMedication: Medication) => void;
    readonly measureListItemHeight: () => void;
}

const MedicationListItem: React.FC<Props> = memo(
    ({ medication, selectedMedicationId, handleSelectMedication, measureListItemHeight }) => {
        const { medication_name, quantity, quantity_unit, medication_entry_id } = medication;

        const selectMedication = useCallback((): void => {
            handleSelectMedication(medication);
        }, [handleSelectMedication, medication]);
        const fontSize = useFontSize();

        useEffect(() => {
            measureListItemHeight();
        }, [measureListItemHeight, medication, fontSize]);

        return (
            <ListItem hideChevron={true} onClick={selectMedication}>
                <Styled.MedicationInfo>
                    <Styled.MedicationName>{medication_name}</Styled.MedicationName>
                    {quantity ? (
                        <Styled.MedicationQuantity>
                            Quantity: {quantity} {quantity_unit}
                        </Styled.MedicationQuantity>
                    ) : null}
                </Styled.MedicationInfo>
                <Radio checked={medication_entry_id === selectedMedicationId} color="default" />
            </ListItem>
        );
    }
);

export { MedicationListItem };
