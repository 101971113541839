import { ListItem } from '@village/ui';
import { useCallback } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Styled from './styles';
import type { TestResultType } from 'types';
import type { LabResult } from 'types/lab';
import { formatDate } from 'utils/date';

interface Props {
    readonly lab: LabResult;
}

const LabEntry: FC<Props> = ({ lab }) => {
    const navigate = useNavigate();
    const { lab_result_datetime, lab_test_name, lab_result_id } = lab;

    const goToLabResults = useCallback(() => {
        navigate(`/individual-test-result/${'labresult' as TestResultType}/${lab_result_id}`);
    }, [navigate, lab_result_id]);

    const labResultDateFormatted = lab_result_datetime ? formatDate(lab_result_datetime, 'fullDate') : null;

    return (
        <ListItem onClick={goToLabResults}>
            <Styled.LabInfo>
                <Styled.LabName>{lab_test_name}</Styled.LabName>
                <Styled.LabDate>{labResultDateFormatted}</Styled.LabDate>
            </Styled.LabInfo>
        </ListItem>
    );
};

export { LabEntry };
