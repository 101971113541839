import { Chip } from '@material-ui/core';
import { SquareListIcon, CircleInfoOutlinedIcon, BuildingIcon, MedicationsIcon } from '@village/icons';
import { Button as VuiButton, Text as VuiText } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const Container = styled.section`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: ${rem(16)};
    padding-top: ${rem(8)};
`;

const ChatContainer = styled.div`
    margin-bottom: ${rem(16)};
`;

const DetailsContainer = styled.div`
    display: flex;
    overflow: auto;
    flex-direction: column;
    flex: 1;
`;

const LoaderContainer = styled.section`
    padding: 1rem;
`;

const Button = styled(VuiButton)`
    align-self: flex-end;
`;

const MedicationName = styled(VuiText).attrs(() => ({ type: 'h3' }))`
    padding: 1rem 0;
`;

const DirectionsText = styled(VuiText).attrs(() => ({ type: 'sub1' }))`
    color: ${({ theme }) => theme.vui.colors.text.primary};
    padding-top: 0.5rem;
    padding-bottom: 1rem;
`;

const Text = styled(VuiText).attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayMuted};
    flex: 1;
`;

const BoldText = styled(VuiText).attrs(() => ({ type: 'sub1' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayMuted};
    padding-bottom: 1rem;
`;

const FlexRow = styled.div`
    display: flex;
    padding: ${rem(8)} 0;
`;

const FlexColumn = styled.div<{ readonly bottomPadding?: boolean }>`
    display: flex;
    flex-direction: column;
    margin-left: ${rem(12)};
    border-bottom: 1px solid ${({ theme }) => theme.vui.colors.border.default.default};
    width: 100%;
    padding-bottom: 1rem;
    padding-bottom: ${({ bottomPadding = true }) => (bottomPadding ? '1rem' : '0rem')};
`;

const FlexLastColumn = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: ${rem(12)};
    width: 100%;
    padding-bottom: 1rem;
`;

const ListIcon = styled(SquareListIcon)`
    color: ${({ theme }) => theme.vui.colors.icon.navyMuted};
`;

const CircleInfoIcon = styled(CircleInfoOutlinedIcon)`
    color: ${({ theme }) => theme.vui.colors.icon.navyMuted};
`;

const Building = styled(BuildingIcon)`
    color: ${({ theme }) => theme.vui.colors.icon.navyMuted};
`;

const Medications = styled(MedicationsIcon)`
    color: ${({ theme }) => theme.vui.colors.icon.navyMuted};
`;

const ButtonContainer = styled.div`
    margin-top: 1rem;
`;

const ChevronContanier = styled.div`
    padding-top: 1rem;
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
`;

const InactiveChip = styled(Chip)`
    color: ${({ theme }) => theme.vui.colors.text.error};
    background-color: ${({ theme }) => theme.color.lightRed};
    font-size: ${rem(12)};
    height: ${rem(22)};
    line-height: 100%;
`;

const ChipContainer = styled.div`
    padding-bottom: 0.5rem;
`;

export {
    Container,
    ChatContainer,
    LoaderContainer,
    DetailsContainer,
    Button,
    Text,
    BoldText,
    FlexRow,
    FlexColumn,
    ListIcon,
    CircleInfoIcon,
    Building,
    Medications,
    FlexLastColumn,
    MedicationName,
    ButtonContainer,
    DirectionsText,
    ChevronContanier,
    InactiveChip,
    ChipContainer,
};
