import type { FC, PropsWithChildren } from 'react';

interface Props {
    readonly activeTab: number;
    readonly index: number;
}

const TabPanel: FC<PropsWithChildren<Props>> = (props) => {
    const { children, activeTab, index, ...other } = props;

    return (
        <div
            aria-labelledby={`scrollable-auto-tab-${index}`}
            hidden={activeTab !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            role="tabpanel"
            {...other}
        >
            {activeTab === index && children}
        </div>
    );
};

interface a11Props {
    readonly 'aria-controls': string;
    readonly id: string;
}
const a11yProps = (index: number): a11Props => ({
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
    id: `scrollable-auto-tab-${index}`,
});

export { TabPanel, a11yProps };
