import { useInfiniteQuery } from 'react-query';
import type { QueryFunctionContext, UseInfiniteQueryResult } from 'react-query';

import { healthRecordsApi } from '../health-records-api-config';
import type { ApiResponse } from 'types/api';
import type { LetterDetails } from 'types/letter-details';

const fetchLetters = async ({
    pageParam,
}: QueryFunctionContext<readonly unknown[], string>): Promise<ApiResponse<LetterDetails>> => {
    if (pageParam) {
        const response = await healthRecordsApi.get<ApiResponse<LetterDetails>>(pageParam);
        return response.data;
    }
    const path = `/letters/details?limit=12`;
    const response = await healthRecordsApi.get<ApiResponse<LetterDetails>>(path);
    return response.data;
};

const useLetters = (enabled = true): UseInfiniteQueryResult<ApiResponse<LetterDetails>> =>
    useInfiniteQuery(['letters'], fetchLetters, {
        cacheTime: 0,
        enabled,
        getNextPageParam: (lastPage) => lastPage.__paging?.next ?? false,
    });
export { useLetters };
