import { createContext } from 'react';

import type { NavHeaderState } from '.';

export interface NavHeaderContextType {
    readonly navigateBack: () => void;
    readonly navHeaderState: NavHeaderState;
    readonly resetHistoryState: () => void;
    readonly setNavHeaderState: React.Dispatch<React.SetStateAction<NavHeaderState>>;
    readonly showingHeaderNav: boolean;
    readonly setShowingHeaderNav: React.Dispatch<boolean>;
    readonly navigateToStartOfFlow: () => void;
}

const NavHeaderContext = createContext<NavHeaderContextType | undefined>(undefined);

export { NavHeaderContext };
