import type { FC, PropsWithChildren } from 'react';
import { useState, useCallback, useMemo } from 'react';

import type { MessagingContextType } from './messaging-context';
import { MessagingContext } from './messaging-context';
import type { MessagingState } from 'types';

const defaultState = {
    messageSubject: '',
    messageText: '',
};
interface Props {
    readonly initialMessagingState?: MessagingState;
}

const MessagingProvider: FC<PropsWithChildren<Props>> = ({ children, initialMessagingState }) => {
    const [messaging, setMessaging] = useState(initialMessagingState ?? defaultState);

    const resetMessagingState = useCallback(() => {
        setMessaging(defaultState);
    }, []);

    const setMessagingFields: MessagingContextType['setMessagingFields'] = useCallback((partialMessaging) => {
        setMessaging((state) => ({ ...state, ...partialMessaging }));
    }, []);

    const messagingContextValue = useMemo(
        () => ({
            messagingState: messaging,
            resetMessagingState,
            setMessagingFields,
        }),
        [messaging, resetMessagingState, setMessagingFields]
    );

    return <MessagingContext.Provider value={messagingContextValue}>{children}</MessagingContext.Provider>;
};

export { MessagingProvider };
