import { useInfiniteQuery } from 'react-query';
import type { UseInfiniteQueryResult } from 'react-query';

import { messagingApi } from '../messaging-api-config';
import { useFeatureFlags } from 'hooks';
import type { ApiResponse, Folder, MessageSource, MessageThread } from 'types';

interface useMessageParams {
    // readonly source?: readonly MessageSource[];
    readonly folder?: Folder;
    readonly limit?: number;
    readonly offset?: number;
}

// TODO: Error handling if the lastPage.__paging.next url is invalid or server connection fails
const useMessage = (
    params: useMessageParams,
    sources: readonly MessageSource[],
    ignoreCache?: boolean
): UseInfiniteQueryResult<ApiResponse<MessageThread>> => {
    const { hasFeature } = useFeatureFlags();
    const onlyReadyDocuments = hasFeature('messaging.allTestResults');
    const customHeaders = {};
    // eslint-disable-next-line functional/immutable-data
    if (ignoreCache) customHeaders['Cache-Control'] = 'no-cache';

    return useInfiniteQuery(
        ['message', ...Object.values(params ?? {})],
        async ({ pageParam }) => {
            if (pageParam) {
                const response = await messagingApi.get<ApiResponse<MessageThread>>(pageParam as string);
                return response.data;
            }
            const source = sources.map((value, index) => (index === 0 ? value : `&source=${value}`)).join('');
            const sourceParameter = source !== '' ? `&source=${source}` : '';
            const response = await messagingApi.get<ApiResponse<MessageThread>>(
                `/message/?only_ready_documents=${onlyReadyDocuments.toString()}${sourceParameter}`,
                { headers: customHeaders, params }
            );
            return response.data;
        },
        {
            cacheTime: 0,
            getNextPageParam: (lastPage) => lastPage.__paging?.next ?? false,
        }
    );
};
export type { useMessageParams };
export { useMessage };
