import { useCallback } from 'react';

import { useNativeIpc } from './use-native-ipc';
import { useDeletePreferredPharmacy, useGetPreferredPharmacy, useSetPreferredPharmacy } from 'data/hooks/use-preferred-pharmacy';
import { Countly } from 'modules/countly';
import { sendToNative } from 'modules/ipc';
import type { DrawerIPCBody, MessageBase } from 'modules/ipc/types';

const useSetPreferredPharmacyIPCEvent = (enabled = true): void => {
    const { data: preferredPharmacy } = useGetPreferredPharmacy(enabled);
    const { mutateAsync: deletePharmacy } = useDeletePreferredPharmacy();
    const { mutateAsync: setPharmacy } = useSetPreferredPharmacy();

    const changePreferredPharmacy = useCallback(
        async (event: CustomEvent<MessageBase<{ readonly ncpdpid: number }>>) => {
            if (!enabled) return;
            if (event.detail.body) {
                const { ncpdpid } = event.detail.body;
                Countly.addEvent({
                    key: 'preferredPharmacyChanged',
                });
                if (preferredPharmacy?.clinicalproviderid) await deletePharmacy(preferredPharmacy?.clinicalproviderid);
                await setPharmacy(ncpdpid);
                sendToNative('openNativeDrawer', 'health-records', {
                    bodyText:
                        'Your preferred pharmacy has been set. When you are prescribed a new medication, it will be sent to this pharmacy by default.',
                    buttonText: 'Okay',
                    icon: 'checkmark',
                    titleText: "You've updated your pharmacy",
                } as DrawerIPCBody);
            }
        },
        [deletePharmacy, enabled, preferredPharmacy?.clinicalproviderid, setPharmacy]
    );

    useNativeIpc('setPreferredPharmacy', changePreferredPharmacy as unknown as EventListener);
};

export { useSetPreferredPharmacyIPCEvent };
