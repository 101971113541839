import type { FeatureFlag, MessageType } from 'types';

export const messageTypeButtonsGenerator: (hasFeature: (featureKey: FeatureFlag) => boolean) => readonly MessageType[] = (
    hasFeature
) => [
    {
        iconName: 'medical-question',
        key: 'PATIENTCASE_CLINICALQUESTION',
        order: 1,
        redirectsTo: `/select-recipient?messageType=PATIENTCASE_CLINICALQUESTION`,
        requiresProviders: true,
        text: 'I have a medical question',
    },
    {
        iconName: hasFeature('imagingResultsSendMessage') ? 'test-results' : 'labs',
        key: 'PATIENTCASE_LABS',
        order: 2,
        redirectsTo: '/select-lab',
        requiresProviders: false,
        text: `I have a question about ${hasFeature('imagingResultsSendMessage') ? 'tests' : 'labs'}`,
    },
    {
        iconName: 'scheduling',
        key: 'PATIENTCASE_APPOINTMENT',
        order: 3,
        redirectsTo: '/scheduling-question-about',
        requiresProviders: false,
        text: 'I have a scheduling question',
    },
    {
        iconName: 'prescriptions',
        key: 'PATIENTCASE_REFILL',
        order: 4,
        redirectsTo: '/select-prescription?redirect-to=/messaging',
        requiresProviders: false,
        text: 'I want to request a refill',
    },
    {
        iconName: 'other',
        key: 'PATIENTCASE_OTHER',
        order: 5,
        redirectsTo: '/select-recipient?redirect-to=/messaging&messageType=PATIENTCASE_OTHER',
        requiresProviders: true,
        text: 'I have another question',
    },
];
