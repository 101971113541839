import { isFuture } from 'date-fns';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { schedulingApi } from '../scheduling-api-config';
import type { ApiResponse, Appointment } from 'types';

const useUpcomingAppointments = (
    options?: Omit<
        UseQueryOptions<readonly Appointment[], unknown, readonly Appointment[], 'upcoming-visits'>,
        'queryFn' | 'queryKey'
    >
): UseQueryResult<readonly Appointment[]> =>
    useQuery(
        'upcoming-visits',
        async () => {
            const response = await schedulingApi.get<ApiResponse<Appointment>>(`/appointments/`, {
                params: {
                    show_cancelled: false,
                    show_past: false,
                },
            });
            const upcomingAppointments = response.data?.results.filter(({ appointmentstatus, utcstarttime, date }) => {
                // if date is not returned, assuming the date is in the future
                // Similar to backend logic.
                const isTodayOrLater = utcstarttime || date ? isFuture(new Date(utcstarttime || date)) : true;
                return ['f', '2'].includes(appointmentstatus) && isTodayOrLater;
            });

            return upcomingAppointments ?? [];
        },
        options
    );

export { useUpcomingAppointments };
