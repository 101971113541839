import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';

import { schedulingApi } from 'data/scheduling-api-config';
import type { ApiResponse, Department } from 'types';

const useDepartments = (): UseQueryResult<ApiResponse<Department>> =>
    useQuery('departments', async () => {
        const response = await schedulingApi.get<ApiResponse<Department>>('/departments/');
        return response.data;
    });

export { useDepartments };
