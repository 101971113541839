import { Chip } from '@village/ui';
import styled from 'styled-components';

interface MessageProps {
    readonly unread?: boolean;
}

const truncate = `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Container = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 0;
    cursor: pointer;
`;

const FromField = styled.div`
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin-bottom: 0.25rem;
`;

const FromName = styled.div<MessageProps>`
    color: ${({ theme }) => theme.color.shark};
    font-weight: ${(props) => (props.unread ? `bold` : `normal`)};
    ${truncate}
`;

const SubjectField = styled.div<MessageProps>`
    color: ${({ theme }) => theme.color.brightGray};
    font-weight: ${(props) => (props.unread ? `bold` : `normal`)};
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
    ${truncate}
`;

const MessagePreview = styled.div`
    color: ${({ theme }) => theme.color.shuttleGray};
    font-size: 0.875rem;
    ${truncate}
`;

const MDPhotoContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 1rem;
`;

const MessagePreviewContainer = styled.div`
    flex: 1;
    margin-right: 1.5rem;
    min-width: 3rem;
`;

const MessageAddInfoContainer = styled.div`
    min-width: 2.5rem;
    text-align: right;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const MDImage = styled.img`
    border-radius: 50%;
    width: 2rem;
`;

const NewChip = styled(Chip)`
    background-color: ${({ theme }) => theme.color.hotPink};
    color: ${({ theme }) => theme.color.white};
    font-size: 0.6875rem;
    padding: 0;
    font-weight: 400;
    height: 17px;
    margin: 0.375rem 0 0 auto;
    pointer-events: none;
`;

const TagContainer = styled.div`
    color: ${({ theme }) => theme.color.shark};
`;

const Underline = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    padding: 1rem 1rem 1rem 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.athensGray};
    ${truncate}
`;

export {
    Container,
    FromField,
    FromName,
    MDPhotoContainer,
    MessagePreview,
    MessagePreviewContainer,
    MessageAddInfoContainer,
    MDImage,
    NewChip,
    SubjectField,
    TagContainer,
    Underline,
};
