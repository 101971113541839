import { Spinner } from '@village/ui';
import { useEffect } from 'react';

import * as Styled from './styles';

interface Props {
    readonly measureListItemHeight: () => void;
}

const MessageLoader: React.FC<Props> = ({ measureListItemHeight }) => {
    useEffect(() => {
        measureListItemHeight();
    }, [measureListItemHeight]);

    return (
        <Styled.AdditionalSpinnerContainer>
            <Spinner />
        </Styled.AdditionalSpinnerContainer>
    );
};

export { MessageLoader };
