/* eslint-disable @typescript-eslint/no-unsafe-argument */
import type { CognitoRegions } from 'types';
import type { AppConfig } from 'types/config';

const asString = (value: string | undefined): string => (typeof value !== 'undefined' ? `${value}`.trim() : '');
const asBoolean = (value: string | undefined): boolean => asString(value) === 'true';
const ios = /(iphone|ipod|ipad).*applewebkit(?!.*safari)/i.test(navigator.userAgent);
const android = navigator.userAgent.includes('wv');

const getConfig = (): AppConfig => {
    const config: AppConfig = {
        basename: asString(import.meta.env.VITE_BASENAME) || '/',
        cognito: {
            aws_cognito_identity_pool_id: asString(import.meta.env.VITE_COGNITO_POOL_ID),
            aws_cognito_region: asString(import.meta.env.VITE_COGNITO_REGION) as CognitoRegions,
            aws_project_region: asString(import.meta.env.VITE_COGNITO_PROJECT_REGION) as CognitoRegions,
            aws_user_pools_id: asString(import.meta.env.VITE_COGNITO_USER_POOLS_ID),
            aws_user_pools_web_client_id: asString(import.meta.env.VITE_COGNITO_POOLS_CLIENT_ID),
        },
        countlyAppKey: asString(import.meta.env.VITE_COUNTLY_APP_KEY),
        countlyUrl: asString(import.meta.env.VITE_COUNTLY_URL),
        datadog: {
            logsClientToken: import.meta.env.VITE_DD_LOGS_CLIENT_TOKEN,
            rumApplicationId: import.meta.env.VITE_DD_RUM_APP_ID,
            rumClientToken: import.meta.env.VITE_DD_RUM_CLIENT_TOKEN,
        },
        environment: (asString(import.meta.env.VITE_ENV) || 'test') as AppConfig['environment'],
        googleApiKey: asString(import.meta.env.VITE_GOOGLE_API_KEY),
        healthRecordsApiBaseUrl: asString(import.meta.env.VITE_HEALTH_RECORDS_API_BASE_URL),
        identityApiBaseUrl: asString(import.meta.env.VITE_IDENTITY_API),
        messagingApiBaseUrl: asString(import.meta.env.VITE_MESSAGING_API_BASE_URL),
        platform: android ? 'android' : ios ? 'ios' : 'web',
        schedulingApiBaseUrl: asString(import.meta.env.VITE_SCHEDULING_API_BASE_URL),
        schedulingBaseUrl: asString(import.meta.env.VITE_SCHEDULING_BASE_URL),
        sentryDsn: asString(import.meta.env.VITE_SENTRY_DSN),
        showBanner: asBoolean(import.meta.env.VITE_SHOW_BANNER) || false,
        supportEmail: asString(import.meta.env.VITE_SUPPORT_EMAIL),
        useMocks: asBoolean(import.meta.env.VITE_USE_MOCKS) || false,
        version: asString(import.meta.env.VITE_VERSION),
    };

    if (
        !config.healthRecordsApiBaseUrl ||
        !config.cognito.aws_cognito_identity_pool_id ||
        !config.cognito.aws_cognito_region ||
        !config.cognito.aws_project_region ||
        !config.cognito.aws_user_pools_id ||
        !config.cognito.aws_user_pools_web_client_id ||
        !config.messagingApiBaseUrl ||
        !config.schedulingApiBaseUrl ||
        !config.schedulingBaseUrl
    ) {
        throw new Error('Not all required envs are set!');
    }

    return config;
};

const appConfig = getConfig();

export { appConfig };
