import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import type { FC } from 'react';

import * as Styled from './styles';

const AppointmentsLoading: FC = () => (
    <Box display="flex" flexDirection="column" height="100%">
        {Array.from(Array.from({ length: 2 })).map((_value, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Styled.LoadingItem key={index}>
                <Styled.SkeletonProviderPhoto animation="wave" height="3.5rem" variant="circle" width="3.5rem" />
                <Styled.TextSkeleton>
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="wave" variant="text" width="80%" />
                </Styled.TextSkeleton>
            </Styled.LoadingItem>
        ))}
    </Box>
);

export { AppointmentsLoading };
