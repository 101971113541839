const PHARMACIES_SUCCESS_RESPONSE = {
    d: {
        __metadata: {
            identity_match_strategy: 'EmpiStrategy',
        },
        results: [
            {
                acceptfax: true,
                address1: '1915 Maple Ave S.W.',
                city: 'Rome',
                clinicalproviderid: 10813802,
                clinicalprovidername: 'CVS/Pharmacy #4502',
                faxnumber: '7062347449',
                pharmacytype: 'RETAIL',
                phonenumber: '7062348096',
                state: 'GA',
                zip: '30161',
            },
            {
                acceptfax: true,
                address1: '4600 N. Hanley Road',
                city: 'St Louis',
                clinicalproviderid: 12292362,
                clinicalprovidername: 'Essds Pharmacy',
                faxnumber: '8664701744',
                pharmacytype: 'MAILORDER',
                phonenumber: '8669973688',
                state: 'MO',
                zip: '63134',
            },
        ],
    },
};

const PHARMACIES_SUCCESS_RESPONSE_NO_ADDRESS = {
    d: {
        __metadata: {
            identity_match_strategy: 'EmpiStrategy',
        },
        results: [
            {
                acceptfax: true,
                address1: null,
                city: null,
                clinicalproviderid: 12292362,
                clinicalprovidername: 'Essds Pharmacy',
                faxnumber: '8664701744',
                pharmacytype: 'MAILORDER',
                phonenumber: '8669973688',
                state: 'MO',
                zip: '63134',
            },
        ],
    },
};

export { PHARMACIES_SUCCESS_RESPONSE, PHARMACIES_SUCCESS_RESPONSE_NO_ADDRESS };
