import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';

import { fillInImagingResult } from './helper-functions';
import { healthRecordsApi } from '../health-records-api-config';
import type { ImagingResultDetails, TestResultDetails } from 'types';

interface ImagingResultParameters {
    readonly enabled?: boolean;
    readonly imagingResultId: number;
}

const useImagingResult = (params: ImagingResultParameters, ignoreCache?: boolean): UseQueryResult<TestResultDetails> => {
    const { imagingResultId, enabled = true } = params;
    const path = `/imaging_results/${imagingResultId}`;
    const customHeaders = {};
    // eslint-disable-next-line functional/immutable-data
    if (ignoreCache) customHeaders['Cache-Control'] = 'no-cache';

    return useQuery(
        ['imaging_result', imagingResultId],
        async (): Promise<TestResultDetails> => {
            const response = await healthRecordsApi.get<ImagingResultDetails>(path, { headers: customHeaders });
            const imagingResult = response.data;
            return imagingResult ? fillInImagingResult(imagingResult) : imagingResult;
        },
        {
            enabled,
        }
    );
};

export { useImagingResult };
