import { Button, Spinner } from '@village/ui';
import type { FC } from 'react';
import { useCallback, useMemo, useEffect, Fragment, useRef, useState } from 'react';
import type { QueryObserverResult, RefetchOptions } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { MessageVirtualizedList } from './components/message-virtualized-list';
import * as Styled from './styles';
import { NoData } from 'components/no-data';
import { PulldownReload } from 'components/pulldown-reload';
import { useMessage } from 'data/hooks/use-message-query';
import type { UnreadMessageCount } from 'data/hooks/use-message-unread-count';
import { useProviders } from 'data/hooks/use-providers';
import { Countly } from 'modules/countly';
import { sendToNative } from 'modules/ipc';
import { numberOfMessagesPerFetch, loadAdditionalMessagesAt } from 'pages/messaging/constants';
import type { ApiResponse, Folder, MessageThread } from 'types';
import { useSourceByFeatureFlag } from 'utils/test-results';

interface Props {
    readonly folder: Folder;
    readonly markUnread?: boolean;
    readonly ignoreCache?: boolean;
    readonly refetchUnreadCount?: (options?: RefetchOptions) => Promise<QueryObserverResult<UnreadMessageCount>>;
}

const MessageList: FC<Props> = ({ folder, markUnread, ignoreCache, refetchUnreadCount }) => {
    const navigate = useNavigate();
    const [scrollPosition, setScrollPosition] = useState(0);

    const rootRef = useRef<HTMLDivElement | null>(null);
    const nativeToolbarVisible = useRef<boolean>(true);

    const {
        data: messageThreadsData,
        isLoading,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch: refetchMessages,
    } = useMessage(
        {
            folder,
            limit: numberOfMessagesPerFetch,
            offset: 0,
        },
        useSourceByFeatureFlag(['imagingResultsSendMessage', 'labsSendMessage'], ['provider']),
        ignoreCache
    );

    const refetchFunction = useCallback(() => {
        Countly.addEvent({
            key: 'pulldownRefresh',
            segmentation: {
                messagingVersion: 2,
                source: 'messaging',
            },
        });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        refetchMessages();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        if (refetchUnreadCount !== undefined) refetchUnreadCount();
    }, [refetchMessages, refetchUnreadCount]);

    useEffect(() => {
        if (scrollPosition > 0 && nativeToolbarVisible.current) {
            sendToNative('hideNativeToolbar', 'messaging');
            // eslint-disable-next-line functional/immutable-data
            nativeToolbarVisible.current = false;
        } else if (scrollPosition === 0 && !nativeToolbarVisible.current) {
            sendToNative('showNativeToolbar', 'messaging');
            // eslint-disable-next-line functional/immutable-data
            nativeToolbarVisible.current = true;
        }
    }, [nativeToolbarVisible, scrollPosition]);

    const onScroll = useCallback(
        ({ scrollTop }) => {
            setScrollPosition(scrollTop as number);
        },
        [setScrollPosition]
    );

    const handleVisibilityChange = useCallback(() => {
        if (!document.hidden) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            refetchMessages();
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            if (refetchUnreadCount !== undefined) refetchUnreadCount();
        }
    }, [refetchMessages, refetchUnreadCount]);

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange, false);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [handleVisibilityChange]);

    const onRowsRendered = useCallback(
        ({ overscanStopIndex, stopIndex }) => {
            if (
                hasNextPage &&
                !isLoading &&
                !isFetching &&
                !isFetchingNextPage &&
                overscanStopIndex - stopIndex < loadAdditionalMessagesAt
            ) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                fetchNextPage();
            }
        },
        [hasNextPage, isLoading, isFetching, isFetchingNextPage, fetchNextPage]
    );

    const { data: providersDataResponse } = useProviders();

    const messageThreadPages = messageThreadsData?.pages;
    const providersData = providersDataResponse?.results;

    const flattenedMessageThreads = useMemo(
        () =>
            messageThreadPages?.reduce(
                (accumulator: readonly MessageThread[], value: ApiResponse<MessageThread>) => accumulator.concat(value.results),
                []
            ),
        [messageThreadPages]
    );

    useEffect(() => {
        Countly.addEvent({
            key: folder === 'inbox' ? 'tapInboxTab' : 'tapSentTab',
            segmentation: {
                messagingVersion: 2,
                source: 'messaging',
            },
        });
    }, [folder]);

    const composeInitiateCountlyEvent = useCallback((messagingVersion: number) => {
        Countly.addEvent({
            key: 'composeInitiate',
            segmentation: {
                initiatedSource: 'inbox',
                messagingVersion,
                source: 'messaging',
            },
        });
    }, []);

    const handleComposeNewMessage = useCallback(() => {
        const messagingVersion = 2;
        composeInitiateCountlyEvent(messagingVersion);
        navigate(`/select-message-type`);
    }, [navigate, composeInitiateCountlyEvent]);

    if (isLoading && !isFetchingNextPage) {
        return (
            <Styled.Container data-testid="messaging-loading">
                <Styled.SpinnerContainer>
                    <Spinner />
                </Styled.SpinnerContainer>
                <Styled.FooterContainer>
                    <Button fullWidth={true} onClick={handleComposeNewMessage}>
                        <Styled.PencilIcon name="pencil" size={1.25} />
                        Compose new message
                    </Button>
                </Styled.FooterContainer>
            </Styled.Container>
        );
    }

    if (flattenedMessageThreads === undefined || messageThreadPages === undefined || messageThreadPages[0].__count === 0) {
        return (
            <Fragment>
                <NoData
                    description="Messages from your provider, or office staff, will appear here."
                    iconName="message"
                    title="No messages"
                />
                <Styled.ButtonContainer>
                    <Button onClick={handleComposeNewMessage}>
                        <Styled.PencilIcon name="pencil" size={1.25} />
                        Compose new message
                    </Button>
                </Styled.ButtonContainer>
            </Fragment>
        );
    }

    return (
        <Styled.Container ref={rootRef}>
            <Styled.MessagesContainer>
                <PulldownReload isFetching={isFetching} reloadFunction={refetchFunction} scrollPosition={scrollPosition}>
                    <MessageVirtualizedList
                        folder={folder}
                        isFetchingNextPage={isFetchingNextPage}
                        markUnread={markUnread}
                        messageThreads={flattenedMessageThreads}
                        onRowsRendered={onRowsRendered}
                        onScroll={onScroll}
                        providersData={providersData}
                    />
                </PulldownReload>
            </Styled.MessagesContainer>
            <Styled.FooterContainer>
                <Button fullWidth={true} onClick={handleComposeNewMessage}>
                    <Styled.PencilIcon name="pencil" size={1.25} />
                    Compose new message
                </Button>
            </Styled.FooterContainer>
        </Styled.Container>
    );
};

export { MessageList };
