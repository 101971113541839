import type { FC } from 'react';
import { memo } from 'react';

import { MessageReplyContent } from './message-reply-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <MessageReplyContent />,
};

const MessageReplyPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Message Reply" />);

export { MessageReplyPage };
