import { PinFilledIcon, PhoneOutlinedIcon, CircleInfoOutlinedIcon } from '@village/icons';
import { Text as VUIText } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

import { Icon } from 'components/icon';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    gap: 1rem;
`;

const Text = styled(VUIText).attrs(() => ({ type: 'body2' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayMuted};
`;

const BoldText = styled(VUIText).attrs(() => ({ type: 'sub1' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayMuted};
    padding-top: 2px;
`;

const FlexRow = styled.div`
    display: flex;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: ${rem(6)};
    gap: ${rem(8)};
`;

const PinIcon = styled(PinFilledIcon)`
    color: ${({ theme }) => theme.vui.colors.icon.navyMuted};
`;

const PhoneIcon = styled(PhoneOutlinedIcon)`
    color: ${({ theme }) => theme.vui.colors.icon.navyMuted};
`;

const InfoIcon = styled(CircleInfoOutlinedIcon)`
    color: ${({ theme }) => theme.vui.colors.icon.navyMuted};
`;

const HouseArrowIcon = styled(Icon)`
    color: ${({ theme }) => theme.vui.colors.icon.navyMuted};
    flex-shrink: 0;
`;

export { Container, Text, BoldText, FlexRow, FlexColumn, PinIcon, PhoneIcon, InfoIcon, HouseArrowIcon };
