import type { FC } from 'react';
import { useCallback, Fragment, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { TestResultsVirtualizedList } from './components/test-results-virtualized-list';
import { SelectTestResultLoading } from './select-test-result-loading';
import * as Styled from './styles';
import { CardButton } from 'components/card-button';
import { useMessage } from 'data/hooks/use-message-query';
import { useTestResults } from 'data/hooks/use-test-results';
import { useFeatureFlags } from 'hooks';
import { useMessaging } from 'hooks/use-messaging';
import { useNativeControls } from 'hooks/use-native-controls';
import { Countly } from 'modules/countly';
import { RoundedContainer } from 'pages/page-layouts';
import type { ApiResponse, ImagingResult, LabResult, MessageThread, TestResultWithMessageData } from 'types';
import { getTestResultType, useSourceByFeatureFlag, useTestResultsByFeatureFlag } from 'utils/test-results';

const MessagingTestResults: FC = () => {
    const navigate = useNavigate();
    const { hasFeature } = useFeatureFlags();

    const { testResults: testResultsData, isFetching: isFetchingTestResults } = useTestResults(
        useTestResultsByFeatureFlag(['imagingResultsSendMessage'], ['labresult'])
    );
    const testResultsEnabled = hasFeature('imagingResultsSendMessage');

    const { data: messageThreadsData, isLoading: isLoadingMessages } = useMessage(
        {
            folder: 'inbox',
            limit: 9999,
            offset: 0,
        },
        useSourceByFeatureFlag(['imagingResultsSendMessage'], ['labresult'])
    );

    const messageThreadPages = messageThreadsData?.pages;

    const flattenedMessageThreads = useMemo(
        () =>
            messageThreadPages
                ? messageThreadPages?.reduce(
                      (accumulator: readonly MessageThread[], value: ApiResponse<MessageThread>) =>
                          accumulator.concat(value.results),
                      []
                  )
                : [],
        [messageThreadPages]
    );

    const testResultsCompleteData = useMemo((): readonly TestResultWithMessageData[] => {
        if (!flattenedMessageThreads || !testResultsData) return [];
        return testResultsData.map((testResult) => {
            const testResultType = getTestResultType(testResult);
            const labResult = testResultType === 'labresult' ? (testResult as LabResult) : undefined;
            const imagingResult = testResultType === 'imagingresult' ? (testResult as ImagingResult) : undefined;
            const testResultId = labResult?.lab_result_id ?? imagingResult?.imagingresultid;
            const labMessageThread = flattenedMessageThreads.find(
                (messageThread) => messageThread.message_thread_id === testResultId
            );
            const lastMessageTimestamp =
                labMessageThread && labMessageThread.messages.length > 1 ? labMessageThread.messages[0].timestamp : null;

            return {
                ...testResult,
                last_message_timestamp: lastMessageTimestamp,
            };
        });
    }, [testResultsData, flattenedMessageThreads]);

    const { setMessagingFields, resetMessagingState } = useMessaging();
    const content = useMemo(() => {
        if (isFetchingTestResults || isLoadingMessages) {
            return <SelectTestResultLoading />;
        }

        if (testResultsCompleteData.length === 0) {
            return (
                <Styled.NoLabs data-testid="no-labs">
                    {testResultsEnabled ? 'Test' : 'Lab'} results will appear here.
                </Styled.NoLabs>
            );
        }

        return (
            <Styled.InnerContainer>
                <TestResultsVirtualizedList isFetchingNextPage={isFetchingTestResults} testResults={testResultsCompleteData} />
            </Styled.InnerContainer>
        );
    }, [isFetchingTestResults, isLoadingMessages, testResultsCompleteData, testResultsEnabled]);

    const selectGeneralLabsQuestion = useCallback(() => {
        resetMessagingState();
        setMessagingFields({
            messageSubject: `General ${testResultsEnabled ? 'tests' : 'labs'} question`,
            messageType: 'PATIENTCASE_GENERAL_TESTS',
            provider: undefined,
        });
        navigate('/compose-message');

        Countly.addEvent({
            key: 'clickSelectTestPage',
            segmentation: {
                action: 'general_tests_question',
            },
        });
    }, [resetMessagingState, setMessagingFields, testResultsEnabled, navigate]);

    useEffect(() => {
        Countly.addEvent({
            key: 'viewSelectLabPage',
            segmentation: {
                flow: 'labs',
                messagingVersion: 2,
                source: 'messaging',
            },
        });
    }, []);

    useNativeControls({
        ipcEvents: ['showControls'],
        source: 'messaging',
    });

    return (
        <RoundedContainer headerContent={<Fragment>Which {testResultsEnabled ? 'test' : 'lab'} result?</Fragment>}>
            <Styled.CardButtonContainer>
                <CardButton iconName="lab" onClick={selectGeneralLabsQuestion}>
                    I have a general {testResultsEnabled ? 'tests' : 'labs'} question
                </CardButton>
            </Styled.CardButtonContainer>
            <Styled.OuterContainer>
                <Styled.RecentLabs>RECENT {testResultsEnabled ? 'TESTS' : 'LABS'}</Styled.RecentLabs>
                {content}
            </Styled.OuterContainer>
        </RoundedContainer>
    );
};

export { MessagingTestResults };
