import { memo } from 'react';
import type { FC } from 'react';

import { VisitOptions } from './visit-options-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <VisitOptions />,
};

const VisitOptionsPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Visit Reason" />);

export { VisitOptionsPage };
