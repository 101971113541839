import type { CognitoIdToken, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

import { sendToNative } from 'modules/ipc';

// Declare errors to throw
const ERROR_NOT_ACTIVE_WINDOW = new Error('Window/Webview is not active');
ERROR_NOT_ACTIVE_WINDOW.name = 'NotActiveWindow'; // eslint-disable-line functional/immutable-data

const ERROR_REFRESH_TOKEN_FAILED = new Error('Token not refreshed');
ERROR_REFRESH_TOKEN_FAILED.name = 'RefreshTokenFailed'; // eslint-disable-line functional/immutable-data

// eslint-disable-next-line functional/no-let
let isRefreshingTokens = false;
const sendRefreshTokens = async (): Promise<CognitoUserSession> =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise<CognitoUserSession>(async (resolve, reject) => {
        if (document.hidden) {
            reject(ERROR_NOT_ACTIVE_WINDOW);
            return;
        }

        // eslint-disable-next-line functional/no-let
        let currentIdToken: CognitoIdToken | null;
        try {
            const session = await Auth.currentSession();
            currentIdToken = session.getIdToken();
        } catch {
            currentIdToken = null;
        }

        if (!isRefreshingTokens) {
            isRefreshingTokens = true;
            sendToNative('refreshAppToken');
        }

        // eslint-disable-next-line functional/no-let
        let attempts = 0;
        const INTERVAL_IS_TOKEN_REFRESHED = 500;
        const MAX_ATTEMPTS = 20;
        const isTokenRefreshed = async (): Promise<void> => {
            if (document.hidden) {
                reject(ERROR_NOT_ACTIVE_WINDOW);
                return;
            }

            attempts += 1;
            if (attempts > MAX_ATTEMPTS) {
                isRefreshingTokens = false;
                reject(ERROR_REFRESH_TOKEN_FAILED);
                return;
            }

            try {
                const newSession = await Auth.currentSession();
                const newIdToken = newSession.getIdToken();
                if (newIdToken && newIdToken !== currentIdToken) {
                    isRefreshingTokens = false;
                    resolve(newSession);
                } else {
                    setTimeout(isTokenRefreshed, INTERVAL_IS_TOKEN_REFRESHED);
                }
            } catch {
                setTimeout(isTokenRefreshed, INTERVAL_IS_TOKEN_REFRESHED);
            }
        };

        isTokenRefreshed(); // eslint-disable-line @typescript-eslint/no-floating-promises
    });

// eslint-disable-next-line functional/no-let
let runningRefreshTokenProcess: Promise<CognitoUserSession> | null;
const getCurrentSession = async (): Promise<CognitoUserSession> =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise<CognitoUserSession>(async (resolve, reject) => {
        try {
            const currentSession = await Auth.currentSession();
            resolve(currentSession);
        } catch {
            if (!runningRefreshTokenProcess) runningRefreshTokenProcess = sendRefreshTokens();

            runningRefreshTokenProcess
                .then((session) => resolve(session))
                .catch((error: unknown) => reject(error))
                .finally(() => {
                    runningRefreshTokenProcess = null;
                });
        }
    });

export { sendRefreshTokens, getCurrentSession };
