/* eslint-disable functional/immutable-data */
import { Spinner } from '@village/ui';
import type { PropsWithChildren, FC } from 'react';
import { useRef, useEffect, useCallback } from 'react';

import * as Styled from './styles';

interface PulldownReloadProps {
    readonly maxPullDownDistance?: number;
    readonly triggerPullDownDistance?: number;
    readonly iconSize?: number;
    readonly deadzone?: number;
    readonly reloadFunction: CallableFunction;
    readonly isFetching: boolean;
    readonly rootRefProp?: React.MutableRefObject<HTMLDivElement | null>; // pass in for regular scroll containers
    readonly scrollPosition?: number; // pass in for virtualized lists
}
interface ListenerElement {
    readonly event: keyof HTMLElementEventMap;
    readonly listener: (event: Event | MouseEvent | TouchEvent) => void;
}

const PulldownReload: FC<PropsWithChildren<PulldownReloadProps>> = ({
    children,
    maxPullDownDistance = 67,
    triggerPullDownDistance = 50,
    deadzone = 10,
    iconSize = 2,
    reloadFunction,
    isFetching,
    rootRefProp,
    scrollPosition,
}) => {
    const loaderRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const startY = useRef<number>(0);
    const currentY = useRef<number>(0);
    const isDragging = useRef<boolean>(false);
    const hasMoved = useRef<boolean>(false);
    const isReloading = useRef<boolean>(false);
    const localRootRef = useRef<HTMLDivElement>(null);
    const rootRef = rootRefProp ?? localRootRef;

    const onStart = useCallback(
        (event: Event | MouseEvent | TouchEvent): void => {
            // only trigger if the list is scrolled to the top
            if (rootRef.current?.scrollTop !== 0) return; // check for normal scrollable container
            if (scrollPosition !== undefined && scrollPosition !== 0) return; // check for virtualized lists

            if (event instanceof MouseEvent) {
                startY.current = event.pageY;
            }
            if (window.TouchEvent && event instanceof TouchEvent) {
                startY.current = event.touches[0].pageY;
            }
            currentY.current = startY.current;
            isDragging.current = true;
            hasMoved.current = false;
        },
        [rootRef, scrollPosition]
    );

    const onMove = useCallback(
        (event: MouseEvent | TouchEvent): void => {
            if (!isDragging.current) {
                return;
            }
            currentY.current =
                window.TouchEvent && event instanceof TouchEvent ? event.touches[0].pageY : (event as MouseEvent).pageY;
            if (currentY.current < startY.current) {
                return;
            }
            const offset = currentY.current - startY.current;
            if (offset > maxPullDownDistance) {
                return;
            }
            if (loaderRef.current && containerRef.current) {
                containerRef.current.style.transform = `translate(0px, ${offset}px)`;
                // the following line will make opacity 0 when fully retracted, and 1 when at triggerPullDownDistance
                const opacityValue = Math.min(
                    Math.max(1 - (triggerPullDownDistance - (offset - deadzone)) / (triggerPullDownDistance - deadzone), 0),
                    1
                ); // clamp to range [0,1]
                loaderRef.current.style.opacity = `${opacityValue}`;
                hasMoved.current = true;
                event.stopPropagation();
                event.preventDefault();
            }
        },
        [deadzone, maxPullDownDistance, triggerPullDownDistance]
    );

    const onEnd = useCallback((): void => {
        const offset = currentY.current - startY.current;
        if (offset > triggerPullDownDistance && loaderRef.current && containerRef.current) {
            isReloading.current = true;
            containerRef.current.style.transform = `translate(0px, ${maxPullDownDistance}px)`;
            reloadFunction();
        } else if (isDragging.current && loaderRef.current && containerRef.current) {
            containerRef.current.style.transform = `translate(0px, 0px)`;
        }
        isDragging.current = false;
        startY.current = 0;
        currentY.current = 0;
    }, [triggerPullDownDistance, maxPullDownDistance, reloadFunction]);

    const preventClick = useCallback((event: MouseEvent | TouchEvent): void => {
        if (hasMoved.current) {
            event.stopPropagation();
        }
    }, []);

    useEffect(() => {
        if (containerRef.current && isReloading.current && !isFetching) {
            isReloading.current = false;
            containerRef.current.style.transform = `translate(0px, 0px)`;
        }
    }, [isFetching]);

    useEffect(() => {
        if (!rootRef.current) return;
        const currentRef = rootRef.current;
        const listeners = [
            { event: 'mousedown', listener: onStart } as ListenerElement,
            { event: 'touchstart', listener: onStart } as ListenerElement,
            { event: 'mousemove', listener: onMove } as ListenerElement,
            { event: 'touchmove', listener: onMove } as ListenerElement,
            { event: 'mouseup', listener: onEnd } as ListenerElement,
            { event: 'mouseleave', listener: onEnd } as ListenerElement,
            { event: 'touchend', listener: onEnd } as ListenerElement,
            { event: 'click', listener: preventClick } as ListenerElement,
        ];

        listeners.forEach((element: ListenerElement) => {
            currentRef.addEventListener(element.event, element.listener);
        });

        // eslint-disable-next-line consistent-return
        return () => {
            if (currentRef) {
                listeners.forEach((element: ListenerElement) => {
                    currentRef.removeEventListener(element.event, element.listener);
                });
            }
        };
    }, [rootRef, onStart, onMove, onEnd, preventClick]);

    return (
        <Styled.Root ref={rootRef}>
            <Styled.DraggableContainer ref={containerRef}>
                <Styled.CenterContent ref={loaderRef} iconSize={iconSize}>
                    <Styled.TransformContainer>
                        <Spinner />
                    </Styled.TransformContainer>
                </Styled.CenterContent>
                {children}
            </Styled.DraggableContainer>
        </Styled.Root>
    );
};

export { PulldownReload };
