import { useContext } from 'react';

import type { NavHeaderContextType } from 'contexts/nav-header';
import { NavHeaderContext } from 'contexts/nav-header';

const useNavHeader = (): NavHeaderContextType => {
    const context = useContext(NavHeaderContext);

    if (context === undefined) {
        throw new Error('useNavHeader must be used within a NavHeaderProvider');
    }

    return context;
};

export { useNavHeader };
