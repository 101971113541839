import type { Dispatch } from 'react';
import { createContext } from 'react';

export interface DebuggingContextType {
    readonly state: readonly string[];
    readonly addEvent: Dispatch<string>;
    readonly debugEnabled: boolean;
}

const DebuggingContext = createContext<DebuggingContextType | undefined>(undefined);

export { DebuggingContext };
