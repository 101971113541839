import { rem } from 'polished';
import styled from 'styled-components';

import { Icon } from 'components/icon';

const MessagesContainer = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

const Messages = styled.div`
    display: flex;
    flex-direction: column;
`;

const LabButton = styled.div`
    margin: 0 0.5rem 1rem;
`;

const CollapsedCards = styled.div`
    background-color: ${({ theme }) => theme.vui.colors.secondary.whiteCatskill};
    color: ${({ theme }) => theme.vui.colors.primary.primaryBlue};
    font-weight: 600;
    font-size: ${rem(14)};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${rem(18)};
`;

const ChevronDown = styled(Icon)`
    margin-left: 1rem;
`;

const MessageSubject = styled.div`
    margin: 1.125rem 0 1rem 1rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25;
`;

const ScrollableContainer = styled.div`
    overflow: auto;
`;

export { MessagesContainer, Messages, LabButton, CollapsedCards, ChevronDown, MessageSubject, ScrollableContainer };
