import { memo } from 'react';
import type { FC } from 'react';

import { MedicationsContent } from './medications-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <MedicationsContent />,
};

const MedicationsPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Medications" />);

export { MedicationsPage };
