import type { FC } from 'react';

import * as Styled from './styles';

interface WarningProps {
    readonly messageLength: number;
    readonly messageLengthWarning: number;
    readonly messageLengthLimit: number;
}

const LengthWarning: FC<WarningProps> = ({ messageLength, messageLengthWarning, messageLengthLimit }) => {
    if (messageLength >= messageLengthWarning && messageLength <= messageLengthLimit) {
        return (
            <Styled.Warning data-testid="length-warning-remaining" type="warning">
                <Styled.WarningIcon name="warning" />
                {`${messageLengthLimit - messageLength} character(s) remaining`}
            </Styled.Warning>
        );
    }
    if (messageLength > messageLengthLimit) {
        return (
            <Styled.Warning data-testid="length-warning-over" type="error">
                <Styled.ErrorIcon name="danger" />
                {messageLength > messageLengthLimit && `${messageLength - messageLengthLimit} character(s) over`}
            </Styled.Warning>
        );
    }
    return null;
};

export { LengthWarning };
