import { createContext } from 'react';

import type { FeatureFlag } from 'types';

interface FeatureFlagsContextType {
    readonly hasFeature: (featureKey: FeatureFlag) => boolean;
}

const FeatureFlagsContext = createContext<FeatureFlagsContextType | undefined>(undefined);

export { FeatureFlagsContext };
export type { FeatureFlagsContextType };
