import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';

import { messagingApi } from '../messaging-api-config';
import { useFeatureFlags } from 'hooks';
import type { MessageSource } from 'types';

interface UnreadMessageCount {
    readonly unread_count: number;
}

const useUnreadMessageCount = (sources: readonly MessageSource[], ignoreCache?: boolean): UseQueryResult<UnreadMessageCount> => {
    const source = sources.map((value, index) => (index === 0 ? value : `&source=${value}`)).join('');
    const { hasFeature } = useFeatureFlags();
    const onlyReadyDocuments = hasFeature('messaging.allTestResults');
    const sourceParameter = source !== '' ? `&source=${source}` : '';
    const customHeaders = {};
    // eslint-disable-next-line functional/immutable-data
    if (ignoreCache) customHeaders['Cache-Control'] = 'no-cache';

    return useQuery(
        'message-unread',
        async () => {
            const response = await messagingApi.get<UnreadMessageCount>(
                `/message/unread?only_ready_documents=${onlyReadyDocuments.toString()}${sourceParameter}`,
                { headers: customHeaders }
            );
            return response.data;
        },
        {
            cacheTime: 0,
        }
    );
};

export type { UnreadMessageCount };
export { useUnreadMessageCount };
