import styled from 'styled-components';

interface ProviderImageProps {
    readonly $size?: number;
}

const ProviderImage = styled.img<ProviderImageProps>`
    width: ${({ $size }) => ($size ? `${$size}rem` : '100%')};
    height: ${({ $size }) => ($size ? `${$size}rem` : '100%')};
    object-fit: cover;
    border-radius: 50%;
`;

export { ProviderImage };
