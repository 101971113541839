import type { Provider } from 'types';

const getProviderDisplayName = ({
    entitytype,
    displayname,
    firstname,
    lastname,
    providertypeid,
    providertype,
}: Provider): string | null => {
    if (entitytype === 'Non-person entity') {
        return 'Nurse or Medical Assistant Visit';
    }

    if (displayname) {
        return displayname;
    }

    if (firstname && lastname) {
        const providerType = providertypeid ?? providertype ?? '';
        return `${firstname} ${lastname}${providerType ? ',' : ''} ${providerType}`.trim();
    }

    return null;
};

export { getProviderDisplayName };
