import type { ApiResponse, MessageThread, MockResponse } from 'types';

const MESSAGE_THREAD_SUCCESS_RESPONSE_1: MockResponse<ApiResponse<MessageThread>> = {
    d: {
        __metadata: {
            identity_match_strategy: 'CdmPersonStrategy',
        },
        results: [
            {
                associated_message_thread_id: null,
                message_thread_id: 1,
                messages: [
                    {
                        displaydate: '03/31/2021  7:56 PM',
                        from: {
                            displayname: 'Pasquale Lynch',
                            username: 'PORTAL',
                        },
                        isreply: true,
                        text: 'Most recent message',
                        timestamp: 1617231375,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                    {
                        displaydate: '03/31/2021  6:56 PM',
                        from: {
                            displayname: 'Pasquale Lynch',
                            username: 'PORTAL',
                        },
                        isreply: true,
                        text: 'Dr. Test has approved the use of this medication along with existing vitamins!',
                        timestamp: 1617231373,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                    {
                        displaydate: '03/31/2021  5:32 PM',
                        from: {
                            displayname: 'Pasquale Lynch',
                            username: 'PORTAL',
                        },
                        isreply: true,
                        text: 'Testing reply to thread 151316',
                        timestamp: 1617226361,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                    {
                        displaydate: '03/31/2021  5:18 PM',
                        from: {
                            displayname: 'Pasquale Lynch',
                            username: 'PORTAL',
                        },
                        isreply: false,
                        text: 'New medication is causing some issues. Please advice on what to do next!',
                        timestamp: 1617225527,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                ],
                source: 'provider',
                subject: 'Vitamins issue',
            },
        ],
    },
};

const MESSAGE_THREAD_SUCCESS_RESPONSE_2: MockResponse<ApiResponse<MessageThread>> = {
    d: {
        __metadata: {
            identity_match_strategy: 'CdmPersonStrategy',
        },
        results: [
            {
                associated_message_thread_id: null,
                message_thread_id: 2,
                messages: [
                    {
                        displaydate: '03/31/2021  7:56 PM',
                        from: {
                            displayname: 'Pasquale Lynch',
                            username: 'PORTAL',
                        },
                        isreply: true,
                        text: 'Most recent message',
                        timestamp: 1617231375,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                    {
                        displaydate: '03/31/2021  6:56 PM',
                        from: {
                            displayname: 'Pasquale Lynch',
                            username: 'PORTAL',
                        },
                        isreply: true,
                        text: 'Testing reply \n to thread 151316. This is a thread with some information on how to use new medications. Dr. Test has approved the use of this medication along with existing vitamins!',
                        timestamp: 1617231373,
                        to: {
                            displayname: 'angela test',
                            username: 'atest51',
                        },
                        unread: false,
                    },
                ],
                source: 'labresult',
                subject: 'New Medication causing issues!',
            },
        ],
    },
};

const MESSAGE_POST_SUCCESS_RESPONSE = {
    success: true,
};

export { MESSAGE_THREAD_SUCCESS_RESPONSE_1, MESSAGE_THREAD_SUCCESS_RESPONSE_2, MESSAGE_POST_SUCCESS_RESPONSE };
