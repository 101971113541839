import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const VaccineName = styled(Text).attrs(() => ({ type: 'h4' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
    font-weight: 600;
    margin-bottom: ${rem(16)};
    text-transform: capitalize;
`;

const List = styled.ul`
    margin: 0;
    padding: 0;
`;

export { VaccineName, List };
