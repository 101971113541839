import { Button } from '@village/ui';
import { useCallback, type FC } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Styled from './styles';
import { Icon } from 'components/icon';
import { Countly } from 'modules/countly';
import { sendToNative } from 'modules/ipc';
import type { Pharmacy } from 'types';

interface Props {
    readonly pharmacy?: Pharmacy;
}

const PharmacyBox: FC<Props> = ({ pharmacy }) => {
    const navigate = useNavigate();

    const addPharmacyAction = useCallback(() => {
        sendToNative('openPharmacyDrawer', 'messaging');
    }, []);

    const viewPharmacyAction = (): void => {
        Countly.addEvent({
            key: 'preferredPharmacy',
        });
        navigate(`/preferred-pharmacy`);
    };

    const isWalgreens = pharmacy?.clinicalprovidername.toLowerCase().includes('walgreens');

    if (!pharmacy) {
        return (
            <Styled.ContainerNoPharmacy data-testid="no-pharmacy-box">
                <Styled.BoxNoPharmacy>
                    <Styled.TitleNoPharmacy>No Preferred Pharmacy</Styled.TitleNoPharmacy>
                    <Styled.TextNoPharmacy>
                        Add your preferred pharmacy to
                        <br />
                        ensure your medications are dispensed
                    </Styled.TextNoPharmacy>
                    <Styled.ButtonContainer>
                        <Button onClick={addPharmacyAction} size="small">
                            Add Pharmacy
                        </Button>
                    </Styled.ButtonContainer>
                </Styled.BoxNoPharmacy>
            </Styled.ContainerNoPharmacy>
        );
    }

    const { clinicalprovidername, address1, city, state } = pharmacy;

    return (
        <Styled.Container data-testid="pharmacy-box" onClick={viewPharmacyAction}>
            <Styled.Box>
                {isWalgreens ? (
                    <Icon name="walgreens" size={3} />
                ) : (
                    <Styled.IconCloud>
                        <Icon name="building" size={1.5} />
                    </Styled.IconCloud>
                )}
            </Styled.Box>
            <Styled.FlexBox>
                <Styled.Title>My pharmacy</Styled.Title>
                <Styled.Text>{clinicalprovidername}</Styled.Text>
                <Styled.Text2>{`${address1}, ${city}, ${state}`}</Styled.Text2>
            </Styled.FlexBox>
            <Styled.Box>
                <Icon name="chevron-right" size={1} />
            </Styled.Box>
        </Styled.Container>
    );
};

export { PharmacyBox };
