import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
    padding-bottom: ${rem(16)};
`;

const Title = styled(Text).attrs(() => ({ type: 'h3' }))`
    color: ${({ theme }) => theme.vui.colors.neutral.gray90};
    margin-bottom: ${rem(4)};
`;

const Heading = styled.div`
    display: flex;
    justify-content: space-between;
`;

const PageType = styled.div`
    display: flex;
    align-items: center;
    column-gap: ${rem(8)};
    margin: ${rem(8)} 0;
    color: ${({ theme }) => theme.vui.colors.neutral.gray80};
`;

const SummaryInfo = styled.div`
    display: grid;
    row-gap: ${rem(4)};
    color: ${({ theme }) => theme.vui.colors.neutral.gray80};
    margin-bottom: ${rem(16)};
`;

const Date = styled(Text).attrs(() => ({ type: 'body2' }))`
    color: ${({ theme }) => theme.vui.colors.neutral.gray60};
`;

export { Container, Heading, Title, SummaryInfo, PageType, Date };
