import { Button } from '@village/ui';
import { Fragment } from 'react';
import type { FC } from 'react';

import * as Styled from './styles';
import * as RootStyled from '../../styles';
import { Icon } from 'components/icon';
import type { EncounterDetail } from 'types';

interface Props {
    readonly encounter: EncounterDetail;
    readonly composeMessage: () => void;
}

const ProviderNote: FC<Props> = ({ encounter, composeMessage }) => {
    const { provider, patientgoals } = encounter;

    return (
        <Fragment>
            <RootStyled.SectionTitle>Instructions</RootStyled.SectionTitle>
            <Styled.Container data-testid="provider-note">
                <Styled.ProviderInfo>
                    {provider.provider_image_url ? (
                        <Styled.ProviderIcon $size={2.5} src={provider.provider_image_url} />
                    ) : (
                        <Styled.DefaultProviderIcon name="default-provider" size={2.5} />
                    )}
                    <div>
                        <Styled.ProviderName>{provider.displayname}</Styled.ProviderName>
                    </div>
                </Styled.ProviderInfo>
                <Styled.Note
                    /* eslint-disable-next-line react-perf/jsx-no-new-object-as-prop */
                    dangerouslySetInnerHTML={{
                        __html:
                            patientgoals.patientinstructions ??
                            'Hello. Below is the summary of your visit today. If you have any questions, please send a message.',
                    }}
                />
                <Styled.SendMessage>
                    <Styled.HaveAQuestion>Have a question ?</Styled.HaveAQuestion>
                    <Button
                        onClick={composeMessage}
                        size="small"
                        startIcon={<Icon name="email" size={1.25} />}
                        variant="secondary"
                    >
                        Send a message
                    </Button>
                </Styled.SendMessage>
            </Styled.Container>
        </Fragment>
    );
};

export { ProviderNote };
