import type { FC, ReactNode } from 'react';

import * as Styled from './styles';
import { Icon } from '../icon';
import type { IconName } from 'components/icon';

interface Props {
    readonly iconName: IconName;
    readonly title: ReactNode;
    readonly description?: ReactNode;
}

const DEFAULT_ACTION_MESSAGE = "Reach out to a member of your care team if you're missing something that should be here.";

const NoData: FC<Props> = ({ iconName, title, description = DEFAULT_ACTION_MESSAGE }) => (
    <Styled.Container data-testid="no-data">
        <Icon name={iconName} size={4} />
        <Styled.Title>{title}</Styled.Title>
        {description ? <Styled.Description>{description ?? DEFAULT_ACTION_MESSAGE}</Styled.Description> : null}
    </Styled.Container>
);

export { NoData };
