import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';

import { healthRecordsApi } from 'data/health-records-api-config';
import type { Encounter } from 'types';
import { formatEncounterProvider } from 'utils/encounter';

const useEncounters = (): UseQueryResult<readonly Encounter[]> =>
    useQuery('encounters', async () => {
        const response = await healthRecordsApi.get<readonly Encounter[]>(`/encounters`);

        return response.data.map(({ provider, ...encounter }) => ({
            ...encounter,
            provider: formatEncounterProvider(provider),
        }));
    });

export { useEncounters };
