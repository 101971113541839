import { memo } from 'react';
import type { FC } from 'react';

import { CareSummariesContent } from './care-summary-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <CareSummariesContent />,
};

const CareSummariesPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Care Summaries" />);

export { CareSummariesPage };
