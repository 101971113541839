import { memo } from 'react';
import type { FC } from 'react';

import { QuestionAboutContent } from './question-about-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <QuestionAboutContent />,
};

const QuestionAboutPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="What is your question about" />);

export { QuestionAboutPage };
