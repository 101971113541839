import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';

import { fillInLabResult } from './helper-functions';
import { healthRecordsApi } from '../health-records-api-config';
import type { TestResultDetails } from 'types';
import type { ApiResponse } from 'types/api';
import type { LabResultDetails } from 'types/lab-result-details';

interface LabResultParameters {
    readonly enabled?: boolean;
    readonly labResultId: number;
}

const useLabResult = (params: LabResultParameters, ignoreCache?: boolean): UseQueryResult<TestResultDetails> => {
    const { labResultId, enabled = true } = params;
    const path = `/labs/${labResultId}`;
    const customHeaders = {};
    // eslint-disable-next-line functional/immutable-data
    if (ignoreCache) customHeaders['Cache-Control'] = 'no-cache';

    return useQuery(
        ['lab-result', labResultId],
        async (): Promise<TestResultDetails> => {
            const response = await healthRecordsApi.get<ApiResponse<LabResultDetails>>(path, { headers: customHeaders });
            const labResult = response.data.results[0];
            return labResult ? fillInLabResult(labResult) : labResult;
        },
        {
            enabled,
        }
    );
};

export { useLabResult };
