import { Button, List } from '@village/ui';
import type { FC } from 'react';
import { useCallback } from 'react';

import { AppointmentsLoading } from './appointments-loading';
import * as Styled from './styles';
import { Icon } from 'components/icon';
import { ProviderImage } from 'components/provider-image';
import { useDepartments } from 'data/hooks/use-departments';
import { useUpcomingAppointments } from 'data/hooks/use-upcoming-appointments';
import { appConfig } from 'modules/config';
import { sendToNative } from 'modules/ipc';
import type { Appointment } from 'types';
import { formatDateWithTimezone, getDateUTC, getDepartmentTimezone } from 'utils';

const goToAppointmentDetails = (appointmentId: number) => (): void => {
    sendToNative('openWebpage', 'health-records', {
        name: 'APPOINTMENT_DETAILS_PAGE',
        url: `${appConfig.schedulingBaseUrl}/my-appointment-details/${appointmentId}`,
    });
};

const goToScheduler = (): void => {
    sendToNative('openWebpage', 'health-records', {
        name: 'SCHEDULER',
        url: `${appConfig.schedulingBaseUrl}?source=care_summary_details`,
    });
};

const UpcomingVisits: FC = () => {
    const { data: departmentsResponse, isLoading: isLoadingDepartments } = useDepartments();
    const {
        data: upcomingAppointments,
        isLoading: isLoadingAppointments,
        isRefetching,
        isError,
        isFetched,
    } = useUpcomingAppointments({ refetchOnWindowFocus: true, useErrorBoundary: false });

    const getAppointmentTimezone = useCallback(
        (appointment: Appointment) =>
            getDepartmentTimezone(appointment.departmentid, departmentsResponse?.results ?? []) ?? appointment.timezone,
        [departmentsResponse]
    );

    if (!isFetched && (isLoadingAppointments || isLoadingDepartments || isRefetching)) {
        return (
            <Styled.Container $noAppointments={true} data-testid="no-upcoming-visits">
                <AppointmentsLoading />
            </Styled.Container>
        );
    }

    if (isError) {
        return (
            <Styled.Container $noAppointments={true} data-testid="no-upcoming-visits">
                <Styled.Paragraph>Failed to load upcoming appointment, please try again later.</Styled.Paragraph>
                <Button onClick={goToScheduler} size="medium" variant="secondary">
                    Book visit
                </Button>
            </Styled.Container>
        );
    }

    if (!upcomingAppointments || upcomingAppointments.length === 0) {
        return (
            <Styled.Container $noAppointments={true} data-testid="no-upcoming-visits">
                <Styled.Paragraph>You currently don’t have any upcoming visits scheduled.</Styled.Paragraph>
                <Button onClick={goToScheduler} size="medium" variant="secondary">
                    Book visit
                </Button>
            </Styled.Container>
        );
    }

    return (
        <div>
            <Styled.Container $noAppointments={false} data-testid="upcoming-visits">
                <List>
                    {upcomingAppointments.map((appointment) => (
                        <Styled.ListItem
                            key={appointment.appointmentid}
                            data-testid="upcoming-appointment"
                            onClick={goToAppointmentDetails(appointment.appointmentid)}
                            picture={
                                appointment.providerimageurl ? (
                                    <ProviderImage
                                        $size={3.5}
                                        alt={appointment.providername}
                                        loading="lazy"
                                        src={appointment.providerimageurl}
                                    />
                                ) : (
                                    <Icon name="icProvider" size={3.5} />
                                )
                            }
                        >
                            <Styled.DateTimeWrapper>
                                {formatDateWithTimezone(
                                    getDateUTC(appointment.utcstarttime),
                                    'shortMonthDateWithTimezone',
                                    getAppointmentTimezone(appointment)
                                )}
                            </Styled.DateTimeWrapper>
                            {appointment.providername ? (
                                <Styled.ProviderInfoText>{appointment.providername}</Styled.ProviderInfoText>
                            ) : null}
                        </Styled.ListItem>
                    ))}
                </List>
            </Styled.Container>

            <Styled.Container $noAppointments={true} data-testid="upcoming-visits-cta">
                <Button onClick={goToScheduler} size="medium" variant="secondary">
                    Book visit
                </Button>
            </Styled.Container>
        </div>
    );
};

export { UpcomingVisits };
