import { rem } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
    display: grid;
    gap: ${rem(8)};
`;

const List = styled.div`
    list-style-type: decimal;
`;

export { Container, List };
