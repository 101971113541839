import { Fragment, type FC, type PropsWithChildren } from 'react';

import * as Styled from './styles';
import { Icon } from 'components/icon';
import { useNavHeader } from 'hooks/use-nav';

interface Props {
    readonly showingHeader: boolean;
}

const NavHeader: FC<PropsWithChildren<Props>> = ({ children, showingHeader }) => {
    const { navHeaderState, navigateBack } = useNavHeader();
    const { backButton, closeButton, sendButton, headerText, replyButton, shareButton } = navHeaderState;

    // note: If send button and cancel button exist at the same time, then the cancel button should move to the left
    const navButtons = (
        <Fragment>
            <Styled.IconBox data-testid="left-button-slot">
                {closeButton && sendButton ? (
                    <Icon data-testid="close-button" name="close" onClick={closeButton} size={1.5} />
                ) : backButton ? (
                    <Icon data-testid="back-button" name="back" onClick={navigateBack} size={1.5} />
                ) : (
                    <div />
                )}
            </Styled.IconBox>
            <Styled.HeaderText id="centerSlot">{headerText}</Styled.HeaderText>
            <Styled.IconBox data-testid="right-button-slot">
                {sendButton ? (
                    <Styled.SendIcon data-testid="send-button" name="sent" onClick={sendButton} size={1.5} />
                ) : closeButton && !sendButton ? (
                    <Icon data-testid="close-button" name="close" onClick={closeButton} size={1.5} />
                ) : replyButton ? (
                    <Styled.TextButton onClick={replyButton}>Reply</Styled.TextButton>
                ) : shareButton ? (
                    <Styled.TextButton onClick={shareButton}>Share</Styled.TextButton>
                ) : (
                    <div />
                )}
            </Styled.IconBox>
        </Fragment>
    );

    return (
        <Styled.MainContainer data-testid="nav-header-main">
            {showingHeader ? <Styled.Header data-testid="nav-header-buttons">{navButtons}</Styled.Header> : null}
            <Styled.ChildrenContainer>{children}</Styled.ChildrenContainer>
        </Styled.MainContainer>
    );
};

export { NavHeader };
