import { useUrlState } from '@village/tools';
import { Button } from '@village/ui';
import type { FC } from 'react';

import * as Styled from './styles';
import { TEST_RESULT_REVIEWED_STATUSES } from '../../constants';
import * as RootStyled from '../../test-result/styles';
import { Icon } from 'components/icon';
import { useFeatureFlags } from 'hooks';
import type { TestResultDetails } from 'types';
import { getProviderDisplayName } from 'utils/provider';

interface Props {
    readonly testResult: TestResultDetails;
    readonly composeMessage: () => void;
}

const ProviderNote: FC<Props> = ({ testResult, composeMessage }) => {
    const { provider, note, enableMessagingFeatureFlag, labResult, imagingResult } = testResult;

    const [hideMessageButton] = useUrlState('hideMessageButton');
    const { hasFeature } = useFeatureFlags();
    const canSendMessage = enableMessagingFeatureFlag ? hasFeature(enableMessagingFeatureFlag) : false;
    const result = labResult ?? imagingResult;

    if (hasFeature('messaging.allTestResults') && result && !TEST_RESULT_REVIEWED_STATUSES.includes(result.status)) {
        return (
            <RootStyled.SidePadding>
                <RootStyled.MessageInReview>
                    Results are often made available to you prior to review by your Care Team. Once results are reviewed, your
                    Care Team will contact you.
                </RootStyled.MessageInReview>
            </RootStyled.SidePadding>
        );
    }

    return (
        <RootStyled.SidePadding>
            <Styled.Container data-testid="provider-note">
                <Styled.ProviderInfo>
                    {provider?.provider_image_url ? (
                        <Styled.ProviderIcon $size={2.5} src={provider.provider_image_url} />
                    ) : (
                        <Styled.DefaultProviderIcon name="default-provider" size={2.5} />
                    )}
                    {provider ? (
                        <div>
                            <Styled.ProviderName>{getProviderDisplayName(provider)}</Styled.ProviderName>
                            <Styled.ProviderSpecialty>{provider.specialty}</Styled.ProviderSpecialty>
                        </div>
                    ) : null}
                </Styled.ProviderInfo>
                <Styled.Note>{note ?? 'If you have any questions, please send a message.'}</Styled.Note>
                {canSendMessage && !hideMessageButton ? (
                    <Styled.SendMessage>
                        <Styled.HaveAQuestion>Have a question ?</Styled.HaveAQuestion>
                        <Button
                            onClick={composeMessage}
                            size="small"
                            startIcon={<Icon name="email" size={1.25} />}
                            variant="secondary"
                        >
                            Send a message
                        </Button>
                    </Styled.SendMessage>
                ) : null}
            </Styled.Container>
        </RootStyled.SidePadding>
    );
};

export { ProviderNote };
