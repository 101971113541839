import { useContext } from 'react';

import type { AuthActions } from 'contexts/auth';
import { AuthActionsContext } from 'contexts/auth';

const useAuthActions = (): AuthActions => {
    const context = useContext(AuthActionsContext);

    if (context === undefined) {
        throw new Error('useAuthActions must be used within a AuthProvider');
    }

    return context;
};

export { useAuthActions };
