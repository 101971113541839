import { memo, useMemo } from 'react';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { TestResultContent } from './test-result-page';
import { MainMasterPage } from 'pages/page-layouts';
import type { TestResultParametersTypes } from 'types';

const TestResultPage: FC = memo(() => {
    const { testResultId, testResultType } = useParams<TestResultParametersTypes>();
    const placeholders = useMemo(
        () => ({
            main: <TestResultContent testResultId={testResultId} testResultType={testResultType} />,
        }),
        [testResultId, testResultType]
    );

    return <MainMasterPage placeholders={placeholders} title="Test Result" />;
});

export { TestResultPage };
