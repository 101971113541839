import { Spinner } from '@village/ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import type { ListRowProps } from 'react-virtualized';
import { AutoSizer, List } from 'react-virtualized';

import { LettersEntry } from './components/letters-entry';
import { LettersLoading } from './components/letters-loading';
import * as Styled from './styles';
import { NoData } from 'components/no-data';
import { PulldownReload } from 'components/pulldown-reload';
import { useLetters } from 'data/hooks/use-letters';
import { useNativeControls } from 'hooks/use-native-controls';
import { Countly } from 'modules/countly';
import type { ApiResponse } from 'types';
import type { LetterDetails } from 'types/letter-details';

const LettersContent: FC = () => {
    const {
        data: lettersData,
        isLoading,
        isFetched,
        refetch: refetchLetters,
        isFetching,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    } = useLetters();
    const [scrollPosition, setScrollPosition] = useState(0);

    useNativeControls({
        ipcEvents: ['showBackButton'],
        source: 'health-records',
        title: 'Documents',
    });

    useEffect(() => {
        Countly.addEvent({ key: 'viewLetters' });
    }, []);

    const refetchFunction = useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        refetchLetters();
    }, [refetchLetters]);

    const onScroll = useCallback(
        ({ scrollTop }) => {
            setScrollPosition(scrollTop as number);
        },
        [setScrollPosition]
    );

    const onRowsRendered = useCallback(
        ({ overscanStopIndex, stopIndex }) => {
            if (hasNextPage && !isLoading && !isFetching && !isFetchingNextPage && overscanStopIndex - stopIndex < 2) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                fetchNextPage();
            }
        },
        [hasNextPage, isLoading, isFetching, isFetchingNextPage, fetchNextPage]
    );

    const lettersPages = lettersData?.pages;
    const flattenedLetters = useMemo(
        () =>
            lettersPages?.reduce(
                (accumulator: readonly LetterDetails[], value: ApiResponse<LetterDetails>) => accumulator.concat(value.results),
                []
            ) ?? [],
        [lettersPages]
    );

    if (isLoading) {
        return <LettersLoading />;
    }

    if (isFetched && flattenedLetters.length === 0) {
        const description = `This is where any signed documents from your provider would be. Reach out to a member of your care team if you're missing something that should be here.`;
        return <NoData description={description} iconName="file-colored" title="No Documents" />;
    }

    const rowRenderer = ({ index, key, style }: ListRowProps): React.ReactNode => {
        const letter = flattenedLetters[index];
        if (isFetchingNextPage && index === flattenedLetters.length) {
            return (
                <Styled.SpinnerContainer key={key} style={style}>
                    <Spinner />
                </Styled.SpinnerContainer>
            );
        }
        return (
            <div key={key} style={style}>
                <LettersEntry key={letter.letter_id} letter={letter} />
            </div>
        );
    };

    return (
        <Styled.Page>
            <Styled.LetterEntryList>
                <PulldownReload isFetching={isFetching} reloadFunction={refetchFunction} scrollPosition={scrollPosition}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                height={height}
                                onRowsRendered={onRowsRendered}
                                onScroll={onScroll}
                                rowCount={flattenedLetters.length + (isFetchingNextPage ? 1 : 0)}
                                rowHeight={81}
                                rowRenderer={rowRenderer}
                                width={width}
                            />
                        )}
                    </AutoSizer>
                </PulldownReload>
            </Styled.LetterEntryList>
        </Styled.Page>
    );
};

export { LettersContent };
