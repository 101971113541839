import { ButtonCard, Text } from '@village/ui';
import React, { useCallback, useMemo } from 'react';

import * as Styled from './styles';
import { Icon } from 'components/icon';
import { ProviderImage } from 'components/provider-image';
import { useMessaging } from 'hooks/use-messaging';
import { useScheduling } from 'pages/scheduling-flow/hooks/use-scheduling';
import type { Provider } from 'types';
import { formatDate } from 'utils/date';
import { getProviderDisplayName } from 'utils/provider';

interface Props {
    readonly provider: Provider;
}

const ProviderCard: React.FC<Props> = ({ provider }) => {
    const { image, specialty, recentencountersdate } = provider;
    const { goToNextStep } = useScheduling();
    const { setMessagingFields } = useMessaging();

    const goToComposeMessage = useCallback((): void => {
        setMessagingFields({
            provider,
        });
        goToNextStep();
    }, [goToNextStep, provider, setMessagingFields]);

    const primaryText = getProviderDisplayName(provider);

    const primaryTextElement = <Styled.ButtonCardPrimaryText>{primaryText}</Styled.ButtonCardPrimaryText>;

    const pictureNode = useMemo(
        () =>
            image?.url ? (
                <ProviderImage alt={primaryText ?? 'Provider image'} src={image.url} />
            ) : (
                <Icon name="default-provider" />
            ),
        [image, primaryText]
    );

    const renderAppointmentInfo = useMemo((): string => {
        if (recentencountersdate && recentencountersdate?.length > 0) {
            const appointments = recentencountersdate.map((date) => new Date(date));
            const today = new Date();

            // find the closest appointment to the current date, if a future date exists it should be given preference
            const appointmentDate = appointments.reduce((previousDate, currentDate): Date => {
                if (previousDate.getTime() > today.getTime() && currentDate.getTime() > today.getTime()) {
                    return currentDate;
                }
                if (previousDate.getTime() > today.getTime() && currentDate.getTime() < today.getTime()) {
                    return previousDate;
                }
                return appointments[0];
            });

            return appointmentDate.getTime() > today.getTime()
                ? `Upcoming appt: ${formatDate(appointmentDate, 'fullDate')}`
                : `Last seen: ${formatDate(appointmentDate, 'fullDate')}`;
        }

        return '';
    }, [recentencountersdate]);

    return (
        <Styled.Contanier>
            <ButtonCard onClick={goToComposeMessage} picture={pictureNode} pictureType="circle" primaryText={primaryTextElement}>
                <Styled.ButtonCardContent>
                    <Text type="body2">{specialty}</Text>
                    <Styled.Date>{renderAppointmentInfo}</Styled.Date>
                </Styled.ButtonCardContent>
            </ButtonCard>
        </Styled.Contanier>
    );
};

export { ProviderCard };
