import { Spinner } from '@village/ui';
import type { FC } from 'react';

import * as Styled from './styles';

const CenteredSpinner: FC = () => (
    <Styled.SpinnerContainer data-testid="centered-spinner">
        <Spinner />
    </Styled.SpinnerContainer>
);

export { CenteredSpinner };
