import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';

import { healthRecordsApi } from '../health-records-api-config';
import type { ApiResponse } from 'types/api';
import type { VaccineInfo } from 'types/vaccine-history';

const useVaccineHistory = (enabled = true): UseQueryResult<ApiResponse<VaccineInfo>> => {
    const path = `/vaccines`;

    return useQuery(
        'vaccines',
        async () => {
            const response = await healthRecordsApi.get<ApiResponse<VaccineInfo>>(path);
            return response.data;
        },
        {
            enabled,
        }
    );
};

export { useVaccineHistory };
