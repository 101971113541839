import React, { useCallback, useMemo, useEffect } from 'react';

import * as Styled from './styles';
import { Icon } from 'components/icon';
import { ProviderImage } from 'components/provider-image';
import { useFontSize } from 'hooks';
import { sendToNative } from 'modules/ipc';
import type { Folder, MessageThread, Provider } from 'types';
import { getDateTag } from 'utils/date';

interface Props {
    readonly folder: Folder;
    readonly markUnread?: boolean;
    readonly messageThread: MessageThread;
    readonly providersData?: readonly Provider[];
    readonly measureListItemHeight: () => void;
}

const MessageEntry: React.FC<Props> = ({ folder, markUnread, messageThread, providersData, measureListItemHeight }) => {
    const { messages, message_thread_id, source, subject } = messageThread;

    const goToMessageDetail = useCallback((): void => {
        const messagePath = `${window.location.origin}/message/${message_thread_id}/${folder}/${source}`;
        sendToNative('onThreadedMessageAction', 'health-records', { url: messagePath });
    }, [message_thread_id, folder, source]);
    const fontSize = useFontSize();

    useEffect(() => {
        measureListItemHeight();
    }, [measureListItemHeight, fontSize]);

    const lastReceivedMessage = messages[0];
    const { from, text, unread, timestamp } = lastReceivedMessage;
    const markMessageAsUnread = markUnread ? unread : false;

    const messageDateTag = useMemo(() => getDateTag(timestamp, 'shortDate'), [timestamp]);

    const providerImage = useMemo(() => {
        const provider = providersData?.find(({ providerid }) => providerid === from?.providerid);
        return provider?.image?.url ? (
            <ProviderImage $size={2.5} alt={provider.displayname ?? ''} src={provider.image.url} />
        ) : (
            <Icon name="icProvider" size={2.5} />
        );
    }, [from?.providerid, providersData]);

    return (
        <Styled.Container onClick={goToMessageDetail}>
            <Styled.MDPhotoContainer>{providerImage}</Styled.MDPhotoContainer>
            <Styled.Underline>
                <Styled.MessagePreviewContainer>
                    <Styled.FromField>
                        <Styled.FromName unread={markMessageAsUnread}>{from?.displayname ?? 'Village Medical'}</Styled.FromName>
                    </Styled.FromField>
                    <Styled.SubjectField unread={markMessageAsUnread}>{subject}</Styled.SubjectField>
                    <Styled.MessagePreview>{text}</Styled.MessagePreview>
                </Styled.MessagePreviewContainer>
                <Styled.MessageAddInfoContainer>
                    <Styled.TagContainer>{messageDateTag}</Styled.TagContainer>
                    {markMessageAsUnread ? <Styled.NewChip label="New" size="small" /> : null}
                </Styled.MessageAddInfoContainer>
            </Styled.Underline>
        </Styled.Container>
    );
};

export { MessageEntry };
