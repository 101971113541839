import { differenceInSeconds, parseISO } from 'date-fns';
import { useMemo } from 'react';

import { useImagingResults } from './use-imaging-results-query';
import { useLabs } from './use-labs-query';
import type { ImagingResult, LabResult, Metadata, TestResultType } from 'types';

const useTestResults = (
    fetchTypes: readonly TestResultType[],
    ignoreCache?: boolean
): {
    readonly testResults: readonly (ImagingResult | LabResult)[];
    readonly isFetching: boolean;
    readonly metadata?: Metadata;
} => {
    const { data: labResults, isFetching: isLabResultsFetching } = useLabs(
        {
            enabled: fetchTypes.includes('labresult'),
            show_portal_only: true,
        },
        ignoreCache
    );
    const { data: imagingResults, isFetching: isImagingResultsFetching } = useImagingResults(
        {
            enabled: fetchTypes.includes('imagingresult'),
        },
        ignoreCache
    );

    return useMemo(() => {
        if (isLabResultsFetching || isImagingResultsFetching) {
            return {
                isFetching: true,
                testResults: [],
            };
        }

        return {
            isFetching: false,
            metadata: labResults?.__metadata,
            // Combine lab-results and imaging-results and sort them by creation date-time.
            testResults: [...(labResults?.results ?? []), ...(imagingResults ?? [])].sort((a, b) => {
                const aDate = 'lab_result_id' in a ? a.lab_result_datetime : a.createddatetime;
                const bDate = 'lab_result_id' in b ? b.lab_result_datetime : b.createddatetime;
                return differenceInSeconds(bDate ? parseISO(bDate) : 0, aDate ? parseISO(aDate) : 0);
            }),
        };
    }, [labResults, imagingResults, isLabResultsFetching, isImagingResultsFetching]);
};

export { useTestResults };
