import { ButtonCard } from '@village/ui';
import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import * as Styled from './styles';
import { Icon } from 'components/icon';
import { ProviderImage } from 'components/provider-image';
import { useMessaging } from 'hooks/use-messaging';
import type { Provider } from 'types';
import { getProviderDisplayName } from 'utils/provider';

interface Props {
    readonly provider: Provider;
}

const RecipientEntry: React.FC<Props> = ({ provider }) => {
    const { image } = provider;
    const { search: queryParams } = useLocation();
    const { setMessagingFields } = useMessaging();
    const navigate = useNavigate();

    const goToComposeMessage = useCallback((): void => {
        setMessagingFields({ provider });
        navigate(`/compose-message${queryParams}`);
    }, [navigate, provider, queryParams, setMessagingFields]);

    const primaryText = `${getProviderDisplayName(provider) ?? ''} & Team`;

    const pictureNode = useMemo(
        () => (image?.url ? <ProviderImage alt={primaryText} src={image.url} /> : <Icon name="default-provider" />),
        [image, primaryText]
    );

    return (
        <Styled.Container>
            <ButtonCard onClick={goToComposeMessage} picture={pictureNode} pictureType="circle" primaryText={primaryText} />
        </Styled.Container>
    );
};

export { RecipientEntry };
