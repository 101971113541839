import { memo, useCallback, useEffect } from 'react';
import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { NotFoundPage } from 'components/not-found-page';
import { PrivateRoute } from 'components/private-route';
import { useAxiosInterceptor } from 'data/api-axios-interceptor';
import { useFeatureFlags } from 'hooks';
import { useNativeRedirect } from 'hooks/use-native-redirect';
import { sendToNative } from 'modules/ipc';
import {
    LabsPage,
    TestResultsPage,
    LoginPage,
    MedicationsPage,
    MedicationDetailsPage,
    MessagingPage,
    ComposeMessagePage,
    MessageThreadPage,
    MessageReplyPage,
    UnverifiedUserPage,
    SelectMessageTypePage,
    SelectRecipientPage,
    CareSummariesPage,
    SelectLabPage,
    TestResultPreviewPage,
    CareSummaryPage,
    SelectPrescriptionPage,
    schedulingRoutes,
    TestResultPage,
    TestResultDocumentsPage,
    VaccineDetailsPage,
    VaccineHistoryPage,
    PharmacyDetailsPage,
    LettersPage,
} from 'pages';
import { DebuggingPage } from 'pages/debugging';

const AppRouter: FC = memo(() => {
    useAxiosInterceptor();
    useNativeRedirect();
    const { hasFeature } = useFeatureFlags();

    useEffect(() => {
        sendToNative('hideHeader', 'health-records');
    }, [hasFeature]);

    const handleVisibilityChange = useCallback(() => {
        if (document.visibilityState === 'visible') {
            sendToNative('hideHeader', 'health-records');
        }
    }, []);

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [handleVisibilityChange]);

    return (
        <Routes>
            <Route element={<PrivateRoute element={<LabsPage />} />} path="/labs" />
            <Route element={<PrivateRoute element={<TestResultsPage />} />} path="/test-results" />
            <Route
                element={<PrivateRoute element={<TestResultPage />} />}
                path="/individual-test-result/:testResultType/:testResultId"
            />
            <Route
                element={<PrivateRoute element={<TestResultDocumentsPage />} />}
                path="/individual-test-result/:testResultType/:testResultId/documents"
            />
            <Route element={<PrivateRoute element={<MedicationDetailsPage />} />} path="/medication/:medicationId" />
            <Route element={<PrivateRoute element={<MedicationsPage />} />} path="/medications" />
            <Route element={<PrivateRoute element={<MessageThreadPage />} />} path="/message/:threadId/:folder/:source" />
            <Route element={<PrivateRoute element={<MessageThreadPage />} />} path="/message/:threadId/:folder/:source/:view" />
            <Route element={<PrivateRoute element={<MessageReplyPage />} />} path="/message-reply/:threadId/:folder/:source" />
            <Route element={<PrivateRoute element={<MessagingPage />} />} path="/messaging" />
            <Route element={<PrivateRoute element={<ComposeMessagePage />} />} path="/compose-message" />
            <Route element={<PrivateRoute element={<SelectPrescriptionPage />} />} path="/select-prescription" />
            <Route element={<PrivateRoute element={<SelectMessageTypePage />} />} path="/select-message-type" />
            <Route element={<PrivateRoute element={<SelectRecipientPage />} />} path="/select-recipient" />
            <Route element={<PrivateRoute element={<SelectLabPage />} />} path="/select-lab" />
            <Route
                element={<PrivateRoute element={<TestResultPreviewPage />} />}
                path="/test-result-messaging-preview/:testResultType/:testResultId"
            />
            <Route element={<PrivateRoute element={<CareSummaryPage />} />} path="/post-visit-summary/:encounterId" />
            <Route element={<PrivateRoute element={<CareSummariesPage />} />} path="/post-visit-summaries" />
            <Route element={<PrivateRoute element={<VaccineHistoryPage />} />} path="/vaccine-history" />
            <Route element={<PrivateRoute element={<VaccineDetailsPage />} />} path="/vaccine-details/:vaccineId" />
            <Route element={<PrivateRoute element={<PharmacyDetailsPage />} />} path="/preferred-pharmacy" />
            <Route element={<PrivateRoute element={<LettersPage />} />} path="/letters" />
            <Route element={<DebuggingPage />} path="/debugging" />
            {schedulingRoutes}
            <Route element={<LoginPage />} path="/auth/login" />
            <Route element={<UnverifiedUserPage />} path="/unverified-user" />
            <Route element={<NotFoundPage />} path="*" />
        </Routes>
    );
});

export { AppRouter };
