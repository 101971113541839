import { rem } from 'polished';
import styled from 'styled-components';

import { Icon } from 'components/icon';

const InfoText = styled.div`
    color: ${({ theme }) => theme.vui.colors.primary.navy80};
    font-size: ${rem(16)};
    font-weight: 400;
    padding-top: ${rem(8)};
    line-height: ${rem(18)};
`;

const SearchFieldContainer = styled.div`
    padding: ${rem(8)} 0;
`;

const ListTitleBlock = styled.div`
    background-color: ${({ theme }) => theme.color.ghostWhite};
    padding: ${rem(8)};
    margin: 0 ${rem(16)};
    border-radius: 4px;
    display: flex;
`;

const TitleText1 = styled.div`
    color: ${({ theme }) => theme.vui.colors.neutral.gray60};
    font-size: ${rem(16)};
`;

const TitleText2 = styled.div`
    padding-left: ${rem(8)};
    color: ${({ theme }) => theme.vui.colors.primary.primaryBlue};
    font-size: ${rem(16)};
    font-weight: 600;
    cursor: pointer;
`;

const WarningText = styled.div`
    color: ${({ theme }) => theme.color.venetianRed};
    font-weight: 400;
    font-size: ${rem(12)};
    display: flex;
    align-items: center;
    padding: ${rem(8)} 0 0 ${rem(16)};
`;

const MyPrescriptions = styled.div`
    color: ${({ theme }) => theme.vui.colors.neutral.gray80};
    font-weight: 600;
    line-height: ${rem(20)};
    padding-top: ${rem(8)};
`;

const Footer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    border-top: 1px solid ${({ theme }) => theme.color.platinum};
    padding: ${rem(16)};
`;

const Header = styled.section`
    padding: ${rem(16)} ${rem(16)} 0 ${rem(16)};
`;

const ScrollableContainer = styled.section`
    overflow: auto;
    flex: 1;
`;

const DangerIcon = styled(Icon)`
    margin-right: ${rem(16)};
`;

const NoPrescriptions = styled.div`
    text-align: center;
    margin-top: ${rem(22)};
`;

export {
    InfoText,
    DangerIcon,
    Header,
    Footer,
    ListTitleBlock,
    MyPrescriptions,
    NoPrescriptions,
    ScrollableContainer,
    SearchFieldContainer,
    TitleText1,
    TitleText2,
    WarningText,
};
