import { useEffect } from 'react';

import type { NativeToWebIpcEvents } from 'modules/ipc/types';

const useNativeIpc = (ipcEvent: NativeToWebIpcEvents, callback: EventListener | (() => void)): void => {
    useEffect(() => {
        document.addEventListener(ipcEvent, callback);

        return () => {
            document.removeEventListener(ipcEvent, callback);
        };
    }, [ipcEvent, callback]);
};

export { useNativeIpc };
