import styled from 'styled-components';
/**
 * Padding is a component that adds padding to its children.
 * It is meant to be used as a root for all pages to share the same padding.
 * It is used in conjunction with FullBleed if some components doesn't need the
 * padding constraint.
 * 

 *
 * @example
 * <Page.Padding>
 *   <Page.FullBleed>
 *     <HeroImage />
 *   </Page.FullBleed>
 * </Page.Padding>
 */
interface PaddingProps {
    /**
     * If true, the block level padding (y-axis) will be applied.
     * @default true
     */
    readonly $hasBlockPadding?: boolean;
}
const Padding = styled.div<PaddingProps>`
    display: grid;
    padding-block: ${({ $hasBlockPadding = true, theme }) => ($hasBlockPadding ? theme.page.pagePaddingBlock : 0)};
    grid-template-columns: ${({ theme }) => theme.page.pagePaddingInline} 1fr ${({ theme }) => theme.page.pagePaddingInline};

    > * {
        grid-column: 2;
    }
`;
/**
 * FullBleed is a component that takes up the full width of the page and is not constrained by the page's padding.
 * It is meant to be used for components that need to be full width, like a hero image
 * or for components that have its own padding like lists.
 *
 * @example
 * <Page.Padding>
 *   <Page.FullBleed>
 *     <HeroImage />
 *   </Page.FullBleed>
 * </Page.Padding>
 */
const FullBleed = styled.section`
    grid-column: 1 / -1;
`;

export { Padding, FullBleed };
