import styled from 'styled-components';

const Container = styled.div`
    padding-bottom: 1rem;
`;

const LastMessageContainer = styled.div`
    font-style: italic;
`;

export { Container, LastMessageContainer };
