import styled from 'styled-components';

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const SpinnerContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const MessagesContainer = styled.div`
    overflow: auto;
`;

const Messages = styled.div`
    display: flex;
    flex-direction: column;
`;

const MessageSubject = styled.div`
    margin: 1.125rem 0 1rem 1rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25;
`;

const LabButton = styled.div`
    margin: 0 0.5rem 1rem;
`;

const ReplyButton = styled.div`
    margin: auto 0.5rem 0.8rem;
`;

export { Container, SpinnerContainer, MessagesContainer, MessageSubject, Messages, LabButton, ReplyButton };
