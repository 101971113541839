import type { FC } from 'react';

import * as Styled from './styles';

interface VaccineDetailEntryProps {
    readonly label: string;
    readonly value?: string;
}

const VaccineDetailEntry: FC<VaccineDetailEntryProps> = ({ label, value }) => (
    <Styled.InfoWrapper>
        <Styled.Label>{label}</Styled.Label>
        <Styled.Value>{value ?? '-'}</Styled.Value>
    </Styled.InfoWrapper>
);

export { VaccineDetailEntry };
