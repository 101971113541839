import styled from 'styled-components';

const LoaderContainer = styled.section`
    padding: 1rem;
`;

const OptionsContainer = styled.div`
    overflow: auto;
    padding: 1rem 1rem 0;
`;

const ChatContainer = styled.div`
    padding: 1rem 1rem 0;
`;

export { ChatContainer, LoaderContainer, OptionsContainer };
