/* eslint-disable react/no-array-index-key */
import type { FC } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { DocumentPageLoading } from './document-page-loading';
import * as Styled from './styles';
import { useImagingResultDocuments } from 'data/hooks/use-imaging-documents-query';
import { useIndividualTestResult } from 'data/hooks/use-individual-test-result';
import { useLabDocuments } from 'data/hooks/use-lab-document-page-query';
import { useNativeControls } from 'hooks/use-native-controls';
import { Countly } from 'modules/countly';
import type { TestResultParametersTypes } from 'types';
import type { CountlyKey } from 'types/countly';

const TestDocumentsContent: FC = () => {
    const { testResultId, testResultType } = useParams<TestResultParametersTypes>();
    const { testResult, isFetching } = useIndividualTestResult({
        testResultId,
        testResultType,
    });

    const imagingResult = testResult?.imagingResult;
    const labResult = testResult?.labResult;

    const { data: imagingDocuments, isLoading: imagingDocumentsLoading } = useImagingResultDocuments({
        enabled: testResultType === 'imagingresult',
        imagingResultId: testResultId ?? '',
        pages: imagingResult?.pages ?? [],
    });

    const { data: labDocuments, isLoading: labDocumentsLoading } = useLabDocuments({
        document_pages: labResult?.document_pages ?? [],
        enabled: testResultType === 'labresult',
        labResultId: testResultId ?? '',
    });

    const result = imagingResult ?? labResult;
    const documents = imagingDocuments ?? labDocuments;

    useNativeControls({
        ipcEvents: ['showBackButton'],
        source: 'health-records',
        title: '',
    });

    useEffect(() => {
        const countlyKey = ((): CountlyKey | null => {
            switch (testResultType) {
                case 'labresult':
                    return 'viewLabDocument';
                case 'imagingresult':
                    return 'viewImagingDocument';
                default:
                    return null;
            }
        })();
        if (countlyKey) {
            Countly.addEvent({
                key: countlyKey,
            });
        }
    }, [testResultType]);

    if (isFetching || imagingDocumentsLoading || labDocumentsLoading) {
        return <DocumentPageLoading />;
    }

    if (!result || !documents || documents.length === 0) {
        return null;
    }

    return (
        <Styled.DocumentPageContainer>
            {documents.map((document, index) => (
                <Styled.Image
                    key={index}
                    alt={`${labResult?.lab_test_name ?? ''} ${imagingResult?.description ?? ''}`}
                    src={document}
                />
            ))}
        </Styled.DocumentPageContainer>
    );
};

export { TestDocumentsContent };
