import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.platinum};
    cursor: pointer;
`;

const ListContainer = styled.div`
    height: 100%;
`;

export { Container, ListContainer };
