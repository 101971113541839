import { Button, Spinner } from '@village/ui';
import { useEffect, useMemo, useState, Fragment, useCallback } from 'react';
import type { FC } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import * as Styled from './styles';
import { Icon } from 'components/icon';
import type { IconName } from 'components/icon';
import { useIdentitySearch } from 'data/hooks/use-identity-search';
import { useAuthState, useFeatureFlags } from 'hooks';
import { useNativeIpc } from 'hooks/use-native-ipc';
import { appConfig } from 'modules/config';
import { Countly } from 'modules/countly';
import { sendToNative } from 'modules/ipc';
import type { MessageBase } from 'modules/ipc/types';

type Route = '/labs' | '/medications' | '/messaging' | 'default';

const previousPathToContent: Record<
    Route,
    {
        readonly iconName: IconName;
        readonly actionMessage: string;
        readonly pageTitle?: string;
        readonly hideUserNotFoundButtons?: boolean;
    }
> = {
    '/labs': {
        actionMessage: 'Connect your medical record to get access to your lab results!',
        iconName: 'lab-result',
        pageTitle: 'Lab result',
    },
    '/medications': {
        actionMessage: 'Connect your medical record to get access to your medications!',
        iconName: 'medications',
        pageTitle: 'Medications',
    },
    '/messaging': {
        actionMessage: 'Connect your medical record to get access to your messages!',
        hideUserNotFoundButtons: true,
        iconName: 'message',
        pageTitle: 'Message',
    },
    default: {
        actionMessage: 'Connect your medical record to get access!',
        iconName: 'placeholder',
        pageTitle: undefined,
    },
};

const UnverifiedUserContent: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { hasFeature } = useFeatureFlags();
    const { authUser } = useAuthState();
    const [email, setEmail] = useState<string | null>(null);
    const [verificationFlowStarted, setVerificationFlowStarted] = useState(false);
    const verifyIdentityFeatureEnabled = hasFeature('verifyIdentity');
    const queryClient = useQueryClient();

    const { data: athenaUsersSearchResponse, isLoading, refetch: searchCurrentUserInAthena } = useIdentitySearch(false);

    const previousPath = useMemo(() => new URLSearchParams(location.search).get('previousPath'), [location.search]);

    const previousPathToContentKey =
        previousPath && Object.keys(previousPathToContent).includes(previousPath) ? previousPath : 'default';

    const { actionMessage, iconName, pageTitle, hideUserNotFoundButtons } = previousPathToContent[previousPathToContentKey];

    const exitHealthRecords = useCallback(async () => {
        sendToNative('exit', 'health-records');
    }, []);

    const handleConnectRecords = useCallback(async () => {
        const { data } = await searchCurrentUserInAthena();

        if (data?.valid) {
            setVerificationFlowStarted(true);
        }
    }, [searchCurrentUserInAthena]);

    const composeSupportEmail = useCallback(() => {
        sendToNative('composeEmail', 'health-records', {
            body: `Please type your message below including your first and last name.
                We'll be in touch with you shortly.`,
            subject: 'Support needed: Village Medical App',
            toEmail: appConfig.supportEmail,
        });
    }, []);

    const listenToMrnFlowEvent = useCallback(
        (event: CustomEvent<MessageBase<{ readonly userVerified: boolean }>>) => {
            if (!verifyIdentityFeatureEnabled) {
                return;
            }

            if (event.detail.body?.userVerified) {
                // Remove all existing queries, to force network reload
                // react-query for some reason caches error state
                // No easy way to fix right now
                // removeQueries makes sense here since the uses just got verified, and we should load fresh data.
                queryClient.removeQueries();

                // If the MRN flow was completed and the user verified
                // Redirect the user to page they visited at first
                navigate(previousPath ?? '/medications', { replace: true });
            } else {
                // If the MRN flow was completed and the user was not verified
                // keep the current page and show the button to trigger the flow again
                setVerificationFlowStarted(false);
            }
        },
        [navigate, queryClient, previousPath, verifyIdentityFeatureEnabled]
    ) as EventListener;

    useNativeIpc('mrnFlowCompleted', listenToMrnFlowEvent);

    useEffect(() => {
        if (verificationFlowStarted) {
            sendToNative('verifyIdentity', 'health-records');
        }
    }, [verificationFlowStarted]);

    useEffect(() => {
        if (authUser) {
            authUser.getUserAttributes((error, result) => {
                if (error) {
                    return;
                }

                const emailAttributes = result?.filter((element) => element.getName() === 'email');
                const emailAttribute = emailAttributes !== undefined ? emailAttributes[0].getValue() : null;
                setEmail(() => emailAttribute);
            });
        }
    }, [authUser]);

    useEffect(() => {
        const messageBody = {
            canClose: true,
            canGoBack: false,
            currentPage: window.location.pathname,
            title: pageTitle,
        };

        sendToNative('navigation', 'health-records', messageBody);
    }, [pageTitle]);

    useEffect(() => {
        if (authUser && email) {
            Countly.addEvent({ key: 'unverifiedUser', segmentation: { email } });
        }
    }, [authUser, email]);

    if (!verifyIdentityFeatureEnabled) {
        return (
            <Styled.Container>
                <Icon name={iconName} size={4} />
                <Styled.UnverifiedMessage data-testid="verify-user-coming-soon">Coming soon</Styled.UnverifiedMessage>
                <Styled.ActionMessage>
                    You will soon be able to connect your Health Record to the Village Medical app!
                </Styled.ActionMessage>
            </Styled.Container>
        );
    }

    return (
        <Styled.Container>
            {athenaUsersSearchResponse === undefined || athenaUsersSearchResponse.valid ? (
                <Fragment>
                    <Icon name={iconName} size={4} />
                    <Styled.UnverifiedMessage>Record not connected</Styled.UnverifiedMessage>
                    <Styled.ActionMessage>{actionMessage}</Styled.ActionMessage>

                    <Styled.ButtonContainer>
                        {isLoading || verificationFlowStarted ? (
                            <Spinner data-testid="spinner" />
                        ) : (
                            <Button
                                data-testid="verify-user-cta"
                                disabled={isLoading}
                                fullWidth={true}
                                onClick={handleConnectRecords}
                                size="medium"
                            >
                                Connect my record
                            </Button>
                        )}
                    </Styled.ButtonContainer>
                </Fragment>
            ) : (
                <Fragment>
                    <Icon name="band-aid" size={4} />
                    <Styled.UnverifiedMessage>
                        Oops!
                        <br />
                        Looks like we can’t verify your account right now
                    </Styled.UnverifiedMessage>
                    <Styled.ActionMessage>
                        We’re taking a look at the issue. Please, contact our team to help us resolve it.
                    </Styled.ActionMessage>

                    {!hideUserNotFoundButtons ? (
                        <Fragment>
                            <Styled.ButtonContainer>
                                <Button data-testid="contact-us-cta" fullWidth={true} onClick={composeSupportEmail} size="medium">
                                    Contact us
                                </Button>
                            </Styled.ButtonContainer>
                            <Styled.ButtonContainer>
                                <Button data-testid="exit-health-records" onClick={exitHealthRecords} variant="link">
                                    Connect my records later
                                </Button>
                            </Styled.ButtonContainer>
                        </Fragment>
                    ) : null}
                </Fragment>
            )}
        </Styled.Container>
    );
};

export { UnverifiedUserContent };
