import type { FC, PropsWithChildren } from 'react';
import { Fragment } from 'react';
import { createGlobalStyle } from 'styled-components';

import { useFeatureFlags, useFontSize } from 'hooks';
import { getPlatform } from 'utils/platform';

const fonts: Record<'Android' | 'iOS', string> = {
    Android: `"Roboto"`,
    iOS: `"SF Pro Text"`,
};

const GlobalStyle = createGlobalStyle<{ readonly platformType: 'Android' | 'iOS'; readonly fontSize: string }>`
  html {
    font-family: ${({ platformType }) => fonts[platformType]}, Arial, sans-serif;
    font-size: ${({ fontSize }) => fontSize};
    background-color: white;
  }
`;

const MUIGlobalStyles: FC<PropsWithChildren> = ({ children }) => {
    const platformType = getPlatform();
    const fontSize = useFontSize();
    const { hasFeature } = useFeatureFlags();

    return (
        <Fragment>
            <GlobalStyle fontSize={hasFeature('fontSizeScale') ? `${fontSize}px` : 'unset'} platformType={platformType} />
            {children}
        </Fragment>
    );
};

export { MUIGlobalStyles };
