import { rem } from 'polished';
import styled from 'styled-components';

const DocumentPageContainer = styled.div`
    width: 100%;
    overflow: auto;
`;

const Image = styled.img`
    width: 100%;
`;

const Container = styled.section`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const SpinnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LoadingTextContainer = styled.section`
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${rem(16)};
    color: ${({ theme }) => theme.color.brightGray};
    line-height: 1.125rem;
`;

export { DocumentPageContainer, Container, Image, SpinnerContainer, LoadingTextContainer };
