import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';

import { healthRecordsApi } from '../health-records-api-config';
import type { EncounterDetail } from 'types';
import { formatEncounterProvider } from 'utils/encounter';

const useEncounterDetails = (encounterId: number): UseQueryResult<EncounterDetail> => {
    const path = `/encounters/${encounterId}`;

    return useQuery(['encounter', encounterId], async () => {
        const response = await healthRecordsApi.get<EncounterDetail>(path);

        return { ...response.data, provider: formatEncounterProvider(response.data.provider) };
    });
};

export { useEncounterDetails };
