import { useUrlState } from '@village/tools';
import { Text } from '@village/ui';
import { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';

import { MessageTypeEntry } from './components/message-type-card';
import * as Styled from './styles';
import { useProviders } from 'data/hooks/use-providers';
import { messageTypeButtonsGenerator } from 'generators';
import { useFeatureFlags } from 'hooks';
import { useMessaging } from 'hooks/use-messaging';
import { useNativeControls } from 'hooks/use-native-controls';
import { RoundedContainer } from 'pages/page-layouts';
import type { MessageType } from 'types';

const SelectMessageTypesContent: FC = () => {
    const { hasFeature } = useFeatureFlags();
    const { data, isLoading } = useProviders();
    const [buttonsEnabled, setButtonsEnabled] = useState(true);
    const [redirectToRouter] = useUrlState('redirect-to');
    const { setMessagingFields } = useMessaging();

    const sortedMessageTypes = useMemo(() => {
        const messageTypes = messageTypeButtonsGenerator(hasFeature);
        const sorted = [...messageTypes].sort((a: MessageType, b: MessageType) => a.order - b.order);
        return sorted;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const disableButtons = (): void => {
        setButtonsEnabled(false);
    };

    useNativeControls({
        ipcEvents: ['showBackButton', 'hideBottomNavBar'],
        source: 'messaging',
    });

    useEffect(() => {
        if (redirectToRouter) {
            setMessagingFields({
                messageInitiatedFrom: redirectToRouter,
            });
        }
    }, [redirectToRouter, setMessagingFields]);

    return (
        <RoundedContainer headerContent="How can we help you today?">
            <Styled.ChatContainer>
                <Text type="body2">
                    Please allow up to 2 business days for a response. In the case of a medical emergency, call 911 immediately.
                </Text>
            </Styled.ChatContainer>
            <Styled.OptionsContainer>
                {sortedMessageTypes.map((messageType) => (
                    <MessageTypeEntry
                        key={messageType.order.toString()}
                        disableButtons={disableButtons}
                        isEnabled={buttonsEnabled}
                        isLoadingProviders={isLoading}
                        messageType={messageType}
                        providersLength={data?.results.length}
                    />
                ))}
            </Styled.OptionsContainer>
        </RoundedContainer>
    );
};

export { SelectMessageTypesContent };
