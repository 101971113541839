import { Button } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

import { Icon } from 'components/icon';
import { ProviderImage } from 'components/provider-image';

interface SubjectCounterProps {
    readonly children: number;
}

const ComposeMessageRoot = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const NewContainer = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const RecipientContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: ${rem(16)};
    height: ${rem(54)};
    padding: 0 ${rem(15)};
`;

const RecipientButton = styled(Button)`
    color: ${({ theme }) => theme.color.brightGray};
    border: ${rem(1)} solid ${({ theme }) => theme.vui.colors.secondary.ghost};
    border-radius: ${rem(20)};
    height: ${rem(40)};

    font-size: ${rem(14)};
    margin-left: ${rem(16)};

    &:hover {
        color: ${({ theme }) => theme.color.brightGray};
        border: ${rem(1)} solid ${({ theme }) => theme.vui.colors.secondary.ghost};
    }
`;

const Image = styled(ProviderImage)`
    margin: 0 ${rem(8)} 0 ${rem(-12)};
`;

const RecipientIcon = styled(Icon)`
    height: ${rem(32)};
    width: ${rem(32)};
    margin: 0 ${rem(8)} 0 ${rem(-12)};
`;

const ColoredIcon = styled(Icon)`
    color: ${({ theme }) => theme.color.lightGray};
    height: ${rem(16)};
    width: ${rem(16)};
    margin: 0 ${rem(-8)} 0 ${rem(8)};
`;

const SubjectContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    height: ${rem(54)};
    padding: 0 ${rem(15)};
    border-top: ${rem(1)} solid ${({ theme }) => theme.color.athensGray};
    border-bottom: ${rem(1)} solid ${({ theme }) => theme.color.athensGray};
`;

const SubjectInput = styled.input`
    border: none;
    width: 100%;
    font-size: ${rem(16)};
    font-weight: 600;
    color: ${({ theme }) => theme.color.brightGray};

    &:focus {
        outline: none;
    }
    ::placeholder {
        color: ${({ theme }) => theme.color.brightGray};
    }
`;

const SubjectCounter = styled.span<SubjectCounterProps>`
    display: ${({ children: remainingCharacters }) => {
        if (remainingCharacters <= 20) {
            return 'block';
        }
        return 'none';
    }};
    color: ${({ theme, children: remainingCharacters }) => {
        if (remainingCharacters > 0) {
            return theme.color.bronzeOrange;
        }
        return theme.color.manatee;
    }};
    margin-left: ${rem(8)};
    font-size: ${rem(12)};
    text-align: right;
`;

const AppointmentContainer = styled.div`
    margin-top: ${rem(32)};
    padding: 0 ${rem(16)};
`;

const CardButtonContainer = styled.div`
    padding: ${rem(16)};
    padding-bottom: 0;
`;

const SpinnerContainer = styled.div`
    margin-right: ${rem(8)};
    height: 1rem;
    width: 1rem;
    & > :first-child {
        height: 1rem !important;
        width: 1rem !important;
    }
`;

export {
    SpinnerContainer,
    CardButtonContainer,
    ComposeMessageRoot,
    NewContainer,
    RecipientContainer,
    RecipientIcon,
    RecipientButton,
    Image,
    ColoredIcon,
    SubjectContainer,
    SubjectInput,
    SubjectCounter,
    AppointmentContainer,
};
