import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { SENTRY_IGNORE_HTTP_ERRORS, UNAUTHENTICATED_HTTP_CODE, UNAUTHORIZED_HTTP_CODE } from './constants';
import { healthRecordsApi } from './health-records-api-config';
import { identityApi } from './identity-api-config';
import { messagingApi } from './messaging-api-config';
import { schedulingApi } from './scheduling-api-config';
import { getCurrentSession } from 'utils/auth';

const useAxiosInterceptor = (): void => {
    const navigate = useNavigate();

    useEffect(() => {
        const goToLoginPage = (): void => {
            if (!window.location.pathname.includes('/auth/login')) {
                navigate(`/auth/login/?redirectTo=${encodeURIComponent(window.location.pathname + window.location.search)}`, {
                    replace: true,
                });
            }
        };

        const goToUnverifiedPage = (): void => {
            const previousPath = window.location.pathname.includes('auth/login') ? '' : window.location.pathname;
            navigate(`/unverified-user/?previousPath=${previousPath}`, { replace: true });
        };

        const apiRequestInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
            const currentSession = await getCurrentSession();
            const idToken = currentSession.getIdToken().getJwtToken();
            // eslint-disable-next-line functional/immutable-data, no-param-reassign
            config.headers = {
                ...config.headers,
                Accept: 'application/json',
                Authorization: `Bearer ${idToken}`,
            };

            return config;
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const apiRequestErrorInterceptor = async (error: any): Promise<any> => {
            Sentry.captureException(error);

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (error?.name !== 'NotActiveWindow') {
                // Sign out to avoid any possibility of infinite redirection.
                await Auth.signOut();
                goToLoginPage();
            }

            return error;
        };

        const apiResponseInterceptor =
            (redirectToLoginPageHttpCode: number, redirectToUnverifiedPageHttpCode?: number) =>
            async (axiosError: Omit<AxiosError, 'request'> & { readonly request?: { readonly response?: string } }) => {
                const { response, message, config = {}, code, request } = axiosError;
                const { url = 'UNKNOWN_URL', baseURL = 'UNKNOWN_BASE_URL', method = 'UNKNOWN_METHOD' } = config;
                const apiErrorMessage = request?.response ?? response?.data;

                if (code === 'ETIMEDOUT' || (response && !SENTRY_IGNORE_HTTP_ERRORS.has(response.status))) {
                    Sentry.captureException({
                        apiErrorMessage,
                        apiUrl: `${baseURL}${url}`,
                        code,
                        message,
                        method,
                        status: response?.status ?? 'UNKNOWN_STATUS',
                    });
                }

                if (response?.status === redirectToLoginPageHttpCode) {
                    await Auth.signOut();
                    goToLoginPage();
                }

                // Ensure we do not redirect on unverified-user page
                if (
                    redirectToUnverifiedPageHttpCode &&
                    response?.status === redirectToUnverifiedPageHttpCode &&
                    !window.location.pathname.includes('unverified-user')
                ) {
                    goToUnverifiedPage();
                }

                // eslint-disable-next-line @typescript-eslint/no-throw-literal
                throw axiosError;
            };

        const interceptorRequestMessaging = messagingApi.interceptors.request.use(
            apiRequestInterceptor,
            apiRequestErrorInterceptor
        );
        const interceptorResponseMessaging = messagingApi.interceptors.response.use(
            (response) => response,
            apiResponseInterceptor(UNAUTHENTICATED_HTTP_CODE, UNAUTHORIZED_HTTP_CODE)
        );
        const interceptorRequestHealthRecords = healthRecordsApi.interceptors.request.use(
            apiRequestInterceptor,
            apiRequestErrorInterceptor
        );
        const interceptorResponseHealthRecords = healthRecordsApi.interceptors.response.use(
            (response) => response,
            apiResponseInterceptor(UNAUTHENTICATED_HTTP_CODE, UNAUTHORIZED_HTTP_CODE)
        );
        const interceptorRequestIdentity = identityApi.interceptors.request.use(
            apiRequestInterceptor,
            apiRequestErrorInterceptor
        );
        const interceptorResponseIdentity = identityApi.interceptors.response.use(
            (response) => response,
            apiResponseInterceptor(UNAUTHORIZED_HTTP_CODE)
        );
        const interceptorRequestScheduling = schedulingApi.interceptors.request.use(
            apiRequestInterceptor,
            apiRequestErrorInterceptor
        );
        const interceptorResponseScheduling = schedulingApi.interceptors.response.use(
            (response) => response,
            apiResponseInterceptor(UNAUTHENTICATED_HTTP_CODE, UNAUTHORIZED_HTTP_CODE)
        );

        return () => {
            messagingApi.interceptors.request.eject(interceptorRequestMessaging);
            messagingApi.interceptors.response.eject(interceptorResponseMessaging);
            healthRecordsApi.interceptors.request.eject(interceptorRequestHealthRecords);
            healthRecordsApi.interceptors.response.eject(interceptorResponseHealthRecords);
            identityApi.interceptors.request.eject(interceptorRequestIdentity);
            identityApi.interceptors.response.eject(interceptorResponseIdentity);
            schedulingApi.interceptors.request.eject(interceptorRequestScheduling);
            schedulingApi.interceptors.response.eject(interceptorResponseScheduling);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export { useAxiosInterceptor };
