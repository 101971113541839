import type { LegacyRef, FC } from 'react';
import { memo, useRef } from 'react';
import type { ListRowProps } from 'react-virtualized';
import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import type { CellMeasurerChildProps } from 'react-virtualized/dist/es/CellMeasurer';

import * as Styled from './styles';
import { MessageEntry } from '../message-entry';
import { MessageLoader } from '../message-loader';
import type { Folder, MessageThread, Provider } from 'types';

interface MessageVirtualizedListProps {
    readonly messageThreads: readonly MessageThread[];
    readonly folder: Folder;
    readonly markUnread?: boolean;
    readonly providersData?: readonly Provider[];
    readonly listRef?: React.RefObject<List>;
    readonly onScroll?: ({ clientHeight, scrollHeight, scrollTop }) => void;
    readonly onRowsRendered?: ({ overscanStartIndex, overscanStopIndex, startIndex, stopIndex }) => void;
    readonly isFetchingNextPage: boolean;
}

const MessageVirtualizedList: FC<MessageVirtualizedListProps> = memo(
    ({ folder, markUnread, messageThreads, providersData, listRef, onScroll, onRowsRendered, isFetchingNextPage }) => {
        const cache = useRef(
            new CellMeasurerCache({
                defaultHeight: 91,
                fixedWidth: true,
            })
        );

        const rowRenderer = ({ index, key, parent, style }: ListRowProps): React.ReactNode => (
            <CellMeasurer key={key} cache={cache.current} columnIndex={0} parent={parent} rowIndex={index}>
                {({ measure, registerChild }: CellMeasurerChildProps) =>
                    isFetchingNextPage && index === messageThreads.length ? (
                        <div ref={registerChild as LegacyRef<HTMLDivElement>} style={style}>
                            <MessageLoader measureListItemHeight={measure} />
                        </div>
                    ) : (
                        <div ref={registerChild as LegacyRef<HTMLDivElement>} style={style}>
                            <MessageEntry
                                folder={folder}
                                markUnread={markUnread}
                                measureListItemHeight={measure}
                                messageThread={messageThreads[index]}
                                providersData={providersData}
                            />
                        </div>
                    )
                }
            </CellMeasurer>
        );

        return (
            <Styled.ListContainer>
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            ref={listRef}
                            deferredMeasurementCache={cache.current}
                            height={height}
                            onRowsRendered={onRowsRendered}
                            onScroll={onScroll}
                            rowCount={messageThreads.length + (isFetchingNextPage ? 1 : 0)}
                            rowHeight={cache.current.rowHeight}
                            rowRenderer={rowRenderer}
                            width={width}
                        />
                    )}
                </AutoSizer>
            </Styled.ListContainer>
        );
    }
);

export { MessageVirtualizedList };
