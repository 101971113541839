import type { FC, PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from 'react-query';
import { useLocation } from 'react-router-dom';

import { Fallback } from './fallback';

const AppErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
    const location = useLocation();
    const { reset } = useQueryErrorResetBoundary();
    const resetKeys = useMemo(() => [location.pathname], [location.pathname]);

    return (
        <ErrorBoundary FallbackComponent={Fallback} onReset={reset} resetKeys={resetKeys}>
            {children}
        </ErrorBoundary>
    );
};

export { AppErrorBoundary };
