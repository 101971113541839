import type { ApiResponse } from 'types/api';
import type { LabResult } from 'types/lab';

const LAB_RESULT_ID_WITHOUT_ATTACHMENT_HEP_C = 1;
const LAB_RESULT_ID_WITH_ATTACHMENT_HEMOGLOBIN = 2;
const LAB_RESULT_ID_WITHOUT_ATTACHMENT_CMP = 3;
const LAB_RESULT_ID_NO_RESULTS = 4;
const LAB_RESULT_ID_NO_PROVIDER = 5;
const LAB_RESULT_ID_NO_PROVIDER_NOTE = 6;

const LABS_SUCCESS_RESPONSE: { readonly d: ApiResponse<LabResult> } = {
    d: {
        __metadata: {
            identity_match_strategy: 'SomeStrategy',
        },
        results: [
            {
                lab_result_date: '2019-02-13',
                lab_result_datetime: '2019-02-13T15:12:00-05:00',
                lab_result_id: LAB_RESULT_ID_WITHOUT_ATTACHMENT_HEP_C,
                lab_result_note: 'When were you treated for hepatitis C?',
                lab_test_name: 'hepatitis C virus RNA, quant, PCR, serum or plasma',
                performing_lab_name: 'Village Medical - Laboratory',
                provider_id: 16,
            },
            {
                lab_result_date: '2019-02-13',
                lab_result_datetime: '2019-02-13T15:12:00-05:00',
                lab_result_id: LAB_RESULT_ID_WITH_ATTACHMENT_HEMOGLOBIN,
                lab_result_note: 'Your diabetes is doing really well. Keep up the good work.',
                lab_test_name: 'HbA1c (hemoglobin A1c), blood',
                performing_lab_name: 'Village Medical - Laboratory',
                provider_id: 32,
            },
            {
                lab_result_date: '2019-02-13',
                lab_result_datetime: '2019-02-13T15:12:00-05:00',
                lab_result_id: LAB_RESULT_ID_WITHOUT_ATTACHMENT_CMP,
                lab_result_note: 'Your electrolytes, kidney function and liver function are normal.',
                lab_test_name: 'CMP, serum or plasma',
                performing_lab_name: 'Village Medical - Laboratory',
                provider_id: 16,
            },
            {
                lab_result_date: '2018-08-19',
                lab_result_datetime: '2018-08-19T14:00:00-05:00',
                lab_result_id: LAB_RESULT_ID_NO_RESULTS,
                lab_result_note: 'Your urine kidney test showed no protein in your urine. This is a good result.',
                lab_test_name: 'microalbumin/creatinine, mass ratio, urine',
                performing_lab_name: 'Village Medical - Laboratory',
                provider_id: 16,
            },
            {
                lab_result_date: '2017-04-25',
                lab_result_datetime: '2017-04-25T15:18:00-05:00',
                lab_result_id: LAB_RESULT_ID_NO_PROVIDER,
                lab_result_note: 'Your urine test was normal',
                lab_test_name: 'urinalysis, dipstick',
                performing_lab_name: 'Village Medical - Laboratory',
                provider_id: null,
            },
            {
                lab_result_date: '2017-02-07',
                lab_result_datetime: '2017-02-07T15:18:00-05:00',
                lab_result_id: LAB_RESULT_ID_NO_PROVIDER_NOTE,
                lab_result_note: null,
                lab_test_name: 'lipase',
                performing_lab_name: 'Village Medical - Laboratory',
                provider_id: null,
            },
        ],
    },
};

export {
    LABS_SUCCESS_RESPONSE,
    LAB_RESULT_ID_WITHOUT_ATTACHMENT_HEP_C,
    LAB_RESULT_ID_WITH_ATTACHMENT_HEMOGLOBIN,
    LAB_RESULT_ID_WITHOUT_ATTACHMENT_CMP,
    LAB_RESULT_ID_NO_RESULTS,
    LAB_RESULT_ID_NO_PROVIDER,
    LAB_RESULT_ID_NO_PROVIDER_NOTE,
};
