import { Skeleton } from '@material-ui/lab';
import { Text } from '@village/ui';
import styled from 'styled-components';

const DateTimeWrapper = styled(Text).attrs(() => ({ type: 'sub1' }))`
    color: ${({ theme }) => theme.color.brightGray};
`;

const ProviderInfoText = styled.div`
    width: 100%;
    color: ${({ theme }) => theme.color.shuttleGray};
`;

const AppointmentInfoText = styled(Text).attrs(() => ({ type: 'body1' }))`
    width: 100%;
    color: ${({ theme }) => theme.color.shuttleGray};
`;

const LoadingItem = styled.div`
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.color.white};
    padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(2)}`};
    margin-bottom: 0.875rem;
`;

const TextSkeleton = styled.div`
    padding-left: 1rem;
    width: 85%;
`;

const SkeletonProviderPhoto = styled(Skeleton)`
    min-width: ${({ theme }) => theme.spacing(7)};
`;

export { DateTimeWrapper, ProviderInfoText, AppointmentInfoText, LoadingItem, TextSkeleton, SkeletonProviderPhoto };
