import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const MessageHeader = styled.div`
    display: flex;
    flex-direction: column;
`;

const MessageText = styled(Text)`
    padding-top: 1rem;
    color: ${({ theme }) => theme.color.shark};
`;

const Container = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.color.flashWhite};
    &:last-child {
        border: none;
    }
`;

const MessageContents = styled.div`
    padding: ${rem(20)} 0;
`;

const GrayText = styled(Text)`
    color: ${({ theme }) => theme.color.shuttleGray};
`;

const InlineText = styled.div`
    display: flex;
    align-items: center;
`;

const Sender = styled(Text)`
    color: ${({ theme }) => theme.color.shark};
    margin-right: 0.5rem;
`;

export { Container, MessageContents, MessageHeader, MessageText, GrayText, InlineText, Sender };
