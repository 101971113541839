import { rem } from 'polished';
import styled from 'styled-components';

const Page = styled.section`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 ${rem(16)};
`;

const LetterEntryList = styled.div`
    margin-left: ${rem(-16)};
    margin-right: ${rem(-16)};
    height: 100%;
`;

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: ${rem(16)};
`;

export { Page, LetterEntryList, SpinnerContainer };
